import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// Importing observable that enables to return something that can be subscribed to
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TcgPlayerService {

  // Creating a variable to hold the information
  public TCGPlayerResponse;

  constructor(public http: HttpClient) {
    // Fetch the tcg_token json to be able to use the latest bearer token
    this.http.get<any>('assets/data/pricing/tcg_token.json')
    .subscribe(data => {
      this.TCGPlayerResponse = data;
    /* Should contain the following:
      "access_token":"pykPdLqMAc_jYBkPQuvY747-...-ns7zwNHM2aeuz-WPOErio7kpg",
      "token_type":"bearer",
      "expires_in":1209599,
      "userName":"d28a5766-d5c4-462b-986b-622b153e66ed",
      ".issued":"Mon, 16 Jul 2018 20:56:56 GMT",
      ".expires":"Mon, 30 Jul 2018 20:56:56 GMT"
    */
       // console.log('Config: ' + this.TCGPlayerResponse.access_token);
    });
  }

  getProductPrice(productId): Observable<any> {

     const headers = new HttpHeaders()
     .set('Authorization', 'bearer ' + this.TCGPlayerResponse.access_token) // bearer_token
     .append('Accept', 'application/json');

     // Returns an observable to subscribe to
     return this.http.get<any>('https://api.tcgplayer.com/v1.17.0/pricing/product/' + productId, {headers: headers});
  }
}
