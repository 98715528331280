import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an OR-pipe,
// which means that any filters coming in will generate total sum of all cards in the selection(s)
// This is intended for everyhing that is NOT card abilities prefixed with "_"

@Pipe({
    name: 'editionSelectionFilter',
    pure: true
})
export class EditionSelectionFilterPipe implements PipeTransform {
  transform(items: MtgCard[], counter?: number): any {
    if (!items) {
      return items;
    }

    // Loop through the card array to identify all editions, and return them in a new array back
    const results: any = [];

    items.forEach(function(item) {
        if (item._basicLand === false && // Don't include basic lands
            item._lastPrint === true && // Only include the sets with the latest print of the card
            (item.editionType === 'expansion' || // only include expansion sets OR
            item.editionType === 'starter' || // Starter packs OR
            item.editionType === 'core' || // Core sets OR
            item.editionType === 'commander' || // Commander sets OR
            item.editionType === 'draft_innovation')) { // Draft Innovation (which can be legal in legacy)
            if (results.indexOf(item.editionName) === -1) { // If item is missing from the results
                // Add it
                results.push(item.editionName);
            }
        }
    });
    // console.log(results);
    // If array contains Unlimited Edition
    if (results.indexOf('Unlimited Edition') !== -1) { // If item is in the array
        // also add Limited Edition Alpha, and Limited Edition Beta
        results.push('Limited Edition Beta');
        results.push('Limited Edition Alpha');
    }
    return results;
    }
}
