import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

// Import translation service
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      // this.statusBar.hide();

      // The language defined here will be used as a fallback when no translation is found in the current language
      this.translate.setDefaultLang('en');

      const browserLang = this.translate.getBrowserLang();
      // console.log(browserLang);
      const browserLangMain = browserLang.substring(0, 2);
      // console.log(browserLangMain);

      // use browser language if applicable, fallback is used when no translations are found there
      if (['ch', 'de', 'es', 'fr', 'it', 'jp', 'ks', 'pt', 'ru'].indexOf(browserLangMain) !== -1) {
        this.translate.use(browserLangMain);
      } else {
        this.translate.use('en'); // Set fallback language here
      }
    });
  }
}
