<ion-content class="card-modal-container" (press)="closeModal(false)">
    <div id="card-modal" class="card-modal">
      <button ion-button icon-only clear small (click)="closeModal(false)" class="card-modal-close">
          <ion-icon name="md-close"></ion-icon>
      </button>
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-md="5">
            <ion-row justify-content-center>
              <div>
                <ion-card id="card-modal-card1" class="card-modal-card1">
                  <div class="card-text-modal" (click)="showAlternateCard()">
                    <p class="card-header-row">
                      {{modalCard[name_lang] !== '' ? modalCard[name_lang] : modalCard.name}} <span innerHTML="{{modalCard.manaCost | toManaIcon}}"></span>
                    </p>
                    <p class="card-type-row">
                      {{modalCard[cardType_lang] !== '' ? modalCard[cardType_lang] : modalCard.cardType}} <span innerHTML="<i class='ss ss-{{modalCard.edition | lowercase}} ss-{{modalCard.rarity | lowercase}} ss-grad ss-fw'></i>"></span>
                    </p>
                    <p class="card-text-row">
                      {{modalCard[cleantext_lang] !== '' ? modalCard[cleantext_lang] : modalCard.cleantext}}
                    </p>
                    <br>
                    <p class="card-pt">
                      {{modalCard.pt}}{{modalCard.loyalty}}
                    </p>
                  </div>
                  <div class="card-image-modal" (click)="showAlternateCard()">
                    <ion-img src={{modalCard[imagePath_lang]}}
                    title="{{modalCard[name_lang] !== '' ? modalCard[name_lang] : modalCard.name}} - {{modalCard.edition}}">
                    </ion-img>
                  </div>
                  <div class="bottom-star-modal" (click)="closeModal(true)" *ngIf="_showSaveButton"> 
                      <i class="ss ss-s99 ss-common ss-grad" *ngIf="!modalCard._isOnWishList"></i>
                      <i class="ss ss-s99 ss-rare ss-grad" *ngIf="modalCard._isOnWishList"></i>
                  </div>
                </ion-card>
                <div class="card-price" *ngIf = "_showPriceTag">
                  <a id="tcgLink" href={{cardShopLink}} target="_blank" >
                    ${{ cardPrice }}
                  </a>
                  <a id="ebayLink" href={{ebayShopLink}} target="_blank" >
                    eBay
                  </a>
                </div>
              </div>
            </ion-row>
          </ion-col>
          <ion-col size-md="2" class="hidden-sm-down">
            <br>
            <div class="buttonGlowRed" (click)="showCardText()">
              <label title="Text" class="modalView" [ngClass]="{modalViewActive: card2view === 'cardText'}">
                <div>
                  <ion-icon name="list-box"></ion-icon>
                </div>{{ 'ModalText' | translate }}
              </label>
            </div>
            <div class="buttonGlowRed" (click)="showAltArt()" *ngIf="cardCollection?.length > 10000 && modalCard.printings?.length > 1">
              <label title="Prints" class="modalView" [ngClass]="{modalViewActive: card2view === 'altArt'}">
                <div>
                  <ion-icon name="images"></ion-icon>
                </div>{{ 'ModalPrints' | translate }}
              </label>
            </div>
            <div class="buttonGlowRed" (click)="showRulings()" *ngIf="modalCard.rulings?.length > 0">
              <label title="Rules" class="modalView" [ngClass]="{modalViewActive: card2view === 'cardRulings'}">
                <div>
                  <ion-icon name="clipboard"></ion-icon>
                </div>{{ 'ModalRules' | translate }}
              </label>
            </div>
            <div class="buttonGlowRed" (click)="showSimilar()">
              <label title="Similar" class="modalView" [ngClass]="{modalViewActive: card2view === 'similarCards'}">
                <div>
                  <ion-icon name="copy"></ion-icon>
                </div>{{ 'ModalSimilar' | translate }}
              </label>
            </div>
            <div class="buttonGlowRed" (click)="showPlayedWith()" *ngIf="synergyCards?.length > 1">
              <label title="Played With" class="modalView" [ngClass]="{modalViewActive: card2view === 'playedWith'}">
                <div>
                  <ion-icon name="trophy"></ion-icon>
                </div>{{ 'ModalPlayed' | translate }}
              </label>
            </div>
          </ion-col>
          <ion-col size="12" class="hidden-md-up" *ngIf="(cardCollection?.length > 10000 && modalCard.printings?.length > 1) || (modalCard.rulings?.length > 0) || (synergyCards?.length > 1)">
            <ion-row justify-content-center>
                <div (click)="showCardText()">
                    <label title="Text" class="modalView-singleCol" [ngClass]="{modalViewActive: card2view === 'cardText'}">
                      <div>
                        <ion-icon name="list-box"></ion-icon>
                      </div>
                    </label>
                  </div>
                  <div (click)="showAltArt()" *ngIf="cardCollection?.length > 10000 && modalCard.printings?.length > 1">
                    <label title="Prints" class="modalView-singleCol" [ngClass]="{modalViewActive: card2view === 'altArt'}">
                      <div>
                        <ion-icon name="images"></ion-icon>
                      </div>
                    </label>
                  </div>
                  <div (click)="showRulings()" *ngIf="modalCard.rulings?.length > 0">
                    <label title="Rules" class="modalView-singleCol" [ngClass]="{modalViewActive: card2view === 'cardRulings'}">
                      <div>
                        <ion-icon name="clipboard"></ion-icon>
                      </div>
                    </label>
                  </div>
                  <div (click)="showPlayedWith()" *ngIf="synergyCards?.length > 1">
                    <label title="Played With" class="modalView-singleCol" [ngClass]="{modalViewActive: card2view === 'playedWith'}">
                      <div>
                        <ion-icon name="trophy"></ion-icon>
                      </div>
                    </label>
                  </div>
            </ion-row>
          </ion-col>
          <ion-col size="12" size-md="5">
            <ion-row justify-content-center>
              <div *ngIf="card2view === 'cardText'">
                <ion-card id="card-modal-card2" class="card-modal-card2" padding>
                  <p class="card-header-row">
                    {{modalCard[name_lang] !== '' ? modalCard[name_lang] : modalCard.name}} <span innerHTML="{{modalCard.manaCost | toManaIcon}}"></span>
                  </p>
                  <p class="card-type-row">
                    {{modalCard[cardType_lang] !== '' ? modalCard[cardType_lang] : modalCard.cardType}} <span innerHTML="<i class='ss ss-{{modalCard.edition | lowercase}} ss-{{modalCard.rarity | lowercase}} ss-grad ss-fw'></i>"></span>
                  </p>
                  <p class="card-text-row">
                    {{modalCard[cleantext_lang] !== '' ? modalCard[cleantext_lang] : modalCard.cleantext}}
                  </p>
                  <br>
                  <p class="card-pt">
                    {{modalCard.pt}}{{modalCard.loyalty}}
                  </p>
                </ion-card>
              </div>
              <div *ngIf="card2view === 'altArt'">
                <ion-card id="card-modal-card2" class="card-modal-card2">
                  <div *ngFor="let print of modalCard.printings">
                      <div class="card-list-item" (click)="identifyCard(modalCard.name,print)">
                          <div innerHTML="<i class='ss ss-{{print | lowercase}} ss-{{modalCard.rarity | lowercase}} ss-grad ss-2x ss-fw'></i> &nbsp {{print}} "></div>
                      </div>
                  </div>
                </ion-card>
              </div>
              <div *ngIf="card2view === 'cardRulings'">
                <ion-card id="card-modal-card2" class="card-modal-card2" padding>
                  <div *ngFor="let rule of modalCard.rulings">
                    <p class="card-rule-text">
                      {{rule.text}}
                    </p>
                    <p class="card-rule-header">
                      {{rule.date}}
                    </p>
                    <br>
                  </div>
                  <p *ngIf="!modalCard.rulings">{{ 'ModalNoRules' | translate }}</p>
                </ion-card>
              </div>
              <div *ngIf="card2view === 'similarCards'">
                <ion-card id="card-modal-card2" class="card-modal-card2">
                  <div *ngFor="let similarCard of similarCardCollection | getSimilarCards:modalCard">
                      <div class="card-list-item" (click)="identifyCard(similarCard.name, similarCard.print)">
                          <label>{{similarCard.name}}</label>
                      </div>
                  </div>
                </ion-card>
              </div>
              <div *ngIf="card2view === 'playedWith'">
                <ion-card id="card-modal-card2" class="card-modal-card2">
                  <div *ngFor="let synergyCard of synergyCards">
                      <div class="card-list-item" (click)="identifyCard(synergyCard)">
                          <label>{{synergyCard}}</label>
                      </div>
                  </div>
                </ion-card>
              </div>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>