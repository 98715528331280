import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getCardByName',
    pure: true
})
export class GetCardByNamePipe implements PipeTransform {
  transform(items: MtgCard[], name: string, edition?: string): MtgCard {
    if (!items || !name) {
        // If either input is null, return nothing
        return null;
    }

    // Loop through the cardcollection
    // If edition is given, find the card with the given name and edition
    if (edition) { // If the edition is given
        for (let i = 0, len_a = items.length; i < len_a; i++) {
            if (items[i].name === name) {
                if (items[i].edition === edition) {
                    return items[i];
                }
            }
        }
      } else {
        // If there is no edition given, find the first card with the given name
        for (let j = 0, len_b = items.length; j < len_b; j++) {
            if (items[j].name === name) {
                return items[j];
            }
        }
      }
  }
}
