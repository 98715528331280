import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
// Language support
import { TranslateModule } from '@ngx-translate/core';
// Import all components
import { ComponentsModule } from './../../components/components';

// Import the page
import { CardModalPage } from './card-modal.page';

const routes: Routes = [
  {
    path: '',
    component: CardModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ComponentsModule
  ],
  declarations: [CardModalPage]
})
export class CardModalPageModule {}
