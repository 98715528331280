import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name : 'cardSearchPipe',
  pure: true
})
export class CardSearchPipe implements PipeTransform {
  public transform(items: MtgCard[], key: string, term: string) {

    return items.filter((item) => {
      if (item.hasOwnProperty(key)) {
        if (term) {
          // Escape sensitive regex characters, then replace wildcards with their respective regex counterparts
          const newterm = term
          .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
          .replace(/\\\*/g, '.*')
          .replace(/\\\?/g, '.')
          .replace(/\\\+/g, '\\+');
          // console.log(newterm);
          const regExp = new RegExp('' + newterm, 'gi');
          return regExp.test(item[key]);
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  }
}
