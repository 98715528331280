import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This pipe is intended to filer the incoming array of cards by seeing if any of them
// has the value 'true' in the filtering field string

// It should return either true or false to be used in an ng-if statement

@Pipe({
    name: 'showAbilityFilter',
    pure: true
})
export class ShowAbilityFilterPipe implements PipeTransform {
  transform(items: MtgCard[], filter: string, format: string): boolean {
    if (!items || !filter || !format || format === 'Legacy' || format === 'Vintage') {
      // If either input is null or format filter is set to Legacy or Vintage
        return true;
    }

    // console.log('showAbilityFilter updated');
    // console.log(filter);

    for (let i = 0, len = items.length; i < len; i++) {
        if (items[i][filter] === true) {
            // If any looped card attribute field contains true, return true
            // console.log('MATCH');
            return true;
        }
      }

    // If it hasn't returned true already, return false as nothing was found
    // console.log('NO MATCH');
    return false;
  }
}
