import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an OR-pipe,
// which means that any filters coming in will generate total sum of all cards in the selection(s)
// This is intended for everyhing that is NOT card abilities prefixed with "_"

@Pipe({
    name: 'mainTypeFilter',
    pure: true
})
export class MainTypeFilterPipe implements PipeTransform {
    transform(items: MtgCard[], filter: MtgCard, counter?: number): MtgCard[] {
        if (!items || !filter.mainType.join()) {
            return items;
        }
        // filter items array, items which match and return true will be kept, false will be filtered out
        // Create empty results array
        const results: MtgCard[] = [];

        items.forEach(function (item) {
            if (filter['mainType']) { // Apply filter only to c_filter.mainType
                // Do a for each on the card array here
                item['mainType'].forEach(function (type) {
                    // Followed by a for each on the filter as it's also an array
                    filter.mainType.forEach(function (fType) {
                        // console.log('Filter: '+mainType, 'Card: '+type)
                        if (fType.toLowerCase() !== type.toLowerCase()) {
                            // If the cards mainType name is not found in the filters mainType sting, do nothing
                        } else {
                            results.push(item); // If item matches search, add it to results array
                        }
                    });
                });
            }
        });
        // console.log(results);
        // console.log('mainTypeFilter updated');
        return results;
    }
}
