import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

// Default maxAge is a day (60*60*24)
let maxAge = 86400;

@Injectable()
export class RequestCache  {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    // console.log(url);

    if (!cached) {
      return undefined;
    }

    console.log('Found cache for: ' + url);

    // Update maxAge depending on what http request was made
    maxAge =
    url.match(/\b(cardCollection)\b/g) != null ? 7776000 // 86400 * 90 for cardCollection
    : url.match(/\b(basket)\b/g) != null ? 604800 // 86400 * 7 for played w/
    : url.match(/\b(tcgplayer)\b/g) != null ? 86400 // 86400 * 1 for prices
    : url.match(/\b(tcg_token)\b/g) != null ? 3600  // 3600 for access token
    : 86400; // 86400 in all other cases

    console.log(url + ': ' + maxAge);

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
