// Default

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

// External components (imports)
// Importing Http that enables http get/set requests
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

// Importing http interceptor and caching services and angular web worker service
import { RequestCache } from './services/request-cache.service';
import { CachingInterceptor } from './services/caching-interceptor.service';
import { WebworkerService } from './services/web-worker.service';

// Importing the angular service worker
import { ServiceWorkerModule } from '@angular/service-worker';
// Importing the angular storage service module
//import { StorageServiceModule } from 'ngx-webstorage-service';
// Importing the ionic storage service module
import { IonicStorageModule } from '@ionic/storage';
// Translation for i18n standard
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Services (providers)
import { MtgDataService } from './services/mtg-data.service';
import { TcgPlayerService } from './services/tcg-player.service';
import { CollectionSortService } from './services/collection-sort.service';
import { WishlistHandlerService } from './services/wishlist-handler.service';

// Pages (declarations)
import { AppComponent } from './app.component';

// Pages (imports)
import { HomePageModule } from './pages/home/home.module';
import { CardModalPageModule } from './pages/card-modal/card-modal.module';

// Components (imports)
import { ComponentsModule } from './components/components';
import { environment } from '../environments/environment';

// Translation file locations
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        HomePageModule,
        CardModalPageModule,
        ComponentsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '_webDb',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        //StatusBar,
        //SplashScreen,
        MtgDataService,
        TcgPlayerService,
        CollectionSortService,
        WishlistHandlerService,
        RequestCache,
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        WebworkerService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
