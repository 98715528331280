import { MtgCard } from '../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getSimilarCards',
    pure: true
})
export class GetSimilarCardsPipe implements PipeTransform {
  transform(items: MtgCard[], compareCard: MtgCard): MtgCard[] {
    if (!items || !compareCard) {
        // If either input is null, return nothing
        return null;
    }


    // Code to pick a number of specified properties from a javascript object
    function pickProperties(o, ...fields) {
        return fields.reduce((a, x) => {
            if (o.hasOwnProperty(x)) {
                a[x] = o[x];
            }
            return a;
        }, {});
    }

    // Code to calculate similarity between objects based on their properties
    const findSimilarity = (unfilteredFirst, unfilteredSecond) => {
        // First filter both objects to only their properties that has values
        const first = Object.keys(unfilteredFirst).filter(function(key) {
            // Return all properties whose values return true when evaluated
            return unfilteredFirst[key];
        });
        const second = Object.keys(unfilteredSecond).filter(function(key) {
            // Return all properties whose values return true when evaluated
            return unfilteredSecond[key];
        });

        // Then determine which object has more properties
        const firstLength = Object.keys(first).length;
        const secondLength = Object.keys(second).length;
        const smaller = firstLength < secondLength ? first : second;
        const greater = smaller === first ? second : first;
        // Reduce the smaller object down to it's respective keys
        const count = Object.keys(smaller).reduce((acc, val) => {
          // If the larger object contains the same key
           if (Object.keys(greater).includes(val)) {
             // And the same property name for that key
              if (greater[val] === smaller[val]) {
                // And the same value for that property
                if (unfilteredFirst[greater[val]] === unfilteredSecond[greater[val]]) {

                    // console.log(greater[val], unfilteredFirst[greater[val]]);
                    // If value is the same in both objects, accumulate the value by 1
                    return ++acc;
                }
              }
           }
           // If not, return the value un-accumulated
           return acc;
        }, 0);
        // Once all key value pairs has been compared,
        // check how many values are the same versus the total length of the smaller object
        // return (count / Math.min(firstLength, secondLength)) * 100;
        return count;
     };


    // Inspiration:
    // https://stackoverflow.com/questions/58490921/comparing-array-of-objects-and-assigning-similarity-score

    // Create a new empty array of MtgCard objects
    const outputCollection: MtgCard[] = [];

    // Use the field MtgCard.price to assign match values
    // It can later be used as a sorting property

    // Create a deep copy of the compareCard, to pick properties to match on
    const compareCard_copy = JSON.parse(JSON.stringify(compareCard));

    // OBS! The more generic the attribute, the less points it's worth
    // For that reason, evergreen is always worth less than non-evergreen

    // Create the set of attributes that should be worth 1 point if matched
    // These are high level card attributes that might tip the scales
    const compareCard1 = pickProperties(
        compareCard_copy,
        'cmc', 'mainType', 'permanent', 'power', 'toughness', 'loyalty',
        '_hasXCost', '_hasHCost', '_hasPCost'
        );

    // Create the set of attributes that should be worth 2 points if matched
    // These are the Evergreen keywords and ability types
    const compareCard2 = pickProperties(
        compareCard_copy,
        '_deathtouch', '_defender', '_double_strike', '_enchant', '_equip', '_first_strike',
        '_flash', '_flying', '_haste', '_hexproof', '_indestructible', '_lifelink',
        '_menace', '_prowess', '_reach', '_trample', '_vigilance', '_ward',
        '_activated_ability', '_casting_ability', '_passive_ability', '_triggered_ability'
        );

    // Create the set of attributes that should be worth 3 points if matched
    // These are non-evergreen keywords and ability words
    const compareCard3 = pickProperties(
        compareCard_copy,
        // Non-evergreen keywords
        '_absorb', '_affinity', '_afflict', '_afterlife', '_aftermath', '_amplify',
        '_annihilator', '_ascend', '_aura_swap', '_awaken', '_banding', '_battle_cry',
        '_bestow', '_bloodthirst', '_boast', '_bushido', '_buyback', '_cascade',
        '_champion', '_changeling', '_cipher', '_companion', '_conspire', '_convoke',
        '_crew', '_cumulative_upkeep', '_cycling', '_dash', '_daybound', '_decayed',
        '_delve', '_dethrone', '_devoid', '_devour', '_disturb', '_dredge', '_echo',
        '_embalm', '_emerge', '_entwine', '_epic', '_escalate', '_escape', '_eternalize',
        '_evoke', '_evolve', '_exalted', '_exploit', '_extort', '_fabricate', '_fading',
        '_fear', '_flanking', '_flashback', '_forecast', '_foretell', '_fortify',
        '_frenzy', '_fuse', '_graft', '_fravestorm', '_haunt', '_hideaway', '_horesemanship',
        '_improvise', '_infect', '_ingest', '_intimidate', '_jump_start', '_kicker',
        '_landwalk', '_level_up', '_living_weapon', '_madness', '_melee', '_mentor',
        '_miracle', '_modular', '_morph', '_mutate', '_myriad', '_nightbound', '_ninjutsu',
        '_offering', '_outlast', '_overload', '_partner', '_persist', '_phasing', '_poisonus',
        '_protection', '_provoke', '_prowl', '_rampage', '_rebound', '_recover', '_reinforce',
        '_renown', '_replicate', '_retrace', '_riot', '_ripple', '_scavenge', '_shadow',
        '_shroud', '_skulk', '_soulbond', '_soulshift', '_spectacle', '_splice', '_split_second',
        '_storm', '_sunburst', '_surge', '_suspend', '_totem_armor', '_transfigure', '_transmute',
        '_tribute', '_undaunted', '_undying', '_unearth', '_unleash', '_vanishing', '_wither',
        // Ability words
        '_adamant', '_addendum', '_battalion', '_bloodrush', '_channel', '_chroma', '_cohort',
        '_constellation', '_converge', '_coven', '_delirium', '_domain', '_eminence', '_enrage',
        '_fateful_hour', '_ferocious', '_formidable', '_grandeur', '_hellbent', '_heroic', '_imprint',
        '_inspired', '_join_forces', '_kinship', '_landfall', '_liutenant', '_magecraft',
        '_metalcraft', '_morbid', '_pack_tactics', '_radiance', '_raid', '_rally', '_revolt',
        '_spell_mastery', '_strive', '_sweep', '_tempting_offer', '_threshold', '_undergrowth'
        );

    // Create the set of attributes that should be worth 4 points if matched
    // These are the Evergreen player actions
    const compareCard4 = pickProperties(
        compareCard_copy,
        '_activate', '_attach', '_cast', '_counter', '_copy', '_create', '_cycle',
        '_destroy', '_discard', '_double', '_draw', '_exchange', '_exile', '_mill',
        '_play', '_reveal', '_sacrifice', '_scry', '_search', '_shuffle', '_tap', '_untap'
        );

    // Create the set of attributes that should be worth 5 points if matched
    // These are non-evergreen player actions
    const compareCard5 = pickProperties(
        compareCard_copy,
        '_adapt', '_amass', '_bolster', '_clash', '_detain', '_exert', '_explore',
        '_fateseal', '_goad', '_investigate', '_learn', '_manifest', '_meld',
        '_monstrosity', '_populate', '_proliferate', '_regenerate', '_roll', '_support',
        '_surveil', '_transform', '_venture', '_vote'
        );


    // Create the set of attributes that should be worth 10 points if matched
    // These are the specified misc mechanics
    // and very specific cards, such as sagas, equipment and vehicle
    const compareCard10 = pickProperties(
        compareCard_copy,
        '_alt_win', '_alt_loss', '_anti_discard', '_bounce', '_burn', '_cantrip',
        '_counterspell', '_enter_the_battlefield', '_looting', '_self_mill', '_rummaging',
        '_stat_increase', '_stat_reduce', '_tutor', '_give_mana', '_give_colorless',
        '_plus1_counters', '_minus1_counters', '_spot_removal', '_mass_removal',
        '_1_removal', '_2_removal', '_3_removal', '_4_removal', '_5_removal',
        '_saga', '_equipment', '_vehicle'
        );

    // Create the set of attributes that should be worth 100 points if matched
    // These are the specified misc mechanics
    const compareCard100 = pickProperties(
        compareCard_copy,
        'cleantext', '_basicLand'
        );


    // Loop through the cardCollection(items) for each item
    for (let j = 0, len_a = items.length; j < len_a; j++) {

        // 0. Set the matchValue (price) to 0 as a default
        items[j].price = 0;

        // 1. Check if the item has the same name as the compareCard
        // If yes, skip to the next card
        if (items[j].name === compareCard.name) {
            // Do nothing, but skip to the next item
        // If no: continue to 2
        } else {

            // 2. Create a deep copy of the item as a referenceCard, to pick properties to match on
            const referenceCard_copy = JSON.parse(JSON.stringify(items[j]));

            // 3. Split each card into several parts with different sets of attributes
            // based on their value of similarity, and add their score in the end to
            // create the total similarity score for the card

            // Create the set of attributes that should be worth 1 point if matched
            // These are card generic attributes that might tip the scales
            const referenceCard1 = pickProperties(
                referenceCard_copy,
                'cmc', 'mainType', 'permanent', 'power', 'toughness', 'loyalty',
                '_hasXCost', '_hasHCost', '_hasPCost'
                );

            // Create the set of attributes that should be worth 2 points if matched
            // These are the Evergreen keywords and ability types
            const referenceCard2 = pickProperties(
                referenceCard_copy,
                '_deathtouch', '_defender', '_double_strike', '_enchant', '_equip', '_first_strike',
                '_flash', '_flying', '_haste', '_hexproof', '_indestructible', '_lifelink',
                '_menace', '_prowess', '_reach', '_trample', '_vigilance', '_ward',
                '_activated_ability', '_casting_ability', '_passive_ability', '_triggered_ability'
                );

            // Create the set of attributes that should be worth 3 points if matched
            // These are non-evergreen keywords and ability words
            const referenceCard3 = pickProperties(
                referenceCard_copy,
                // Non-evergreen keywords
                '_absorb', '_affinity', '_afflict', '_afterlife', '_aftermath', '_amplify',
                '_annihilator', '_ascend', '_aura_swap', '_awaken', '_banding', '_battle_cry',
                '_bestow', '_bloodthirst', '_boast', '_bushido', '_buyback', '_cascade',
                '_champion', '_changeling', '_cipher', '_companion', '_conspire', '_convoke',
                '_crew', '_cumulative_upkeep', '_cycling', '_dash', '_daybound', '_decayed',
                '_delve', '_dethrone', '_devoid', '_devour', '_disturb', '_dredge', '_echo',
                '_embalm', '_emerge', '_entwine', '_epic', '_escalate', '_escape', '_eternalize',
                '_evoke', '_evolve', '_exalted', '_exploit', '_extort', '_fabricate', '_fading',
                '_fear', '_flanking', '_flashback', '_forecast', '_foretell', '_fortify',
                '_frenzy', '_fuse', '_graft', '_fravestorm', '_haunt', '_hideaway', '_horesemanship',
                '_improvise', '_infect', '_ingest', '_intimidate', '_jump_start', '_kicker',
                '_landwalk', '_level_up', '_living_weapon', '_madness', '_melee', '_mentor',
                '_miracle', '_modular', '_morph', '_mutate', '_myriad', '_nightbound', '_ninjutsu',
                '_offering', '_outlast', '_overload', '_partner', '_persist', '_phasing', '_poisonus',
                '_protection', '_provoke', '_prowl', '_rampage', '_rebound', '_recover', '_reinforce',
                '_renown', '_replicate', '_retrace', '_riot', '_ripple', '_scavenge', '_shadow',
                '_shroud', '_skulk', '_soulbond', '_soulshift', '_spectacle', '_splice', '_split_second',
                '_storm', '_sunburst', '_surge', '_suspend', '_totem_armor', '_transfigure', '_transmute',
                '_tribute', '_undaunted', '_undying', '_unearth', '_unleash', '_vanishing', '_wither',
                // Ability words
                '_adamant', '_addendum', '_battalion', '_bloodrush', '_channel', '_chroma', '_cohort',
                '_constellation', '_converge', '_coven', '_delirium', '_domain', '_eminence', '_enrage',
                '_fateful_hour', '_ferocious', '_formidable', '_grandeur', '_hellbent', '_heroic', '_imprint',
                '_inspired', '_join_forces', '_kinship', '_landfall', '_liutenant', '_magecraft',
                '_metalcraft', '_morbid', '_pack_tactics', '_radiance', '_raid', '_rally', '_revolt',
                '_spell_mastery', '_strive', '_sweep', '_tempting_offer', '_threshold', '_undergrowth'
                );

            // Create the set of attributes that should be worth 4 points if matched
            // These are the Evergreen player actions
            const referenceCard4 = pickProperties(
                referenceCard_copy,
                '_activate', '_attach', '_cast', '_counter', '_copy', '_create', '_cycle',
                '_destroy', '_discard', '_double', '_draw', '_exchange', '_exile', '_mill',
                '_play', '_reveal', '_sacrifice', '_scry', '_search', '_shuffle', '_tap', '_untap'
                );

            // Create the set of attributes that should be worth 5 points if matched
            // These are non-evergreen player actions
            const referenceCard5 = pickProperties(
                referenceCard_copy,
                '_adapt', '_amass', '_bolster', '_clash', '_detain', '_exert', '_explore',
                '_fateseal', '_goad', '_investigate', '_learn', '_manifest', '_meld',
                '_monstrosity', '_populate', '_proliferate', '_regenerate', '_roll', '_support',
                '_surveil', '_transform', '_venture', '_vote'
                );


            // Create the set of attributes that should be worth 10 points if matched
            // These are the specified misc mechanics
            // and very specific cards, such as sagas, equipment and vehicle
            const referenceCard10 = pickProperties(
                referenceCard_copy,
                '_alt_win', '_alt_loss', '_anti_discard', '_bounce', '_burn', '_cantrip',
                '_counterspell', '_enter_the_battlefield', '_looting', '_self_mill', '_rummaging',
                '_stat_increase', '_stat_reduce', '_tutor', '_give_mana', '_give_colorless',
                '_plus1_counters', '_minus1_counters', '_spot_removal', '_mass_removal',
                '_1_removal', '_2_removal', '_3_removal', '_4_removal', '_5_removal',
                '_saga', '_equipment', '_vehicle'
                );

            // Create the set of attributes that should be worth 100 points if matched
            // These are the specified misc mechanics
            const referenceCard100 = pickProperties(
                referenceCard_copy,
                'cleantext', '_basicLand'
                );

            // 4. Compare the cardCollection item to the compareCard, and output the value as price
            items[j].price =
            1 * (findSimilarity(referenceCard1, compareCard1)) +
            2 * (findSimilarity(referenceCard2, compareCard2)) +
            3 * (findSimilarity(referenceCard3, compareCard3)) +
            4 * (findSimilarity(referenceCard4, compareCard4)) +
            5 * (findSimilarity(referenceCard5, compareCard5)) +
            10 * (findSimilarity(referenceCard10, compareCard10)) +
            100 * (findSimilarity(referenceCard100, compareCard100))
            ;
            // console.log(items[j].name + ' vs ' + compareCard.name + ':', items[j].price);
        }

        // 5. Push it into the output array if similarity is significant enough
        if (items[j].price > 6) {
            outputCollection.push(items[j]);
        }

        // Print out which cards are being compared
        // console.log(items[j].name, compareCard.name);

    }

    // Sort the outputCollection based on price, descending order
    outputCollection.sort((a, b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));

    // Print out the sorted cardCollection to retun
    // console.log(outputCollection);

    // Return the outputCollection as the cardCollection to show in the modalView of similar cards
    return outputCollection;
  }
}
