import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'basketFilter',
    pure: true
})
export class BasketFilterPipe implements PipeTransform {
  transform(basket: any, name: string, format: string): string[] {
    if (!basket || !name || !format) {
        // If either input is null, return an empty array
        // return [''];
    }

    let results: string[] = [''];

    // console.log(basket.Analysis.length);

    for (let i = 0, len = basket.Analysis.length; i < len ; i++) {
        if (basket.Analysis[i].Format.toLowerCase() === format.toLowerCase() &&
        basket.Analysis[i].Card.toLowerCase() === name.toLowerCase()) {
            results = basket.Analysis[i].Above20;
            // console.log(i);
        }
    }
    // console.log(results);
    return results;

    }
}
