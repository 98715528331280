import { Component, OnInit } from '@angular/core';

// Importing navController and navParams
import { NavController, NavParams, ModalController } from '@ionic/angular';

// Import interface
import { MtgCard } from './../../models/mtgcard.model';

// Provider of TCG Player price info
import { TcgPlayerService } from './../../services/tcg-player.service';

// Importing pipes & filters
import { BasketFilterPipe } from './../../components/pipes/filters/basket-filter-pipe.pipe';
import { GetCardByNamePipe } from './../../components/pipes/functions/get-card-by-name-pipe.pipe';
import { GetCardByUUIDPipe } from './../../components/pipes/functions/get-card-by-uuid-pipe.pipe';
import { GetSimilarCardsPipe } from './../../components/pipes/functions/get-similar-cards-pipe.pipe';


@Component({
  selector: 'app-page-card-modal',
  templateUrl: './card-modal.page.html',
  styleUrls: ['./card-modal.page.scss'],
})
export class CardModalPage implements OnInit {

  // To enable/disable more extensive logging
  public devMode = false;

  // Initiate the variable that controls what is currently shown in the second card
  public card2view = 'cardText';

  // Initating variable for showing card price - only used for visualization here
  public cardPrice = '-';
  public foilPrice = '-';
  public cardShopLink = 'http://shop.tcgplayer.com/magic/'; // Default for mtg category search
  public ebayShopLink = 'http://www.ebay.com/sch/?_sacat=38292&_sop=7&_nkw='; // Default for mtg category search
  private lastWordEditionName = '';

  // Language parameters
  public name_lang = 'name';
  public cardType_lang = 'cardType';
  public cleantext_lang = 'cleantext';
  public imagePath_lang = 'imagePath';

  // Navigation Parameters
  modalCard: MtgCard = this.navParams.get('modalCard');
  cardCollection: MtgCard[] = this.navParams.get('cardCollection');
  basketAnalysis: any = this.navParams.get('basketAnalysis');
  format: string = this.navParams.get('format');
  language: string = this.navParams.get('language');
  openParentModal: any = this.navParams.get('open');

  // Modal specific variables
  similarCardCollection: MtgCard[];
  synergyCards: string[];
  _showSaveButton = true;
  _showPriceTag = false;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public TCGPlayer: TcgPlayerService,
    public basketFilterPipe: BasketFilterPipe,
    public getCardByNamePipe: GetCardByNamePipe,
    public getCardByUUIDPipe: GetCardByUUIDPipe,
    public getSimilarCardsPipe: GetSimilarCardsPipe,
    ) {

    // Writing parameters to log
    if (this.devMode === true) {
      console.log(this.navParams.get('modalCard'));
      console.log(this.navParams.get('cardCollection'));
      console.log(this.navParams.get('basketAnalysis'));
      console.log(this.navParams.get('format'));
      console.log(this.navParams.get('language'));
      console.log(this.navParams.get('open'));
    }

    // Setting language parameters
    if (this.language !== 'EN') {
      this.name_lang = 'name_' + this.language;
      this.cardType_lang = 'cardType_' + this.language;
      this.cleantext_lang = 'cleantext_' + this.language;
      this.imagePath_lang = 'imagePath_' + this.language;
    }
  }

  // ngOnInit is called after the constructor and called after the first ngOnChanges()
  ngOnInit() {

    // Make sure we do not show the save button for the "non-playable-side" of split or doublefaced cards
    if (this.modalCard._layout_DoubleFaced || this.modalCard._layout_Flip || this.modalCard._layout_Split) {
      if (this.modalCard.name === this.modalCard.names[1]) {
        this._showSaveButton = false;
      }
    }

    // Create the array of synergy cards based on basketAnalysis array
    if (this.modalCard._layout_Split) {
      // Fetch the synergy cards based on the basketanalysis naming of split cards
      this.synergyCards = this.basketFilterPipe
      .transform(this.basketAnalysis, this.modalCard.names[0] + ' / ' + this.modalCard.names[1], this.format);
    } else {
      // Fetch the synergy cards based on the regular naming
      this.synergyCards = this.basketFilterPipe.transform(this.basketAnalysis, this.modalCard.name, this.format);
    }
    // console.log(this.synergyCards);

    // Set the shop link for the actual card if it exists in the product catalogue, else use default
    if (this.modalCard.tcg_url.length > 0) {
      this.cardShopLink = this.modalCard.tcg_url + '?partner=MTGAtlas&utm_campaign=affiliate&utm_medium=MTGAtlas&utm_source=MTGAtlas';
    }

    // Generating the ebay shop link based on the cardname and the last word in edition
    if (this.modalCard.editionName.indexOf(' ') > 0) {
      const editionNameWordArray = this.modalCard.editionName.split(' ');
      this.lastWordEditionName = editionNameWordArray[editionNameWordArray.length - 1];
    } else {
      this.lastWordEditionName = this.modalCard.editionName;
    }
    // this.ebayShopLink = this.ebayShopLink + this.modalCard.name.replace(' ', '%20') + '%20' + this.lastWordEditionName;

    // ebayShopLink with my personal partnerID attached
    this.ebayShopLink = 'https://rover.ebay.com/rover/1/711-53200-19255-0/1?icep_id=114&ipn=icep&toolid=20004&campid=5338471804&mpre='
    + 'https%3A%2F%2Fwww.ebay.com%2Fsch%2Fi.html%3F_nkw%3D'
    + this.modalCard.name.replace(' ', '%2520') + '%2520' + this.lastWordEditionName + '%26_sacat%3D38292%26_sop%3D7';


    // If there is a productId
    if (this.modalCard.tcg_productId !== '') {
      // Fetch the product price for the actual card to show in the modal
      this.TCGPlayer.getProductPrice(this.modalCard.tcg_productId)
        .subscribe(data => {
          // console.log(data);
          if (data) { // Avoiding null results
            data.results.forEach(element => {
              if (element.subTypeName === 'Normal') { // Normal pricing
                this.cardPrice = element.marketPrice;
              }
              if (element.subTypeName === 'Foil') { // Foil pricing
                this.foilPrice = element.marketPrice;
                if (this.cardPrice === '-') { // If the card is only available in foil, show that price
                  this.cardPrice = this.foilPrice;
                }
              }
              if (this._showSaveButton) {
                // Show the pricetag once price is available
                this._showPriceTag = true;
              }
            });
          } // If null response, default cardPrice '-' is shown
          // console.log(this.cardPrice);
          // console.log(this.foilPrice);
        }, (err) => {
          console.log(err);
          this._showPriceTag = false;
        });
      } else {
        // console.log('No tcg_productId for this card');
      }

  }

  // v- ALL CODE RELATED TO MODALS -v //
  // Determine wether the closing of the modal should also save the card to the wishlist
  closeModal(_save: boolean) {
    if (this.devMode === true) { console.log('closeModal: ' + _save); }
    // console.log('closeModal: ' + _save);

    if (_save) { // In order to pass object data on dismiss
      // console.log(this.modalCard);
      this.modalCtrl.dismiss(this.modalCard);
    } else { // Close without passing object
      this.modalCtrl.dismiss();
    }
  }

  // Calling the parent function embedded in the object passed to the modal
  openModal(modalCard: MtgCard) {
    if (this.devMode === true) { console.log('openModal'); }
    // console.log('openModal');

    this.openParentModal(modalCard);
  }
  // ^- ALL CODE RELATED TO MODALS -^ //

  // v- ALL CODE RELATED TO SHOWING ALTERNATE CARDS -v //
  // IdentifyCard by Name when switching between prints, or checking "played with" for a card
  identifyCard(name: string, print?: string, close?: boolean) {
    if (this.devMode === true) { console.log('identifyCard: ' + name); }
    // console.log('identifyCard: ' + name);

    // Identify card by name and print
    // console.log(name, print);

    // Alter the parameter name to the first half of the name if a split card name is used
    /* No longer the case, as all names now include both sides of the card
    if (name.indexOf('/') !== -1) {
      name = name.substring(0, name.indexOf('/')).trim();
    }*/

    const card: MtgCard = this.getCardByNamePipe.transform(this.cardCollection, name, print);

    // console.log(card);
    if (card) { // If there is a card output
      // Close the current modal ONLY when requested in the parameters
      if (close) {
        this.closeModal(false);
      }
      // Then open a modal based on the identified card
      this.openModal(card);
    } else {
      // This should be a toast, depending of the input was a print request or played w/ request
      if (print) {
        console.log('No card found in the requested print');
      } else {
        console.log('Requested card not found in the selected format');
      }
    }
  }

  // IdentifyCardByUUID when flipping between front/back of a card
  identifyCardByUUID(uuid: string, close?: boolean) {
    if (this.devMode === true) { console.log('identifyCard: ' + uuid); }

    const card: MtgCard = this.getCardByUUIDPipe.transform(this.cardCollection, uuid);

    if (this.devMode === true) { console.log(card); }

    if (card) { // If there is a card output
      // Close the current modal ONLY when requested in the parameters
      if (close) {
        this.closeModal(false);
      }
      // Then open a modal based on the identified card
      this.openModal(card);
    } else {
      // This should be a toast
      console.log('Requested card not found in the selected format');
    }
  }

  // This function is intended to open additional modals for cards that are split, flip or double-faced
  showAlternateCard() {
    if (this.devMode === true) { console.log('showAlternateCard'); }
    // console.log('showAlternateCard');

    // If the card is a split, flip, or double faced card, open a modal with the other card in focus
    if (this.modalCard._layout_Split || this.modalCard._layout_Flip || this.modalCard._layout_DoubleFaced) {
      if (this.modalCard.names.length > 1) {
        // Open modal instances for each alternate found
        for (let i = 0, len = this.modalCard.names.length; i < len; i++) {
          this.identifyCardByUUID(this.modalCard.names[i], false);
        }
      } else {
        // Open a single instance modal with the alternate
        this.identifyCardByUUID(this.modalCard.names[0], true);
      }
      /*
      // If the card is the first of the two, open the second
      if (this.modalCard.name === this.modalCard.names[0]) {
        this.identifyCard(this.modalCard.names[1], this.modalCard.edition, true);
      } else
      // If the card is the second of the two, open the first
      if (this.modalCard.name === this.modalCard.names[1]) {
        this.identifyCard(this.modalCard.names[0], this.modalCard.edition, true);
      }*/
    }
  }

  showPlayedWith() {
    if (this.devMode === true) { console.log('showPlayedWith'); }
    // console.log('showPlayedWith');

    this.card2view = 'playedWith';
  }


  showSimilar() {
    if (this.devMode === true) { console.log('showSimilar'); }
    // console.log('showSimilar');

    this.card2view = 'similarCards';

    // Create a duplicate of cardCollection
    this.similarCardCollection = this.cardCollection;

    // Fetch the similarCardCollection based on the compareCard
    // this.similarCardCollection = this.getSimilarCardsPipe.transform(this.similarCardCollection, this.modalCard);
    // console.log(this.getSimilarCardsPipe.transform(this.cardCollection, this.modalCard));

  }

  showRulings() {
    if (this.devMode === true) { console.log('showRulings'); }
    // console.log('showRulings');

    this.card2view = 'cardRulings';

  }

  showAltArt() {
    if (this.devMode === true) { console.log('showAltArt'); }
    // console.log('showAltArt');

    this.card2view = 'altArt';
  }

  showCardText() {
    if (this.devMode === true) { console.log('showCardText'); }
    // console.log('showCardText');

    this.card2view = 'cardText';
  }
  // ^- ALL CODE RELATED TO SHOWING ALTERNATE CARDS -^ //

}
