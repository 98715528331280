// This is the web worker script to allocate cardCollection data from JSON and return a promise
// All this is intended to run on a secondary thread, not blocking GUI operations
import { MtgCard } from './../models/mtgcard.model';

// Defining postMessage functions
// Boolean postMessage - not required
// declare function postMessage(message: boolean): void;
// JSON postMessage - not required
// declare function postMessage(message: MtgCard[]): void;
// ArrayBuffer postMessage - required when running ArrayBuffer response
declare function postMessage(data, moreData?): void;

// self.onmessage = function(event) {
export const LOAD_COLLECTION = (input: string): any =>  {
    // Split inputmessage in three strings (action, input, version)
    const inputMessage = input.split(';', 3);
    // Determine which action is requested
    const action = inputMessage[0];
    // Determine any path data
    const path = inputMessage[1];
    // Determine version of cardCollection
    const version = inputMessage[2];
    switch (action) {
        case 'ARRAYBUFFER':
        {
            const req = new XMLHttpRequest();

            req.overrideMimeType('application/json');
            req.open('GET', path, true);
            req.responseType = 'arraybuffer'; // Set the responseType to arraybuffer

            req.onload  = function() {
                // console.log(this.response);

                // Define what type of array holds the response
                const uInt16Array = new Uint16Array(this.response);
                // console.log(uInt16Array);

                // Define the arraybuffer based on the typed array
                const jsonArrayBuffer = uInt16Array.buffer;
                // console.log(jsonArrayBuffer);

                // Send back the arraybuffer through the web worker
                return postMessage(jsonArrayBuffer, [jsonArrayBuffer]);
            };
            req.onerror = function() {
                console.log('error');
            };
            req.send(null);
        }
        break;
        case 'JSON':
        {
            const req = new XMLHttpRequest();

            req.overrideMimeType('application/json');
            req.open('GET', path, true);
            req.onload  = function() {
                // console.log(this.response);

                // Handle the response once request is loaded
                const jsonResponse: MtgCard[] = JSON.parse(req.responseText);
                // console.log(jsonResponse);

                // Send back the response through the web worker
                return postMessage(jsonResponse);
            };
            req.onerror = function() {
                console.log('error');
            };
            req.send(null);
        }
        break;
        case 'INDEXEDDB':
        {

            let dbOpenReq: IDBOpenDBRequest;
            let objectStore: IDBObjectStore;
            let db: IDBDatabase;
            let dbReq: IDBRequest;

            // Open indexedDB for saving of the cardCollection
            dbOpenReq = self.indexedDB.open('_webDb');

            dbOpenReq.onupgradeneeded = function(e) {
                db =  dbOpenReq.result;
                if (!dbOpenReq.result.objectStoreNames.contains('_cardCollection')) {
                    objectStore = dbOpenReq.result.createObjectStore('_ionickv', { autoIncrement: true });
                    // console.log('Successfully upgraded _webDb');
                }
            };
            dbOpenReq.onsuccess = function(e) {
                console.log('[onsuccess]', dbOpenReq.result);
                db = dbOpenReq.result;
                // console.log('_webDb successfully opened');
            };
            dbOpenReq.onerror = function(e) {
                console.log('[onerror]', dbOpenReq.error);
                // Communicate to the main thread that the operation didn't go through
                return postMessage(false);
            };

            let cardCollection: MtgCard[] = [];

            const req = new XMLHttpRequest();

            req.overrideMimeType('application/json');
            req.open('GET', path, true);
            req.onload  = function() {

                // console.log('httprequest started');
                // console.log(this.response);

                // Handle the response once request is loaded
                cardCollection = JSON.parse(req.responseText);
                // console.log(cardCollection);

                // Slice the array in chunks
                const chunks = [];
                // Create 5 chunks of cards
                const chunksize = Math.floor(cardCollection.length / 5) + 1; // Number of cards per chunk
                for (let i = 0, len = cardCollection.length; i < len; i += chunksize) {
                    chunks.push(cardCollection.slice(i, i + chunksize));
                }
                // console.log(chunks);

                // Then save the chunks in indexedDB
                chunks.forEach( function (chunk) {
                    dbReq = db.transaction('_ionickv', 'readwrite').objectStore('_ionickv')
                    .put( chunk, 'cardCollection_' + version + '_' + (chunks.indexOf(chunk) + 1));
                    // Also add a control value under cardCollection_version to see how complete the storage is
                    dbReq = db.transaction('_ionickv', 'readwrite').objectStore('_ionickv')
                    .put( chunks.indexOf(chunk) + 1, 'cardCollection_' + version);

                    dbReq.onerror = function() {
                        console.log('[onerror]', dbReq.error);
                        // Communicate to the main thread that the operation didn't go through
                        return postMessage(false);
                    };
                    dbReq.onsuccess = function() {
                        // console.log('Chunk ' + (chunks.indexOf(chunk) + 1) + ' added to indexedDB');
                        // Tell the mainPage that the storage is completed upon storing the last chunk
                        if ((chunks.indexOf(chunk) + 1) === chunks.length) {
                            // console.log('Storage completed at chunk: ' + chunks.length);
                            return postMessage(true);
                        }
                    };
                });
            };
            req.send(null);
        }
        break;
    }
};

/* JSON_EXPORT as ARRAYBUFFER below
import { MtgCard } from './../models/mtgcard.model';

export const JSON_EXPORT = (input: string): MtgCard[] =>  {
    const req = new XMLHttpRequest();

    req.overrideMimeType('application/json');
    req.open('GET', input, true);
    req.responseType = 'arraybuffer'; // Set the responseType to arraybuffer

    req.onload  = function() {
        // console.log(this.response);

        // Define what type of array holds the response
        const uInt16Array = new Uint16Array(this.response);
        // console.log(uInt8Array);

        // Define the arraybuffer based on the typed array
        const jsonArrayBuffer = uInt16Array.buffer;
        // console.log(jsonArrayBuffer);

        // Send back the arraybuffer through the web worker
        return postMessageAB(jsonArrayBuffer, [jsonArrayBuffer]);
    };
    req.send(null);
    return []; // Doesn't matter what this const returns, as the message is sent through postMessage
};
*/

/* JSON_EXPORT as JSON_RESPONSE below
import { MtgCard } from './../models/mtgcard.model';

// Defining postMessage function
declare function postMessageJSON(message: MtgCard[]): void;

export const JSON_EXPORT = (input: string): MtgCard[] =>  {
    const req = new XMLHttpRequest();

    req.overrideMimeType('application/json');
    req.open('GET', input, true);
    req.onload  = function() {
        // Handle the response once request is loaded
        const jsonResponse: MtgCard[] = JSON.parse(req.responseText);

        // Send back the response through the web worker
        return postMessageJSON(jsonResponse);
    };
    req.send(null);
    return []; // Doesn't matter what this const returns, as the message is sent through postMessage
};
*/
