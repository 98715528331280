import { Injectable } from '@angular/core';
// Import interface
import { MtgCard } from './../models/mtgcard.model';
// Import interface
import { Wishlist } from '../models/wishlist.model';

@Injectable({
  providedIn: 'root'
})
export class WishlistHandlerService {

  constructor() { }

  addCard(card: MtgCard, wishList: Wishlist[]): Wishlist[] {
  // Creating the wishCard to be pushed to the wishList (default 0 as price)
  const wishCard = {amount: 1, wishCard: card, priceTotal: 0, price: 0};

  // Add it to the wishList
  wishList.push(wishCard);

  return wishList;
  }

  removeCard(card: MtgCard, wishList: Wishlist[]): Wishlist[] {
    // Loop through the wishList looking for a matching card
    wishList.forEach(function(item) {
      if (card.imagePath === item.wishCard['imagePath']) {
          // If the card is there - remove it
          wishList.splice(wishList.indexOf(item), 1);
          // Then return the wishList without continuing the loop
          return wishList;
      }
    });
    return wishList;
  }

  updatePriceTotal(wishList: Wishlist[]): number {
    // Calculate the total value of all priceTotals and set to wishListTotal
    let sumPriceTotal = 0;
    if ( wishList.length === 0) {
      return 0;
    } else {
    for (let i = 0; i < wishList.length; i++) {
      if (wishList[i].priceTotal) {
        sumPriceTotal += wishList[i].priceTotal;
      }
    }
    return sumPriceTotal;
    }
  }

  updateAmountTotal(wishList: Wishlist[]): number {
    // Calculate the total value of all priceTotals and set to wishListTotal
    let sumAmountTotal = 0;
    if ( wishList.length === 0) {
      return 0;
    } else {
    for (let i = 0; i < wishList.length; i++) {
      if (wishList[i].amount) {
        sumAmountTotal += wishList[i].amount;
      }
    }
    return sumAmountTotal;
    }
  }

  getExportData(target: string, wishList: Wishlist[], name_lang: string, format?: string): string {
  // Building the export file from the WishList array

  if ( target === 'dec') {
    let data = ''; // Will hold the final text
    let dataString = ''; // Will hold the content of each row

    wishList.forEach(function(item, index) {
      // Only apply split specific logic for english cards
      if (item.wishCard._layout_Split && name_lang === 'name') {
        // Use a different naming of cards for split cards when recording to decklists or tcgstore purchase
        dataString = item.amount.toString()
        + ' '
        + item.wishCard.names[0]
        + ' // '
        + item.wishCard.names[1];
       } else {
        dataString = item.amount.toString()
        + ' '
        + item.wishCard[name_lang];
       }
       data += index <= wishList.length ? dataString + '\r\n' : dataString;
    });

    if (format === 'uri') {
      // Create a URI for each data type
      const uri = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
      // Return the uri
      return uri;
    }

    if (format === 'blob') {
      // Create a blob for each data type
      const blob = new Blob([data], {type: 'text/csv;charset=utf-8'});
      // Return the blobUrl
      return URL.createObjectURL(blob);
    }
  }

  if ( target === 'mwd') {
    let data = '// Deck file created with www.mtgatlas.com' + '\r\n'; // Will hold the final text
    let dataString = ''; // Will hold the content of each row

    wishList.forEach(function(item, index) {
      if (item.wishCard._layout_Split) {
        // Use a different naming of cards for split cards when recording to decklists or tcgstore purchase
        dataString = item.amount.toString()
        + ' ['
        + item.wishCard.edition
        + '] '
        + item.wishCard.names[0]
        + ' // '
        + item.wishCard.names[1];
       } else {
        dataString = item.amount.toString()
        + ' ['
        + item.wishCard.edition
        + '] '
        + item.wishCard.name;
       }
       data += index <= wishList.length ? dataString + '\r\n' : dataString;
    });

    if (format === 'uri') {
      // Create a URI for each data type
      const uri = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
      // Return the uri
      return uri;
    }

    if (format === 'blob') {
      // Create a blob for each data type
      const blob = new Blob([data], {type: 'text/csv;charset=utf-8'});
      // Return the blobUrl
      return URL.createObjectURL(blob);
    }
  }

  if ( target === 'tcg') {
    // OLD let data = 'http://store.tcgplayer.com/massentry?partner=MTGAtlas&c='; // Will hold the final URL
    // Will hold the final URL
    let data = 'http://store.tcgplayer.com/massentry?partner=MTGAtlas&utm_campaign=affiliate&utm_medium=MTGAtlas&utm_source=MTGAtlas&c=';
    // Will hold the content of each row
    let dataString = '';

    wishList.forEach(function(item, index) {
      if (item.wishCard._layout_Split) {
        // Since the update of card names, use the same formatting for all card types
        dataString = item.amount.toString()
        + ' '
        + item.wishCard.name;
       } else {
        dataString = item.amount.toString()
        + ' '
        + item.wishCard.name;
       }
       data += index <= wishList.length ? dataString + '||' : dataString;

    });

    return data;
  }
  console.log('no export processed');
  return 'invalid target'; // If an invalid target has been specified
  }
}
