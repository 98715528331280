import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an AND-pipe,
// which means that any filters coming in will generate the minimum common denominator
// between all filters made.
// This is intended for all card abilities prefixed with "_"

@Pipe({
    name: 'abilityFilter',
    pure: true
})
export class AbilityFilterPipe implements PipeTransform {
  transform(items: MtgCard[], filter: MtgCard, counter?: number): MtgCard[] {
    if (!items || !filter) {
      return items;
    }

    // filter items array, items which match and return true will be kept, false will be filtered out
    // console.log('abilityFilter updated');
    return items.filter((item: MtgCard) => this.applyFilter(item, filter));
  }

  /**
   * Perform the filtering.
   *
   * @param {Card} card The card to compare to the filter.
   * @param {Card} filter The filter to apply.
   * @return {boolean} True if card satisfies filters, false if not.
   */
  applyFilter(card: MtgCard, filter: MtgCard): boolean {
    for (const field in filter) {
      // console.log('Field: '+field, 'Card: '+card[field], 'Filter: '+filter[field]);
        if (typeof filter[field] === 'boolean') {
          if (card[field] !== filter[field]) { // Checks if the field is the same state
            return false;
          }
        }
    }
    return true;
  }
}
