import { Injectable } from '@angular/core';
// Import interface
import { MtgCard } from './../models/mtgcard.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionSortService {

  // Defining the sortOrder map
  public sortOrder_map: Map<any, any> = new Map();

  constructor() {
      // For CMC/Power/Toughness
      this.sortOrder_map.set('0', 'a');
      this.sortOrder_map.set('1', 'b');
      this.sortOrder_map.set('2', 'c');
      this.sortOrder_map.set('3', 'd');
      this.sortOrder_map.set('4', 'e');
      this.sortOrder_map.set('5', 'f');
      this.sortOrder_map.set('6', 'g');
      this.sortOrder_map.set('7', 'h');
      this.sortOrder_map.set('8', 'i');
      this.sortOrder_map.set('9', 'j');
      this.sortOrder_map.set('10', 'k');
      this.sortOrder_map.set('11', 'l');
      this.sortOrder_map.set('12', 'm');
      this.sortOrder_map.set('13', 'n');
      this.sortOrder_map.set('14', 'o');
      this.sortOrder_map.set('15', 'p');
      this.sortOrder_map.set('16', 'q');
      this.sortOrder_map.set('*', 'y');
      this.sortOrder_map.set(null, 'z');
      // For rarity
      this.sortOrder_map.set('Common', 'a');
      this.sortOrder_map.set('Uncommon', 'b');
      this.sortOrder_map.set('Rare', 'c');
      this.sortOrder_map.set('Mythic Rare', 'd');
      this.sortOrder_map.set('Special', 'e');
      // For permanent
      this.sortOrder_map.set('White', 'a');
      this.sortOrder_map.set('Blue', 'b');
      this.sortOrder_map.set('Black', 'c');
      this.sortOrder_map.set('Red', 'd');
      this.sortOrder_map.set('Green', 'e');
      this.sortOrder_map.set('Colorless', 'f');
      this.sortOrder_map.set('Multicolor', 'g');
      // For mainType
      this.sortOrder_map.set('Land', 'a');
      this.sortOrder_map.set('Artifact', 'b');
      this.sortOrder_map.set('Artifact Creature', 'c');
      this.sortOrder_map.set('Creature', 'd');
      this.sortOrder_map.set('Instant', 'e');
      this.sortOrder_map.set('Sorcery', 'f');
      this.sortOrder_map.set('Enchantment', 'g');
      this.sortOrder_map.set('Planeswalker', 'h');
  }

  sortCardCollection(collection: MtgCard[], sB1: string, sO1: string, sB2?: string, sO2?: string, sB3?: string, sO3?: string): MtgCard[] {

    // Declare variables
    let field1: string;
    let field2: string;
    let field3: string;

    // Fetched from the sort order variable
    const field1_prefix: string = sO1.indexOf(String.fromCharCode(9650)) >= 0 ? '-' : '';
    const field2_prefix: string = sO2.indexOf(String.fromCharCode(9650)) >= 0 ? '-' : '';
    const field3_prefix: string = sO3.indexOf(String.fromCharCode(9650)) >= 0 ? '-' : '';

    if (sB3) { // If searchBy field 3 is not null, use field 1 & 2 & 3
      field3 = sB3.indexOf('Color') >= 0 ? 'permanent' : sB3.toLowerCase();
      field2 = sB2.indexOf('Color') >= 0 ? 'permanent' : sB2.toLowerCase();
      field1 = sB1.indexOf('Color') >= 0 ? 'permanent' : sB1.toLowerCase();
      field3 = sB3.indexOf('Edition') >= 0 ? 'releaseDate' : field3;
      field2 = sB2.indexOf('Edition') >= 0 ? 'releaseDate' : field2;
      field1 = sB1.indexOf('Edition') >= 0 ? 'releaseDate' : field1;
      field3 = sB3.indexOf('Type') >= 0 ? 'mainType' : field3;
      field2 = sB2.indexOf('Type') >= 0 ? 'mainType' : field2;
      field1 = sB1.indexOf('Type') >= 0 ? 'mainType' : field1;
      field3 = sB3.indexOf('Value') >= 0 ? 'price' : field3;
      field2 = sB2.indexOf('Value') >= 0 ? 'price' : field2;
      field1 = sB1.indexOf('Value') >= 0 ? 'price' : field1;

      // Make sure the sort order is captured last, before applying the sort
      field1 = field1_prefix + field1;
      field2 = field2_prefix + field2;
      field3 = field3_prefix + field3;

      return collection.sort(this.dynamicSortMultiple([field1, field2, field3]));
    } else {
      if (sB2) { // If searchBy field 2 is not null, use field 1 & 2
        field2 = sB2.indexOf('Color') >= 0 ? 'permanent' : sB2.toLowerCase();
        field1 = sB1.indexOf('Color') >= 0 ? 'permanent' : sB1.toLowerCase();
        field2 = sB2.indexOf('Edition') >= 0 ? 'releaseDate' : field2;
        field1 = sB1.indexOf('Edition') >= 0 ? 'releaseDate' : field1;
        field2 = sB2.indexOf('Type') >= 0 ? 'mainType' : field2;
        field1 = sB1.indexOf('Type') >= 0 ? 'mainType' : field1;
        field2 = sB2.indexOf('Value') >= 0 ? 'price' : field2;
        field1 = sB1.indexOf('Value') >= 0 ? 'price' : field1;

        // Make sure the sort order is captured last, before applying the sort
        field1 = field1_prefix + field1;
        field2 = field2_prefix + field2;

        return collection.sort(this.dynamicSortMultiple([field1, field2]));
    } else {
      if (sB1) { // If searchBy field 2 is not null, use field 1 & 2
        field1 = sB1.indexOf('Color') >= 0 ? 'permanent' : sB1.toLowerCase();
        field1 = sB1.indexOf('Edition') >= 0 ? 'releaseDate' : field1;
        field1 = sB1.indexOf('Type') >= 0 ? 'mainType' : field1;
        field1 = sB1.indexOf('Value') >= 0 ? 'price' : field1;

        // Make sure the sort order is captured last, before applying the sort
        field1 = field1_prefix + field1;

        return collection.sort(this.dynamicSortMultiple([field1]));
      }
    }
    }
    // If no sortOrder has been specified, return what was sent in
    return collection;
  }

  // Generic function for sorting an array with multiple parameters
  dynamicSortMultiple(sortArray: string[]) {
    /*
    * save the arguments object as it will be overwritten
    * note that arguments object is an array-like object
    * consisting of the names of the properties to sort by
    */

    const _this = this;
    const props = sortArray;
    return function (obj1, obj2) {
        let i = 0;
        let result = 0;
        const numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
        * as long as we have extra properties to compare
        */
        while (result === 0 && i < numberOfProperties) {
            result = _this.dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    };
  }

  // Generic function for sorting an array with one parameter
  dynamicSort(property) {
    const _this = this;

    let sortOrder = 1;
    if (property[0] === '-') { // If the first character is -, reverse the sort order
        sortOrder = -1;
        property = property.substr(1); // Then use everything past - as the property requested
    }
    return function (a, b) {
      // Make sure we sort all fields according to their mapped sortOrder

      // Return the matched value if it exist in the map
      // Return the input value of no match was found in the map
      let a_mapped: string;
      let b_mapped: string;
      if (property === 'mainType') { // Change the map behaviour if the input is an array of strings
        a_mapped = _this.sortOrder_map.get(a[property].join()) ? _this.sortOrder_map.get(a[property].join()) : a[property];
        b_mapped = _this.sortOrder_map.get(b[property].join()) ? _this.sortOrder_map.get(b[property].join()) : b[property];
      } else {
        a_mapped = _this.sortOrder_map.get(a[property]) ? _this.sortOrder_map.get(a[property]) : a[property];
        b_mapped = _this.sortOrder_map.get(b[property]) ? _this.sortOrder_map.get(b[property]) : b[property];
      }
        const result = (a_mapped < b_mapped) ? -1 : (a_mapped > b_mapped) ? 1 : 0;
        return result * sortOrder;
    };
  }
}
