import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an OR-pipe,
// which means that any filters coming in will generate total sum of all cards in the selection(s)
// This is intended for everyhing that is NOT card abilities prefixed with "_"

@Pipe({
    name: 'formatFilter',
    pure: true
})
export class FormatFilterPipe implements PipeTransform {
  transform(items: MtgCard[], filter: string): MtgCard[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    // Create empty results array
    const results: MtgCard[] = [];

    filter = filter.replace('#', ''); // To fix the resetImageScroll hack

    items.forEach(function(item) {
          // Do a for each on the card array here
          item['format'].forEach(function(format) {
            if (format.toLowerCase() !== filter.toLowerCase()) {
                // If the cards format is not equal to the filter string, do nothing
            } else { // For item format "Standard", make sure the card belongs to an expansion or starter or core
              if (format.toLowerCase() === 'standard') {
                if (item.editionType === 'expansion' || item.editionType === 'starter' || item.editionType === 'core') {
                  results.push(item); // If item matches search, add it to results array
                }
                // If "Standard", and not expansion or starter or core, do nothing
              } else {
                results.push(item); // If item matches search, add it to results array
              }
                }
          });
    });

    // console.log(results);
    // console.log('formatFilter updated');
    return results;

  }
}
