<ion-header class="header">
  <div *ngIf="!isLoading && !devMode">
        {{ 'Page Header' | translate }} {{ (formattedCardCollection 
        | editionFilter:edition_filter:triggerPipeCounter 
        | permanentFilter:c_filter:triggerPipeCounter 
        | mainTypeFilter:c_filter:triggerPipeCounter
        | powerFilter:c_filter:triggerPipeCounter
        | toughnessFilter:c_filter:triggerPipeCounter
        | loyaltyFilter:c_filter:triggerPipeCounter 
        | rarityFilter:c_filter:triggerPipeCounter 
        | cmcFilter:c_filter:triggerPipeCounter 
        | abilityFilter:c_filter:triggerPipeCounter
        | cardSearchPipe:name_lang:cardNameSearch
        | cardSearchPipe:cardType_lang:cardTypeSearch
        | cardSearchPipe:cleantext_lang:cardTextSearch
        )?.length }}
      </div>
  <div class="language-left" *ngIf="platformWidth>600">
    <ion-icon name="images" class="header-options" (click)="changeScrollView('cardImage')"></ion-icon>
    <ion-icon name="list-box" class="header-options" (click)="changeScrollView('cardText')"></ion-icon>
  </div>
  <div class="language" *ngIf="platformWidth>600">
    <img src="../../assets/gui/flag-usa.png" alt="usa" class="flag" (click)="changeLanguage('en')" />
    <img src="../../assets/gui/flag-brazil.png" alt="brazil" class="flag" (click)="changeLanguage('pt')" />
    <img src="../../assets/gui/flag-japan.png" alt="japan" class="flag" (click)="changeLanguage('jp')" />
    <img src="../../assets/gui/flag-korea.png" alt="korea" class="flag" (click)="changeLanguage('ks')" />
    <img src="../../assets/gui/flag-russia.png" alt="russia" class="flag" (click)="changeLanguage('ru')" />
    <img src="../../assets/gui/flag-germany.png" alt="germany" class="flag" (click)="changeLanguage('de')" />
    <img src="../../assets/gui/flag-france.png" alt="france" class="flag" (click)="changeLanguage('fr')" />
    <img src="../../assets/gui/flag-italy.png" alt="italy" class="flag" (click)="changeLanguage('it')" />
    <img src="../../assets/gui/flag-spain.png" alt="spain" class="flag" (click)="changeLanguage('es')" />
    <img src="../../assets/gui/flag-china.png" alt="china" class="flag" (click)="changeLanguage('ch')" />
  </div>
  <div class="language-left" *ngIf="platformWidth<600">
    <img src="../../assets/gui/flag-usa.png" alt="usa" class="flag" (click)="changeLanguage('en')" />
    <img src="../../assets/gui/flag-japan.png" alt="japan" class="flag" (click)="changeLanguage('jp')" />
    <img src="../../assets/gui/flag-china.png" alt="china" class="flag" (click)="changeLanguage('ch')" />
    <img src="../../assets/gui/flag-korea.png" alt="korea" class="flag" (click)="changeLanguage('ks')" />
    <img src="../../assets/gui/flag-russia.png" alt="russia" class="flag" (click)="changeLanguage('ru')" />
  </div>
  <div class="language" *ngIf="platformWidth<600">
    <img src="../../assets/gui/flag-germany.png" alt="germany" class="flag" (click)="changeLanguage('de')" />
    <img src="../../assets/gui/flag-italy.png" alt="italy" class="flag" (click)="changeLanguage('it')" />
    <img src="../../assets/gui/flag-brazil.png" alt="brazil" class="flag" (click)="changeLanguage('pt')" />
    <img src="../../assets/gui/flag-france.png" alt="france" class="flag" (click)="changeLanguage('fr')" />
    <img src="../../assets/gui/flag-spain.png" alt="spain" class="flag" (click)="changeLanguage('es')" />
  </div>
</ion-header>

<!-- Loader animation showing while class is NOT "loaded" -->
<div class="loaded" [class.loaded]="!isLoading">
  <div id="loader-wrapper">
    <div class="spinner">
      <div class="bounce1" [style.opacity]="isLoadingPercent < 20 ? 0 : 1"><i class="ms ms-w ms-5x ms-cost ms-shadow"></i></div>
      <div class="bounce2" [style.opacity]="isLoadingPercent < 40 ? 0 : 1"><i class="ms ms-u ms-5x ms-cost ms-shadow"></i></div>
      <div class="bounce3" [style.opacity]="isLoadingPercent < 60 ? 0 : 1"><i class="ms ms-b ms-5x ms-cost ms-shadow"></i></div>
      <div class="bounce4" [style.opacity]="isLoadingPercent < 80 ? 0 : 1"><i class="ms ms-r ms-5x ms-cost ms-shadow"></i></div>
      <div class="bounce5" [style.opacity]="isLoadingPercent < 100 ? 0 : 1"><i class="ms ms-g ms-5x ms-cost ms-shadow"></i></div>
      <h1>{{ 'Loading cards...' | translate }}</h1>
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
  </div>
</div>

<!-- ImageScroll X -->
<div class="fixed-header image-carousel" *ngIf="!isLoading && platformWidth<1200">
  <!-- Batch loading image scroll -->
  <drag-scroll id="imageScrollX" #imageDragScrollX class="image-scroll-x" drag-scroll-y-disabled="true" snap-disabled="true" scroll-x-wheel-enabled="true" (reachesRightBound)="addPage($event)" appMouseWheel (mouseWheelUp)="mouseWheelUpFunc(event)" (mouseWheelDown)="mouseWheelDownFunc(event)" *ngIf="formattedCardCollection"> <!--(scroll)="onScroll($event)"-->
    <ion-card class="card-frame" drag-scroll-item *ngFor="let card of formattedCardCollection  
      | editionFilter:edition_filter:triggerPipeCounter 
      | permanentFilter:c_filter:triggerPipeCounter 
      | mainTypeFilter:c_filter:triggerPipeCounter
      | powerFilter:c_filter:triggerPipeCounter
      | toughnessFilter:c_filter:triggerPipeCounter
      | loyaltyFilter:c_filter:triggerPipeCounter 
      | rarityFilter:c_filter:triggerPipeCounter 
      | cmcFilter:c_filter:triggerPipeCounter 
      | abilityFilter:c_filter:triggerPipeCounter 
      | cardSearchPipe:name_lang:cardNameSearch
      | cardSearchPipe:cardType_lang:cardTypeSearch
      | cardSearchPipe:cleantext_lang:cardTextSearch
      | slice:0:limit+page*limit">
      <ion-card-content class="card-content"> 
        <div id="cardText" class="card-text" (click)="openModal(card)"> 
          <p *ngIf="scrollView === 'cardImage'">
            &nbsp;  
          </p>
          <p class="card-header-row">
            {{card[name_lang] !== '' ? card[name_lang] : card.name}} <span innerHTML="{{card.manaCost | toManaIcon}}"></span>
          </p>
          <p class="card-type-row">
            {{card[cardType_lang] !== '' ? card[cardType_lang] : card.cardType}} <span innerHTML="<i class='ss ss-{{card.edition | lowercase}} ss-{{card.rarity | lowercase}} ss-grad ss-fw'></i>"></span>
          </p>
          <p class="card-text-row">
            {{card[cleantext_lang] !== '' ? card[cleantext_lang] : card.cleantext}}
          </p>
          <br>
          <p class="card-pt">
            {{card.pt}}{{card.loyalty}}
          </p>
        </div>

        <div id="cardImage" class="card-image" (click)="openModal(card)" *ngIf="scrollView === 'cardImage'">
          <ion-img  src={{card[imagePath_lang]}}
          title="{{card[name_lang] !== '' ? card[name_lang] : card.name}} - {{card.manaCost}}
{{card[cardType_lang] !== '' ? card[cardType_lang] : card.cardType}} ({{card.edition}} - {{card.rarity | translate}})
{{card[cleantext_lang] !== '' ? card[cleantext_lang] : card.cleantext}}
{{card.pt}}{{card.loyalty}}">
          </ion-img>
        </div>
    
        <div class="corner-ribbon" [ngClass]="{'banned':(card._legacy_banned && format_filter=='Legacy') || (card._vintage_banned && format_filter=='Vintage') || (card._commander_banned && format_filter=='Commander') || (card._modern_banned && format_filter=='Modern') || (card._standard_banned && format_filter=='Standard')}">  
          {{ 'Banned' | translate }}
        </div>
        <div class="corner-ribbon" [ngClass]="{'restricted':(card._legacy_restricted && format_filter=='Legacy') || (card._vintage_restricted && format_filter=='Vintage') || (card._commander_restricted && format_filter=='Commander') || (card._modern_restricted && format_filter=='Modern') || (card._standard_restricted && format_filter=='Standard')}">  
          {{ 'Restricted' | translate }}
        </div>
        <div class="bottom-star" (click)="toggleWishList(card)" *ngIf="!((card._layout_DoubleFaced || card._layout_Flip || card._layout_Split) && card.name === card.names[1])"> 
          <i class="ss ss-s99 ss-common ss-grad" *ngIf="!card._isOnWishList"></i>
          <i class="ss ss-s99 ss-rare ss-grad" *ngIf="card._isOnWishList"></i>
        </div>        
      </ion-card-content>
    </ion-card> 
  </drag-scroll>
</div>

<ion-content id="pageContent" class="container" scrollEvents="true" *ngIf="!isLoading">
  <!-- MOBILE GRID -->
  <ion-grid id="selectionGrid" *ngIf="platformWidth<1200">
    <!-- SEARCH 
    DO NOT SHOW ON SMALL SCREENS (<768) - TO SIMPLIFY LAYOUT
    -->
    <ion-row>
      <!-- LOGO -->
      <ion-col size="12" class="container_logo"><!--size-md="4" size-lg="3" size-xl="3"-->
        <!-- LOGO -->
        <img src="../../assets/icon/GoldAtlasLogo.jpg" alt="logo" class="logo-large">
      </ion-col>
      <!-- SEARCH -->
      <ion-col size="12" class="container_search" *ngIf="platformWidth>767"><!--size-md="8" size-lg="9" size-xl="9"-->
        <ion-row>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardNameSearch" class="search-bar" mode="md" [(ngModel)]="cardNameSearch" placeholder="{{ 'cardName' | translate }}" (ionClear)="cardNameSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardTextSearch" class="search-bar" mode="md" [(ngModel)]="cardTextSearch" placeholder="{{ 'cardText' | translate }}" (ionClear)="cardTextSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardTypeSearch" class="search-bar" mode="md" [(ngModel)]="cardTypeSearch" placeholder="{{ 'cardType' | translate }}" (ionClear)="cardTypeSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <!-- MAIN SELECTIONS -->
    <ion-row>
      <!-- FORMAT/EDITION/SORT 
      DO NOT SHOW ON <1199 - TO SIMPLIFY LAYOUT
      -->
      <ion-col class="container_dropdown" *ngIf="platformWidth>1199" size="12"> <!-- size-md="4" size-lg="3" size-xl="3" -->
        <ion-list>
          <ion-item class="dropdown_selections">
            <label>{{ 'Format' | translate }}</label>
            <ion-select [(ngModel)]="format_filter" (ionChange)="formatFilterChange()" disabled={{disableFormatSelection}}>
              <ion-select-option *ngFor="let format of formatSelection">{{format}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="dropdown_selections">
            <label>{{ 'Edition' | translate }}</label>
            <ion-select [(ngModel)]="edition_filter" (ionChange)="editionFilterChange()" multiple="true" cancelText="Cancel" okText="Select">
              <ion-select-option *ngFor="let edition of editionSelection">{{edition}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item class="dropdown_selections_sortorder">
            <label>{{ 'Order By' | translate }}</label>
            <ion-select [(ngModel)]="sortBy_1" (ionChange)="sortCardCollection()" placeholder="{{ 'Name' | translate }}">
              <ion-select-option *ngFor="let sortOption1 of sortOptions" [value]="sortOption1">{{ sortOption1 | translate }}</ion-select-option>
            </ion-select>
            <ion-select [(ngModel)]="sortOrder_1" (ionChange)="sortCardCollection()" interface="action-sheet"
            [style.min-width]="sortBy_1? '30px' : '30px'" [style.max-width]="sortBy_1? '30px' : '30px'" placeholder="{{ forward_arrow }}">
              <ion-select-option>
                {{forward_arrow}}
              </ion-select-option>
              <ion-select-option>
                {{backward_arrow}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="dropdown_selections_sortorder" *ngIf="sortBy_1">
            <label>{{ 'Order By' | translate }}</label>
            <ion-select [(ngModel)]="sortBy_2" (ionChange)="sortCardCollection()">
              <ion-select-option *ngFor="let sortOption2 of sortOptions" [value]="sortOption2">{{ sortOption2 | translate }}</ion-select-option>
            </ion-select>
            <ion-select [(ngModel)]="sortOrder_2" (ionChange)="sortCardCollection()" interface="action-sheet"
            [style.min-width]="sortBy_1? '30px' : '30px'" [style.max-width]="sortBy_1? '30px' : '30px'" placeholder="{{ forward_arrow }}">
              <ion-select-option>
                {{forward_arrow}}
              </ion-select-option>
              <ion-select-option>
                {{backward_arrow}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="dropdown_selections_sortorder" *ngIf="sortBy_2">
            <label>{{ 'Order By' | translate }}</label>
            <ion-select [(ngModel)]="sortBy_3" (ionChange)="sortCardCollection()">
              <ion-select-option *ngFor="let sortOption3 of sortOptions" [value]="sortOption3">{{ sortOption3 | translate }}</ion-select-option>
            </ion-select>
            <ion-select [(ngModel)]="sortOrder_3" (ionChange)="sortCardCollection()" interface="action-sheet"
            [style.min-width]="sortBy_1? '30px' : '30px'" [style.max-width]="sortBy_1? '30px' : '30px'" placeholder="{{ forward_arrow }}">
              <ion-select-option>
                {{forward_arrow}}
              </ion-select-option>
              <ion-select-option>
                {{backward_arrow}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
      <!-- SELECTIONS -->
      <ion-col class="container_selection" size="12"> <!-- size-md="8" size-lg="9" size-xl="9"-->
        <ion-row>
          <ion-col size="auto">
            <!-- Checkbox for adding/removing MainType selections to the c_filter.mainType attribute 
                  Creature / Land / Artifact / ArtifactCreature (added) / Creature / Instant / Sorcery / Enchantment / Planeswalker // following excluded: Phenomenon / Plane / Scheme / Tribal / Vanguard
                  -->
            <input type="checkbox" class="mainType" id="Land" [(ngModel)]="Land" (change)="toggleMainType('Land')" />
            <label for="Land" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-land ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="mainType" id="Artifact" [(ngModel)]="Artifact" (change)="toggleMainType('Artifact')" />
            <label for="Artifact" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-artifact ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="mainType" id="ArtifactCreature" [(ngModel)]="ArtifactCreature" (change)="toggleMainType('Artifact Creature')"
            />
            <label for="ArtifactCreature" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-artifactcreature ms-4x ms-cost ms-split"></i>
            </label>
            <input type="checkbox" class="mainType" id="Creature" [(ngModel)]="Creature" (change)="toggleMainType('Creature')" />
            <label for="Creature" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-creature ms-4x ms-cost"></i>
            </label>
          </ion-col>
          <ion-col size="auto">
            <input type="checkbox" class="mainType" id="Instant" [(ngModel)]="Instant" (change)="toggleMainType('Instant')" />
            <label for="Instant" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-instant ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="mainType" id="Sorcery" [(ngModel)]="Sorcery" (change)="toggleMainType('Sorcery')" />
            <label for="Sorcery" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-sorcery ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="mainType" id="Enchantment" [(ngModel)]="Enchantment" (change)="toggleMainType('Enchantment')"/>
            <label for="Enchantment" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-enchantment ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="mainType" id="Planeswalker" [(ngModel)]="Planeswalker" (change)="toggleMainType('Planeswalker')"/>
            <label for="Planeswalker" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-planeswalker ms-4x ms-cost"></i>
            </label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="(Creature || ArtifactCreature) && platformWidth>767">
          <ion-col size="auto">
            <!-- Selection boxes for creature power 0 - 8 -->
            <input type="checkbox" class="power" id="Power" [(ngModel)]="Power1" [disabled]="true" />
            <label for="Power" [class.hoverSelection]="isMobile === false">
              <i class="ms pt-icon ms-power ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power1" [(ngModel)]="Power1" (change)="togglePower('1')" />
            <label for="Power1" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-1 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power2" [(ngModel)]="Power2" (change)="togglePower('2')" />
            <label for="Power2" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-2 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power3" [(ngModel)]="Power3" (change)="togglePower('3')" />
            <label for="Power3" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-3 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power4" [(ngModel)]="Power4" (change)="togglePower('4')" />
            <label for="Power4" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-4 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power5" [(ngModel)]="Power5" (change)="togglePower('5')" />
            <label for="Power5" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-5 ms-2x ms-cost"></i>
            </label>
          </ion-col>
          <ion-col size="auto">
            <input type="checkbox" class="power" id="Power6" [(ngModel)]="Power6" (change)="togglePower('6')" />
            <label for="Power6" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-6 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power7" [(ngModel)]="Power7" (change)="togglePower('7')" />
            <label for="Power7" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-7 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="Power8" [(ngModel)]="Power8" (change)="togglePower('8')" />
            <label for="Power8" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-8-plus ms-2x ms-cost ms-split"></i>
            </label>
            <input type="checkbox" class="power" id="Power0" [(ngModel)]="Power0" (change)="togglePower('0')" />
            <label for="Power0" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-0 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="power" id="PowerX" [(ngModel)]="PowerX" (change)="togglePower('*')" />
            <label for="PowerX" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-x ms-2x ms-cost"></i>
            </label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="(Creature || ArtifactCreature) && platformWidth>767">
          <ion-col size="auto">
            <!-- Selection boxes for creature toughness 0 - 8 -->
            <input type="checkbox" class="toughness" id="Toughness" [(ngModel)]="Toughness1" [disabled]="true" />
            <label for="Toughness" [class.hoverSelection]="isMobile === false">
              <i class="ms pt-icon ms-toughness ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness1" [(ngModel)]="Toughness1" (change)="toggleToughness('1')" />
            <label for="Toughness1" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-1 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness2" [(ngModel)]="Toughness2" (change)="toggleToughness('2')" />
            <label for="Toughness2" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-2 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness3" [(ngModel)]="Toughness3" (change)="toggleToughness('3')" />
            <label for="Toughness3" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-3 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness4" [(ngModel)]="Toughness4" (change)="toggleToughness('4')" />
            <label for="Toughness4" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-4 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness5" [(ngModel)]="Toughness5" (change)="toggleToughness('5')" />
            <label for="Toughness5" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-5 ms-2x ms-cost"></i>
            </label>
          </ion-col>
          <ion-col size="auto">
            <input type="checkbox" class="toughness" id="Toughness6" [(ngModel)]="Toughness6" (change)="toggleToughness('6')" />
            <label for="Toughness6" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-6 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness7" [(ngModel)]="Toughness7" (change)="toggleToughness('7')" />
            <label for="Toughness7" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-7 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness8" [(ngModel)]="Toughness8" (change)="toggleToughness('8')" />
            <label for="Toughness8" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-8-plus ms-2x ms-cost ms-split"></i>
            </label>
            <input type="checkbox" class="toughness" id="Toughness0" [(ngModel)]="Toughness0" (change)="toggleToughness('0')" />
            <label for="Toughness0" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-0 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="toughness" id="ToughnessX" [(ngModel)]="ToughnessX" (change)="toggleToughness('*')" />
            <label for="ToughnessX" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-x ms-2x ms-cost"></i>
            </label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="Planeswalker && platformWidth>767">
          <ion-col size="auto">
            <!-- Selection boxes for planeswalker loytalty 0 - 8 -->
            <input type="checkbox" class="loyalty" id="Loyalty1" [(ngModel)]="Loyalty1" (change)="toggleLoyalty('1')" />
            <label for="Loyalty1" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-1 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty2" [(ngModel)]="Loyalty2" (change)="toggleLoyalty('2')" />
            <label for="Loyalty2" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-2 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty3" [(ngModel)]="Loyalty3" (change)="toggleLoyalty('3')" />
            <label for="Loyalty3" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-3 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty4" [(ngModel)]="Loyalty4" (change)="toggleLoyalty('4')" />
            <label for="Loyalty4" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-4 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty5" [(ngModel)]="Loyalty5" (change)="toggleLoyalty('5')" />
            <label for="Loyalty5" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-5 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty6" [(ngModel)]="Loyalty6" (change)="toggleLoyalty('6')" />
            <label for="Loyalty6" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-6 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="Loyalty7" [(ngModel)]="Loyalty7" (change)="toggleLoyalty('7')" />
            <label for="Loyalty7" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-7 ms-2x"></i>
            </label>
            <input type="checkbox" class="loyalty" id="LoyaltyX" [(ngModel)]="LoyaltyX" (change)="toggleLoyalty('X')" />
            <label for="LoyaltyX" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-loyalty-start ms-loyalty-x ms-2x"></i>
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- Checkbox for adding/removing Rarity selections to the c_filter.rarity attribute 
                Common / Uncommon / Rare / Mythic Rare
                DO NOT SHOW ON SMALL SCREENS (<768) - TO SIMPLIFY LAYOUT
          -->
          <ion-col size="auto" *ngIf="platformWidth>767">
            <input type="checkbox" class="rarity" id="Common" [(ngModel)]="Common" (change)="toggleRarity('Common')" />
            <label for="Common" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-4x ms-cost ss-common"></i>
            </label>
            <input type="checkbox" class="rarity" id="Uncommon" [(ngModel)]="Uncommon" (change)="toggleRarity('Uncommon')" />
            <label for="Uncommon" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-4x ms-cost ss-uncommon"></i>
            </label>
            <input type="checkbox" class="rarity" id="Rare" [(ngModel)]="Rare" (change)="toggleRarity('Rare')" />
            <label for="Rare" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-4x ms-cost ss-rare"></i>
            </label>
            <input type="checkbox" class="rarity" id="Mythic" [(ngModel)]="Mythic" (change)="toggleRarity('Mythic')" />
            <label for="Mythic" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-4x ms-cost ss-mythic"></i>
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- Checkbox for adding/removing Permanent selections to the c_filter.permanent attribute 
                White / Blue / Red / Black / Green / Colorless / Multicolor
                -->
          <ion-col size="auto">
            <input type="checkbox" class="permanent" id="White" [(ngModel)]="White" (change)="togglePermanent('White')" />
            <label for="White" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false"> 
              <i class="ms ms-w ms-4x ms-cost"></i>
              <div class="bottom-subColor" *ngIf="Multicolor">
                <div class="wrapper subWhite">
                  <input type="radio" name="subWhite" id="subWhite1" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="false"
                    class="no-radio">
                  <label for="subWhite1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="subWhite" id="subWhite2" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="null"
                    class="maybe-radio">
                  <label for="subWhite2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="subWhite" id="subWhite3" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="true"
                    class="yes-radio">
                  <label for="subWhite3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </div>
            </label>
            <input type="checkbox" class="permanent" id="Blue" [(ngModel)]="Blue" (change)="togglePermanent('Blue')" />
            <label for="Blue" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
              <i class="ms ms-u ms-4x ms-cost"></i>
              <div class="bottom-subColor" *ngIf="Multicolor">
                <div class="wrapper subBlue">
                  <input type="radio" name="subBlue" id="subBlue1" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="false"
                    class="no-radio">
                  <label for="subBlue1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="subBlue" id="subBlue2" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="null"
                    class="maybe-radio">
                  <label for="subBlue2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="subBlue" id="subBlue3" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="true"
                    class="yes-radio">
                  <label for="subBlue3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </div>
            </label>
            <input type="checkbox" class="permanent" id="Black" [(ngModel)]="Black" (change)="togglePermanent('Black')" />
            <label for="Black" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
              <i class="ms ms-b ms-4x ms-cost"></i>
              <div class="bottom-subColor" *ngIf="Multicolor">
                <div class="wrapper subBlack">
                  <input type="radio" name="subBlack" id="subBlack1" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="false"
                    class="no-radio">
                  <label for="subBlack1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="subBlack" id="subBlack2" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="null"
                    class="maybe-radio">
                  <label for="subBlack2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="subBlack" id="subBlack3" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="true"
                    class="yes-radio">
                  <label for="subBlack3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </div>
            </label>
            <input type="checkbox" class="permanent" id="Red" [(ngModel)]="Red" (change)="togglePermanent('Red')" />
            <label for="Red" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
              <i class="ms ms-r ms-4x ms-cost"></i>
              <div class="bottom-subColor" *ngIf="Multicolor">
                <div class="wrapper subRed">
                  <input type="radio" name="subRed" id="subRed1" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="false"
                    class="no-radio">
                  <label for="subRed1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="subRed" id="subRed2" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="null"
                    class="maybe-radio">
                  <label for="subRed2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="subRed" id="subRed3" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="true"
                    class="yes-radio">
                  <label for="subRed3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </div>
            </label>
          </ion-col>
          <ion-col size="auto">
            <input type="checkbox" class="permanent" id="Green" [(ngModel)]="Green" (change)="togglePermanent('Green')" />
            <label for="Green" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
              <i class="ms ms-g ms-4x ms-cost"></i>
              <div class="bottom-subColor" *ngIf="Multicolor">
                <div class="wrapper subGreen">
                  <input type="radio" name="subGreen" id="subGreen1" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="false"
                    class="no-radio">
                  <label for="subGreen1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="subGreen" id="subGreen2" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="null"
                    class="maybe-radio">
                  <label for="subGreen2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="subGreen" id="subGreen3" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="true"
                    class="yes-radio">
                  <label for="subGreen3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </div>
            </label>
            <input type="checkbox" class="permanent" id="Colorless" [(ngModel)]="Colorless" (change)="togglePermanent('Colorless')" />
            <label for="Colorless" [class.hoverSelection]="Multicolor === false && isMobile === false">
              <i class="ms ms-c ms-4x ms-cost"></i>
            </label>
            <input type="checkbox" class="permanent" id="Multicolor" [(ngModel)]="Multicolor" (change)="togglePermanent('Multicolor')" />
            <label for="Multicolor" [class.hoverSelection]="isMobile === false">
              <div class="circle-container">
                <i class="ms ms-w deg0"></i>
                <i class="ms ms-u deg72"></i>
                <i class="ms ms-b deg144"></i>
                <i class="ms ms-r deg216"></i>
                <i class="ms ms-g deg288"></i>
              </div>
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="auto">
            <!-- Selection boxes for card CMC 0 - 8 -->
            <input type="checkbox" class="CMC" id="CMC0" [(ngModel)]="CMC0" (change)="toggleCMC('0')" />
            <label for="CMC0" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-0 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC1" [(ngModel)]="CMC1" (change)="toggleCMC('1')" />
            <label for="CMC1" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-1 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC2" [(ngModel)]="CMC2" (change)="toggleCMC('2')" />
            <label for="CMC2" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-2 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC3" [(ngModel)]="CMC3" (change)="toggleCMC('3')" />
            <label for="CMC3" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-3 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC4" [(ngModel)]="CMC4" (change)="toggleCMC('4')" />
            <label for="CMC4" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-4 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC5" [(ngModel)]="CMC5" (change)="toggleCMC('5')" />
            <label for="CMC5" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-5 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC6" [(ngModel)]="CMC6" (change)="toggleCMC('6')" />
            <label for="CMC6" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-6 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC7" [(ngModel)]="CMC7" (change)="toggleCMC('7')" />
            <label for="CMC7" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-7 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC8" [(ngModel)]="CMC8" (change)="toggleCMC('8')" />
            <label for="CMC8" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-8-plus ms-2x ms-cost ms-split"></i>
            </label>
            <!-- Additioncal criteria for casting cost boxes, shown after a small space -->
            &nbsp;
            <input type="checkbox" class="CMC" id="XCost" [(ngModel)]="c_filter._hasXCost" (change)="toggleManaSelection('X')" />
            <label for="XCost" [class.hoverSelection]="isMobile === false" *ngIf="(formattedCardCollection | showAbilityFilter:'_hasXCost':format_filter)">
              <i class="ms ms-x ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="HCost" [(ngModel)]="c_filter._hasHCost" (change)="toggleManaSelection('H')" />
            <label for="HCost" [class.hoverSelection]="isMobile === false" *ngIf="(formattedCardCollection | showAbilityFilter:'_hasHCost':format_filter)">
              <i class="ms ms-bg ms-2x ms-cost ms-split"></i>
            </label>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <!-- ADVANCED SELECTIONS -->
    <ion-row class="container_yesno">
      <ion-button id="collapseBtn" fill="solid" expand="full" color="dark" (click)="toggleAdvancedSelections()" aria-expanded="false">
        {{ 'Show Advanced Selections' | translate }}
      </ion-button>
      <div id="collapseDiv" style="display:none;">
      <ion-col>
        <div padding>
          <ion-segment [(ngModel)]="cardAbility" class="container_yesno_header">
            <ion-segment-button value="ability">
              <label>
                {{ 'Ability' | translate }}
              </label>
            </ion-segment-button>
            <ion-segment-button value="action">
              <label>
                {{ 'Action' | translate }}
              </label>
            </ion-segment-button>
            <ion-segment-button value="identity">
              <label>
                {{ 'Identity' | translate }}
              </label>
            </ion-segment-button>
            <ion-segment-button value="mechanics">
              <label>
                {{ 'Mechanics' | translate }}
              </label>
            </ion-segment-button>
          </ion-segment>
        </div>
        <div [ngSwitch]="cardAbility">
          <div *ngSwitchCase="'ability'">
            <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_deathtouch' | translate }}</label>
                <div class="wrapper" text-start>
                  <input type="radio" name="Deathtouch" id="Deathtouch1" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                    [value]="false" class="no-radio">
                  <label for="Deathtouch1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Deathtouch" id="Deathtouch2" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                    [value]="null" class="maybe-radio">
                  <label for="Deathtouch2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Deathtouch" id="Deathtouch3" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                    [value]="true" class="yes-radio">
                  <label for="Deathtouch3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_defender' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Defender" id="Defender1" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                    [value]="false" class="no-radio">
                  <label for="Defender1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Defender" id="Defender2" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                    [value]="null" class="maybe-radio">
                  <label for="Defender2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Defender" id="Defender3" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                    [value]="true" class="yes-radio">
                  <label for="Defender3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_double_strike' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="DoubleStrike" id="DoubleStrike1" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                    [value]="false" class="no-radio">
                  <label for="DoubleStrike1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="DoubleStrike" id="DoubleStrike2" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                    [value]="null" class="maybe-radio">
                  <label for="DoubleStrike2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="DoubleStrike" id="DoubleStrike3" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                    [value]="true" class="yes-radio">
                  <label for="DoubleStrike3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_enchant' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Enchant" id="Enchant1" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="false"
                    class="no-radio">
                  <label for="Enchant1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Enchant" id="Enchant2" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="null"
                    class="maybe-radio">
                  <label for="Enchant2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Enchant" id="Enchant3" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="true"
                    class="yes-radio">
                  <label for="Enchant3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_equip' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Equip" id="Equip1" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="false"
                    class="no-radio">
                  <label for="Equip1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Equip" id="Equip2" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="null"
                    class="maybe-radio">
                  <label for="Equip2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Equip" id="Equip3" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="true"
                    class="yes-radio">
                  <label for="Equip3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_first_strike' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="FirstStrike" id="FirstStrike1" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                    [value]="false" class="no-radio">
                  <label for="FirstStrike1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="FirstStrike" id="FirstStrike2" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                    [value]="null" class="maybe-radio">
                  <label for="FirstStrike2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="FirstStrike" id="FirstStrike3" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                    [value]="true" class="yes-radio">
                  <label for="FirstStrike3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_flash' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Flash" id="Flash1" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="false"
                    class="no-radio">
                  <label for="Flash1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Flash" id="Flash2" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="null"
                    class="maybe-radio">
                  <label for="Flash2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Flash" id="Flash3" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="true"
                    class="yes-radio">
                  <label for="Flash3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_flying' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Flying" id="Flying1" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="false"
                    class="no-radio">
                  <label for="Flying1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Flying" id="Flying2" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="null"
                    class="maybe-radio">
                  <label for="Flying2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Flying" id="Flying3" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="true"
                    class="yes-radio">
                  <label for="Flying3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_haste' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Haste" id="Haste1" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="false"
                    class="no-radio">
                  <label for="Haste1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Haste" id="Haste2" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="null"
                    class="maybe-radio">
                  <label for="Haste2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Haste" id="Haste3" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="true"
                    class="yes-radio">
                  <label for="Haste3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_hexproof' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Hexproof" id="Hexproof1" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                    [value]="false" class="no-radio">
                  <label for="Hexproof1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Hexproof" id="Hexproof2" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                    [value]="null" class="maybe-radio">
                  <label for="Hexproof2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Hexproof" id="Hexproof3" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                    [value]="true" class="yes-radio">
                  <label for="Hexproof3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_indestructible' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Indestructible" id="Indestructible1" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                    [value]="false" class="no-radio">
                  <label for="Indestructible1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Indestructible" id="Indestructible2" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                    [value]="null" class="maybe-radio">
                  <label for="Indestructible2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Indestructible" id="Indestructible3" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                    [value]="true" class="yes-radio">
                  <label for="Indestructible3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_lifelink' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Lifelink" id="Lifelink1" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                    [value]="false" class="no-radio">
                  <label for="Lifelink1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Lifelink" id="Lifelink2" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                    [value]="null" class="maybe-radio">
                  <label for="Lifelink2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Lifelink" id="Lifelink3" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                    [value]="true" class="yes-radio">
                  <label for="Lifelink3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_menace' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Menace" id="Menace1" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="false"
                    class="no-radio">
                  <label for="Menace1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Menace" id="Menace2" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="null"
                    class="maybe-radio">
                  <label for="Menace2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Menace" id="Menace3" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="true"
                    class="yes-radio">
                  <label for="Menace3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_prowess' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Prowess" id="Prowess1" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="false"
                    class="no-radio">
                  <label for="Prowess1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Prowess" id="Prowess2" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="null"
                    class="maybe-radio">
                  <label for="Prowess2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Prowess" id="Prowess3" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="true"
                    class="yes-radio">
                  <label for="Prowess3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_reach' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Reach" id="Reach1" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="false"
                    class="no-radio">
                  <label for="Reach1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Reach" id="Reach2" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="null"
                    class="maybe-radio">
                  <label for="Reach2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Reach" id="Reach3" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="true"
                    class="yes-radio">
                  <label for="Reach3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_trample' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Trample" id="Trample1" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="false"
                    class="no-radio">
                  <label for="Trample1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Trample" id="Trample2" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="null"
                    class="maybe-radio">
                  <label for="Trample2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Trample" id="Trample3" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="true"
                    class="yes-radio">
                  <label for="Trample3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_vigilance' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Vigilance" id="Vigilance1" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                    [value]="false" class="no-radio">
                  <label for="Vigilance1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Vigilance" id="Vigilance2" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                    [value]="null" class="maybe-radio">
                  <label for="Vigilance2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Vigilance" id="Vigilance3" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                    [value]="true" class="yes-radio">
                  <label for="Vigilance3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
            <h4 class="container_yesno_subheader">{{ 'Other' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_absorb':format_filter)">
                <label position="stacked">
                  {{ '_absorb' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Absorb" id="Absorb1" [(ngModel)]="c_filter._absorb" (change)="addAbilityChip('_absorb')" [value]="false"
                    class="no-radio">
                  <label for="Absorb1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Absorb" id="Absorb2" [(ngModel)]="c_filter._absorb" (change)="addAbilityChip('_absorb')" [value]="null"
                    class="maybe-radio">
                  <label for="Absorb2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Absorb" id="Absorb3" [(ngModel)]="c_filter._absorb" (change)="addAbilityChip('_absorb')" [value]="true"
                    class="yes-radio">
                  <label for="Absorb3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_adamant':format_filter)">
                <label position="stacked">
                  {{ '_adamant' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Adamant" id="Adamant1" [(ngModel)]="c_filter._adamant" (change)="addAbilityChip('_adamant')"
                    [value]="false" class="no-radio">
                  <label for="Adamant1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Adamant" id="Adamant2" [(ngModel)]="c_filter._adamant" (change)="addAbilityChip('_adamant')"
                    [value]="null" class="maybe-radio">
                  <label for="Adamant2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Adamant" id="Adamant3" [(ngModel)]="c_filter._adamant" (change)="addAbilityChip('_adamant')"
                    [value]="true" class="yes-radio">
                  <label for="Adamant3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_addendum':format_filter)">
                <label position="stacked">
                  {{ '_addendum' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Addendum" id="Addendum1" [(ngModel)]="c_filter._addendum" (change)="addAbilityChip('_addendum')"
                    [value]="false" class="no-radio">
                  <label for="Addendum1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Addendum" id="Addendum2" [(ngModel)]="c_filter._addendum" (change)="addAbilityChip('_addendum')"
                    [value]="null" class="maybe-radio">
                  <label for="Addendum2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Addendum" id="Addendum3" [(ngModel)]="c_filter._addendum" (change)="addAbilityChip('_addendum')"
                    [value]="true" class="yes-radio">
                  <label for="Addendum3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_affinity':format_filter)">
                <label position="stacked">
                  {{ '_affinity' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Affinity" id="Affinity1" [(ngModel)]="c_filter._affinity" (change)="addAbilityChip('_affinity')"
                    [value]="false" class="no-radio">
                  <label for="Affinity1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Affinity" id="Affinity2" [(ngModel)]="c_filter._affinity" (change)="addAbilityChip('_affinity')"
                    [value]="null" class="maybe-radio">
                  <label for="Affinity2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Affinity" id="Affinity3" [(ngModel)]="c_filter._affinity" (change)="addAbilityChip('_affinity')"
                    [value]="true" class="yes-radio">
                  <label for="Affinity3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_afflict':format_filter)">
                <label position="stacked">
                  {{ '_afflict' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Afflict" id="Afflict1" [(ngModel)]="c_filter._afflict" (change)="addAbilityChip('_afflict')" [value]="false"
                    class="no-radio">
                  <label for="Afflict1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Afflict" id="Afflict2" [(ngModel)]="c_filter._afflict" (change)="addAbilityChip('_afflict')" [value]="null"
                    class="maybe-radio">
                  <label for="Afflict2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Afflict" id="Afflict3" [(ngModel)]="c_filter._afflict" (change)="addAbilityChip('_afflict')" [value]="true"
                    class="yes-radio">
                  <label for="Afflict3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_afterlife':format_filter)">
                <label position="stacked">
                  {{ '_afterlife' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Afterlife" id="Afterlife1" [(ngModel)]="c_filter._afterlife" (change)="addAbilityChip('_afterlife')" [value]="false"
                    class="no-radio">
                  <label for="Afterlife1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Afterlife" id="Afterlife2" [(ngModel)]="c_filter._afterlife" (change)="addAbilityChip('_afterlife')" [value]="null"
                    class="maybe-radio">
                  <label for="Afterlife2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Afterlife" id="Afterlife3" [(ngModel)]="c_filter._afterlife" (change)="addAbilityChip('_afterlife')" [value]="true"
                    class="yes-radio">
                  <label for="Afterlife3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_aftermath':format_filter)">
                <label position="stacked">
                  {{ '_aftermath' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Aftermath" id="Aftermath1" [(ngModel)]="c_filter._aftermath" (change)="addAbilityChip('_aftermath')"
                    [value]="false" class="no-radio">
                  <label for="Aftermath1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Aftermath" id="Aftermath2" [(ngModel)]="c_filter._aftermath" (change)="addAbilityChip('_aftermath')"
                    [value]="null" class="maybe-radio">
                  <label for="Aftermath2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Aftermath" id="Aftermath3" [(ngModel)]="c_filter._aftermath" (change)="addAbilityChip('_aftermath')"
                    [value]="true" class="yes-radio">
                  <label for="Aftermath3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_amplify':format_filter)">
                <label position="stacked">
                  {{ '_amplify' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Amplify" id="Amplify1" [(ngModel)]="c_filter._amplify" (change)="addAbilityChip('_amplify')" [value]="false"
                    class="no-radio">
                  <label for="Amplify1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Amplify" id="Amplify2" [(ngModel)]="c_filter._amplify" (change)="addAbilityChip('_amplify')" [value]="null"
                    class="maybe-radio">
                  <label for="Amplify2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Amplify" id="Amplify3" [(ngModel)]="c_filter._amplify" (change)="addAbilityChip('_amplify')" [value]="true"
                    class="yes-radio">
                  <label for="Amplify3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_annihilator':format_filter)">
                <label position="stacked">
                  {{ '_annihilator' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Annihilator" id="Annihilator1" [(ngModel)]="c_filter._annihilator" (change)="addAbilityChip('_annihilator')"
                    [value]="false" class="no-radio">
                  <label for="Annihilator1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Annihilator" id="Annihilator2" [(ngModel)]="c_filter._annihilator" (change)="addAbilityChip('_annihilator')"
                    [value]="null" class="maybe-radio">
                  <label for="Annihilator2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Annihilator" id="Annihilator3" [(ngModel)]="c_filter._annihilator" (change)="addAbilityChip('_annihilator')"
                    [value]="true" class="yes-radio">
                  <label for="Annihilator3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ascend':format_filter)">
                <label position="stacked">
                  {{ '_ascend' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Ascend" id="Ascend1" [(ngModel)]="c_filter._ascend" (change)="addAbilityChip('_ascend')" [value]="false"
                    class="no-radio">
                  <label for="Ascend1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Ascend" id="Ascend2" [(ngModel)]="c_filter._ascend" (change)="addAbilityChip('_ascend')" [value]="null"
                    class="maybe-radio">
                  <label for="Ascend2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Ascend" id="Ascend3" [(ngModel)]="c_filter._ascend" (change)="addAbilityChip('_ascend')" [value]="true"
                    class="yes-radio">
                  <label for="Ascend3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_aura_swap':format_filter)">
                <label position="stacked">
                  {{ '_aura_swap' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="AuraSwap" id="AuraSwap1" [(ngModel)]="c_filter._aura_swap" (change)="addAbilityChip('_aura_swap')"
                    [value]="false" class="no-radio">
                  <label for="AuraSwap1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="AuraSwap" id="AuraSwap2" [(ngModel)]="c_filter._aura_swap" (change)="addAbilityChip('_aura_swap')"
                    [value]="null" class="maybe-radio">
                  <label for="AuraSwap2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="AuraSwap" id="AuraSwap3" [(ngModel)]="c_filter._aura_swap" (change)="addAbilityChip('_aura_swap')"
                    [value]="true" class="yes-radio">
                  <label for="AuraSwap3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_awaken':format_filter)">
                <label position="stacked">
                  {{ '_awaken' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Awaken" id="Awaken1" [(ngModel)]="c_filter._awaken" (change)="addAbilityChip('_awaken')" [value]="false"
                    class="no-radio">
                  <label for="Awaken1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Awaken" id="Awaken2" [(ngModel)]="c_filter._awaken" (change)="addAbilityChip('_awaken')" [value]="null"
                    class="maybe-radio">
                  <label for="Awaken2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Awaken" id="Awaken3" [(ngModel)]="c_filter._awaken" (change)="addAbilityChip('_awaken')" [value]="true"
                    class="yes-radio">
                  <label for="Awaken3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_banding':format_filter)">
                <label position="stacked">
                  {{ '_banding' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Banding" id="Banding1" [(ngModel)]="c_filter._banding" (change)="addAbilityChip('_banding')" [value]="false"
                    class="no-radio">
                  <label for="Banding1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Banding" id="Banding2" [(ngModel)]="c_filter._banding" (change)="addAbilityChip('_banding')" [value]="null"
                    class="maybe-radio">
                  <label for="Banding2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Banding" id="Banding3" [(ngModel)]="c_filter._banding" (change)="addAbilityChip('_banding')" [value]="true"
                    class="yes-radio">
                  <label for="Banding3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_battalion':format_filter)">
                <label position="stacked">
                  {{ '_battalion' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Battalion" id="Battalion1" [(ngModel)]="c_filter._battalion" (change)="addAbilityChip('_battalion')"
                    [value]="false" class="no-radio">
                  <label for="Battalion1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Battalion" id="Battalion2" [(ngModel)]="c_filter._battalion" (change)="addAbilityChip('_battalion')"
                    [value]="null" class="maybe-radio">
                  <label for="Battalion2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Battalion" id="Battalion3" [(ngModel)]="c_filter._battalion" (change)="addAbilityChip('_battalion')"
                    [value]="true" class="yes-radio">
                  <label for="Battalion3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_battle_cry':format_filter)">
                <label position="stacked">
                  {{ '_battle_cry' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="BattleCry" id="BattleCry1" [(ngModel)]="c_filter._battle_cry" (change)="addAbilityChip('_battle_cry')"
                    [value]="false" class="no-radio">
                  <label for="BattleCry1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="BattleCry" id="BattleCry2" [(ngModel)]="c_filter._battle_cry" (change)="addAbilityChip('_battle_cry')"
                    [value]="null" class="maybe-radio">
                  <label for="BattleCry2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="BattleCry" id="BattleCry3" [(ngModel)]="c_filter._battle_cry" (change)="addAbilityChip('_battle_cry')"
                    [value]="true" class="yes-radio">
                  <label for="BattleCry3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bestow':format_filter)">
                <label position="stacked">
                  {{ '_bestow' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bestow" id="Bestow1" [(ngModel)]="c_filter._bestow" (change)="addAbilityChip('_bestow')" [value]="false"
                    class="no-radio">
                  <label for="Bestow1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bestow" id="Bestow2" [(ngModel)]="c_filter._bestow" (change)="addAbilityChip('_bestow')" [value]="null"
                    class="maybe-radio">
                  <label for="Bestow2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bestow" id="Bestow3" [(ngModel)]="c_filter._bestow" (change)="addAbilityChip('_bestow')" [value]="true"
                    class="yes-radio">
                  <label for="Bestow3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_boast':format_filter)">
                <label position="stacked">
                  {{ '_boast' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Boast" id="Boast1" [(ngModel)]="c_filter._boast" (change)="addAbilityChip('_boast')" [value]="false"
                    class="no-radio">
                  <label for="Boast1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Boast" id="Boast2" [(ngModel)]="c_filter._boast" (change)="addAbilityChip('_boast')" [value]="null"
                    class="maybe-radio">
                  <label for="Boast2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Boast" id="Boast3" [(ngModel)]="c_filter._boast" (change)="addAbilityChip('_boast')" [value]="true"
                    class="yes-radio">
                  <label for="Boast3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bloodrush':format_filter)">
                <label position="stacked">
                  {{ '_bloodrush' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bloodrush" id="Bloodrush1" [(ngModel)]="c_filter._bloodrush" (change)="addAbilityChip('_bloodrush')"
                    [value]="false" class="no-radio">
                  <label for="Bloodrush1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bloodrush" id="Bloodrush2" [(ngModel)]="c_filter._bloodrush" (change)="addAbilityChip('_bloodrush')"
                    [value]="null" class="maybe-radio">
                  <label for="Bloodrush2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bloodrush" id="Bloodrush3" [(ngModel)]="c_filter._bloodrush" (change)="addAbilityChip('_bloodrush')"
                    [value]="true" class="yes-radio">
                  <label for="Bloodrush3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bloodthirst':format_filter)">
                <label position="stacked">
                  {{ '_bloodthirst' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bloodthirst" id="Bloodthirst1" [(ngModel)]="c_filter._bloodthirst" (change)="addAbilityChip('_bloodthirst')"
                    [value]="false" class="no-radio">
                  <label for="Bloodthirst1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bloodthirst" id="Bloodthirst2" [(ngModel)]="c_filter._bloodthirst" (change)="addAbilityChip('_bloodthirst')"
                    [value]="null" class="maybe-radio">
                  <label for="Bloodthirst2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bloodthirst" id="Bloodthirst3" [(ngModel)]="c_filter._bloodthirst" (change)="addAbilityChip('_bloodthirst')"
                    [value]="true" class="yes-radio">
                  <label for="Bloodthirst3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bushido':format_filter)">
                <label position="stacked">
                  {{ '_bushido' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bushido" id="Bushido1" [(ngModel)]="c_filter._bushido" (change)="addAbilityChip('_bushido')" [value]="false"
                    class="no-radio">
                  <label for="Bushido1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bushido" id="Bushido2" [(ngModel)]="c_filter._bushido" (change)="addAbilityChip('_bushido')" [value]="null"
                    class="maybe-radio">
                  <label for="Bushido2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bushido" id="Bushido3" [(ngModel)]="c_filter._bushido" (change)="addAbilityChip('_bushido')" [value]="true"
                    class="yes-radio">
                  <label for="Bushido3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_buyback':format_filter)">
                <label position="stacked">
                  {{ '_buyback' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Buyback" id="Buyback1" [(ngModel)]="c_filter._buyback" (change)="addAbilityChip('_buyback')" [value]="false"
                    class="no-radio">
                  <label for="Buyback1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Buyback" id="Buyback2" [(ngModel)]="c_filter._buyback" (change)="addAbilityChip('_buyback')" [value]="null"
                    class="maybe-radio">
                  <label for="Buyback2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Buyback" id="Buyback3" [(ngModel)]="c_filter._buyback" (change)="addAbilityChip('_buyback')" [value]="true"
                    class="yes-radio">
                  <label for="Buyback3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cascade':format_filter)">
                <label position="stacked">
                  {{ '_cascade' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cascade" id="Cascade1" [(ngModel)]="c_filter._cascade" (change)="addAbilityChip('_cascade')" [value]="false"
                    class="no-radio">
                  <label for="Cascade1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cascade" id="Cascade2" [(ngModel)]="c_filter._cascade" (change)="addAbilityChip('_cascade')" [value]="null"
                    class="maybe-radio">
                  <label for="Cascade2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cascade" id="Cascade3" [(ngModel)]="c_filter._cascade" (change)="addAbilityChip('_cascade')" [value]="true"
                    class="yes-radio">
                  <label for="Cascade3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_champion':format_filter)">
                <label position="stacked">
                  {{ '_champion' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Champion" id="Champion1" [(ngModel)]="c_filter._champion" (change)="addAbilityChip('_champion')"
                    [value]="false" class="no-radio">
                  <label for="Champion1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Champion" id="Champion2" [(ngModel)]="c_filter._champion" (change)="addAbilityChip('_champion')"
                    [value]="null" class="maybe-radio">
                  <label for="Champion2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Champion" id="Champion3" [(ngModel)]="c_filter._champion" (change)="addAbilityChip('_champion')"
                    [value]="true" class="yes-radio">
                  <label for="Champion3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_channel':format_filter)">
                <label position="stacked">
                  {{ '_channel' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Channel" id="Channel1" [(ngModel)]="c_filter._channel" (change)="addAbilityChip('_channel')" [value]="false"
                    class="no-radio">
                  <label for="Channel1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Channel" id="Channel2" [(ngModel)]="c_filter._channel" (change)="addAbilityChip('_channel')" [value]="null"
                    class="maybe-radio">
                  <label for="Channel2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Channel" id="Channel3" [(ngModel)]="c_filter._channel" (change)="addAbilityChip('_channel')" [value]="true"
                    class="yes-radio">
                  <label for="Channel3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_changeling':format_filter)">
                <label position="stacked">
                  {{ '_changeling' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Changeling" id="Changeling1" [(ngModel)]="c_filter._changeling" (change)="addAbilityChip('_changeling')"
                    [value]="false" class="no-radio">
                  <label for="Changeling1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Changeling" id="Changeling2" [(ngModel)]="c_filter._changeling" (change)="addAbilityChip('_changeling')"
                    [value]="null" class="maybe-radio">
                  <label for="Changeling2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Changeling" id="Changeling3" [(ngModel)]="c_filter._changeling" (change)="addAbilityChip('_changeling')"
                    [value]="true" class="yes-radio">
                  <label for="Changeling3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_chroma':format_filter)">
                <label position="stacked">
                  {{ '_chroma' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Chroma" id="Chroma1" [(ngModel)]="c_filter._chroma" (change)="addAbilityChip('_chroma')" [value]="false"
                    class="no-radio">
                  <label for="Chroma1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Chroma" id="Chroma2" [(ngModel)]="c_filter._chroma" (change)="addAbilityChip('_chroma')" [value]="null"
                    class="maybe-radio">
                  <label for="Chroma2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Chroma" id="Chroma3" [(ngModel)]="c_filter._chroma" (change)="addAbilityChip('_chroma')" [value]="true"
                    class="yes-radio">
                  <label for="Chroma3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cipher':format_filter)">
                <label position="stacked">
                  {{ '_cipher' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cipher" id="Cipher1" [(ngModel)]="c_filter._cipher" (change)="addAbilityChip('_cipher')" [value]="false"
                    class="no-radio">
                  <label for="Cipher1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cipher" id="Cipher2" [(ngModel)]="c_filter._cipher" (change)="addAbilityChip('_cipher')" [value]="null"
                    class="maybe-radio">
                  <label for="Cipher2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cipher" id="Cipher3" [(ngModel)]="c_filter._cipher" (change)="addAbilityChip('_cipher')" [value]="true"
                    class="yes-radio">
                  <label for="Cipher3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cohort':format_filter)">
                <label position="stacked">
                  {{ '_cohort' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cohort" id="Cohort1" [(ngModel)]="c_filter._cohort" (change)="addAbilityChip('_cohort')" [value]="false"
                    class="no-radio">
                  <label for="Cohort1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cohort" id="Cohort2" [(ngModel)]="c_filter._cohort" (change)="addAbilityChip('_cohort')" [value]="null"
                    class="maybe-radio">
                  <label for="Cohort2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cohort" id="Cohort3" [(ngModel)]="c_filter._cohort" (change)="addAbilityChip('_cohort')" [value]="true"
                    class="yes-radio">
                  <label for="Cohort3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_companion':format_filter)">
                <label position="stacked">
                  {{ '_companion' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Companion" id="Companion1" [(ngModel)]="c_filter._companion" (change)="addAbilityChip('_companion')"
                    [value]="false" class="no-radio">
                  <label for="Companion1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Companion" id="Companion2" [(ngModel)]="c_filter._companion" (change)="addAbilityChip('_companion')"
                    [value]="null" class="maybe-radio">
                  <label for="Companion2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Companion" id="Companion3" [(ngModel)]="c_filter._companion" (change)="addAbilityChip('_companion')"
                    [value]="true" class="yes-radio">
                  <label for="Companion3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_conspire':format_filter)">
                <label position="stacked">
                  {{ '_conspire' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Conspire" id="Conspire1" [(ngModel)]="c_filter._conspire" (change)="addAbilityChip('_conspire')"
                    [value]="false" class="no-radio">
                  <label for="Conspire1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Conspire" id="Conspire2" [(ngModel)]="c_filter._conspire" (change)="addAbilityChip('_conspire')"
                    [value]="null" class="maybe-radio">
                  <label for="Conspire2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Conspire" id="Conspire3" [(ngModel)]="c_filter._conspire" (change)="addAbilityChip('_conspire')"
                    [value]="true" class="yes-radio">
                  <label for="Conspire3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_constellation':format_filter)">
                <label position="stacked">
                  {{ '_constellation' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Constellation" id="Constellation1" [(ngModel)]="c_filter._constellation" (change)="addAbilityChip('_constellation')"
                    [value]="false" class="no-radio">
                  <label for="Constellation1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Constellation" id="Constellation2" [(ngModel)]="c_filter._constellation" (change)="addAbilityChip('_constellation')"
                    [value]="null" class="maybe-radio">
                  <label for="Constellation2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Constellation" id="Constellation3" [(ngModel)]="c_filter._constellation" (change)="addAbilityChip('_constellation')"
                    [value]="true" class="yes-radio">
                  <label for="Constellation3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_converge':format_filter)">
                <label position="stacked">
                  {{ '_converge' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Converge" id="Converge1" [(ngModel)]="c_filter._converge" (change)="addAbilityChip('_converge')"
                    [value]="false" class="no-radio">
                  <label for="Converge1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Converge" id="Converge2" [(ngModel)]="c_filter._converge" (change)="addAbilityChip('_converge')"
                    [value]="null" class="maybe-radio">
                  <label for="Converge2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Converge" id="Converge3" [(ngModel)]="c_filter._converge" (change)="addAbilityChip('_converge')"
                    [value]="true" class="yes-radio">
                  <label for="Converge3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_convoke':format_filter)">
                <label position="stacked">
                  {{ '_convoke' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Convoke" id="Convoke1" [(ngModel)]="c_filter._convoke" (change)="addAbilityChip('_convoke')" [value]="false"
                    class="no-radio">
                  <label for="Convoke1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Convoke" id="Convoke2" [(ngModel)]="c_filter._convoke" (change)="addAbilityChip('_convoke')" [value]="null"
                    class="maybe-radio">
                  <label for="Convoke2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Convoke" id="Convoke3" [(ngModel)]="c_filter._convoke" (change)="addAbilityChip('_convoke')" [value]="true"
                    class="yes-radio">
                  <label for="Convoke3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_coven':format_filter)">
                <label position="stacked">
                  {{ '_coven' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Coven" id="Coven1" [(ngModel)]="c_filter._coven" (change)="addAbilityChip('_coven')" [value]="false"
                    class="no-radio">
                  <label for="Coven1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Coven" id="Coven2" [(ngModel)]="c_filter._coven" (change)="addAbilityChip('_coven')" [value]="null"
                    class="maybe-radio">
                  <label for="Coven2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Coven" id="Coven3" [(ngModel)]="c_filter._coven" (change)="addAbilityChip('_coven')" [value]="true"
                    class="yes-radio">
                  <label for="Coven3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_crew':format_filter)">
                <label position="stacked">
                  {{ '_crew' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Crew" id="Crew1" [(ngModel)]="c_filter._crew" (change)="addAbilityChip('_crew')" [value]="false"
                    class="no-radio">
                  <label for="Crew1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Crew" id="Crew2" [(ngModel)]="c_filter._crew" (change)="addAbilityChip('_crew')" [value]="null"
                    class="maybe-radio">
                  <label for="Crew2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Crew" id="Crew3" [(ngModel)]="c_filter._crew" (change)="addAbilityChip('_crew')" [value]="true"
                    class="yes-radio">
                  <label for="Crew3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cumulative_upkeep':format_filter)">
                <label position="stacked">
                  {{ '_cumulative_upkeep' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CumUpkeep" id="CumUpkeep1" [(ngModel)]="c_filter._cumulative_upkeep" (change)="addAbilityChip('_cumulative_upkeep')"
                    [value]="false" class="no-radio">
                  <label for="CumUpkeep1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CumUpkeep" id="CumUpkeep2" [(ngModel)]="c_filter._cumulative_upkeep" (change)="addAbilityChip('_cumulative_upkeep')"
                    [value]="null" class="maybe-radio">
                  <label for="CumUpkeep2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CumUpkeep" id="CumUpkeep3" [(ngModel)]="c_filter._cumulative_upkeep" (change)="addAbilityChip('_cumulative_upkeep')"
                    [value]="true" class="yes-radio">
                  <label for="CumUpkeep3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cycling':format_filter)">
                <label position="stacked">
                  {{ '_cycling' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cycling" id="Cycling1" [(ngModel)]="c_filter._cycling" (change)="addAbilityChip('_cycling')" [value]="false"
                    class="no-radio">
                  <label for="Cycling1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cycling" id="Cycling2" [(ngModel)]="c_filter._cycling" (change)="addAbilityChip('_cycling')" [value]="null"
                    class="maybe-radio">
                  <label for="Cycling2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cycling" id="Cycling3" [(ngModel)]="c_filter._cycling" (change)="addAbilityChip('_cycling')" [value]="true"
                    class="yes-radio">
                  <label for="Cycling3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_dash':format_filter)">
                <label position="stacked">
                  {{ '_dash' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Dash" id="Dash1" [(ngModel)]="c_filter._dash" (change)="addAbilityChip('_dash')" [value]="false"
                    class="no-radio">
                  <label for="Dash1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Dash" id="Dash2" [(ngModel)]="c_filter._dash" (change)="addAbilityChip('_dash')" [value]="null"
                    class="maybe-radio">
                  <label for="Dash2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Dash" id="Dash3" [(ngModel)]="c_filter._dash" (change)="addAbilityChip('_dash')" [value]="true"
                    class="yes-radio">
                  <label for="Dash3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_daybound':format_filter)">
                <label position="stacked">
                  {{ '_daybound' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Daybound" id="Daybound1" [(ngModel)]="c_filter._daybound" (change)="addAbilityChip('_daybound')" [value]="false"
                    class="no-radio">
                  <label for="Daybound1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Daybound" id="Daybound2" [(ngModel)]="c_filter._daybound" (change)="addAbilityChip('_daybound')" [value]="null"
                    class="maybe-radio">
                  <label for="Daybound2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Daybound" id="Daybound3" [(ngModel)]="c_filter._daybound" (change)="addAbilityChip('_daybound')" [value]="true"
                    class="yes-radio">
                  <label for="Daybound3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_decayed':format_filter)">
                <label position="stacked">
                  {{ '_decayed' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Decayed" id="Decayed1" [(ngModel)]="c_filter._decayed" (change)="addAbilityChip('_decayed')" [value]="false"
                    class="no-radio">
                  <label for="Decayed1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Decayed" id="Decayed2" [(ngModel)]="c_filter._decayed" (change)="addAbilityChip('_decayed')" [value]="null"
                    class="maybe-radio">
                  <label for="Decayed2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Decayed" id="Decayed3" [(ngModel)]="c_filter._decayed" (change)="addAbilityChip('_decayed')" [value]="true"
                    class="yes-radio">
                  <label for="Decayed3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_delirium':format_filter)">
                <label position="stacked">
                  {{ '_delirium' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Delirium" id="Delirium1" [(ngModel)]="c_filter._delirium" (change)="addAbilityChip('_delirium')"
                    [value]="false" class="no-radio">
                  <label for="Delirium1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Delirium" id="Delirium2" [(ngModel)]="c_filter._delirium" (change)="addAbilityChip('_delirium')"
                    [value]="null" class="maybe-radio">
                  <label for="Delirium2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Delirium" id="Delirium3" [(ngModel)]="c_filter._delirium" (change)="addAbilityChip('_delirium')"
                    [value]="true" class="yes-radio">
                  <label for="Delirium3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_delve':format_filter)">
                <label position="stacked">
                  {{ '_delve' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Delve" id="Delve1" [(ngModel)]="c_filter._delve" (change)="addAbilityChip('_delve')" [value]="false"
                    class="no-radio">
                  <label for="Delve1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Delve" id="Delve2" [(ngModel)]="c_filter._delve" (change)="addAbilityChip('_delve')" [value]="null"
                    class="maybe-radio">
                  <label for="Delve2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Delve" id="Delve3" [(ngModel)]="c_filter._delve" (change)="addAbilityChip('_delve')" [value]="true"
                    class="yes-radio">
                  <label for="Delve3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_dethrone':format_filter)">
                <label position="stacked">
                  {{ '_dethrone' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Dethrone" id="Dethrone1" [(ngModel)]="c_filter._dethrone" (change)="addAbilityChip('_dethrone')"
                    [value]="false" class="no-radio">
                  <label for="Dethrone1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Dethrone" id="Dethrone2" [(ngModel)]="c_filter._dethrone" (change)="addAbilityChip('_dethrone')"
                    [value]="null" class="maybe-radio">
                  <label for="Dethrone2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Dethrone" id="Dethrone3" [(ngModel)]="c_filter._dethrone" (change)="addAbilityChip('_dethrone')"
                    [value]="true" class="yes-radio">
                  <label for="Dethrone3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_devoid':format_filter)">
                <label position="stacked">
                  {{ '_devoid' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Devoid" id="Devoid1" [(ngModel)]="c_filter._devoid" (change)="addAbilityChip('_devoid')" [value]="false"
                    class="no-radio">
                  <label for="Devoid1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Devoid" id="Devoid2" [(ngModel)]="c_filter._devoid" (change)="addAbilityChip('_devoid')" [value]="null"
                    class="maybe-radio">
                  <label for="Devoid2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Devoid" id="Devoid3" [(ngModel)]="c_filter._devoid" (change)="addAbilityChip('_devoid')" [value]="true"
                    class="yes-radio">
                  <label for="Devoid3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_devour':format_filter)">
                <label position="stacked">
                  {{ '_devour' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Devour" id="Devour1" [(ngModel)]="c_filter._devour" (change)="addAbilityChip('_devour')" [value]="false"
                    class="no-radio">
                  <label for="Devour1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Devour" id="Devour2" [(ngModel)]="c_filter._devour" (change)="addAbilityChip('_devour')" [value]="null"
                    class="maybe-radio">
                  <label for="Devour2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Devour" id="Devour3" [(ngModel)]="c_filter._devour" (change)="addAbilityChip('_devour')" [value]="true"
                    class="yes-radio">
                  <label for="Devour3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_disturb':format_filter)">
                <label position="stacked">
                  {{ '_disturb' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Disturb" id="Disturb1" [(ngModel)]="c_filter._disturb" (change)="addAbilityChip('_disturb')" [value]="false"
                    class="no-radio">
                  <label for="Disturb1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Disturb" id="Disturb2" [(ngModel)]="c_filter._disturb" (change)="addAbilityChip('_disturb')" [value]="null"
                    class="maybe-radio">
                  <label for="Disturb2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Disturb" id="Disturb3" [(ngModel)]="c_filter._disturb" (change)="addAbilityChip('_disturb')" [value]="true"
                    class="yes-radio">
                  <label for="Disturb3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_domain':format_filter)">
                <label position="stacked">
                  {{ '_domain' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Domain" id="Domain1" [(ngModel)]="c_filter._domain" (change)="addAbilityChip('_domain')" [value]="false"
                    class="no-radio">
                  <label for="Domain1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Domain" id="Domain2" [(ngModel)]="c_filter._domain" (change)="addAbilityChip('_domain')" [value]="null"
                    class="maybe-radio">
                  <label for="Domain2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Domain" id="Domain3" [(ngModel)]="c_filter._domain" (change)="addAbilityChip('_domain')" [value]="true"
                    class="yes-radio">
                  <label for="Domain3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_dredge':format_filter)">
                <label position="stacked">
                  {{ '_dredge' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Dredge" id="Dredge1" [(ngModel)]="c_filter._dredge" (change)="addAbilityChip('_dredge')" [value]="false"
                    class="no-radio">
                  <label for="Dredge1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Dredge" id="Dredge2" [(ngModel)]="c_filter._dredge" (change)="addAbilityChip('_dredge')" [value]="null"
                    class="maybe-radio">
                  <label for="Dredge2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Dredge" id="Dredge3" [(ngModel)]="c_filter._dredge" (change)="addAbilityChip('_dredge')" [value]="true"
                    class="yes-radio">
                  <label for="Dredge3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_echo':format_filter)">
                <label position="stacked">
                  {{ '_echo' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Echo" id="Echo1" [(ngModel)]="c_filter._echo" (change)="addAbilityChip('_echo')" [value]="false"
                    class="no-radio">
                  <label for="Echo1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Echo" id="Echo2" [(ngModel)]="c_filter._echo" (change)="addAbilityChip('_echo')" [value]="null"
                    class="maybe-radio">
                  <label for="Echo2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Echo" id="Echo3" [(ngModel)]="c_filter._echo" (change)="addAbilityChip('_echo')" [value]="true"
                    class="yes-radio">
                  <label for="Echo3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_embalm':format_filter)">
                <label position="stacked">
                  {{ '_embalm' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Embalm" id="Embalm1" [(ngModel)]="c_filter._embalm" (change)="addAbilityChip('_embalm')" [value]="false"
                    class="no-radio">
                  <label for="Embalm1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Embalm" id="Embalm2" [(ngModel)]="c_filter._embalm" (change)="addAbilityChip('_embalm')" [value]="null"
                    class="maybe-radio">
                  <label for="Embalm2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Embalm" id="Embalm3" [(ngModel)]="c_filter._embalm" (change)="addAbilityChip('_embalm')" [value]="true"
                    class="yes-radio">
                  <label for="Embalm3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_emerge':format_filter)">
                <label position="stacked">
                  {{ '_emerge' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Emerge" id="Emerge1" [(ngModel)]="c_filter._emerge" (change)="addAbilityChip('_emerge')" [value]="false"
                    class="no-radio">
                  <label for="Emerge1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Emerge" id="Emerge2" [(ngModel)]="c_filter._emerge" (change)="addAbilityChip('_emerge')" [value]="null"
                    class="maybe-radio">
                  <label for="Emerge2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Emerge" id="Emerge3" [(ngModel)]="c_filter._emerge" (change)="addAbilityChip('_emerge')" [value]="true"
                    class="yes-radio">
                  <label for="Emerge3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_eminence':format_filter)">
                <label position="stacked">
                  {{ '_eminence' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Eminence" id="Eminence1" [(ngModel)]="c_filter._eminence" (change)="addAbilityChip('_eminence')"
                    [value]="false" class="no-radio">
                  <label for="Eminence1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Eminence" id="Eminence2" [(ngModel)]="c_filter._eminence" (change)="addAbilityChip('_eminence')"
                    [value]="null" class="maybe-radio">
                  <label for="Eminence2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Eminence" id="Eminence3" [(ngModel)]="c_filter._eminence" (change)="addAbilityChip('_eminence')"
                    [value]="true" class="yes-radio">
                  <label for="Eminence3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_enrage':format_filter)">
                <label position="stacked">
                  {{ '_enrage' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Enrage" id="Enrage1" [(ngModel)]="c_filter._enrage" (change)="addAbilityChip('_enrage')" [value]="false"
                    class="no-radio">
                  <label for="Enrage1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Enrage" id="Enrage2" [(ngModel)]="c_filter._enrage" (change)="addAbilityChip('_enrage')" [value]="null"
                    class="maybe-radio">
                  <label for="Enrage2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Enrage" id="Enrage3" [(ngModel)]="c_filter._enrage" (change)="addAbilityChip('_enrage')" [value]="true"
                    class="yes-radio">
                  <label for="Enrage3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_entwine':format_filter)">
                <label position="stacked">
                  {{ '_entwine' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Entwine" id="Entwine1" [(ngModel)]="c_filter._entwine" (change)="addAbilityChip('_entwine')" [value]="false"
                    class="no-radio">
                  <label for="Entwine1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Entwine" id="Entwine2" [(ngModel)]="c_filter._entwine" (change)="addAbilityChip('_entwine')" [value]="null"
                    class="maybe-radio">
                  <label for="Entwine2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Entwine" id="Entwine3" [(ngModel)]="c_filter._entwine" (change)="addAbilityChip('_entwine')" [value]="true"
                    class="yes-radio">
                  <label for="Entwine3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_epic':format_filter)">
                <label position="stacked">
                  {{ '_epic' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Epic" id="Epic1" [(ngModel)]="c_filter._epic" (change)="addAbilityChip('_epic')" [value]="false"
                    class="no-radio">
                  <label for="Epic1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Epic" id="Epic2" [(ngModel)]="c_filter._epic" (change)="addAbilityChip('_epic')" [value]="null"
                    class="maybe-radio">
                  <label for="Epic2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Epic" id="Epic3" [(ngModel)]="c_filter._epic" (change)="addAbilityChip('_epic')" [value]="true"
                    class="yes-radio">
                  <label for="Epic3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_escalate':format_filter)">
                <label position="stacked">
                  {{ '_escalate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Escalate" id="Escalate1" [(ngModel)]="c_filter._escalate" (change)="addAbilityChip('_escalate')"
                    [value]="false" class="no-radio">
                  <label for="Escalate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Escalate" id="Escalate2" [(ngModel)]="c_filter._escalate" (change)="addAbilityChip('_escalate')"
                    [value]="null" class="maybe-radio">
                  <label for="Escalate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Escalate" id="Escalate3" [(ngModel)]="c_filter._escalate" (change)="addAbilityChip('_escalate')"
                    [value]="true" class="yes-radio">
                  <label for="Escalate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_escape':format_filter)">
                <label position="stacked">
                  {{ '_escape' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Escape" id="Escape1" [(ngModel)]="c_filter._escape" (change)="addAbilityChip('_escape')"
                    [value]="false" class="no-radio">
                  <label for="Escape1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Escape" id="Escape2" [(ngModel)]="c_filter._escape" (change)="addAbilityChip('_escape')"
                    [value]="null" class="maybe-radio">
                  <label for="Escape2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Escape" id="Escape3" [(ngModel)]="c_filter._escape" (change)="addAbilityChip('_escape')"
                    [value]="true" class="yes-radio">
                  <label for="Escape3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_eternalize':format_filter)">
                <label position="stacked">
                  {{ '_eternalize' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Eternalize" id="Eternalize1" [(ngModel)]="c_filter._eternalize" (change)="addAbilityChip('_eternalize')"
                    [value]="false" class="no-radio">
                  <label for="Eternalize1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Eternalize" id="Eternalize2" [(ngModel)]="c_filter._eternalize" (change)="addAbilityChip('_eternalize')"
                    [value]="null" class="maybe-radio">
                  <label for="Eternalize2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Eternalize" id="Eternalize3" [(ngModel)]="c_filter._eternalize" (change)="addAbilityChip('_eternalize')"
                    [value]="true" class="yes-radio">
                  <label for="Eternalize3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_evoke':format_filter)">
                <label position="stacked">
                  {{ '_evoke' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Evoke" id="Evoke1" [(ngModel)]="c_filter._evoke" (change)="addAbilityChip('_evoke')" [value]="false"
                    class="no-radio">
                  <label for="Evoke1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Evoke" id="Evoke2" [(ngModel)]="c_filter._evoke" (change)="addAbilityChip('_evoke')" [value]="null"
                    class="maybe-radio">
                  <label for="Evoke2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Evoke" id="Evoke3" [(ngModel)]="c_filter._evoke" (change)="addAbilityChip('_evoke')" [value]="true"
                    class="yes-radio">
                  <label for="Evoke3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_evolve':format_filter)">
                <label position="stacked">
                  {{ '_evolve' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Evolve" id="Evolve1" [(ngModel)]="c_filter._evolve" (change)="addAbilityChip('_evolve')" [value]="false"
                    class="no-radio">
                  <label for="Evolve1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Evolve" id="Evolve2" [(ngModel)]="c_filter._evolve" (change)="addAbilityChip('_evolve')" [value]="null"
                    class="maybe-radio">
                  <label for="Evolve2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Evolve" id="Evolve3" [(ngModel)]="c_filter._evolve" (change)="addAbilityChip('_evolve')" [value]="true"
                    class="yes-radio">
                  <label for="Evolve3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exalted':format_filter)">
                <label position="stacked">
                  {{ '_exalted' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Exalted" id="Exalted1" [(ngModel)]="c_filter._exalted" (change)="addAbilityChip('_exalted')" [value]="false"
                    class="no-radio">
                  <label for="Exalted1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Exalted" id="Exalted2" [(ngModel)]="c_filter._exalted" (change)="addAbilityChip('_exalted')" [value]="null"
                    class="maybe-radio">
                  <label for="Exalted2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Exalted" id="Exalted3" [(ngModel)]="c_filter._exalted" (change)="addAbilityChip('_exalted')" [value]="true"
                    class="yes-radio">
                  <label for="Exalted3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exploit':format_filter)">
                <label position="stacked">
                  {{ '_exploit' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Exploit" id="Exploit1" [(ngModel)]="c_filter._exploit" (change)="addAbilityChip('_exploit')" [value]="false"
                    class="no-radio">
                  <label for="Exploit1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Exploit" id="Exploit2" [(ngModel)]="c_filter._exploit" (change)="addAbilityChip('_exploit')" [value]="null"
                    class="maybe-radio">
                  <label for="Exploit2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Exploit" id="Exploit3" [(ngModel)]="c_filter._exploit" (change)="addAbilityChip('_exploit')" [value]="true"
                    class="yes-radio">
                  <label for="Exploit3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_extort':format_filter)">
                <label position="stacked">
                  {{ '_extort' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Extort" id="Extort1" [(ngModel)]="c_filter._extort" (change)="addAbilityChip('_extort')" [value]="false"
                    class="no-radio">
                  <label for="Extort1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Extort" id="Extort2" [(ngModel)]="c_filter._extort" (change)="addAbilityChip('_extort')" [value]="null"
                    class="maybe-radio">
                  <label for="Extort2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Extort" id="Extort3" [(ngModel)]="c_filter._extort" (change)="addAbilityChip('_extort')" [value]="true"
                    class="yes-radio">
                  <label for="Extort3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fabricate':format_filter)">
                <label position="stacked">
                  {{ '_fabricate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fabricate" id="Fabricate1" [(ngModel)]="c_filter._fabricate" (change)="addAbilityChip('_fabricate')"
                    [value]="false" class="no-radio">
                  <label for="Fabricate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fabricate" id="Fabricate2" [(ngModel)]="c_filter._fabricate" (change)="addAbilityChip('_fabricate')"
                    [value]="null" class="maybe-radio">
                  <label for="Fabricate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fabricate" id="Fabricate3" [(ngModel)]="c_filter._fabricate" (change)="addAbilityChip('_fabricate')"
                    [value]="true" class="yes-radio">
                  <label for="Fabricate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fading':format_filter)">
                <label position="stacked">
                  {{ '_fading' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fading" id="Fading1" [(ngModel)]="c_filter._fading" (change)="addAbilityChip('_fading')" [value]="false"
                    class="no-radio">
                  <label for="Fading1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fading" id="Fading2" [(ngModel)]="c_filter._fading" (change)="addAbilityChip('_fading')" [value]="null"
                    class="maybe-radio">
                  <label for="Fading2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fading" id="Fading3" [(ngModel)]="c_filter._fading" (change)="addAbilityChip('_fading')" [value]="true"
                    class="yes-radio">
                  <label for="Fading3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fateful_hour':format_filter)">
                <label position="stacked">
                  {{ '_fateful_hour' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="FatefulHour" id="FatefulHour1" [(ngModel)]="c_filter._fateful_hour" (change)="addAbilityChip('_fateful_hour')"
                    [value]="false" class="no-radio">
                  <label for="FatefulHour1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="FatefulHour" id="FatefulHour2" [(ngModel)]="c_filter._fateful_hour" (change)="addAbilityChip('_fateful_hour')"
                    [value]="null" class="maybe-radio">
                  <label for="FatefulHour2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="FatefulHour" id="FatefulHour3" [(ngModel)]="c_filter._fateful_hour" (change)="addAbilityChip('_fateful_hour')"
                    [value]="true" class="yes-radio">
                  <label for="FatefulHour3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fear':format_filter)">
                <label position="stacked">
                  {{ '_fear' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fear" id="Fear1" [(ngModel)]="c_filter._fear" (change)="addAbilityChip('_fear')" [value]="false"
                    class="no-radio">
                  <label for="Fear1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fear" id="Fear2" [(ngModel)]="c_filter._fear" (change)="addAbilityChip('_fear')" [value]="null"
                    class="maybe-radio">
                  <label for="Fear2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fear" id="Fear3" [(ngModel)]="c_filter._fear" (change)="addAbilityChip('_fear')" [value]="true"
                    class="yes-radio">
                  <label for="Fear3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ferocious':format_filter)">
                <label position="stacked">
                  {{ '_ferocious' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Ferocious" id="Ferocious1" [(ngModel)]="c_filter._ferocious" (change)="addAbilityChip('_ferocious')"
                    [value]="false" class="no-radio">
                  <label for="Ferocious1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Ferocious" id="Ferocious2" [(ngModel)]="c_filter._ferocious" (change)="addAbilityChip('_ferocious')"
                    [value]="null" class="maybe-radio">
                  <label for="Ferocious2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Ferocious" id="Ferocious3" [(ngModel)]="c_filter._ferocious" (change)="addAbilityChip('_ferocious')"
                    [value]="true" class="yes-radio">
                  <label for="Ferocious3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_flanking':format_filter)">
                <label position="stacked">
                  {{ '_flanking' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Flanking" id="Flanking1" [(ngModel)]="c_filter._flanking" (change)="addAbilityChip('_flanking')"
                    [value]="false" class="no-radio">
                  <label for="Flanking1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Flanking" id="Flanking2" [(ngModel)]="c_filter._flanking" (change)="addAbilityChip('_flanking')"
                    [value]="null" class="maybe-radio">
                  <label for="Flanking2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Flanking" id="Flanking3" [(ngModel)]="c_filter._flanking" (change)="addAbilityChip('_flanking')"
                    [value]="true" class="yes-radio">
                  <label for="Flanking3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_flashback':format_filter)">
                <label position="stacked">
                  {{ '_flashback' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Flashback" id="Flashback1" [(ngModel)]="c_filter._flashback" (change)="addAbilityChip('_flashback')"
                    [value]="false" class="no-radio">
                  <label for="Flashback1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Flashback" id="Flashback2" [(ngModel)]="c_filter._flashback" (change)="addAbilityChip('_flashback')"
                    [value]="null" class="maybe-radio">
                  <label for="Flashback2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Flashback" id="Flashback3" [(ngModel)]="c_filter._flashback" (change)="addAbilityChip('_flashback')"
                    [value]="true" class="yes-radio">
                  <label for="Flashback3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_forecast':format_filter)">
                <label position="stacked">
                  {{ '_forecast' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Forecast" id="Forecast1" [(ngModel)]="c_filter._forecast" (change)="addAbilityChip('_forecast')"
                    [value]="false" class="no-radio">
                  <label for="Forecast1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Forecast" id="Forecast2" [(ngModel)]="c_filter._forecast" (change)="addAbilityChip('_forecast')"
                    [value]="null" class="maybe-radio">
                  <label for="Forecast2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Forecast" id="Forecast3" [(ngModel)]="c_filter._forecast" (change)="addAbilityChip('_forecast')"
                    [value]="true" class="yes-radio">
                  <label for="Forecast3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_foretell':format_filter)">
                <label position="stacked">
                  {{ '_foretell' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Foretell" id="Foretell1" [(ngModel)]="c_filter._foretell" (change)="addAbilityChip('_foretell')"
                    [value]="false" class="no-radio">
                  <label for="Foretell1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Foretell" id="Foretell2" [(ngModel)]="c_filter._foretell" (change)="addAbilityChip('_foretell')"
                    [value]="null" class="maybe-radio">
                  <label for="Foretell2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Foretell" id="Foretell3" [(ngModel)]="c_filter._foretell" (change)="addAbilityChip('_foretell')"
                    [value]="true" class="yes-radio">
                  <label for="Foretell3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_formidable':format_filter)">
                <label position="stacked">
                  {{ '_formidable' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Formidable" id="Formidable1" [(ngModel)]="c_filter._formidable" (change)="addAbilityChip('_formidable')"
                    [value]="false" class="no-radio">
                  <label for="Formidable1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Formidable" id="Formidable2" [(ngModel)]="c_filter._formidable" (change)="addAbilityChip('_formidable')"
                    [value]="null" class="maybe-radio">
                  <label for="Formidable2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Formidable" id="Formidable3" [(ngModel)]="c_filter._formidable" (change)="addAbilityChip('_formidable')"
                    [value]="true" class="yes-radio">
                  <label for="Formidable3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fortify':format_filter)">
                <label position="stacked">
                  {{ '_fortify' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fortify" id="Fortify1" [(ngModel)]="c_filter._fortify" (change)="addAbilityChip('_fortify')" [value]="false"
                    class="no-radio">
                  <label for="Fortify1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fortify" id="Fortify2" [(ngModel)]="c_filter._fortify" (change)="addAbilityChip('_fortify')" [value]="null"
                    class="maybe-radio">
                  <label for="Fortify2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fortify" id="Fortify3" [(ngModel)]="c_filter._fortify" (change)="addAbilityChip('_fortify')" [value]="true"
                    class="yes-radio">
                  <label for="Fortify3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_frenzy':format_filter)">
                <label position="stacked">
                  {{ '_frenzy' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Frenzy" id="Frenzy1" [(ngModel)]="c_filter._frenzy" (change)="addAbilityChip('_frenzy')" [value]="false"
                    class="no-radio">
                  <label for="Frenzy1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Frenzy" id="Frenzy2" [(ngModel)]="c_filter._frenzy" (change)="addAbilityChip('_frenzy')" [value]="null"
                    class="maybe-radio">
                  <label for="Frenzy2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Frenzy" id="Frenzy3" [(ngModel)]="c_filter._frenzy" (change)="addAbilityChip('_frenzy')" [value]="true"
                    class="yes-radio">
                  <label for="Frenzy3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fuse':format_filter)">
                <label position="stacked">
                  {{ '_fuse' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fuse" id="Fuse1" [(ngModel)]="c_filter._fuse" (change)="addAbilityChip('_fuse')" [value]="false"
                    class="no-radio">
                  <label for="Fuse1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fuse" id="Fuse2" [(ngModel)]="c_filter._fuse" (change)="addAbilityChip('_fuse')" [value]="null"
                    class="maybe-radio">
                  <label for="Fuse2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fuse" id="Fuse3" [(ngModel)]="c_filter._fuse" (change)="addAbilityChip('_fuse')" [value]="true"
                    class="yes-radio">
                  <label for="Fuse3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_graft':format_filter)">
                <label position="stacked">
                  {{ '_graft' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Graft" id="Graft1" [(ngModel)]="c_filter._graft" (change)="addAbilityChip('_graft')" [value]="false"
                    class="no-radio">
                  <label for="Graft1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Graft" id="Graft2" [(ngModel)]="c_filter._graft" (change)="addAbilityChip('_graft')" [value]="null"
                    class="maybe-radio">
                  <label for="Graft2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Graft" id="Graft3" [(ngModel)]="c_filter._graft" (change)="addAbilityChip('_graft')" [value]="true"
                    class="yes-radio">
                  <label for="Graft3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_grandeur':format_filter)">
                <label position="stacked">
                  {{ '_grandeur' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Grandeur" id="Grandeur1" [(ngModel)]="c_filter._grandeur" (change)="addAbilityChip('_grandeur')"
                    [value]="false" class="no-radio">
                  <label for="Grandeur1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Grandeur" id="Grandeur2" [(ngModel)]="c_filter._grandeur" (change)="addAbilityChip('_grandeur')"
                    [value]="null" class="maybe-radio">
                  <label for="Grandeur2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Grandeur" id="Grandeur3" [(ngModel)]="c_filter._grandeur" (change)="addAbilityChip('_grandeur')"
                    [value]="true" class="yes-radio">
                  <label for="Grandeur3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_gravestorm':format_filter)">
                <label position="stacked">
                  {{ '_gravestorm' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Gravestorm" id="Gravestorm1" [(ngModel)]="c_filter._gravestorm" (change)="addAbilityChip('_gravestorm')"
                    [value]="false" class="no-radio">
                  <label for="Gravestorm1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Gravestorm" id="Gravestorm2" [(ngModel)]="c_filter._gravestorm" (change)="addAbilityChip('_gravestorm')"
                    [value]="null" class="maybe-radio">
                  <label for="Gravestorm2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Gravestorm" id="Gravestorm3" [(ngModel)]="c_filter._gravestorm" (change)="addAbilityChip('_gravestorm')"
                    [value]="true" class="yes-radio">
                  <label for="Gravestorm3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_haunt':format_filter)">
                <label position="stacked">
                  {{ '_haunt' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Haunt" id="Haunt1" [(ngModel)]="c_filter._haunt" (change)="addAbilityChip('_haunt')" [value]="false"
                    class="no-radio">
                  <label for="Haunt1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Haunt" id="Haunt2" [(ngModel)]="c_filter._haunt" (change)="addAbilityChip('_haunt')" [value]="null"
                    class="maybe-radio">
                  <label for="Haunt2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Haunt" id="Haunt3" [(ngModel)]="c_filter._haunt" (change)="addAbilityChip('_haunt')" [value]="true"
                    class="yes-radio">
                  <label for="Haunt3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_hellbent':format_filter)">
                <label position="stacked">
                  {{ '_hellbent' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Hellbent" id="Hellbent1" [(ngModel)]="c_filter._hellbent" (change)="addAbilityChip('_hellbent')"
                    [value]="false" class="no-radio">
                  <label for="Hellbent1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Hellbent" id="Hellbent2" [(ngModel)]="c_filter._hellbent" (change)="addAbilityChip('_hellbent')"
                    [value]="null" class="maybe-radio">
                  <label for="Hellbent2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Hellbent" id="Hellbent3" [(ngModel)]="c_filter._hellbent" (change)="addAbilityChip('_hellbent')"
                    [value]="true" class="yes-radio">
                  <label for="Hellbent3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_heroic':format_filter)">
                <label position="stacked">
                  {{ '_heroic' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Heroic" id="Heroic1" [(ngModel)]="c_filter._heroic" (change)="addAbilityChip('_heroic')" [value]="false"
                    class="no-radio">
                  <label for="Heroic1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Heroic" id="Heroic2" [(ngModel)]="c_filter._heroic" (change)="addAbilityChip('_heroic')" [value]="null"
                    class="maybe-radio">
                  <label for="Heroic2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Heroic" id="Heroic3" [(ngModel)]="c_filter._heroic" (change)="addAbilityChip('_heroic')" [value]="true"
                    class="yes-radio">
                  <label for="Heroic3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_hideaway':format_filter)">
                <label position="stacked">
                  {{ '_hideaway' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Hideaway" id="Hideaway1" [(ngModel)]="c_filter._hideaway" (change)="addAbilityChip('_hideaway')"
                    [value]="false" class="no-radio">
                  <label for="Hideaway1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Hideaway" id="Hideaway2" [(ngModel)]="c_filter._hideaway" (change)="addAbilityChip('_hideaway')"
                    [value]="null" class="maybe-radio">
                  <label for="Hideaway2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Hideaway" id="Hideaway3" [(ngModel)]="c_filter._hideaway" (change)="addAbilityChip('_hideaway')"
                    [value]="true" class="yes-radio">
                  <label for="Hideaway3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_horsemanship':format_filter)">
                <label position="stacked">
                  {{ '_horsemanship' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Horsemanship" id="Horsemanship1" [(ngModel)]="c_filter._horsemanship" (change)="addAbilityChip('_horsemanship')"
                    [value]="false" class="no-radio">
                  <label for="Horsemanship1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Horsemanship" id="Horsemanship2" [(ngModel)]="c_filter._horsemanship" (change)="addAbilityChip('_horsemanship')"
                    [value]="null" class="maybe-radio">
                  <label for="Horsemanship2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Horsemanship" id="Horsemanship3" [(ngModel)]="c_filter._horsemanship" (change)="addAbilityChip('_horsemanship')"
                    [value]="true" class="yes-radio">
                  <label for="Horsemanship3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_imprint':format_filter)">
                <label position="stacked">
                  {{ '_imprint' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Imprint" id="Imprint1" [(ngModel)]="c_filter._imprint" (change)="addAbilityChip('_imprint')" [value]="false"
                    class="no-radio">
                  <label for="Imprint1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Imprint" id="Imprint2" [(ngModel)]="c_filter._imprint" (change)="addAbilityChip('_imprint')" [value]="null"
                    class="maybe-radio">
                  <label for="Imprint2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Imprint" id="Imprint3" [(ngModel)]="c_filter._imprint" (change)="addAbilityChip('_imprint')" [value]="true"
                    class="yes-radio">
                  <label for="Imprint3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_improvise':format_filter)">
                <label position="stacked">
                  {{ '_improvise' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Improvise" id="Improvise1" [(ngModel)]="c_filter._improvise" (change)="addAbilityChip('_improvise')"
                    [value]="false" class="no-radio">
                  <label for="Improvise1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Improvise" id="Improvise2" [(ngModel)]="c_filter._improvise" (change)="addAbilityChip('_improvise')"
                    [value]="null" class="maybe-radio">
                  <label for="Improvise2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Improvise" id="Improvise3" [(ngModel)]="c_filter._improvise" (change)="addAbilityChip('_improvise')"
                    [value]="true" class="yes-radio">
                  <label for="Improvise3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_infect':format_filter)">
                <label position="stacked">
                  {{ '_infect' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Infect" id="Infect1" [(ngModel)]="c_filter._infect" (change)="addAbilityChip('_infect')" [value]="false"
                    class="no-radio">
                  <label for="Infect1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Infect" id="Infect2" [(ngModel)]="c_filter._infect" (change)="addAbilityChip('_infect')" [value]="null"
                    class="maybe-radio">
                  <label for="Infect2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Infect" id="Infect3" [(ngModel)]="c_filter._infect" (change)="addAbilityChip('_infect')" [value]="true"
                    class="yes-radio">
                  <label for="Infect3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ingest':format_filter)">
                <label position="stacked">
                  {{ '_ingest' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Ingest" id="Ingest1" [(ngModel)]="c_filter._ingest" (change)="addAbilityChip('_ingest')" [value]="false"
                    class="no-radio">
                  <label for="Ingest1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Ingest" id="Ingest2" [(ngModel)]="c_filter._ingest" (change)="addAbilityChip('_ingest')" [value]="null"
                    class="maybe-radio">
                  <label for="Ingest2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Ingest" id="Ingest3" [(ngModel)]="c_filter._ingest" (change)="addAbilityChip('_ingest')" [value]="true"
                    class="yes-radio">
                  <label for="Ingest3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_inspired':format_filter)">
                <label position="stacked">
                  {{ '_inspired' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Inspired" id="Inspired1" [(ngModel)]="c_filter._inspired" (change)="addAbilityChip('_inspired')"
                    [value]="false" class="no-radio">
                  <label for="Inspired1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Inspired" id="Inspired2" [(ngModel)]="c_filter._inspired" (change)="addAbilityChip('_inspired')"
                    [value]="null" class="maybe-radio">
                  <label for="Inspired2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Inspired" id="Inspired3" [(ngModel)]="c_filter._inspired" (change)="addAbilityChip('_inspired')"
                    [value]="true" class="yes-radio">
                  <label for="Inspired3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_intimidate':format_filter)">
                <label position="stacked">
                  {{ '_intimidate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Intimidate" id="Intimidate1" [(ngModel)]="c_filter._intimidate" (change)="addAbilityChip('_intimidate')"
                    [value]="false" class="no-radio">
                  <label for="Intimidate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Intimidate" id="Intimidate2" [(ngModel)]="c_filter._intimidate" (change)="addAbilityChip('_intimidate')"
                    [value]="null" class="maybe-radio">
                  <label for="Intimidate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Intimidate" id="Intimidate3" [(ngModel)]="c_filter._intimidate" (change)="addAbilityChip('_intimidate')"
                    [value]="true" class="yes-radio">
                  <label for="Intimidate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_join_forces':format_filter)">
                <label position="stacked">
                  {{ '_join_forces' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="JoinForces" id="JoinForces1" [(ngModel)]="c_filter._join_forces" (change)="addAbilityChip('_join_forces')"
                    [value]="false" class="no-radio">
                  <label for="JoinForces1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="JoinForces" id="JoinForces2" [(ngModel)]="c_filter._join_forces" (change)="addAbilityChip('_join_forces')"
                    [value]="null" class="maybe-radio">
                  <label for="JoinForces2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="JoinForces" id="JoinForces3" [(ngModel)]="c_filter._join_forces" (change)="addAbilityChip('_join_forces')"
                    [value]="true" class="yes-radio">
                  <label for="JoinForces3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_jump_start':format_filter)">
                <label position="stacked">
                  {{ '_jump_start' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Jump_start" id="Jump_start1" [(ngModel)]="c_filter._jump_start" (change)="addAbilityChip('_jump_start')" [value]="false"
                    class="no-radio">
                  <label for="Jump_start1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Jump_start" id="Jump_start2" [(ngModel)]="c_filter._jump_start" (change)="addAbilityChip('_jump_start')" [value]="null"
                    class="maybe-radio">
                  <label for="Jump_start2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Jump_start" id="Jump_start3" [(ngModel)]="c_filter._jump_start" (change)="addAbilityChip('_jump_start')" [value]="true"
                    class="yes-radio">
                  <label for="Jump_start3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_kicker':format_filter)">
                <label position="stacked">
                  {{ '_kicker' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Kicker" id="Kicker1" [(ngModel)]="c_filter._kicker" (change)="addAbilityChip('_kicker')" [value]="false"
                    class="no-radio">
                  <label for="Kicker1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Kicker" id="Kicker2" [(ngModel)]="c_filter._kicker" (change)="addAbilityChip('_kicker')" [value]="null"
                    class="maybe-radio">
                  <label for="Kicker2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Kicker" id="Kicker3" [(ngModel)]="c_filter._kicker" (change)="addAbilityChip('_kicker')" [value]="true"
                    class="yes-radio">
                  <label for="Kicker3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_kinship':format_filter)">
                <label position="stacked">
                  {{ '_kinship' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Kinship" id="Kinship1" [(ngModel)]="c_filter._kinship" (change)="addAbilityChip('_kinship')" [value]="false"
                    class="no-radio">
                  <label for="Kinship1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Kinship" id="Kinship2" [(ngModel)]="c_filter._kinship" (change)="addAbilityChip('_kinship')" [value]="null"
                    class="maybe-radio">
                  <label for="Kinship2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Kinship" id="Kinship3" [(ngModel)]="c_filter._kinship" (change)="addAbilityChip('_kinship')" [value]="true"
                    class="yes-radio">
                  <label for="Kinship3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_landfall':format_filter)">
                <label position="stacked">
                  {{ '_landfall' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Landfall" id="Landfall1" [(ngModel)]="c_filter._landfall" (change)="addAbilityChip('_landfall')"
                    [value]="false" class="no-radio">
                  <label for="Landfall1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Landfall" id="Landfall2" [(ngModel)]="c_filter._landfall" (change)="addAbilityChip('_landfall')"
                    [value]="null" class="maybe-radio">
                  <label for="Landfall2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Landfall" id="Landfall3" [(ngModel)]="c_filter._landfall" (change)="addAbilityChip('_landfall')"
                    [value]="true" class="yes-radio">
                  <label for="Landfall3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_landwalk':format_filter)">
                <label position="stacked">
                  {{ '_landwalk' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Landwalk" id="Landwalk1" [(ngModel)]="c_filter._landwalk" (change)="addAbilityChip('_landwalk')"
                    [value]="false" class="no-radio">
                  <label for="Landwalk1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Landwalk" id="Landwalk2" [(ngModel)]="c_filter._landwalk" (change)="addAbilityChip('_landwalk')"
                    [value]="null" class="maybe-radio">
                  <label for="Landwalk2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Landwalk" id="Landwalk3" [(ngModel)]="c_filter._landwalk" (change)="addAbilityChip('_landwalk')"
                    [value]="true" class="yes-radio">
                  <label for="Landwalk3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_level_up':format_filter)">
                <label position="stacked">
                  {{ '_level_up' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="LevelUp" id="LevelUp1" [(ngModel)]="c_filter._level_up" (change)="addAbilityChip('_level_up')"
                    [value]="false" class="no-radio">
                  <label for="LevelUp1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="LevelUp" id="LevelUp2" [(ngModel)]="c_filter._level_up" (change)="addAbilityChip('_level_up')"
                    [value]="null" class="maybe-radio">
                  <label for="LevelUp2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="LevelUp" id="LevelUp3" [(ngModel)]="c_filter._level_up" (change)="addAbilityChip('_level_up')"
                    [value]="true" class="yes-radio">
                  <label for="LevelUp3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_lieutenant':format_filter)">
                <label position="stacked">
                  {{ '_lieutenant' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Lieutenant" id="Lieutenant1" [(ngModel)]="c_filter._lieutenant" (change)="addAbilityChip('_lieutenant')"
                    [value]="false" class="no-radio">
                  <label for="Lieutenant1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Lieutenant" id="Lieutenant2" [(ngModel)]="c_filter._lieutenant" (change)="addAbilityChip('_lieutenant')"
                    [value]="null" class="maybe-radio">
                  <label for="Lieutenant2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Lieutenant" id="Lieutenant3" [(ngModel)]="c_filter._lieutenant" (change)="addAbilityChip('_lieutenant')"
                    [value]="true" class="yes-radio">
                  <label for="Lieutenant3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_living_weapon':format_filter)">
                <label position="stacked">
                  {{ '_living_weapon' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="LivingWeapon" id="LivingWeapon1" [(ngModel)]="c_filter._living_weapon" (change)="addAbilityChip('_living_weapon')"
                    [value]="false" class="no-radio">
                  <label for="LivingWeapon1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="LivingWeapon" id="LivingWeapon2" [(ngModel)]="c_filter._living_weapon" (change)="addAbilityChip('_living_weapon')"
                    [value]="null" class="maybe-radio">
                  <label for="LivingWeapon2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="LivingWeapon" id="LivingWeapon3" [(ngModel)]="c_filter._living_weapon" (change)="addAbilityChip('_living_weapon')"
                    [value]="true" class="yes-radio">
                  <label for="LivingWeapon3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_madness':format_filter)">
                <label position="stacked">
                  {{ '_madness' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Madness" id="Madness1" [(ngModel)]="c_filter._madness" (change)="addAbilityChip('_madness')" [value]="false"
                    class="no-radio">
                  <label for="Madness1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Madness" id="Madness2" [(ngModel)]="c_filter._madness" (change)="addAbilityChip('_madness')" [value]="null"
                    class="maybe-radio">
                  <label for="Madness2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Madness" id="Madness3" [(ngModel)]="c_filter._madness" (change)="addAbilityChip('_madness')" [value]="true"
                    class="yes-radio">
                  <label for="Madness3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_magecraft':format_filter)">
                <label position="stacked">
                  {{ '_magecraft' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Magecraft" id="Magecraft1" [(ngModel)]="c_filter._magecraft" (change)="addAbilityChip('_magecraft')" [value]="false"
                    class="no-radio">
                  <label for="Magecraft1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Magecraft" id="Magecraft2" [(ngModel)]="c_filter._magecraft" (change)="addAbilityChip('_magecraft')" [value]="null"
                    class="maybe-radio">
                  <label for="Magecraft2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Magecraft" id="Magecraft3" [(ngModel)]="c_filter._magecraft" (change)="addAbilityChip('_magecraft')" [value]="true"
                    class="yes-radio">
                  <label for="Magecraft3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_melee':format_filter)">
                <label position="stacked">
                  {{ '_melee' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Melee" id="Melee1" [(ngModel)]="c_filter._melee" (change)="addAbilityChip('_melee')" [value]="false"
                    class="no-radio">
                  <label for="Melee1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Melee" id="Melee2" [(ngModel)]="c_filter._melee" (change)="addAbilityChip('_melee')" [value]="null"
                    class="maybe-radio">
                  <label for="Melee2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Melee" id="Melee3" [(ngModel)]="c_filter._melee" (change)="addAbilityChip('_melee')" [value]="true"
                    class="yes-radio">
                  <label for="Melee3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mentor':format_filter)">
                <label position="stacked">
                  {{ '_mentor' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Mentor" id="Mentor1" [(ngModel)]="c_filter._mentor" (change)="addAbilityChip('_mentor')" [value]="false"
                    class="no-radio">
                  <label for="Mentor1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Mentor" id="Mentor2" [(ngModel)]="c_filter._mentor" (change)="addAbilityChip('_mentor')" [value]="null"
                    class="maybe-radio">
                  <label for="Mentor2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Mentor" id="Mentor3" [(ngModel)]="c_filter._mentor" (change)="addAbilityChip('_mentor')" [value]="true"
                    class="yes-radio">
                  <label for="Mentor3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_metalcraft':format_filter)">
                <label position="stacked">
                  {{ '_metalcraft' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Metalcraft" id="Metalcraft1" [(ngModel)]="c_filter._metalcraft" (change)="addAbilityChip('_metalcraft')"
                    [value]="false" class="no-radio">
                  <label for="Metalcraft1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Metalcraft" id="Metalcraft2" [(ngModel)]="c_filter._metalcraft" (change)="addAbilityChip('_metalcraft')"
                    [value]="null" class="maybe-radio">
                  <label for="Metalcraft2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Metalcraft" id="Metalcraft3" [(ngModel)]="c_filter._metalcraft" (change)="addAbilityChip('_metalcraft')"
                    [value]="true" class="yes-radio">
                  <label for="Metalcraft3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_miracle':format_filter)">
                <label position="stacked">
                  {{ '_miracle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Miracle" id="Miracle1" [(ngModel)]="c_filter._miracle" (change)="addAbilityChip('_miracle')" [value]="false"
                    class="no-radio">
                  <label for="Miracle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Miracle" id="Miracle2" [(ngModel)]="c_filter._miracle" (change)="addAbilityChip('_miracle')" [value]="null"
                    class="maybe-radio">
                  <label for="Miracle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Miracle" id="Miracle3" [(ngModel)]="c_filter._miracle" (change)="addAbilityChip('_miracle')" [value]="true"
                    class="yes-radio">
                  <label for="Miracle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_modular':format_filter)">
                <label position="stacked">
                  {{ '_modular' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Modular" id="Modular1" [(ngModel)]="c_filter._modular" (change)="addAbilityChip('_modular')" [value]="false"
                    class="no-radio">
                  <label for="Modular1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Modular" id="Modular2" [(ngModel)]="c_filter._modular" (change)="addAbilityChip('_modular')" [value]="null"
                    class="maybe-radio">
                  <label for="Modular2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Modular" id="Modular3" [(ngModel)]="c_filter._modular" (change)="addAbilityChip('_modular')" [value]="true"
                    class="yes-radio">
                  <label for="Modular3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_morbid':format_filter)">
                <label position="stacked">
                  {{ '_morbid' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Morbid" id="Morbid1" [(ngModel)]="c_filter._morbid" (change)="addAbilityChip('_morbid')" [value]="false"
                    class="no-radio">
                  <label for="Morbid1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Morbid" id="Morbid2" [(ngModel)]="c_filter._morbid" (change)="addAbilityChip('_morbid')" [value]="null"
                    class="maybe-radio">
                  <label for="Morbid2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Morbid" id="Morbid3" [(ngModel)]="c_filter._morbid" (change)="addAbilityChip('_morbid')" [value]="true"
                    class="yes-radio">
                  <label for="Morbid3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_morph':format_filter)">
                <label position="stacked">
                  {{ '_morph' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Morph" id="Morph1" [(ngModel)]="c_filter._morph" (change)="addAbilityChip('_morph')" [value]="false"
                    class="no-radio">
                  <label for="Morph1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Morph" id="Morph2" [(ngModel)]="c_filter._morph" (change)="addAbilityChip('_morph')" [value]="null"
                    class="maybe-radio">
                  <label for="Morph2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Morph" id="Morph3" [(ngModel)]="c_filter._morph" (change)="addAbilityChip('_morph')" [value]="true"
                    class="yes-radio">
                  <label for="Morph3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mutate':format_filter)">
                <label position="stacked">
                  {{ '_mutate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Mutate" id="Mutate1" [(ngModel)]="c_filter._mutate" (change)="addAbilityChip('_mutate')" [value]="false"
                    class="no-radio">
                  <label for="Mutate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Mutate" id="Mutate2" [(ngModel)]="c_filter._mutate" (change)="addAbilityChip('_mutate')" [value]="null"
                    class="maybe-radio">
                  <label for="Mutate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Mutate" id="Mutate3" [(ngModel)]="c_filter._mutate" (change)="addAbilityChip('_mutate')" [value]="true"
                    class="yes-radio">
                  <label for="Mutate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_myriad':format_filter)">
                <label position="stacked">
                  {{ '_myriad' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Myriad" id="Myriad1" [(ngModel)]="c_filter._myriad" (change)="addAbilityChip('_myriad')" [value]="false"
                    class="no-radio">
                  <label for="Myriad1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Myriad" id="Myriad2" [(ngModel)]="c_filter._myriad" (change)="addAbilityChip('_myriad')" [value]="null"
                    class="maybe-radio">
                  <label for="Myriad2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Myriad" id="Myriad3" [(ngModel)]="c_filter._myriad" (change)="addAbilityChip('_myriad')" [value]="true"
                    class="yes-radio">
                  <label for="Myriad3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_nightbound':format_filter)">
                <label position="stacked">
                  {{ '_nightbound' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Nightbound" id="Nightbound1" [(ngModel)]="c_filter._nightbound" (change)="addAbilityChip('_nightbound')"
                    [value]="false" class="no-radio">
                  <label for="Nightbound1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Nightbound" id="Nightbound2" [(ngModel)]="c_filter._nightbound" (change)="addAbilityChip('_nightbound')"
                    [value]="null" class="maybe-radio">
                  <label for="Nightbound2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Nightbound" id="Nightbound3" [(ngModel)]="c_filter._nightbound" (change)="addAbilityChip('_nightbound')"
                    [value]="true" class="yes-radio">
                  <label for="Nightbound3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ninjutsu':format_filter)">
                <label position="stacked">
                  {{ '_ninjutsu' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Ninjutsu" id="Ninjutsu1" [(ngModel)]="c_filter._ninjutsu" (change)="addAbilityChip('_ninjutsu')"
                    [value]="false" class="no-radio">
                  <label for="Ninjutsu1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Ninjutsu" id="Ninjutsu2" [(ngModel)]="c_filter._ninjutsu" (change)="addAbilityChip('_ninjutsu')"
                    [value]="null" class="maybe-radio">
                  <label for="Ninjutsu2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Ninjutsu" id="Ninjutsu3" [(ngModel)]="c_filter._ninjutsu" (change)="addAbilityChip('_ninjutsu')"
                    [value]="true" class="yes-radio">
                  <label for="Ninjutsu3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_offering':format_filter)">
                <label position="stacked">
                  {{ '_offering' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Offering" id="Offering1" [(ngModel)]="c_filter._offering" (change)="addAbilityChip('_offering')"
                    [value]="false" class="no-radio">
                  <label for="Offering1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Offering" id="Offering2" [(ngModel)]="c_filter._offering" (change)="addAbilityChip('_offering')"
                    [value]="null" class="maybe-radio">
                  <label for="Offering2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Offering" id="Offering3" [(ngModel)]="c_filter._offering" (change)="addAbilityChip('_offering')"
                    [value]="true" class="yes-radio">
                  <label for="Offering3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_outlast':format_filter)">
                <label position="stacked">
                  {{ '_outlast' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Outlast" id="Outlast1" [(ngModel)]="c_filter._outlast" (change)="addAbilityChip('_outlast')" [value]="false"
                    class="no-radio">
                  <label for="Outlast1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Outlast" id="Outlast2" [(ngModel)]="c_filter._outlast" (change)="addAbilityChip('_outlast')" [value]="null"
                    class="maybe-radio">
                  <label for="Outlast2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Outlast" id="Outlast3" [(ngModel)]="c_filter._outlast" (change)="addAbilityChip('_outlast')" [value]="true"
                    class="yes-radio">
                  <label for="Outlast3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_overload':format_filter)">
                <label position="stacked">
                  {{ '_overload' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Overload" id="Overload1" [(ngModel)]="c_filter._overload" (change)="addAbilityChip('_overload')"
                    [value]="false" class="no-radio">
                  <label for="Overload1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Overload" id="Overload2" [(ngModel)]="c_filter._overload" (change)="addAbilityChip('_overload')"
                    [value]="null" class="maybe-radio">
                  <label for="Overload2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Overload" id="Overload3" [(ngModel)]="c_filter._overload" (change)="addAbilityChip('_overload')"
                    [value]="true" class="yes-radio">
                  <label for="Overload3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_pack_tactics':format_filter)">
                <label position="stacked">
                  {{ '_pack_tactics' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="PackTactics" id="PackTactics1" [(ngModel)]="c_filter._pack_tactics" (change)="addAbilityChip('_pack_tactics')" [value]="false"
                    class="no-radio">
                  <label for="PackTactics1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="PackTactics" id="PackTactics2" [(ngModel)]="c_filter._pack_tactics" (change)="addAbilityChip('_pack_tactics')" [value]="null"
                    class="maybe-radio">
                  <label for="PackTactics2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="PackTactics" id="PackTactics3" [(ngModel)]="c_filter._pack_tactics" (change)="addAbilityChip('_pack_tactics')" [value]="true"
                    class="yes-radio">
                  <label for="PackTactics3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_parley':format_filter)">
                <label position="stacked">
                  {{ '_parley' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Parley" id="Parley1" [(ngModel)]="c_filter._parley" (change)="addAbilityChip('_parley')" [value]="false"
                    class="no-radio">
                  <label for="Parley1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Parley" id="Parley2" [(ngModel)]="c_filter._parley" (change)="addAbilityChip('_parley')" [value]="null"
                    class="maybe-radio">
                  <label for="Parley2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Parley" id="Parley3" [(ngModel)]="c_filter._parley" (change)="addAbilityChip('_parley')" [value]="true"
                    class="yes-radio">
                  <label for="Parley3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_partner':format_filter)">
                <label position="stacked">
                  {{ '_partner' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Partner" id="Partner1" [(ngModel)]="c_filter._partner" (change)="addAbilityChip('_partner')" [value]="false"
                    class="no-radio">
                  <label for="Partner1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Partner" id="Partner2" [(ngModel)]="c_filter._partner" (change)="addAbilityChip('_partner')" [value]="null"
                    class="maybe-radio">
                  <label for="Partner2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Partner" id="Partner3" [(ngModel)]="c_filter._partner" (change)="addAbilityChip('_partner')" [value]="true"
                    class="yes-radio">
                  <label for="Partner3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_persist':format_filter)">
                <label position="stacked">
                  {{ '_persist' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Persist" id="Persist1" [(ngModel)]="c_filter._persist" (change)="addAbilityChip('_persist')" [value]="false"
                    class="no-radio">
                  <label for="Persist1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Persist" id="Persist2" [(ngModel)]="c_filter._persist" (change)="addAbilityChip('_persist')" [value]="null"
                    class="maybe-radio">
                  <label for="Persist2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Persist" id="Persist3" [(ngModel)]="c_filter._persist" (change)="addAbilityChip('_persist')" [value]="true"
                    class="yes-radio">
                  <label for="Persist3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_phasing':format_filter)">
                <label position="stacked">
                  {{ '_phasing' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Phasing" id="Phasing1" [(ngModel)]="c_filter._phasing" (change)="addAbilityChip('_phasing')" [value]="false"
                    class="no-radio">
                  <label for="Phasing1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Phasing" id="Phasing2" [(ngModel)]="c_filter._phasing" (change)="addAbilityChip('_phasing')" [value]="null"
                    class="maybe-radio">
                  <label for="Phasing2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Phasing" id="Phasing3" [(ngModel)]="c_filter._phasing" (change)="addAbilityChip('_phasing')" [value]="true"
                    class="yes-radio">
                  <label for="Phasing3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_poisonous':format_filter)">
                <label position="stacked">
                  {{ '_poisonous' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Poisonous" id="Poisonous1" [(ngModel)]="c_filter._poisonous" (change)="addAbilityChip('_poisonous')"
                    [value]="false" class="no-radio">
                  <label for="Poisonous1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Poisonous" id="Poisonous2" [(ngModel)]="c_filter._poisonous" (change)="addAbilityChip('_poisonous')"
                    [value]="null" class="maybe-radio">
                  <label for="Poisonous2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Poisonous" id="Poisonous3" [(ngModel)]="c_filter._poisonous" (change)="addAbilityChip('_poisonous')"
                    [value]="true" class="yes-radio">
                  <label for="Poisonous3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_protection':format_filter)">
                <label position="stacked">
                  {{ '_protection' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Protection" id="Protection1" [(ngModel)]="c_filter._protection" (change)="addAbilityChip('_protection')"
                    [value]="false" class="no-radio">
                  <label for="Protection1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Protection" id="Protection2" [(ngModel)]="c_filter._protection" (change)="addAbilityChip('_protection')"
                    [value]="null" class="maybe-radio">
                  <label for="Protection2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Protection" id="Protection3" [(ngModel)]="c_filter._protection" (change)="addAbilityChip('_protection')"
                    [value]="true" class="yes-radio">
                  <label for="Protection3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_provoke':format_filter)">
                <label position="stacked">
                  {{ '_provoke' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Provoke" id="Provoke1" [(ngModel)]="c_filter._provoke" (change)="addAbilityChip('_provoke')" [value]="false"
                    class="no-radio">
                  <label for="Provoke1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Provoke" id="Provoke2" [(ngModel)]="c_filter._provoke" (change)="addAbilityChip('_provoke')" [value]="null"
                    class="maybe-radio">
                  <label for="Provoke2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Provoke" id="Provoke3" [(ngModel)]="c_filter._provoke" (change)="addAbilityChip('_provoke')" [value]="true"
                    class="yes-radio">
                  <label for="Provoke3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_prowl':format_filter)">
                <label position="stacked">
                  {{ '_prowl' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Prowl" id="Prowl1" [(ngModel)]="c_filter._prowl" (change)="addAbilityChip('_prowl')" [value]="false"
                    class="no-radio">
                  <label for="Prowl1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Prowl" id="Prowl2" [(ngModel)]="c_filter._prowl" (change)="addAbilityChip('_prowl')" [value]="null"
                    class="maybe-radio">
                  <label for="Prowl2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Prowl" id="Prowl3" [(ngModel)]="c_filter._prowl" (change)="addAbilityChip('_prowl')" [value]="true"
                    class="yes-radio">
                  <label for="Prowl3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_radiance':format_filter)">
                <label position="stacked">
                  {{ '_radiance' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Radiance" id="Radiance1" [(ngModel)]="c_filter._radiance" (change)="addAbilityChip('_radiance')"
                    [value]="false" class="no-radio">
                  <label for="Radiance1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Radiance" id="Radiance2" [(ngModel)]="c_filter._radiance" (change)="addAbilityChip('_radiance')"
                    [value]="null" class="maybe-radio">
                  <label for="Radiance2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Radiance" id="Radiance3" [(ngModel)]="c_filter._radiance" (change)="addAbilityChip('_radiance')"
                    [value]="true" class="yes-radio">
                  <label for="Radiance3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_raid':format_filter)">
                <label position="stacked">
                  {{ '_raid' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Raid" id="Raid1" [(ngModel)]="c_filter._raid" (change)="addAbilityChip('_raid')" [value]="false"
                    class="no-radio">
                  <label for="Raid1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Raid" id="Raid2" [(ngModel)]="c_filter._raid" (change)="addAbilityChip('_raid')" [value]="null"
                    class="maybe-radio">
                  <label for="Raid2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Raid" id="Raid3" [(ngModel)]="c_filter._raid" (change)="addAbilityChip('_raid')" [value]="true"
                    class="yes-radio">
                  <label for="Raid3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_rally':format_filter)">
                <label position="stacked">
                  {{ '_rally' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Rally" id="Rally1" [(ngModel)]="c_filter._rally" (change)="addAbilityChip('_rally')" [value]="false"
                    class="no-radio">
                  <label for="Rally1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Rally" id="Rally2" [(ngModel)]="c_filter._rally" (change)="addAbilityChip('_rally')" [value]="null"
                    class="maybe-radio">
                  <label for="Rally2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Rally" id="Rally3" [(ngModel)]="c_filter._rally" (change)="addAbilityChip('_rally')" [value]="true"
                    class="yes-radio">
                  <label for="Rally3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_rampage':format_filter)">
                <label position="stacked">
                  {{ '_rampage' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Rampage" id="Rampage1" [(ngModel)]="c_filter._rampage" (change)="addAbilityChip('_rampage')" [value]="false"
                    class="no-radio">
                  <label for="Rampage1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Rampage" id="Rampage2" [(ngModel)]="c_filter._rampage" (change)="addAbilityChip('_rampage')" [value]="null"
                    class="maybe-radio">
                  <label for="Rampage2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Rampage" id="Rampage3" [(ngModel)]="c_filter._rampage" (change)="addAbilityChip('_rampage')" [value]="true"
                    class="yes-radio">
                  <label for="Rampage3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_rebound':format_filter)">
                <label position="stacked">
                  {{ '_rebound' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Rebound" id="Rebound1" [(ngModel)]="c_filter._rebound" (change)="addAbilityChip('_rebound')" [value]="false"
                    class="no-radio">
                  <label for="Rebound1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Rebound" id="Rebound2" [(ngModel)]="c_filter._rebound" (change)="addAbilityChip('_rebound')" [value]="null"
                    class="maybe-radio">
                  <label for="Rebound2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Rebound" id="Rebound3" [(ngModel)]="c_filter._rebound" (change)="addAbilityChip('_rebound')" [value]="true"
                    class="yes-radio">
                  <label for="Rebound3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_recover':format_filter)">
                <label position="stacked">
                  {{ '_recover' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Recover" id="Recover1" [(ngModel)]="c_filter._recover" (change)="addAbilityChip('_recover')" [value]="false"
                    class="no-radio">
                  <label for="Recover1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Recover" id="Recover2" [(ngModel)]="c_filter._recover" (change)="addAbilityChip('_recover')" [value]="null"
                    class="maybe-radio">
                  <label for="Recover2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Recover" id="Recover3" [(ngModel)]="c_filter._recover" (change)="addAbilityChip('_recover')" [value]="true"
                    class="yes-radio">
                  <label for="Recover3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_reinforce':format_filter)">
                <label position="stacked">
                  {{ '_reinforce' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Reinforce" id="Reinforce1" [(ngModel)]="c_filter._reinforce" (change)="addAbilityChip('_reinforce')"
                    [value]="false" class="no-radio">
                  <label for="Reinforce1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Reinforce" id="Reinforce2" [(ngModel)]="c_filter._reinforce" (change)="addAbilityChip('_reinforce')"
                    [value]="null" class="maybe-radio">
                  <label for="Reinforce2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Reinforce" id="Reinforce3" [(ngModel)]="c_filter._reinforce" (change)="addAbilityChip('_reinforce')"
                    [value]="true" class="yes-radio">
                  <label for="Reinforce3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_renown':format_filter)">
                <label position="stacked">
                  {{ '_renown' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Renown" id="Renown1" [(ngModel)]="c_filter._renown" (change)="addAbilityChip('_renown')" [value]="false"
                    class="no-radio">
                  <label for="Renown1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Renown" id="Renown2" [(ngModel)]="c_filter._renown" (change)="addAbilityChip('_renown')" [value]="null"
                    class="maybe-radio">
                  <label for="Renown2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Renown" id="Renown3" [(ngModel)]="c_filter._renown" (change)="addAbilityChip('_renown')" [value]="true"
                    class="yes-radio">
                  <label for="Renown3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_replicate':format_filter)">
                <label position="stacked">
                  {{ '_replicate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Replicate" id="Replicate1" [(ngModel)]="c_filter._replicate" (change)="addAbilityChip('_replicate')"
                    [value]="false" class="no-radio">
                  <label for="Replicate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Replicate" id="Replicate2" [(ngModel)]="c_filter._replicate" (change)="addAbilityChip('_replicate')"
                    [value]="null" class="maybe-radio">
                  <label for="Replicate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Replicate" id="Replicate3" [(ngModel)]="c_filter._replicate" (change)="addAbilityChip('_replicate')"
                    [value]="true" class="yes-radio">
                  <label for="Replicate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_retrace':format_filter)">
                <label position="stacked">
                  {{ '_retrace' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Retrace" id="Retrace1" [(ngModel)]="c_filter._retrace" (change)="addAbilityChip('_retrace')" [value]="false"
                    class="no-radio">
                  <label for="Retrace1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Retrace" id="Retrace2" [(ngModel)]="c_filter._retrace" (change)="addAbilityChip('_retrace')" [value]="null"
                    class="maybe-radio">
                  <label for="Retrace2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Retrace" id="Retrace3" [(ngModel)]="c_filter._retrace" (change)="addAbilityChip('_retrace')" [value]="true"
                    class="yes-radio">
                  <label for="Retrace3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_revolt':format_filter)">
                <label position="stacked">
                  {{ '_revolt' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Revolt" id="Revolt1" [(ngModel)]="c_filter._revolt" (change)="addAbilityChip('_revolt')" [value]="false"
                    class="no-radio">
                  <label for="Revolt1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Revolt" id="Revolt2" [(ngModel)]="c_filter._revolt" (change)="addAbilityChip('_revolt')" [value]="null"
                    class="maybe-radio">
                  <label for="Revolt2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Revolt" id="Revolt3" [(ngModel)]="c_filter._revolt" (change)="addAbilityChip('_revolt')" [value]="true"
                    class="yes-radio">
                  <label for="Revolt3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_riot':format_filter)">
                <label position="stacked">
                  {{ '_riot' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Riot" id="Riot1" [(ngModel)]="c_filter._riot" (change)="addAbilityChip('_riot')" [value]="false"
                    class="no-radio">
                  <label for="Riot1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Riot" id="Riot2" [(ngModel)]="c_filter._riot" (change)="addAbilityChip('_riot')" [value]="null"
                    class="maybe-radio">
                  <label for="Riot2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Riot" id="Riot3" [(ngModel)]="c_filter._riot" (change)="addAbilityChip('_riot')" [value]="true"
                    class="yes-radio">
                  <label for="Riot3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ripple':format_filter)">
                <label position="stacked">
                  {{ '_ripple' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Ripple" id="Ripple1" [(ngModel)]="c_filter._ripple" (change)="addAbilityChip('_ripple')" [value]="false"
                    class="no-radio">
                  <label for="Ripple1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Ripple" id="Ripple2" [(ngModel)]="c_filter._ripple" (change)="addAbilityChip('_ripple')" [value]="null"
                    class="maybe-radio">
                  <label for="Ripple2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Ripple" id="Ripple3" [(ngModel)]="c_filter._ripple" (change)="addAbilityChip('_ripple')" [value]="true"
                    class="yes-radio">
                  <label for="Ripple3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_scavenge':format_filter)">
                <label position="stacked">
                  {{ '_scavenge' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Scavenge" id="Scavenge1" [(ngModel)]="c_filter._scavenge" (change)="addAbilityChip('_scavenge')"
                    [value]="false" class="no-radio">
                  <label for="Scavenge1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Scavenge" id="Scavenge2" [(ngModel)]="c_filter._scavenge" (change)="addAbilityChip('_scavenge')"
                    [value]="null" class="maybe-radio">
                  <label for="Scavenge2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Scavenge" id="Scavenge3" [(ngModel)]="c_filter._scavenge" (change)="addAbilityChip('_scavenge')"
                    [value]="true" class="yes-radio">
                  <label for="Scavenge3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_shadow':format_filter)">
                <label position="stacked">
                  {{ '_shadow' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Shadow" id="Shadow1" [(ngModel)]="c_filter._shadow" (change)="addAbilityChip('_shadow')" [value]="false"
                    class="no-radio">
                  <label for="Shadow1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Shadow" id="Shadow2" [(ngModel)]="c_filter._shadow" (change)="addAbilityChip('_shadow')" [value]="null"
                    class="maybe-radio">
                  <label for="Shadow2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Shadow" id="Shadow3" [(ngModel)]="c_filter._shadow" (change)="addAbilityChip('_shadow')" [value]="true"
                    class="yes-radio">
                  <label for="Shadow3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_shroud':format_filter)">
                <label position="stacked">
                  {{ '_shroud' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Shroud" id="Shroud1" [(ngModel)]="c_filter._shroud" (change)="addAbilityChip('_shroud')" [value]="false"
                    class="no-radio">
                  <label for="Shroud1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Shroud" id="Shroud2" [(ngModel)]="c_filter._shroud" (change)="addAbilityChip('_shroud')" [value]="null"
                    class="maybe-radio">
                  <label for="Shroud2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Shroud" id="Shroud3" [(ngModel)]="c_filter._shroud" (change)="addAbilityChip('_shroud')" [value]="true"
                    class="yes-radio">
                  <label for="Shroud3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_skulk':format_filter)">
                <label position="stacked">
                  {{ '_skulk' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Skulk" id="Skulk1" [(ngModel)]="c_filter._skulk" (change)="addAbilityChip('_skulk')" [value]="false"
                    class="no-radio">
                  <label for="Skulk1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Skulk" id="Skulk2" [(ngModel)]="c_filter._skulk" (change)="addAbilityChip('_skulk')" [value]="null"
                    class="maybe-radio">
                  <label for="Skulk2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Skulk" id="Skulk3" [(ngModel)]="c_filter._skulk" (change)="addAbilityChip('_skulk')" [value]="true"
                    class="yes-radio">
                  <label for="Skulk3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_soulbond':format_filter)">
                <label position="stacked">
                  {{ '_soulbond' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Soulbond" id="Soulbond1" [(ngModel)]="c_filter._soulbond" (change)="addAbilityChip('_soulbond')"
                    [value]="false" class="no-radio">
                  <label for="Soulbond1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Soulbond" id="Soulbond2" [(ngModel)]="c_filter._soulbond" (change)="addAbilityChip('_soulbond')"
                    [value]="null" class="maybe-radio">
                  <label for="Soulbond2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Soulbond" id="Soulbond3" [(ngModel)]="c_filter._soulbond" (change)="addAbilityChip('_soulbond')"
                    [value]="true" class="yes-radio">
                  <label for="Soulbond3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_soulshift':format_filter)">
                <label position="stacked">
                  {{ '_soulshift' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Soulshift" id="Soulshift1" [(ngModel)]="c_filter._soulshift" (change)="addAbilityChip('_soulshift')"
                    [value]="false" class="no-radio">
                  <label for="Soulshift1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Soulshift" id="Soulshift2" [(ngModel)]="c_filter._soulshift" (change)="addAbilityChip('_soulshift')"
                    [value]="null" class="maybe-radio">
                  <label for="Soulshift2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Soulshift" id="Soulshift3" [(ngModel)]="c_filter._soulshift" (change)="addAbilityChip('_soulshift')"
                    [value]="true" class="yes-radio">
                  <label for="Soulshift3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_spell_mastery':format_filter)">
                <label position="stacked">
                  {{ '_spell_mastery' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="SpellMastery" id="SpellMastery1" [(ngModel)]="c_filter._spell_mastery" (change)="addAbilityChip('_spell_mastery')"
                    [value]="false" class="no-radio">
                  <label for="SpellMastery1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="SpellMastery" id="SpellMastery2" [(ngModel)]="c_filter._spell_mastery" (change)="addAbilityChip('_spell_mastery')"
                    [value]="null" class="maybe-radio">
                  <label for="SpellMastery2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="SpellMastery" id="SpellMastery3" [(ngModel)]="c_filter._spell_mastery" (change)="addAbilityChip('_spell_mastery')"
                    [value]="true" class="yes-radio">
                  <label for="SpellMastery3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item> 
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_spectacle':format_filter)">
                <label position="stacked">
                  {{ '_spectacle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Spectacle" id="Spectacle1" [(ngModel)]="c_filter._spectacle" (change)="addAbilityChip('_spectacle')" [value]="false"
                    class="no-radio">
                  <label for="Spectacle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Spectacle" id="Spectacle2" [(ngModel)]="c_filter._spectacle" (change)="addAbilityChip('_spectacle')" [value]="null"
                    class="maybe-radio">
                  <label for="Spectacle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Spectacle" id="Spectacle3" [(ngModel)]="c_filter._spectacle" (change)="addAbilityChip('_spectacle')" [value]="true"
                    class="yes-radio">
                  <label for="Spectacle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_splice':format_filter)">
                <label position="stacked">
                  {{ '_splice' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Splice" id="Splice1" [(ngModel)]="c_filter._splice" (change)="addAbilityChip('_splice')" [value]="false"
                    class="no-radio">
                  <label for="Splice1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Splice" id="Splice2" [(ngModel)]="c_filter._splice" (change)="addAbilityChip('_splice')" [value]="null"
                    class="maybe-radio">
                  <label for="Splice2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Splice" id="Splice3" [(ngModel)]="c_filter._splice" (change)="addAbilityChip('_splice')" [value]="true"
                    class="yes-radio">
                  <label for="Splice3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_split_second':format_filter)">
                <label position="stacked">
                  {{ '_split_second' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="SplitSecond" id="SplitSecond1" [(ngModel)]="c_filter._split_second" (change)="addAbilityChip('_split_second')"
                    [value]="false" class="no-radio">
                  <label for="SplitSecond1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="SplitSecond" id="SplitSecond2" [(ngModel)]="c_filter._split_second" (change)="addAbilityChip('_split_second')"
                    [value]="null" class="maybe-radio">
                  <label for="SplitSecond2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="SplitSecond" id="SplitSecond3" [(ngModel)]="c_filter._split_second" (change)="addAbilityChip('_split_second')"
                    [value]="true" class="yes-radio">
                  <label for="SplitSecond3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_storm':format_filter)">
                <label position="stacked">
                  {{ '_storm' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Storm" id="Storm1" [(ngModel)]="c_filter._storm" (change)="addAbilityChip('_storm')" [value]="false"
                    class="no-radio">
                  <label for="Storm1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Storm" id="Storm2" [(ngModel)]="c_filter._storm" (change)="addAbilityChip('_storm')" [value]="null"
                    class="maybe-radio">
                  <label for="Storm2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Storm" id="Storm3" [(ngModel)]="c_filter._storm" (change)="addAbilityChip('_storm')" [value]="true"
                    class="yes-radio">
                  <label for="Storm3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_strive':format_filter)">
                <label position="stacked">
                  {{ '_strive' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Strive" id="Strive1" [(ngModel)]="c_filter._strive" (change)="addAbilityChip('_strive')" [value]="false"
                    class="no-radio">
                  <label for="Strive1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Strive" id="Strive2" [(ngModel)]="c_filter._strive" (change)="addAbilityChip('_strive')" [value]="null"
                    class="maybe-radio">
                  <label for="Strive2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Strive" id="Strive3" [(ngModel)]="c_filter._strive" (change)="addAbilityChip('_strive')" [value]="true"
                    class="yes-radio">
                  <label for="Strive3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_sunburst':format_filter)">
                <label position="stacked">
                  {{ '_sunburst' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Sunburst" id="Sunburst1" [(ngModel)]="c_filter._sunburst" (change)="addAbilityChip('_sunburst')"
                    [value]="false" class="no-radio">
                  <label for="Sunburst1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Sunburst" id="Sunburst2" [(ngModel)]="c_filter._sunburst" (change)="addAbilityChip('_sunburst')"
                    [value]="null" class="maybe-radio">
                  <label for="Sunburst2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Sunburst" id="Sunburst3" [(ngModel)]="c_filter._sunburst" (change)="addAbilityChip('_sunburst')"
                    [value]="true" class="yes-radio">
                  <label for="Sunburst3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_surge':format_filter)">
                <label position="stacked">
                  {{ '_surge' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Surge" id="Surge1" [(ngModel)]="c_filter._surge" (change)="addAbilityChip('_surge')" [value]="false"
                    class="no-radio">
                  <label for="Surge1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Surge" id="Surge2" [(ngModel)]="c_filter._surge" (change)="addAbilityChip('_surge')" [value]="null"
                    class="maybe-radio">
                  <label for="Surge2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Surge" id="Surge3" [(ngModel)]="c_filter._surge" (change)="addAbilityChip('_surge')" [value]="true"
                    class="yes-radio">
                  <label for="Surge3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_suspend':format_filter)">
                <label position="stacked">
                  {{ '_suspend' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Suspend" id="Suspend1" [(ngModel)]="c_filter._suspend" (change)="addAbilityChip('_suspend')" [value]="false"
                    class="no-radio">
                  <label for="Suspend1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Suspend" id="Suspend2" [(ngModel)]="c_filter._suspend" (change)="addAbilityChip('_suspend')" [value]="null"
                    class="maybe-radio">
                  <label for="Suspend2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Suspend" id="Suspend3" [(ngModel)]="c_filter._suspend" (change)="addAbilityChip('_suspend')" [value]="true"
                    class="yes-radio">
                  <label for="Suspend3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_sweep':format_filter)">
                <label position="stacked">
                  {{ '_sweep' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Sweep" id="Sweep1" [(ngModel)]="c_filter._sweep" (change)="addAbilityChip('_sweep')" [value]="false"
                    class="no-radio">
                  <label for="Sweep1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Sweep" id="Sweep2" [(ngModel)]="c_filter._sweep" (change)="addAbilityChip('_sweep')" [value]="null"
                    class="maybe-radio">
                  <label for="Sweep2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Sweep" id="Sweep3" [(ngModel)]="c_filter._sweep" (change)="addAbilityChip('_sweep')" [value]="true"
                    class="yes-radio">
                  <label for="Sweep3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tempting_offer':format_filter)">
                <label position="stacked">
                  {{ '_tempting_offer' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="TemptingOffer" id="TemptingOffer1" [(ngModel)]="c_filter._tempting_offer" (change)="addAbilityChip('_tempting_offer')"
                    [value]="false" class="no-radio">
                  <label for="TemptingOffer1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="TemptingOffer" id="TemptingOffer2" [(ngModel)]="c_filter._tempting_offer" (change)="addAbilityChip('_tempting_offer')"
                    [value]="null" class="maybe-radio">
                  <label for="TemptingOffer2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="TemptingOffer" id="TemptingOffer3" [(ngModel)]="c_filter._tempting_offer" (change)="addAbilityChip('_tempting_offer')"
                    [value]="true" class="yes-radio">
                  <label for="TemptingOffer3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_threshold':format_filter)">
                <label position="stacked">
                  {{ '_threshold' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Threshold" id="Threshold1" [(ngModel)]="c_filter._threshold" (change)="addAbilityChip('_threshold')"
                    [value]="false" class="no-radio">
                  <label for="Threshold1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Threshold" id="Threshold2" [(ngModel)]="c_filter._threshold" (change)="addAbilityChip('_threshold')"
                    [value]="null" class="maybe-radio">
                  <label for="Threshold2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Threshold" id="Threshold3" [(ngModel)]="c_filter._threshold" (change)="addAbilityChip('_threshold')"
                    [value]="true" class="yes-radio">
                  <label for="Threshold3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_totem_armor':format_filter)">
                <label position="stacked">
                  {{ '_totem_armor' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="TotemArmor" id="TotemArmor1" [(ngModel)]="c_filter._totem_armor" (change)="addAbilityChip('_totem_armor')"
                    [value]="false" class="no-radio">
                  <label for="TotemArmor1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="TotemArmor" id="TotemArmor2" [(ngModel)]="c_filter._totem_armor" (change)="addAbilityChip('_totem_armor')"
                    [value]="null" class="maybe-radio">
                  <label for="TotemArmor2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="TotemArmor" id="TotemArmor3" [(ngModel)]="c_filter._totem_armor" (change)="addAbilityChip('_totem_armor')"
                    [value]="true" class="yes-radio">
                  <label for="TotemArmor3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_transfigure':format_filter)">
                <label position="stacked">
                  {{ '_transfigure' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Transfigure" id="Transfigure1" [(ngModel)]="c_filter._transfigure" (change)="addAbilityChip('_transfigure')"
                    [value]="false" class="no-radio">
                  <label for="Transfigure1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Transfigure" id="Transfigure2" [(ngModel)]="c_filter._transfigure" (change)="addAbilityChip('_transfigure')"
                    [value]="null" class="maybe-radio">
                  <label for="Transfigure2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Transfigure" id="Transfigure3" [(ngModel)]="c_filter._transfigure" (change)="addAbilityChip('_transfigure')"
                    [value]="true" class="yes-radio">
                  <label for="Transfigure3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_transmute':format_filter)">
                <label position="stacked">
                  {{ '_transmute' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Transmute" id="Transmute1" [(ngModel)]="c_filter._transmute" (change)="addAbilityChip('_transmute')"
                    [value]="false" class="no-radio">
                  <label for="Transmute1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Transmute" id="Transmute2" [(ngModel)]="c_filter._transmute" (change)="addAbilityChip('_transmute')"
                    [value]="null" class="maybe-radio">
                  <label for="Transmute2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Transmute" id="Transmute3" [(ngModel)]="c_filter._transmute" (change)="addAbilityChip('_transmute')"
                    [value]="true" class="yes-radio">
                  <label for="Transmute3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tribute':format_filter)">
                <label position="stacked">
                  {{ '_tribute' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Tribute" id="Tribute1" [(ngModel)]="c_filter._tribute" (change)="addAbilityChip('_tribute')" [value]="false"
                    class="no-radio">
                  <label for="Tribute1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Tribute" id="Tribute2" [(ngModel)]="c_filter._tribute" (change)="addAbilityChip('_tribute')" [value]="null"
                    class="maybe-radio">
                  <label for="Tribute2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Tribute" id="Tribute3" [(ngModel)]="c_filter._tribute" (change)="addAbilityChip('_tribute')" [value]="true"
                    class="yes-radio">
                  <label for="Tribute3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_undaunted':format_filter)">
                <label position="stacked">
                  {{ '_undaunted' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Undaunted" id="Undaunted1" [(ngModel)]="c_filter._undaunted" (change)="addAbilityChip('_undaunted')"
                    [value]="false" class="no-radio">
                  <label for="Undaunted1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Undaunted" id="Undaunted2" [(ngModel)]="c_filter._undaunted" (change)="addAbilityChip('_undaunted')"
                    [value]="null" class="maybe-radio">
                  <label for="Undaunted2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Undaunted" id="Undaunted3" [(ngModel)]="c_filter._undaunted" (change)="addAbilityChip('_undaunted')"
                    [value]="true" class="yes-radio">
                  <label for="Undaunted3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_undergrowth':format_filter)">
                <label position="stacked">
                  {{ '_undergrowth' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Undergrowth" id="Undergrowth1" [(ngModel)]="c_filter._undergrowth" (change)="addAbilityChip('_undergrowth')"
                    [value]="false" class="no-radio">
                  <label for="Undergrowth1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Undergrowth" id="Undergrowth2" [(ngModel)]="c_filter._undergrowth" (change)="addAbilityChip('_undergrowth')"
                    [value]="null" class="maybe-radio">
                  <label for="Undergrowth2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Undergrowth" id="Undergrowth3" [(ngModel)]="c_filter._undergrowth" (change)="addAbilityChip('_undergrowth')"
                    [value]="true" class="yes-radio">
                  <label for="Undergrowth3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_undying':format_filter)">
                <label position="stacked">
                  {{ '_undying' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Undying" id="Undying1" [(ngModel)]="c_filter._undying" (change)="addAbilityChip('_undying')" [value]="false"
                    class="no-radio">
                  <label for="Undying1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Undying" id="Undying2" [(ngModel)]="c_filter._undying" (change)="addAbilityChip('_undying')" [value]="null"
                    class="maybe-radio">
                  <label for="Undying2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Undying" id="Undying3" [(ngModel)]="c_filter._undying" (change)="addAbilityChip('_undying')" [value]="true"
                    class="yes-radio">
                  <label for="Undying3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_unearth':format_filter)">
                <label position="stacked">
                  {{ '_unearth' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Unearth" id="Unearth1" [(ngModel)]="c_filter._unearth" (change)="addAbilityChip('_unearth')" [value]="false"
                    class="no-radio">
                  <label for="Unearth1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Unearth" id="Unearth2" [(ngModel)]="c_filter._unearth" (change)="addAbilityChip('_unearth')" [value]="null"
                    class="maybe-radio">
                  <label for="Unearth2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Unearth" id="Unearth3" [(ngModel)]="c_filter._unearth" (change)="addAbilityChip('_unearth')" [value]="true"
                    class="yes-radio">
                  <label for="Unearth3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_unleash':format_filter)">
                <label position="stacked">
                  {{ '_unleash' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Unleash" id="Unleash1" [(ngModel)]="c_filter._unleash" (change)="addAbilityChip('_unleash')" [value]="false"
                    class="no-radio">
                  <label for="Unleash1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Unleash" id="Unleash2" [(ngModel)]="c_filter._unleash" (change)="addAbilityChip('_unleash')" [value]="null"
                    class="maybe-radio">
                  <label for="Unleash2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Unleash" id="Unleash3" [(ngModel)]="c_filter._unleash" (change)="addAbilityChip('_unleash')" [value]="true"
                    class="yes-radio">
                  <label for="Unleash3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_vanishing':format_filter)">
                <label position="stacked">
                  {{ '_vanishing' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Vanishing" id="Vanishing1" [(ngModel)]="c_filter._vanishing" (change)="addAbilityChip('_vanishing')"
                    [value]="false" class="no-radio">
                  <label for="Vanishing1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Vanishing" id="Vanishing2" [(ngModel)]="c_filter._vanishing" (change)="addAbilityChip('_vanishing')"
                    [value]="null" class="maybe-radio">
                  <label for="Vanishing2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Vanishing" id="Vanishing3" [(ngModel)]="c_filter._vanishing" (change)="addAbilityChip('_vanishing')"
                    [value]="true" class="yes-radio">
                  <label for="Vanishing3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_wither':format_filter)">
                <label position="stacked">
                  {{ '_wither' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Wither" id="Wither1" [(ngModel)]="c_filter._wither" (change)="addAbilityChip('_wither')" [value]="false"
                    class="no-radio">
                  <label for="Wither1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Wither" id="Wither2" [(ngModel)]="c_filter._wither" (change)="addAbilityChip('_wither')" [value]="null"
                    class="maybe-radio">
                  <label for="Wither2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Wither" id="Wither3" [(ngModel)]="c_filter._wither" (change)="addAbilityChip('_wither')" [value]="true"
                    class="yes-radio">
                  <label for="Wither3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
          </div>
        </div>

        <div [ngSwitch]="cardAbility">
          <div *ngSwitchCase="'action'">
            <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activate':format_filter)">
                <label position="stacked">
                  {{ '_activate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Activate" id="Activate1" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                    [value]="false" class="no-radio">
                  <label for="Activate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Activate" id="Activate2" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                    [value]="null" class="maybe-radio">
                  <label for="Activate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Activate" id="Activate3" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                    [value]="true" class="yes-radio">
                  <label for="Activate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_attach':format_filter)">
                <label position="stacked">
                  {{ '_attach' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Attach" id="Attach1" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="false"
                    class="no-radio">
                  <label for="Attach1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Attach" id="Attach2" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="null"
                    class="maybe-radio">
                  <label for="Attach2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Attach" id="Attach3" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="true"
                    class="yes-radio">
                  <label for="Attach3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cast':format_filter)">
                <label position="stacked">
                  {{ '_cast' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cast" id="Cast1" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="false"
                    class="no-radio">
                  <label for="Cast1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cast" id="Cast2" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="null"
                    class="maybe-radio">
                  <label for="Cast2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cast" id="Cast3" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="true"
                    class="yes-radio">
                  <label for="Cast3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counter':format_filter)">
                <label position="stacked">
                  {{ '_counter' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Counter" id="Counter1" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="false"
                    class="no-radio">
                  <label for="Counter1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Counter" id="Counter2" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="null"
                    class="maybe-radio">
                  <label for="Counter2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Counter" id="Counter3" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="true"
                    class="yes-radio">
                  <label for="Counter3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_copy':format_filter)">
                  <label position="stacked">
                    {{ '_copy' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Copy" id="Copy1" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="false"
                      class="no-radio">
                    <label for="Copy1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Copy" id="Copy2" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="null"
                      class="maybe-radio">
                    <label for="Copy2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Copy" id="Copy3" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="true"
                      class="yes-radio">
                    <label for="Copy3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_create':format_filter)">
                <label position="stacked">
                  {{ '_create' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Create" id="Create1" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="false"
                    class="no-radio">
                  <label for="Create1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Create" id="Create2" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="null"
                    class="maybe-radio">
                  <label for="Create2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Create" id="Create3" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="true"
                    class="yes-radio">
                  <label for="Create3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cycle':format_filter)">
                <label position="stacked">
                  {{ '_cycle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cycle" id="Cycle1" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="false"
                    class="no-radio">
                  <label for="Cycle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cycle" id="Cycle2" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="null"
                    class="maybe-radio">
                  <label for="Cycle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cycle" id="Cycle3" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="true"
                    class="yes-radio">
                  <label for="Cycle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_destroy':format_filter)">
                <label position="stacked">
                  {{ '_destroy' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Destroy" id="Destroy1" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="false"
                    class="no-radio">
                  <label for="Destroy1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Destroy" id="Destroy2" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="null"
                    class="maybe-radio">
                  <label for="Destroy2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Destroy" id="Destroy3" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="true"
                    class="yes-radio">
                  <label for="Destroy3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_discard':format_filter)">
                <label position="stacked">
                  {{ '_discard' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Discard" id="Discard1" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="false"
                    class="no-radio">
                  <label for="Discard1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Discard" id="Discard2" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="null"
                    class="maybe-radio">
                  <label for="Discard2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Discard" id="Discard3" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="true"
                    class="yes-radio">
                  <label for="Discard3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_double':format_filter)">
                <label position="stacked">
                  {{ '_double' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Double" id="Double1" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="false"
                    class="no-radio">
                  <label for="Double1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Double" id="Double2" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="null"
                    class="maybe-radio">
                  <label for="Double2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Double" id="Double3" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="true"
                    class="yes-radio">
                  <label for="Double3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_draw':format_filter)">
                <label position="stacked">
                  {{ '_draw' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Draw" id="Draw1" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="false"
                    class="no-radio">
                  <label for="Draw1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Draw" id="Draw2" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="null"
                    class="maybe-radio">
                  <label for="Draw2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Draw" id="Draw3" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="true"
                    class="yes-radio">
                  <label for="Draw3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exchange':format_filter)">
                <label position="stacked">
                  {{ '_exchange' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Exchange" id="Exchange1" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                    [value]="false" class="no-radio">
                  <label for="Exchange1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Exchange" id="Exchange2" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                    [value]="null" class="maybe-radio">
                  <label for="Exchange2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Exchange" id="Exchange3" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                    [value]="true" class="yes-radio">
                  <label for="Exchange3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exile':format_filter)">
                <label position="stacked">
                  {{ '_exile' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Exile" id="Exile1" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="false"
                    class="no-radio">
                  <label for="Exile1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Exile" id="Exile2" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="null"
                    class="maybe-radio">
                  <label for="Exile2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Exile" id="Exile3" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="true"
                    class="yes-radio">
                  <label for="Exile3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fight':format_filter)">
                <label position="stacked">
                  {{ '_fight' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fight" id="Fight1" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="false"
                    class="no-radio">
                  <label for="Fight1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fight" id="Fight2" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="null"
                    class="maybe-radio">
                  <label for="Fight2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fight" id="Fight3" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="true"
                    class="yes-radio">
                  <label for="Fight3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_play':format_filter)">
                <label position="stacked">
                  {{ '_play' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Play" id="Play1" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="false"
                    class="no-radio">
                  <label for="Play1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Play" id="Play2" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="null"
                    class="maybe-radio">
                  <label for="Play2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Play" id="Play3" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="true"
                    class="yes-radio">
                  <label for="Play3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_reveal':format_filter)">
                <label position="stacked">
                  {{ '_reveal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Reveal" id="Reveal1" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="false"
                    class="no-radio">
                  <label for="Reveal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Reveal" id="Reveal2" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="null"
                    class="maybe-radio">
                  <label for="Reveal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Reveal" id="Reveal3" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="true"
                    class="yes-radio">
                  <label for="Reveal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_sacrifice':format_filter)">
                <label position="stacked">
                  {{ '_sacrifice' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Sacrifice" id="Sacrifice1" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                    [value]="false" class="no-radio">
                  <label for="Sacrifice1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Sacrifice" id="Sacrifice2" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                    [value]="null" class="maybe-radio">
                  <label for="Sacrifice2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Sacrifice" id="Sacrifice3" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                    [value]="true" class="yes-radio">
                  <label for="Sacrifice3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_scry':format_filter)">
                <label position="stacked">
                  {{ '_scry' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Scry" id="Scry1" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="false"
                    class="no-radio">
                  <label for="Scry1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Scry" id="Scry2" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="null"
                    class="maybe-radio">
                  <label for="Scry2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Scry" id="Scry3" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="true"
                    class="yes-radio">
                  <label for="Scry3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_search':format_filter)">
                <label position="stacked">
                  {{ '_search' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Search" id="Search1" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="false"
                    class="no-radio">
                  <label for="Search1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Search" id="Search2" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="null"
                    class="maybe-radio">
                  <label for="Search2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Search" id="Search3" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="true"
                    class="yes-radio">
                  <label for="Search3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_shuffle':format_filter)">
                <label position="stacked">
                  {{ '_shuffle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Shuffle" id="Shuffle1" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="false"
                    class="no-radio">
                  <label for="Shuffle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Shuffle" id="Shuffle2" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="null"
                    class="maybe-radio">
                  <label for="Shuffle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Shuffle" id="Shuffle3" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="true"
                    class="yes-radio">
                  <label for="Shuffle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tap':format_filter)">
                <label position="stacked">
                  {{ '_tap' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Tap" id="Tap1" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="false" class="no-radio">
                  <label for="Tap1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Tap" id="Tap2" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="null" class="maybe-radio">
                  <label for="Tap2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Tap" id="Tap3" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="true" class="yes-radio">
                  <label for="Tap3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_untap':format_filter)">
                <label position="stacked">
                  {{ '_untap' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Untap" id="Untap1" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="false"
                    class="no-radio">
                  <label for="Untap1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Untap" id="Untap2" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="null"
                    class="maybe-radio">
                  <label for="Untap2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Untap" id="Untap3" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="true"
                    class="yes-radio">
                  <label for="Untap3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
            <h4 class="container_yesno_subheader">{{ 'Other' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_adapt':format_filter)">
                <label position="stacked">
                  {{ '_adapt' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Adapt" id="Adapt1" [(ngModel)]="c_filter._adapt" (change)="addAbilityChip('_adapt')" [value]="false"
                    class="no-radio">
                  <label for="Adapt1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Adapt" id="Adapt2" [(ngModel)]="c_filter._adapt" (change)="addAbilityChip('_adapt')" [value]="null"
                    class="maybe-radio">
                  <label for="Adapt2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Adapt" id="Adapt3" [(ngModel)]="c_filter._adapt" (change)="addAbilityChip('_adapt')" [value]="true"
                    class="yes-radio">
                  <label for="Adapt3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_amass':format_filter)">
                <label position="stacked">
                  {{ '_amass' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Amass" id="Amass1" [(ngModel)]="c_filter._amass" (change)="addAbilityChip('_amass')" [value]="false"
                    class="no-radio">
                  <label for="Amass1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Amass" id="Amass2" [(ngModel)]="c_filter._amass" (change)="addAbilityChip('_amass')" [value]="null"
                    class="maybe-radio">
                  <label for="Amass2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Amass" id="Amass3" [(ngModel)]="c_filter._amass" (change)="addAbilityChip('_amass')" [value]="true"
                    class="yes-radio">
                  <label for="Amass3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bolster':format_filter)">
                <label position="stacked">
                  {{ '_bolster' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bolster" id="Bolster1" [(ngModel)]="c_filter._bolster" (change)="addAbilityChip('_bolster')" [value]="false"
                    class="no-radio">
                  <label for="Bolster1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bolster" id="Bolster2" [(ngModel)]="c_filter._bolster" (change)="addAbilityChip('_bolster')" [value]="null"
                    class="maybe-radio">
                  <label for="Bolster2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bolster" id="Bolster3" [(ngModel)]="c_filter._bolster" (change)="addAbilityChip('_bolster')" [value]="true"
                    class="yes-radio">
                  <label for="Bolster3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_clash':format_filter)">
                <label position="stacked">
                  {{ '_clash' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Clash" id="Clash1" [(ngModel)]="c_filter._clash" (change)="addAbilityChip('_clash')" [value]="false"
                    class="no-radio">
                  <label for="Clash1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Clash" id="Clash2" [(ngModel)]="c_filter._clash" (change)="addAbilityChip('_clash')" [value]="null"
                    class="maybe-radio">
                  <label for="Clash2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Clash" id="Clash3" [(ngModel)]="c_filter._clash" (change)="addAbilityChip('_clash')" [value]="true"
                    class="yes-radio">
                  <label for="Clash3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_detain':format_filter)">
                <label position="stacked">
                  {{ '_detain' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Detain" id="Detain1" [(ngModel)]="c_filter._detain" (change)="addAbilityChip('_detain')" [value]="false"
                    class="no-radio">
                  <label for="Detain1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Detain" id="Detain2" [(ngModel)]="c_filter._detain" (change)="addAbilityChip('_detain')" [value]="null"
                    class="maybe-radio">
                  <label for="Detain2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Detain" id="Detain3" [(ngModel)]="c_filter._detain" (change)="addAbilityChip('_detain')" [value]="true"
                    class="yes-radio">
                  <label for="Detain3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exert':format_filter)">
                <label position="stacked">
                  {{ '_exert' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Exert" id="Exert1" [(ngModel)]="c_filter._exert" (change)="addAbilityChip('_exert')" [value]="false"
                    class="no-radio">
                  <label for="Exert1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Exert" id="Exert2" [(ngModel)]="c_filter._exert" (change)="addAbilityChip('_exert')" [value]="null"
                    class="maybe-radio">
                  <label for="Exert2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Exert" id="Exert3" [(ngModel)]="c_filter._exert" (change)="addAbilityChip('_exert')" [value]="true"
                    class="yes-radio">
                  <label for="Exert3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_explore':format_filter)">
                <label position="stacked">
                  {{ '_explore' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Explore" id="Explore1" [(ngModel)]="c_filter._explore" (change)="addAbilityChip('_explore')" [value]="false"
                    class="no-radio">
                  <label for="Explore1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Explore" id="Explore2" [(ngModel)]="c_filter._explore" (change)="addAbilityChip('_explore')" [value]="null"
                    class="maybe-radio">
                  <label for="Explore2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Explore" id="Explore3" [(ngModel)]="c_filter._explore" (change)="addAbilityChip('_explore')" [value]="true"
                    class="yes-radio">
                  <label for="Explore3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fateseal':format_filter)">
                <label position="stacked">
                  {{ '_fateseal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Fateseal" id="Fateseal1" [(ngModel)]="c_filter._fateseal" (change)="addAbilityChip('_fateseal')"
                    [value]="false" class="no-radio">
                  <label for="Fateseal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Fateseal" id="Fateseal2" [(ngModel)]="c_filter._fateseal" (change)="addAbilityChip('_fateseal')"
                    [value]="null" class="maybe-radio">
                  <label for="Fateseal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Fateseal" id="Fateseal3" [(ngModel)]="c_filter._fateseal" (change)="addAbilityChip('_fateseal')"
                    [value]="true" class="yes-radio">
                  <label for="Fateseal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_goad':format_filter)">
                <label position="stacked">
                  {{ '_goad' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Goad" id="Goad1" [(ngModel)]="c_filter._goad" (change)="addAbilityChip('_goad')" [value]="false"
                    class="no-radio">
                  <label for="Goad1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Goad" id="Goad2" [(ngModel)]="c_filter._goad" (change)="addAbilityChip('_goad')" [value]="null"
                    class="maybe-radio">
                  <label for="Goad2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Goad" id="Goad3" [(ngModel)]="c_filter._goad" (change)="addAbilityChip('_goad')" [value]="true"
                    class="yes-radio">
                  <label for="Goad3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_investigate':format_filter)">
                <label position="stacked">
                  {{ '_investigate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Investigate" id="Investigate1" [(ngModel)]="c_filter._investigate" (change)="addAbilityChip('_investigate')"
                    [value]="false" class="no-radio">
                  <label for="Investigate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Investigate" id="Investigate2" [(ngModel)]="c_filter._investigate" (change)="addAbilityChip('_investigate')"
                    [value]="null" class="maybe-radio">
                  <label for="Investigate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Investigate" id="Investigate3" [(ngModel)]="c_filter._investigate" (change)="addAbilityChip('_investigate')"
                    [value]="true" class="yes-radio">
                  <label for="Investigate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_learn':format_filter)">
                <label position="stacked">
                  {{ '_learn' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Learn" id="Learn1" [(ngModel)]="c_filter._learn" (change)="addAbilityChip('_learn')"
                    [value]="false" class="no-radio">
                  <label for="Learn1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Learn" id="Learn2" [(ngModel)]="c_filter._learn" (change)="addAbilityChip('_learn')"
                    [value]="null" class="maybe-radio">
                  <label for="Learn2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Learn" id="Learn3" [(ngModel)]="c_filter._learn" (change)="addAbilityChip('_learn')"
                    [value]="true" class="yes-radio">
                  <label for="Learn3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_manifest':format_filter)">
                <label position="stacked">
                  {{ '_manifest' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Manifest" id="Manifest1" [(ngModel)]="c_filter._manifest" (change)="addAbilityChip('_manifest')"
                    [value]="false" class="no-radio">
                  <label for="Manifest1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Manifest" id="Manifest2" [(ngModel)]="c_filter._manifest" (change)="addAbilityChip('_manifest')"
                    [value]="null" class="maybe-radio">
                  <label for="Manifest2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Manifest" id="Manifest3" [(ngModel)]="c_filter._manifest" (change)="addAbilityChip('_manifest')"
                    [value]="true" class="yes-radio">
                  <label for="Manifest3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_meld':format_filter)">
                <label position="stacked">
                  {{ '_meld' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Meld" id="Meld1" [(ngModel)]="c_filter._meld" (change)="addAbilityChip('_meld')" [value]="false"
                    class="no-radio">
                  <label for="Meld1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Meld" id="Meld2" [(ngModel)]="c_filter._meld" (change)="addAbilityChip('_meld')" [value]="null"
                    class="maybe-radio">
                  <label for="Meld2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Meld" id="Meld3" [(ngModel)]="c_filter._meld" (change)="addAbilityChip('_meld')" [value]="true"
                    class="yes-radio">
                  <label for="Meld3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_monstrosity':format_filter)">
                <label position="stacked">
                  {{ '_monstrosity' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Monstrosity" id="Monstrosity1" [(ngModel)]="c_filter._monstrosity" (change)="addAbilityChip('_monstrosity')"
                    [value]="false" class="no-radio">
                  <label for="Monstrosity1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Monstrosity" id="Monstrosity2" [(ngModel)]="c_filter._monstrosity" (change)="addAbilityChip('_monstrosity')"
                    [value]="null" class="maybe-radio">
                  <label for="Monstrosity2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Monstrosity" id="Monstrosity3" [(ngModel)]="c_filter._monstrosity" (change)="addAbilityChip('_monstrosity')"
                    [value]="true" class="yes-radio">
                  <label for="Monstrosity3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_populate':format_filter)">
                <label position="stacked">
                  {{ '_populate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Populate" id="Populate1" [(ngModel)]="c_filter._populate" (change)="addAbilityChip('_populate')"
                    [value]="false" class="no-radio">
                  <label for="Populate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Populate" id="Populate2" [(ngModel)]="c_filter._populate" (change)="addAbilityChip('_populate')"
                    [value]="null" class="maybe-radio">
                  <label for="Populate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Populate" id="Populate3" [(ngModel)]="c_filter._populate" (change)="addAbilityChip('_populate')"
                    [value]="true" class="yes-radio">
                  <label for="Populate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_proliferate':format_filter)">
                <label position="stacked">
                  {{ '_proliferate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Proliferate" id="Proliferate1" [(ngModel)]="c_filter._proliferate" (change)="addAbilityChip('_proliferate')"
                    [value]="false" class="no-radio">
                  <label for="Proliferate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Proliferate" id="Proliferate2" [(ngModel)]="c_filter._proliferate" (change)="addAbilityChip('_proliferate')"
                    [value]="null" class="maybe-radio">
                  <label for="Proliferate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Proliferate" id="Proliferate3" [(ngModel)]="c_filter._proliferate" (change)="addAbilityChip('_proliferate')"
                    [value]="true" class="yes-radio">
                  <label for="Proliferate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_regenerate':format_filter)">
                <label position="stacked">
                  {{ '_regenerate' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Regenerate" id="Regenerate1" [(ngModel)]="c_filter._regenerate" (change)="addAbilityChip('_regenerate')"
                    [value]="false" class="no-radio">
                  <label for="Regenerate1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Regenerate" id="Regenerate2" [(ngModel)]="c_filter._regenerate" (change)="addAbilityChip('_regenerate')"
                    [value]="null" class="maybe-radio">
                  <label for="Regenerate2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Regenerate" id="Regenerate3" [(ngModel)]="c_filter._regenerate" (change)="addAbilityChip('_regenerate')"
                    [value]="true" class="yes-radio">
                  <label for="Regenerate3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_roll':format_filter)">
                <label position="stacked">
                  {{ '_roll' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Roll" id="Roll1" [(ngModel)]="c_filter._roll" (change)="addAbilityChip('_roll')" [value]="false"
                    class="no-radio">
                  <label for="Roll1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Roll" id="Roll2" [(ngModel)]="c_filter._roll" (change)="addAbilityChip('_roll')" [value]="null"
                    class="maybe-radio">
                  <label for="Roll2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Roll" id="Roll3" [(ngModel)]="c_filter._roll" (change)="addAbilityChip('_roll')" [value]="true"
                    class="yes-radio">
                  <label for="Roll3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_support':format_filter)">
                <label position="stacked">
                  {{ '_support' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Support" id="Support1" [(ngModel)]="c_filter._support" (change)="addAbilityChip('_support')" [value]="false"
                    class="no-radio">
                  <label for="Support1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Support" id="Support2" [(ngModel)]="c_filter._support" (change)="addAbilityChip('_support')" [value]="null"
                    class="maybe-radio">
                  <label for="Support2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Support" id="Support3" [(ngModel)]="c_filter._support" (change)="addAbilityChip('_support')" [value]="true"
                    class="yes-radio">
                  <label for="Support3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_surveil':format_filter)">
                <label position="stacked">
                  {{ '_surveil' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Surveil" id="Surveil1" [(ngModel)]="c_filter._surveil" (change)="addAbilityChip('_surveil')" [value]="false"
                    class="no-radio">
                  <label for="Surveil1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Surveil" id="Surveil2" [(ngModel)]="c_filter._surveil" (change)="addAbilityChip('_surveil')" [value]="null"
                    class="maybe-radio">
                  <label for="Surveil2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Surveil" id="Surveil3" [(ngModel)]="c_filter._surveil" (change)="addAbilityChip('_surveil')" [value]="true"
                    class="yes-radio">
                  <label for="Surveil3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_transform':format_filter)">
                <label position="stacked">
                  {{ '_transform' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Transform" id="Transform1" [(ngModel)]="c_filter._transform" (change)="addAbilityChip('_transform')"
                    [value]="false" class="no-radio">
                  <label for="Transform1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Transform" id="Transform2" [(ngModel)]="c_filter._transform" (change)="addAbilityChip('_transform')"
                    [value]="null" class="maybe-radio">
                  <label for="Transform2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Transform" id="Transform3" [(ngModel)]="c_filter._transform" (change)="addAbilityChip('_transform')"
                    [value]="true" class="yes-radio">
                  <label for="Transform3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_venture':format_filter)">
                <label position="stacked">
                  {{ '_venture' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Venture" id="Venture1" [(ngModel)]="c_filter._venture" (change)="addAbilityChip('_venture')"
                    [value]="false" class="no-radio">
                  <label for="Venture1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Venture" id="Venture2" [(ngModel)]="c_filter._venture" (change)="addAbilityChip('_venture')"
                    [value]="null" class="maybe-radio">
                  <label for="Venture2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Venture" id="Venture3" [(ngModel)]="c_filter._venture" (change)="addAbilityChip('_venture')"
                    [value]="true" class="yes-radio">
                  <label for="Venture3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_vote':format_filter)">
                <label position="stacked">
                  {{ '_vote' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Vote" id="Vote1" [(ngModel)]="c_filter._vote" (change)="addAbilityChip('_vote')" [value]="false"
                    class="no-radio">
                  <label for="Vote1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Vote" id="Vote2" [(ngModel)]="c_filter._vote" (change)="addAbilityChip('_vote')" [value]="null"
                    class="maybe-radio">
                  <label for="Vote2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Vote" id="Vote3" [(ngModel)]="c_filter._vote" (change)="addAbilityChip('_vote')" [value]="true"
                    class="yes-radio">
                  <label for="Vote3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
          </div>
        </div>

        <div [ngSwitch]="cardAbility">
          <div *ngSwitchCase="'identity'">
            <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes">
                <label position="stacked">
                  {{ '_layout_Normal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="layoutNormal" id="layoutNormal1" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                    [value]="false" class="no-radio">
                  <label for="layoutNormal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="layoutNormal" id="layoutNormal2" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                    [value]="null" class="maybe-radio">
                  <label for="layoutNormal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="layoutNormal" id="layoutNormal3" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                    [value]="true" class="yes-radio">
                  <label for="layoutNormal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_Split':format_filter)">
                <label position="stacked">
                  {{ '_layout_Split' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="layoutSplit" id="layoutSplit1" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                    [value]="false" class="no-radio">
                  <label for="layoutSplit1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="layoutSplit" id="layoutSplit2" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                    [value]="null" class="maybe-radio">
                  <label for="layoutSplit2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="layoutSplit" id="layoutSplit3" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                    [value]="true" class="yes-radio">
                  <label for="layoutSplit3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_Flip':format_filter)">
                <label position="stacked">
                  {{ '_layout_Flip' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="layoutFlip" id="layoutFlip1" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                    [value]="false" class="no-radio">
                  <label for="layoutFlip1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="layoutFlip" id="layoutFlip2" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                    [value]="null" class="maybe-radio">
                  <label for="layoutFlip2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="layoutFlip" id="layoutFlip3" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                    [value]="true" class="yes-radio">
                  <label for="layoutFlip3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_DoubleFaced':format_filter)">
                <label position="stacked">
                  {{ '_layout_DoubleFaced' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced1" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                    [value]="false" class="no-radio">
                  <label for="layoutDoubleFaced1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced2" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                    [value]="null" class="maybe-radio">
                  <label for="layoutDoubleFaced2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced3" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                    [value]="true" class="yes-radio">
                  <label for="layoutDoubleFaced3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_white':format_filter)">
                <label position="stacked">
                  {{ '_ci_white' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CIwhite" id="CIwhite1" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                    [value]="false" class="no-radio">
                  <label for="CIwhite1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CIwhite" id="CIwhite2" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                    [value]="null" class="maybe-radio">
                  <label for="CIwhite2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CIwhite" id="CIwhite3" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                    [value]="true" class="yes-radio">
                  <label for="CIwhite3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_blue':format_filter)">
                <label position="stacked">
                  {{ '_ci_blue' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CIblue" id="CIblue1" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="false"
                    class="no-radio">
                  <label for="CIblue1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CIblue" id="CIblue2" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="null"
                    class="maybe-radio">
                  <label for="CIblue2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CIblue" id="CIblue3" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="true"
                    class="yes-radio">
                  <label for="CIblue3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_black':format_filter)">
                <label position="stacked">
                  {{ '_ci_black' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CIblack" id="CIblack1" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                    [value]="false" class="no-radio">
                  <label for="CIblack1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CIblack" id="CIblack2" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                    [value]="null" class="maybe-radio">
                  <label for="CIblack2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CIblack" id="CIblack3" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                    [value]="true" class="yes-radio">
                  <label for="CIblack3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_red':format_filter)">
                <label position="stacked">
                  {{ '_ci_red' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CIred" id="CIred1" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="false"
                    class="no-radio">
                  <label for="CIred1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CIred" id="CIred2" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="null"
                    class="maybe-radio">
                  <label for="CIred2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CIred" id="CIred3" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="true"
                    class="yes-radio">
                  <label for="CIred3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_green':format_filter)">
                <label position="stacked">
                  {{ '_ci_green' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="CIgreen" id="CIgreen1" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                    [value]="false" class="no-radio">
                  <label for="CIgreen1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="CIgreen" id="CIgreen2" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                    [value]="null" class="maybe-radio">
                  <label for="CIgreen2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="CIgreen" id="CIgreen3" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                    [value]="true" class="yes-radio">
                  <label for="CIgreen3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_commander':format_filter)">
                <label position="stacked">
                  {{ '_commander' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="commander" id="commander1" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="false" class="no-radio">
                  <label for="commander1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="commander" id="commander2" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="null" class="maybe-radio">
                  <label for="commander2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="commander" id="commander3" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="true" class="yes-radio">
                  <label for="commander3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_legendary':format_filter)">
                <label position="stacked">
                  {{ '_legendary' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="legendary" id="legendary1" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="false" class="no-radio">
                  <label for="legendary1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="legendary" id="legendary2" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="null" class="maybe-radio">
                  <label for="legendary2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="legendary" id="legendary3" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="true" class="yes-radio">
                  <label for="legendary3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
            <h4 class="container_yesno_subheader">{{ 'Other' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_historic':format_filter)">
                <label position="stacked">
                  {{ '_historic' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="historic" id="historic1" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="false" class="no-radio">
                  <label for="historic1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="historic" id="historic2" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="null" class="maybe-radio">
                  <label for="historic2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="historic" id="historic3" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="true" class="yes-radio">
                  <label for="historic3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_saga':format_filter)">
                <label position="stacked">
                  {{ '_saga' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="saga" id="saga1" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="false"
                    class="no-radio">
                  <label for="saga1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="saga" id="saga2" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="null"
                    class="maybe-radio">
                  <label for="saga2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="saga" id="saga3" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="true"
                    class="yes-radio">
                  <label for="saga3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_equipment':format_filter)">
                <label position="stacked">
                  {{ '_equipment' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="equipment" id="equipment1" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="false" class="no-radio">
                  <label for="equipment1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="equipment" id="equipment2" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="null" class="maybe-radio">
                  <label for="equipment2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="equipment" id="equipment3" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="true" class="yes-radio">
                  <label for="equipment3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_vehicle':format_filter)">
                <label position="stacked">
                  {{ '_vehicle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="vehicle" id="vehicle1" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="false"
                    class="no-radio">
                  <label for="vehicle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="vehicle" id="vehicle2" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="null"
                    class="maybe-radio">
                  <label for="vehicle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="vehicle" id="vehicle3" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="true"
                    class="yes-radio">
                  <label for="vehicle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_aura':format_filter)">
                <label position="stacked">
                  {{ '_aura' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="aura" id="aura1" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="false"
                    class="no-radio">
                  <label for="aura1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="aura" id="aura2" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="null"
                    class="maybe-radio">
                  <label for="aura2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="aura" id="aura3" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="true"
                    class="yes-radio">
                  <label for="aura3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tribal':format_filter)">
                <label position="stacked">
                  {{ '_tribal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="tribal" id="tribal1" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="false"
                    class="no-radio">
                  <label for="tribal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="tribal" id="tribal2" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="null"
                    class="maybe-radio">
                  <label for="tribal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="tribal" id="tribal3" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="true"
                    class="yes-radio">
                  <label for="tribal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_snow':format_filter)">
                <label position="stacked">
                  {{ '_snow' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="snow" id="snow1" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="false"
                    class="no-radio">
                  <label for="snow1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="snow" id="snow2" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="null"
                    class="maybe-radio">
                  <label for="snow2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="snow" id="snow3" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="true"
                    class="yes-radio">
                  <label for="snow3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_world':format_filter)">
                <label position="stacked">
                  {{ '_world' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="world" id="world1" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="false"
                    class="no-radio">
                  <label for="world1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="world" id="world2" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="null"
                    class="maybe-radio">
                  <label for="world2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="world" id="world3" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="true"
                    class="yes-radio">
                  <label for="world3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
          </div>
        </div>

        <div [ngSwitch]="cardAbility">
          <div *ngSwitchCase="'mechanics'">
            <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_alt_loss':format_filter)">
                <label position="stacked">
                  {{ '_alt_loss' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="AltLoss" id="AltLoss1" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                    [value]="false" class="no-radio">
                  <label for="AltLoss1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="AltLoss" id="AltLoss2" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                    [value]="null" class="maybe-radio">
                  <label for="AltLoss2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="AltLoss" id="AltLoss3" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                    [value]="true" class="yes-radio">
                  <label for="AltLoss3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_alt_win':format_filter)">
                <label position="stacked">
                  {{ '_alt_win' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="AltWin" id="AltWin1" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="false"
                    class="no-radio">
                  <label for="AltWin1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="AltWin" id="AltWin2" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="null"
                    class="maybe-radio">
                  <label for="AltWin2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="AltWin" id="AltWin3" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="true"
                    class="yes-radio">
                  <label for="AltWin3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_anti_discard':format_filter)">
                <label position="stacked">
                  {{ '_anti_discard' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="AntiDiscard" id="AntiDiscard1" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                    [value]="false" class="no-radio">
                  <label for="AntiDiscard1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="AntiDiscard" id="AntiDiscard2" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                    [value]="null" class="maybe-radio">
                  <label for="AntiDiscard2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="AntiDiscard" id="AntiDiscard3" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                    [value]="true" class="yes-radio">
                  <label for="AntiDiscard3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bounce':format_filter)">
                <label position="stacked">
                  {{ '_bounce' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Bounce" id="Bounce1" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="false"
                    class="no-radio">
                  <label for="Bounce1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Bounce" id="Bounce2" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="null"
                    class="maybe-radio">
                  <label for="Bounce2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Bounce" id="Bounce3" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="true"
                    class="yes-radio">
                  <label for="Bounce3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_burn':format_filter)">
                <label position="stacked">
                  {{ '_burn' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Burn" id="Burn1" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="false"
                    class="no-radio">
                  <label for="Burn1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Burn" id="Burn2" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="null"
                    class="maybe-radio">
                  <label for="Burn2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Burn" id="Burn3" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="true"
                    class="yes-radio">
                  <label for="Burn3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cantrip':format_filter)">
                <label position="stacked">
                  {{ '_cantrip' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Cantrip" id="Cantrip1" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="false"
                    class="no-radio">
                  <label for="Cantrip1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Cantrip" id="Cantrip2" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="null"
                    class="maybe-radio">
                  <label for="Cantrip2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Cantrip" id="Cantrip3" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="true"
                    class="yes-radio">
                  <label for="Cantrip3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counterspell':format_filter)">
                <label position="stacked">
                  {{ '_counterspell' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Counterspell" id="Counterspell1" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                    [value]="false" class="no-radio">
                  <label for="Counterspell1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Counterspell" id="Counterspell2" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                    [value]="null" class="maybe-radio">
                  <label for="Counterspell2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Counterspell" id="Counterspell3" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                    [value]="true" class="yes-radio">
                  <label for="Counterspell3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_enter_the_battlefield':format_filter)">
                <label position="stacked">
                  {{ '_enter_the_battlefield' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="ETB" id="ETB1" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                    [value]="false" class="no-radio">
                  <label for="ETB1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="ETB" id="ETB2" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                    [value]="null" class="maybe-radio">
                  <label for="ETB2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="ETB" id="ETB3" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                    [value]="true" class="yes-radio">
                  <label for="ETB3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_looting':format_filter)">
                <label position="stacked">
                  {{ '_looting' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Looting" id="Looting1" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="false"
                    class="no-radio">
                  <label for="Looting1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Looting" id="Looting2" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="null"
                    class="maybe-radio">
                  <label for="Looting2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Looting" id="Looting3" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="true"
                    class="yes-radio">
                  <label for="Looting3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mill':format_filter)">
                <label position="stacked">
                  {{ '_mill' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Mill" id="Mill1" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="false"
                    class="no-radio">
                  <label for="Mill1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Mill" id="Mill2" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="null"
                    class="maybe-radio">
                  <label for="Mill2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Mill" id="Mill3" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="true"
                    class="yes-radio">
                  <label for="Mill3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_self_mill':format_filter)">
                <label position="stacked">
                  {{ '_self_mill' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="SelfMill" id="SelfMill1" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="false"
                    class="no-radio">
                  <label for="SelfMill1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="SelfMill" id="SelfMill2" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="null"
                    class="maybe-radio">
                  <label for="SelfMill2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="SelfMill" id="SelfMill3" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="true"
                    class="yes-radio">
                  <label for="SelfMill3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_rummaging':format_filter)">
                <label position="stacked">
                  {{ '_rummaging' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Rummaging" id="Rummaging1" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="false"
                    class="no-radio">
                  <label for="Rummaging1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Rummaging" id="Rummaging2" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="null"
                    class="maybe-radio">
                  <label for="Rummaging2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Rummaging" id="Rummaging3" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="true"
                    class="yes-radio">
                  <label for="Rummaging3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_stat_increase':format_filter)">
                <label position="stacked">
                  {{ '_stat_increase' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="StatIncrease" id="StatIncrease1" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                    [value]="false" class="no-radio">
                  <label for="StatIncrease1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="StatIncrease" id="StatIncrease2" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                    [value]="null" class="maybe-radio">
                  <label for="StatIncrease2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="StatIncrease" id="StatIncrease3" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                    [value]="true" class="yes-radio">
                  <label for="StatIncrease3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_stat_reduce':format_filter)">
                <label position="stacked">
                  {{ '_stat_reduce' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="StatReduce" id="StatReduce1" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                    [value]="false" class="no-radio">
                  <label for="StatReduce1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="StatReduce" id="StatReduce2" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                    [value]="null" class="maybe-radio">
                  <label for="StatReduce2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="StatReduce" id="StatReduce3" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                    [value]="true" class="yes-radio">
                  <label for="StatReduce3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tutor':format_filter)">
                <label position="stacked">
                  {{ '_tutor' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Tutor" id="Tutor1" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="false"
                    class="no-radio">
                  <label for="Tutor1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Tutor" id="Tutor2" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="null"
                    class="maybe-radio">
                  <label for="Tutor2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Tutor" id="Tutor3" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="true"
                    class="yes-radio">
                  <label for="Tutor3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
            <h4 class="container_yesno_subheader">{{ 'Other' | translate }}</h4>
            <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activated_ability':format_filter)">
                <label position="stacked">
                  {{ '_activated_ability' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activatedAbility" id="activatedAbility1" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                    [value]="false" class="no-radio">
                  <label for="activatedAbility1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activatedAbility" id="activatedAbility2" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                    [value]="null" class="maybe-radio">
                  <label for="activatedAbility2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activatedAbility" id="activatedAbility3" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                    [value]="true" class="yes-radio">
                  <label for="activatedAbility3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc0':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc0' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC0" id="activationCMC0_1" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC0_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC0" id="activationCMC0_2" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC0_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC0" id="activationCMC0_3" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC0_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc1':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc1' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC1" id="activationCMC1_1" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC1_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC1" id="activationCMC1_2" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC1_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC1" id="activationCMC1_3" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC1_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc2':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc2' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC2" id="activationCMC2_1" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC2_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC2" id="activationCMC2_2" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC2_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC2" id="activationCMC2_3" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC2_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc3':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc3' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC3" id="activationCMC3_1" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC3_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC3" id="activationCMC3_2" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC3_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC3" id="activationCMC3_3" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC3_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc4':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc4' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC4" id="activationCMC4_1" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC4_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC4" id="activationCMC4_2" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC4_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC4" id="activationCMC4_3" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC4_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc5':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc5' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC5" id="activationCMC5_1" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC5_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC5" id="activationCMC5_2" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC5_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC5" id="activationCMC5_3" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC5_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc6':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc6' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC6" id="activationCMC6_1" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC6_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC6" id="activationCMC6_2" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC6_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC6" id="activationCMC6_3" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC6_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc7':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc7' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC7" id="activationCMC7_1" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC7_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC7" id="activationCMC7_2" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC7_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC7" id="activationCMC7_3" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC7_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc8_plus':format_filter) && c_filter._activated_ability === true">
                <label position="stacked">
                  {{ '_activation_cmc8_plus' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_1" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                    [value]="false" class="no-radio">
                  <label for="activationCMC8_plus_1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_2" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                    [value]="null" class="maybe-radio">
                  <label for="activationCMC8_plus_2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_3" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                    [value]="true" class="yes-radio">
                  <label for="activationCMC8_plus_3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_casting_ability':format_filter)">
                <label position="stacked">
                  {{ '_casting_ability' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="castingAbility" id="castingAbility1" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                    [value]="false" class="no-radio">
                  <label for="castingAbility1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="castingAbility" id="castingAbility2" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                    [value]="null" class="maybe-radio">
                  <label for="castingAbility2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="castingAbility" id="castingAbility3" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                    [value]="true" class="yes-radio">
                  <label for="castingAbility3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_passive_ability':format_filter)">
                <label position="stacked">
                  {{ '_passive_ability' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="passiveAbility" id="passiveAbility1" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                    [value]="false" class="no-radio">
                  <label for="passiveAbility1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="passiveAbility" id="passiveAbility2" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                    [value]="null" class="maybe-radio">
                  <label for="passiveAbility2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="passiveAbility" id="passiveAbility3" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                    [value]="true" class="yes-radio">
                  <label for="passiveAbility3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_triggered_ability':format_filter)">
                <label position="stacked">
                  {{ '_triggered_ability' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="triggeredAbility" id="triggeredAbility1" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                    [value]="false" class="no-radio">
                  <label for="triggeredAbility1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="triggeredAbility" id="triggeredAbility2" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                    [value]="null" class="maybe-radio">
                  <label for="triggeredAbility2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="triggeredAbility" id="triggeredAbility3" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                    [value]="true" class="yes-radio">
                  <label for="triggeredAbility3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_tap':format_filter)">
                <label position="stacked">
                  {{ '_require_tap' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="requireTap" id="requireTap1" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                    [value]="false" class="no-radio">
                  <label for="requireTap1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="requireTap" id="requireTap2" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                    [value]="null" class="maybe-radio">
                  <label for="requireTap2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="requireTap" id="requireTap3" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                    [value]="true" class="yes-radio">
                  <label for="requireTap3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_untap':format_filter)">
                <label position="stacked">
                  {{ '_require_untap' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="requireUntap" id="requireUntap1" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                    [value]="false" class="no-radio">
                  <label for="requireUntap1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="requireUntap" id="requireUntap2" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                    [value]="null" class="maybe-radio">
                  <label for="requireUntap2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="requireUntap" id="requireUntap3" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                    [value]="true" class="yes-radio">
                  <label for="requireUntap3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_colorless':format_filter)">
                <label position="stacked">
                  {{ '_require_colorless' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="requireColorless" id="requireColorless1" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                    [value]="false" class="no-radio">
                  <label for="requireColorless1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="requireColorless" id="requireColorless2" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                    [value]="null" class="maybe-radio">
                  <label for="requireColorless2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="requireColorless" id="requireColorless3" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                    [value]="true" class="yes-radio">
                  <label for="requireColorless3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_mana':format_filter)">
                <label position="stacked">
                  {{ '_require_mana' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="requireMana" id="requireMana1" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                    [value]="false" class="no-radio">
                  <label for="requireMana1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="requireMana" id="requireMana2" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                    [value]="null" class="maybe-radio">
                  <label for="requireMana2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="requireMana" id="requireMana3" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                    [value]="true" class="yes-radio">
                  <label for="requireMana3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_give_colorless':format_filter)">
                <label position="stacked">
                  {{ '_give_colorless' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="giveColorless" id="giveColorless1" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                    [value]="false" class="no-radio">
                  <label for="giveColorless1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="giveColorless" id="giveColorless2" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                    [value]="null" class="maybe-radio">
                  <label for="giveColorless2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="giveColorless" id="giveColorless3" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                    [value]="true" class="yes-radio">
                  <label for="giveColorless3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_give_mana':format_filter)">
                <label position="stacked">
                  {{ '_give_mana' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="giveMana" id="giveMana1" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                    [value]="false" class="no-radio">
                  <label for="giveMana1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="giveMana" id="giveMana2" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                    [value]="null" class="maybe-radio">
                  <label for="giveMana2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="giveMana" id="giveMana3" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                    [value]="true" class="yes-radio">
                  <label for="giveMana3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counters':format_filter)">
                <label position="stacked">
                  {{ '_counters' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="counters" id="counters1" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                    [value]="false" class="no-radio">
                  <label for="counters1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="counters" id="counters2" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                    [value]="null" class="maybe-radio">
                  <label for="counters2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="counters" id="counters3" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                    [value]="true" class="yes-radio">
                  <label for="counters3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_energy':format_filter)">
                <label position="stacked">
                  {{ '_energy' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="Energy" id="Energy1" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="false"
                    class="no-radio">
                  <label for="Energy1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="Energy" id="Energy2" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="null"
                    class="maybe-radio">
                  <label for="Energy2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="Energy" id="Energy3" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="true"
                    class="yes-radio">
                  <label for="Energy3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_plus1_counters':format_filter)">
                <label position="stacked">
                  {{ '_plus1_counters' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="plus1Counters" id="plus1Counters1" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                    [value]="false" class="no-radio">
                  <label for="plus1Counters1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="plus1Counters" id="plus1Counters2" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                    [value]="null" class="maybe-radio">
                  <label for="plus1Counters2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="plus1Counters" id="plus1Counters3" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                    [value]="true" class="yes-radio">
                  <label for="plus1Counters3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_minus1_counters':format_filter)">
                <label position="stacked">
                  {{ '_minus1_counters' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="minus1Counters" id="minus1Counters1" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                    [value]="false" class="no-radio">
                  <label for="minus1Counters1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="minus1Counters" id="minus1Counters2" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                    [value]="null" class="maybe-radio">
                  <label for="minus1Counters2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="minus1Counters" id="minus1Counters3" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                    [value]="true" class="yes-radio">
                  <label for="minus1Counters3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mass_removal':format_filter)">
                <label position="stacked">
                  {{ '_mass_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="massRemoval" id="massRemoval1" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                    [value]="false" class="no-radio">
                  <label for="massRemoval1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="massRemoval" id="massRemoval2" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="massRemoval2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="massRemoval" id="massRemoval3" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                    [value]="true" class="yes-radio">
                  <label for="massRemoval3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_spot_removal':format_filter)">
                <label position="stacked">
                  {{ '_spot_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="spotRemoval" id="spotRemoval1" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                    [value]="false" class="no-radio">
                  <label for="spotRemoval1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="spotRemoval" id="spotRemoval2" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="spotRemoval2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="spotRemoval" id="spotRemoval3" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                    [value]="true" class="yes-radio">
                  <label for="spotRemoval3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_artifact':format_filter)">
                <label position="stacked">
                  {{ '_target_artifact' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetArtifact" id="targetArtifact1" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                    [value]="false" class="no-radio">
                  <label for="targetArtifact1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetArtifact" id="targetArtifact2" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                    [value]="null" class="maybe-radio">
                  <label for="targetArtifact2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetArtifact" id="targetArtifact3" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                    [value]="true" class="yes-radio">
                  <label for="targetArtifact3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_creature':format_filter)">
                <label position="stacked">
                  {{ '_target_creature' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetCreature" id="targetCreature1" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                    [value]="false" class="no-radio">
                  <label for="targetCreature1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetCreature" id="targetCreature2" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                    [value]="null" class="maybe-radio">
                  <label for="targetCreature2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetCreature" id="targetCreature3" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                    [value]="true" class="yes-radio">
                  <label for="targetCreature3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_card':format_filter)">
                <label position="stacked">
                  {{ '_target_card' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetCard" id="targetCard1" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                    [value]="false" class="no-radio">
                  <label for="targetCard1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetCard" id="targetCard2" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                    [value]="null" class="maybe-radio">
                  <label for="targetCard2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetCard" id="targetCard3" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                    [value]="true" class="yes-radio">
                  <label for="targetCard3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_enchantment':format_filter)">
                <label position="stacked">
                  {{ '_target_enchantment' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetEnchantment" id="targetEnchantment1" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                    [value]="false" class="no-radio">
                  <label for="targetEnchantment1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetEnchantment" id="targetEnchantment2" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                    [value]="null" class="maybe-radio">
                  <label for="targetEnchantment2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetEnchantment" id="targetEnchantment3" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                    [value]="true" class="yes-radio">
                  <label for="targetEnchantment3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_land':format_filter)">
                <label position="stacked">
                  {{ '_target_land' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetLand" id="targetLand1" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                    [value]="false" class="no-radio">
                  <label for="targetLand1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetLand" id="targetLand2" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                    [value]="null" class="maybe-radio">
                  <label for="targetLand2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetLand" id="targetLand3" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                    [value]="true" class="yes-radio">
                  <label for="targetLand3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_permanent':format_filter)">
                <label position="stacked">
                  {{ '_target_permanent' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetPermanent" id="targetPermanent1" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                    [value]="false" class="no-radio">
                  <label for="targetPermanent1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetPermanent" id="targetPermanent2" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                    [value]="null" class="maybe-radio">
                  <label for="targetPermanent2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetPermanent" id="targetPermanent3" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                    [value]="true" class="yes-radio">
                  <label for="targetPermanent3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_planeswalker':format_filter)">
                <label position="stacked">
                  {{ '_target_planeswalker' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker1" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                    [value]="false" class="no-radio">
                  <label for="targetPlaneswalker1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker2" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                    [value]="null" class="maybe-radio">
                  <label for="targetPlaneswalker2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker3" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                    [value]="true" class="yes-radio">
                  <label for="targetPlaneswalker3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_player':format_filter)">
                <label position="stacked">
                  {{ '_target_player' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="targetPlayer" id="targetPlayer1" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                    [value]="false" class="no-radio">
                  <label for="targetPlayer1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="targetPlayer" id="targetPlayer2" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                    [value]="null" class="maybe-radio">
                  <label for="targetPlayer2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="targetPlayer" id="targetPlayer3" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                    [value]="true" class="yes-radio">
                  <label for="targetPlayer3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_1_removal':format_filter)">
                <label position="stacked">
                  {{ '_1_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="1Removal" id="1Removal1" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                    [value]="false" class="no-radio">
                  <label for="1Removal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="1Removal" id="1Removal2" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="1Removal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="1Removal" id="1Removal3" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                    [value]="true" class="yes-radio">
                  <label for="1Removal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_2_removal':format_filter)">
                <label position="stacked">
                  {{ '_2_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="2Removal" id="2Removal1" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                    [value]="false" class="no-radio">
                  <label for="2Removal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="2Removal" id="2Removal2" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="2Removal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="2Removal" id="2Removal3" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                    [value]="true" class="yes-radio">
                  <label for="2Removal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_3_removal':format_filter)">
                <label position="stacked">
                  {{ '_3_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="3Removal" id="3Removal1" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                    [value]="false" class="no-radio">
                  <label for="3Removal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="3Removal" id="3Removal2" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="3Removal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="3Removal" id="3Removal3" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                    [value]="true" class="yes-radio">
                  <label for="3Removal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_4_removal':format_filter)">
                <label position="stacked">
                  {{ '_4_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="4Removal" id="4Removal1" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                    [value]="false" class="no-radio">
                  <label for="4Removal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="4Removal" id="4Removal2" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="4Removal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="4Removal" id="4Removal3" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                    [value]="true" class="yes-radio">
                  <label for="4Removal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_5_removal':format_filter)">
                <label position="stacked">
                  {{ '_5_removal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="5Removal" id="5Removal1" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                    [value]="false" class="no-radio">
                  <label for="5Removal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="5Removal" id="5Removal2" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                    [value]="null" class="maybe-radio">
                  <label for="5Removal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="5Removal" id="5Removal3" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                    [value]="true" class="yes-radio">
                  <label for="5Removal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_until_eot':format_filter)">
                <label position="stacked">
                  {{ '_until_eot' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="untilEOT" id="untilEOT1" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                    [value]="false" class="no-radio">
                  <label for="untilEOT1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="untilEOT" id="untilEOT2" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                    [value]="null" class="maybe-radio">
                  <label for="untilEOT2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="untilEOT" id="untilEOT3" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                    [value]="true" class="yes-radio">
                  <label for="untilEOT3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_until_condition':format_filter)">
                <label position="stacked">
                  {{ '_until_condition' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="untilCondition" id="untilCondition1" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                    [value]="false" class="no-radio">
                  <label for="untilCondition1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="untilCondition" id="untilCondition2" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                    [value]="null" class="maybe-radio">
                  <label for="untilCondition2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="untilCondition" id="untilCondition3" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                    [value]="true" class="yes-radio">
                  <label for="untilCondition3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </ion-col>
      </div>
    </ion-row>
    <!-- LEGAL -->
    <ion-row>
      <p class="page-legal">
        {{ 'Page Footer' | translate }}
      </p>
    </ion-row>
  </ion-grid>
  <!-- DESKTOP GRID -->
  <ion-grid id="selectionGridDesktop" slot="fixed" *ngIf="platformWidth>1199">
    <!-- SEARCH -->
    <ion-row>
      <!-- LOGO -->
      <ion-col size="12" size-md="4" size-lg="3" size-xl="3" class="container_logo">
        <!-- LOGO -->
        <img src="../../assets/icon/GoldAtlasLogo.jpg" alt="logo" class="logo-large">
      </ion-col>
      <!-- SEARCH -->
      <ion-col size="12" size-md="8" size-lg="9" size-xl="9" class="container_search">
        <ion-row>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardNameSearch" class="search-bar" mode="md" [(ngModel)]="cardNameSearch" placeholder="{{ 'cardName' | translate }}" (ionClear)="cardNameSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardTextSearch" class="search-bar" mode="md" [(ngModel)]="cardTextSearch" placeholder="{{ 'cardText' | translate }}" (ionClear)="cardTextSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
          <ion-col size="12" size-md="4">
            <ion-searchbar id="cardTypeSearch" class="search-bar" mode="md" [(ngModel)]="cardTypeSearch" placeholder="{{ 'cardType' | translate }}" (ionClear)="cardTypeSearch = ''" (window:keydown)="onSearch($event)" debounce=500></ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <!-- SORT -->
    <ion-row>
      <ion-col class="container_dropdown">
        <ion-item class="dropdown_selections">
          <label>{{ 'Format' | translate }}</label>
          <ion-select [(ngModel)]="format_filter" (ionChange)="formatFilterChange()" disabled={{disableFormatSelection}} toggleIcon="chevron-expand">
            <ion-select-option *ngFor="let format of formatSelection">{{format}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <!--ion-col class="container_dropdown" *ngIf="platformWidth>1599">
        <ion-item class="dropdown_selections">
          <label>{{ 'Edition' | translate }}</label>
          <ion-select [(ngModel)]="edition_filter" (ionChange)="editionFilterChange()" multiple="true" cancelText="Cancel" okText="Select">
            <ion-select-option *ngFor="let edition of editionSelection">{{edition}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col-->
      <ion-col class="container_dropdown">
        <ion-item class="dropdown_selections_sortorder">
          <label (click)="toggleSortOrder(1)">{{ sortOrder_1 }} {{ 'Order By' | translate }}</label>
          <ion-select [(ngModel)]="sortBy_1" (ionChange)="sortCardCollection()" placeholder="{{ 'Name' | translate }}" toggleIcon="chevron-expand">
            <ion-select-option *ngFor="let sortOption1 of sortOptions" [value]="sortOption1">{{ sortOption1 | translate }}</ion-select-option>
          </ion-select>
          <!--ion-button id="sortOrder_1" fill="solid" expand="full" color="dark" (click)="toggleSortOrder(1)" aria-expanded="false">
            {{ sortOrder_1 }}
          </ion-button-->
          <!--ion-select [(ngModel)]="sortOrder_1" (ionChange)="sortCardCollection()"
          [style.min-width]="sortBy_1? '30px' : '30px'" [style.max-width]="sortBy_1? '30px' : '30px'" placeholder="{{ desc_arrow }}">
            <ion-select-option>
              {{asc_arrow}}
            </ion-select-option>
            <ion-select-option>
              {{desc_arrow}}
            </ion-select-option>
          </ion-select-->
        </ion-item>
      </ion-col>
      <ion-col class="container_dropdown">
        <ion-item class="dropdown_selections_sortorder">
          <label (click)="toggleSortOrder(2)">{{ sortOrder_2 }} {{ 'Order By' | translate }}</label>
          <ion-select [(ngModel)]="sortBy_2" (ionChange)="sortCardCollection()" [disabled]="sortBy_1? 'false' : 'true'" toggleIcon="chevron-expand">
            <ion-select-option *ngFor="let sortOption2 of sortOptions" [value]="sortOption2">{{ sortOption2 | translate }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col class="container_dropdown">
        <ion-item class="dropdown_selections_sortorder">
          <label (click)="toggleSortOrder(3)">{{ sortOrder_3 }} {{ 'Order By' | translate }}</label>
          <ion-select [(ngModel)]="sortBy_3" (ionChange)="sortCardCollection()" [disabled]="sortBy_2? 'false' : 'true'" toggleIcon="chevron-expand">
            <ion-select-option *ngFor="let sortOption3 of sortOptions" [value]="sortOption3">{{ sortOption3 | translate }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- MAIN SELECTIONS -->
    <ion-row>
      <ion-col size="12" class="container_selection">
        <ion-row>
          <!--MainType -->
          <ion-col size="auto">
            <ion-row>
              <ion-col size="auto">
                <!-- Checkbox for adding/removing MainType selections to the c_filter.mainType attribute 
                      Creature / Land / Artifact / ArtifactCreature (added) / Creature / Instant / Sorcery / Enchantment / Planeswalker // following excluded: Phenomenon / Plane / Scheme / Tribal / Vanguard
                      -->
                <input type="checkbox" class="mainType" id="Land" [(ngModel)]="Land" (change)="toggleMainType('Land')" />
                <label for="Land" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-land ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="mainType" id="Artifact" [(ngModel)]="Artifact" (change)="toggleMainType('Artifact')" />
                <label for="Artifact" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-artifact ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="mainType" id="ArtifactCreature" [(ngModel)]="ArtifactCreature" (change)="toggleMainType('Artifact Creature')"
                />
                <label for="ArtifactCreature" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-artifactcreature ms-4x ms-cost ms-split"></i>
                </label>
                <input type="checkbox" class="mainType" id="Creature" [(ngModel)]="Creature" (change)="toggleMainType('Creature')" />
                <label for="Creature" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-creature ms-4x ms-cost"></i>
                </label>
              </ion-col>
              <ion-col size="auto">
                <input type="checkbox" class="mainType" id="Instant" [(ngModel)]="Instant" (change)="toggleMainType('Instant')" />
                <label for="Instant" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-instant ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="mainType" id="Sorcery" [(ngModel)]="Sorcery" (change)="toggleMainType('Sorcery')" />
                <label for="Sorcery" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-sorcery ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="mainType" id="Enchantment" [(ngModel)]="Enchantment" (change)="toggleMainType('Enchantment')"/>
                <label for="Enchantment" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-enchantment ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="mainType" id="Planeswalker" [(ngModel)]="Planeswalker" (change)="toggleMainType('Planeswalker')"/>
                <label for="Planeswalker" [class.hoverSelection]="isMobile === false">
                  <i class="ms ms-planeswalker ms-4x ms-cost"></i>
                </label>
                <!-- A small space, before the next column -->
                &nbsp;
                &nbsp;
              </ion-col>
            </ion-row>
          </ion-col>
          <!--Color -->
          <ion-col size="auto">
            <ion-row>
              <!-- Checkbox for adding/removing Permanent selections to the c_filter.permanent attribute 
                    White / Blue / Red / Black / Green / Colorless / Multicolor
                    -->
              <ion-col size="auto">
                <input type="checkbox" class="permanent" id="White" [(ngModel)]="White" (change)="togglePermanent('White')" />
                <label for="White" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false"> 
                  <i class="ms ms-w ms-4x ms-cost"></i>
                  <div class="bottom-subColor" *ngIf="Multicolor">
                    <div class="wrapper subWhite">
                      <input type="radio" name="subWhite" id="subWhite1" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="false"
                        class="no-radio">
                      <label for="subWhite1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="subWhite" id="subWhite2" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="null"
                        class="maybe-radio">
                      <label for="subWhite2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="subWhite" id="subWhite3" [(ngModel)]="c_filter._subColorWhite" (change)="resetImageScroll()" [value]="true"
                        class="yes-radio">
                      <label for="subWhite3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </div>
                </label>
                <input type="checkbox" class="permanent" id="Blue" [(ngModel)]="Blue" (change)="togglePermanent('Blue')" />
                <label for="Blue" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
                  <i class="ms ms-u ms-4x ms-cost"></i>
                  <div class="bottom-subColor" *ngIf="Multicolor">
                    <div class="wrapper subBlue">
                      <input type="radio" name="subBlue" id="subBlue1" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="false"
                        class="no-radio">
                      <label for="subBlue1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="subBlue" id="subBlue2" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="null"
                        class="maybe-radio">
                      <label for="subBlue2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="subBlue" id="subBlue3" [(ngModel)]="c_filter._subColorBlue" (change)="resetImageScroll()" [value]="true"
                        class="yes-radio">
                      <label for="subBlue3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </div>
                </label>
                <input type="checkbox" class="permanent" id="Black" [(ngModel)]="Black" (change)="togglePermanent('Black')" />
                <label for="Black" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
                  <i class="ms ms-b ms-4x ms-cost"></i>
                  <div class="bottom-subColor" *ngIf="Multicolor">
                    <div class="wrapper subBlack">
                      <input type="radio" name="subBlack" id="subBlack1" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="false"
                        class="no-radio">
                      <label for="subBlack1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="subBlack" id="subBlack2" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="null"
                        class="maybe-radio">
                      <label for="subBlack2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="subBlack" id="subBlack3" [(ngModel)]="c_filter._subColorBlack" (change)="resetImageScroll()" [value]="true"
                        class="yes-radio">
                      <label for="subBlack3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </div>
                </label>
                <input type="checkbox" class="permanent" id="Red" [(ngModel)]="Red" (change)="togglePermanent('Red')" />
                <label for="Red" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
                  <i class="ms ms-r ms-4x ms-cost"></i>
                  <div class="bottom-subColor" *ngIf="Multicolor">
                    <div class="wrapper subRed">
                      <input type="radio" name="subRed" id="subRed1" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="false"
                        class="no-radio">
                      <label for="subRed1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="subRed" id="subRed2" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="null"
                        class="maybe-radio">
                      <label for="subRed2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="subRed" id="subRed3" [(ngModel)]="c_filter._subColorRed" (change)="resetImageScroll()" [value]="true"
                        class="yes-radio">
                      <label for="subRed3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </div>
                </label>
              </ion-col>
              <ion-col size="auto">
                <input type="checkbox" class="permanent" id="Green" [(ngModel)]="Green" (change)="togglePermanent('Green')" />
                <label for="Green" class="label-subColor" [class.hoverSelection]="Multicolor === false && isMobile === false">
                  <i class="ms ms-g ms-4x ms-cost"></i>
                  <div class="bottom-subColor" *ngIf="Multicolor">
                    <div class="wrapper subGreen">
                      <input type="radio" name="subGreen" id="subGreen1" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="false"
                        class="no-radio">
                      <label for="subGreen1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="subGreen" id="subGreen2" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="null"
                        class="maybe-radio">
                      <label for="subGreen2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="subGreen" id="subGreen3" [(ngModel)]="c_filter._subColorGreen" (change)="resetImageScroll()" [value]="true"
                        class="yes-radio">
                      <label for="subGreen3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </div>
                </label>
                <input type="checkbox" class="permanent" id="Colorless" [(ngModel)]="Colorless" (change)="togglePermanent('Colorless')" />
                <label for="Colorless" [class.hoverSelection]="Multicolor === false && isMobile === false">
                  <i class="ms ms-c ms-4x ms-cost"></i>
                </label>
                <input type="checkbox" class="permanent" id="Multicolor" [(ngModel)]="Multicolor" (change)="togglePermanent('Multicolor')" />
                <label for="Multicolor" [class.hoverSelection]="isMobile === false">
                  <div class="circle-container">
                    <i class="ms ms-w deg0"></i>
                    <i class="ms ms-u deg72"></i>
                    <i class="ms ms-b deg144"></i>
                    <i class="ms ms-r deg216"></i>
                    <i class="ms ms-g deg288"></i>
                  </div>
                </label>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row>
          <!--CMC -->
          <ion-col size="auto">
            <!-- Selection boxes for card CMC 0 - 8 -->
            <input type="checkbox" class="CMC" id="CMC0" [(ngModel)]="CMC0" (change)="toggleCMC('0')" />
            <label for="CMC0" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-0 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC1" [(ngModel)]="CMC1" (change)="toggleCMC('1')" />
            <label for="CMC1" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-1 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC2" [(ngModel)]="CMC2" (change)="toggleCMC('2')" />
            <label for="CMC2" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-2 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC3" [(ngModel)]="CMC3" (change)="toggleCMC('3')" />
            <label for="CMC3" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-3 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC4" [(ngModel)]="CMC4" (change)="toggleCMC('4')" />
            <label for="CMC4" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-4 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC5" [(ngModel)]="CMC5" (change)="toggleCMC('5')" />
            <label for="CMC5" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-5 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC6" [(ngModel)]="CMC6" (change)="toggleCMC('6')" />
            <label for="CMC6" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-6 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC7" [(ngModel)]="CMC7" (change)="toggleCMC('7')" />
            <label for="CMC7" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-7 ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="CMC8" [(ngModel)]="CMC8" (change)="toggleCMC('8')" />
            <label for="CMC8" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-8-plus ms-2x ms-cost ms-split"></i>
            </label>
            <!-- Additioncal criteria for casting cost boxes -->
            &nbsp;
            <input type="checkbox" class="CMC" id="XCost" [(ngModel)]="c_filter._hasXCost" (change)="toggleManaSelection('X')" />
            <label for="XCost" [class.hoverSelection]="isMobile === false" *ngIf="(formattedCardCollection | showAbilityFilter:'_hasXCost':format_filter)">
              <i class="ms ms-x ms-2x ms-cost"></i>
            </label>
            <input type="checkbox" class="CMC" id="HCost" [(ngModel)]="c_filter._hasHCost" (change)="toggleManaSelection('H')" />
            <label for="HCost" [class.hoverSelection]="isMobile === false" *ngIf="(formattedCardCollection | showAbilityFilter:'_hasHCost':format_filter)">
              <i class="ms ms-bg ms-2x ms-cost ms-split"></i>
            </label>
            <!-- A small space, before the next column -->
            &nbsp;
            &nbsp;
          </ion-col>
          <!--Rarity -->
          <ion-col size="auto">
            <!-- Checkbox for adding/removing Rarity selections to the c_filter.rarity attribute 
                  Common / Uncommon / Rare / Mythic Rare
                  -->
            <input type="checkbox" class="rarity" id="Common" [(ngModel)]="Common" (change)="toggleRarity('Common')" />
            <label for="Common" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-2x ms-cost ss-common"></i>
            </label>
            <input type="checkbox" class="rarity" id="Uncommon" [(ngModel)]="Uncommon" (change)="toggleRarity('Uncommon')" />
            <label for="Uncommon" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-2x ms-cost ss-uncommon"></i>
            </label>
            <input type="checkbox" class="rarity" id="Rare" [(ngModel)]="Rare" (change)="toggleRarity('Rare')" />
            <label for="Rare" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-2x ms-cost ss-rare"></i>
            </label>
            <input type="checkbox" class="rarity" id="Mythic" [(ngModel)]="Mythic" (change)="toggleRarity('Mythic')" />
            <label for="Mythic" [class.hoverSelection]="isMobile === false">
              <i class="ms ms-rarity ms-2x ms-cost ss-mythic"></i>
            </label>
            <!-- A small space, before the next column -->
            &nbsp;
            &nbsp;
          </ion-col>
          <!-- Advanced Selections -->
          <!-- Card Ability / Player Action / Card Identity -->
          <!-- Card Trigger (what causes it to go off?) / Card Target (what is the target?) / Card Effect (what happens to the target?) -->
          <!-- Move Counters to Identity, Move ETB to Trigger, Create Self as Target, Create Effect Condition -->
          <ion-col size="auto">
            <a id="advancedSelectionsOpenBtn" class='glowBtn' (click)="toggleAdvancedSelections()">
              {{ 'Show Advanced Selections' | translate }}
            </a>
            <ion-row id="advancedSelectionsBtns1" style="display:none;">
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('identity')">
                {{ 'Identity' | translate }} 
              </button>
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('action')">
                {{ 'Player Action' | translate }}
              </button>
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('ability')">
                {{ 'Ability' | translate }}
              </button>
            </ion-row>
            <ion-row id="advancedSelectionsBtns2" style="display:none;">
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('trigger')">
                {{ 'Trigger' | translate }} >>
              </button>
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('effect')">
                {{ 'Effect' | translate }}
              </button>
              <button class="advancedSelectionsBtn" (click)="toggleAdvancedSelectionsModal('target')">
                << {{ 'Target' | translate }}
              </button>        
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid id="imageGridDesktop" class="image-grid" *ngIf="platformWidth>1199">
    <ion-row>
      <!-- Selection boxes for creature power 0 - 8 -->
      <ion-col size="auto" id='selectionColumnPower' class="container_selection" *ngIf="Creature || ArtifactCreature">
        <ion-row>
          <input type="checkbox" class="power" id="Power" [(ngModel)]="Power" [disabled]="true" />
          <label for="Power" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-power pt-icon ms-cost"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power0" [(ngModel)]="Power0" (change)="togglePower('0')" />
          <label for="Power0" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-0 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power1" [(ngModel)]="Power1" (change)="togglePower('1')" />
          <label for="Power1" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-1 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power2" [(ngModel)]="Power2" (change)="togglePower('2')" />
          <label for="Power2" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-2 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power3" [(ngModel)]="Power3" (change)="togglePower('3')" />
          <label for="Power3" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-3 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power4" [(ngModel)]="Power4" (change)="togglePower('4')" />
          <label for="Power4" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-4 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power5" [(ngModel)]="Power5" (change)="togglePower('5')" />
          <label for="Power5" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-5 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power6" [(ngModel)]="Power6" (change)="togglePower('6')" />
          <label for="Power6" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-6 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power7" [(ngModel)]="Power7" (change)="togglePower('7')" />
          <label for="Power7" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-7 ms-cost power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="Power8" [(ngModel)]="Power8" (change)="togglePower('8')" />
          <label for="Power8" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-8-plus ms-cost ms-split power"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="power" id="PowerX" [(ngModel)]="PowerX" (change)="togglePower('*')" />
          <label for="PowerX" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-x ms-cost power"></i>
          </label>
        </ion-row>
      </ion-col>
      <!-- Selection boxes for creature toughness 0 - 8 -->
      <ion-col size="auto" id='selectionColumnToughness' class="container_selection" *ngIf="Creature || ArtifactCreature">
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness" [(ngModel)]="Toughness" [disabled]="true" />
          <label for="Toughness" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-toughness pt-icon ms-cost"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness0" [(ngModel)]="Toughness0" (change)="toggleToughness('0')" />
          <label for="Toughness0" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-0 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness1" [(ngModel)]="Toughness1" (change)="toggleToughness('1')" />
          <label for="Toughness1" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-1 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness2" [(ngModel)]="Toughness2" (change)="toggleToughness('2')" />
          <label for="Toughness2" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-2 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness3" [(ngModel)]="Toughness3" (change)="toggleToughness('3')" />
          <label for="Toughness3" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-3 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness4" [(ngModel)]="Toughness4" (change)="toggleToughness('4')" />
          <label for="Toughness4" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-4 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness5" [(ngModel)]="Toughness5" (change)="toggleToughness('5')" />
          <label for="Toughness5" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-5 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness6" [(ngModel)]="Toughness6" (change)="toggleToughness('6')" />
          <label for="Toughness6" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-6 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness7" [(ngModel)]="Toughness7" (change)="toggleToughness('7')" />
          <label for="Toughness7" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-7 ms-cost toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="Toughness8" [(ngModel)]="Toughness8" (change)="toggleToughness('8')" />
          <label for="Toughness8" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-8-plus ms-cost ms-split toughness"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="toughness" id="ToughnessX" [(ngModel)]="ToughnessX" (change)="toggleToughness('*')" />
          <label for="ToughnessX" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-x ms-cost toughness"></i>
          </label>
        </ion-row>
      </ion-col>
      <!-- Selection boxes for planeswalker loytalty 0 - 8 -->
      <ion-col size="auto" id='selectionColumnLoyalty' class="container_selection" *ngIf="Planeswalker">
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty" [(ngModel)]="Loyalty" [disabled]="true" />
          <label for="Loyalty" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty loyalty-icon ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty1" [(ngModel)]="Loyalty1" (change)="toggleLoyalty('1')" />
          <label for="Loyalty1" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-1 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty2" [(ngModel)]="Loyalty2" (change)="toggleLoyalty('2')" />
          <label for="Loyalty2" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-2 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty3" [(ngModel)]="Loyalty3" (change)="toggleLoyalty('3')" />
          <label for="Loyalty3" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-3 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty4" [(ngModel)]="Loyalty4" (change)="toggleLoyalty('4')" />
          <label for="Loyalty4" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-4 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty5" [(ngModel)]="Loyalty5" (change)="toggleLoyalty('5')" />
          <label for="Loyalty5" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-5 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty6" [(ngModel)]="Loyalty6" (change)="toggleLoyalty('6')" />
          <label for="Loyalty6" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-6 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="Loyalty7" [(ngModel)]="Loyalty7" (change)="toggleLoyalty('7')" />
          <label for="Loyalty7" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-7 ms-2x"></i>
          </label>
        </ion-row>
        <ion-row>
          <input type="checkbox" class="loyalty" id="LoyaltyX" [(ngModel)]="LoyaltyX" (change)="toggleLoyalty('X')" />
          <label for="LoyaltyX" [class.hoverSelection]="isMobile === false">
            <i class="ms ms-loyalty-start ms-loyalty-x ms-2x"></i>
          </label>
        </ion-row>
      </ion-col>
      <ion-col offset="1" align-self-center>
        <ion-row id="imageScrollY" class="image-scroll-y" (scroll)="onScroll($event)" *ngIf="formattedCardCollection">
          <ion-card col-6 col-md-4 col-xl-3 class="card-frame" *ngFor="let card of formattedCardCollection  
              | editionFilter:edition_filter:triggerPipeCounter 
              | permanentFilter:c_filter:triggerPipeCounter 
              | mainTypeFilter:c_filter:triggerPipeCounter
              | powerFilter:c_filter:triggerPipeCounter
              | toughnessFilter:c_filter:triggerPipeCounter
              | loyaltyFilter:c_filter:triggerPipeCounter 
              | rarityFilter:c_filter:triggerPipeCounter 
              | cmcFilter:c_filter:triggerPipeCounter 
              | abilityFilter:c_filter:triggerPipeCounter 
              | cardSearchPipe:name_lang:cardNameSearch
              | cardSearchPipe:cardType_lang:cardTypeSearch
              | cardSearchPipe:cleantext_lang:cardTextSearch
              | slice:0:3*limit+page*limit">
            <ion-card-content class="card-content"> 
              <div class="card-text" (click)="openModal(card)"> 
                <p *ngIf="scrollView === 'cardImage'">
                  &nbsp;  
                </p>
                <p class="card-header-row">
                  {{card[name_lang] !== '' ? card[name_lang] : card.name}} <span innerHTML="{{card.manaCost | toManaIcon}}"></span>
                </p>
                <p class="card-type-row">
                  {{card[cardType_lang] !== '' ? card[cardType_lang] : card.cardType}} <span innerHTML="<i class='ss ss-{{card.edition | lowercase}} ss-{{card.rarity | lowercase}} ss-grad ss-fw'></i>"></span>
                </p>
                <p class="card-text-row">
                  {{card[cleantext_lang] !== '' ? card[cleantext_lang] : card.cleantext}}
                </p>
                <br>
                <p class="card-pt">
                  {{card.pt}}{{card.loyalty}}
                </p>
              </div>
  
              <div class="card-image" (click)="openModal(card)" *ngIf="scrollView === 'cardImage'">
                <ion-img  src={{card[imagePath_lang]}}
                title="{{card[name_lang] !== '' ? card[name_lang] : card.name}} - {{card.manaCost}}
      {{card[cardType_lang] !== '' ? card[cardType_lang] : card.cardType}} ({{card.edition}} - {{card.rarity | translate}})
      {{card[cleantext_lang] !== '' ? card[cleantext_lang] : card.cleantext}}
      {{card.pt}}{{card.loyalty}}">
                </ion-img>
              </div>
          
              <div class="corner-ribbon" [ngClass]="{'banned':(card._legacy_banned && format_filter=='Legacy') || (card._vintage_banned && format_filter=='Vintage') || (card._commander_banned && format_filter=='Commander') || (card._modern_banned && format_filter=='Modern') || (card._standard_banned && format_filter=='Standard')}">  
                {{ 'Banned' | translate }}
              </div>
              <div class="corner-ribbon" [ngClass]="{'restricted':(card._legacy_restricted && format_filter=='Legacy') || (card._vintage_restricted && format_filter=='Vintage') || (card._commander_restricted && format_filter=='Commander') || (card._modern_restricted && format_filter=='Modern') || (card._standard_restricted && format_filter=='Standard')}">  
                {{ 'Restricted' | translate }}
              </div>
              <div class="bottom-star" (click)="toggleWishList(card)" *ngIf="!((card._layout_DoubleFaced || card._layout_Flip || card._layout_Split) && card.name === card.names[1])"> 
                <i class="ss ss-s99 ss-common ss-grad" *ngIf="!card._isOnWishList"></i>
                <i class="ss ss-s99 ss-rare ss-grad" *ngIf="card._isOnWishList"></i>
              </div>        
            </ion-card-content>
          </ion-card>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row>
      <p class="page-legal">
        {{ 'Page Footer' | translate }}
      </p>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- SHOW FOOTER ONLY IF ON iPAD OR DESKTOP OR WHEN ADVANCED SELECTIONS ARE VISIBLE ON MOBILE -->
<ion-footer class="page-footer" *ngIf="platformWidth>767 || advancedSelectionsShown">
  <ion-grid>
    <ion-row id="abilityChips">
      <!-- Chips for searches shown first -->
      <ion-chip (click)="cardNameSearch = ''" *ngIf="cardNameSearch !== ''">
        <ion-icon name="search" class="search-icon ms-cost"></ion-icon>
        <label [class.hoverSelection]="isMobile === false">
          {{ cardNameSearch }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="cardTextSearch = ''" *ngIf="cardTextSearch !== ''">
        <ion-icon name="search" class="icon search-icon ms-cost"></ion-icon>
        <label [class.hoverSelection]="isMobile === false">
          {{ cardTextSearch }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="cardTypeSearch = ''" *ngIf="cardTypeSearch !== ''">
        <ion-icon name="search" class="icon search-icon ms-cost"></ion-icon>
        <label [class.hoverSelection]="isMobile === false">
          {{ cardTypeSearch }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <!-- Here abilityChips gets autopopulated when selections are made in yes/no boxes -->
      <ion-chip (click)="toggleMainType('Land',true)" *ngIf="c_filter.mainType.indexOf('Land') !== -1">
        <i class="ms ms-land ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Land' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Artifact',true)" *ngIf="c_filter.mainType.indexOf('Artifact') !== -1">
        <i class="ms ms-artifact ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Artifact' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Artifact Creature',true)" *ngIf="c_filter.mainType.indexOf('Artifact Creature') !== -1">
        <i class="ms ms-artifactcreature ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Artifact Creature' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Creature',true)" *ngIf="c_filter.mainType.indexOf('Creature') !== -1">
        <i class="ms ms-creature ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Creature' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Instant',true)" *ngIf="c_filter.mainType.indexOf('Instant') !== -1">
        <i class="ms ms-instant ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Instant' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Sorcery',true)" *ngIf="c_filter.mainType.indexOf('Sorcery') !== -1">
        <i class="ms ms-sorcery ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Sorcery' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Enchantment',true)" *ngIf="c_filter.mainType.indexOf('Enchantment') !== -1">
        <i class="ms ms-enchantment ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Enchantment' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleMainType('Planeswalker',true)" *ngIf="c_filter.mainType.indexOf('Planeswalker') !== -1">
        <i class="ms ms-planeswalker ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Planeswalker' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('1',true)" *ngIf="c_filter.power.indexOf(';1;') !== -1">
        <i class="ms ms-1 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('2',true)" *ngIf="c_filter.power.indexOf(';2;') !== -1">
        <i class="ms ms-2 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('3',true)" *ngIf="c_filter.power.indexOf(';3;') !== -1">
        <i class="ms ms-3 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('4',true)" *ngIf="c_filter.power.indexOf(';4;') !== -1">
        <i class="ms ms-4 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('5',true)" *ngIf="c_filter.power.indexOf(';5;') !== -1">
        <i class="ms ms-5 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('6',true)" *ngIf="c_filter.power.indexOf(';6;') !== -1">
        <i class="ms ms-6 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('7',true)" *ngIf="c_filter.power.indexOf(';7;') !== -1">
        <i class="ms ms-7 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('8',true)" *ngIf="c_filter.power.indexOf(';8;') !== -1">
        <i class="ms ms-8-plus ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('0',true)" *ngIf="c_filter.power.indexOf(';0;') !== -1">
        <i class="ms ms-0 ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePower('*',true)" *ngIf="c_filter.power.indexOf(';*;') !== -1">
        <i class="ms ms-x ms-power ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Power' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('1',true)" *ngIf="c_filter.toughness.indexOf(';1;') !== -1">
        <i class="ms ms-1 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('2',true)" *ngIf="c_filter.toughness.indexOf(';2;') !== -1">
        <i class="ms ms-2 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('3',true)" *ngIf="c_filter.toughness.indexOf(';3;') !== -1">
        <i class="ms ms-3 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('4',true)" *ngIf="c_filter.toughness.indexOf(';4;') !== -1">
        <i class="ms ms-4 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('5',true)" *ngIf="c_filter.toughness.indexOf(';5;') !== -1">
        <i class="ms ms-5 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('6',true)" *ngIf="c_filter.toughness.indexOf(';6;') !== -1">
        <i class="ms ms-6 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('7',true)" *ngIf="c_filter.toughness.indexOf(';7;') !== -1">
        <i class="ms ms-7 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('8',true)" *ngIf="c_filter.toughness.indexOf(';8;') !== -1">
        <i class="ms ms-8-plus ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('0',true)" *ngIf="c_filter.toughness.indexOf(';0;') !== -1">
        <i class="ms ms-0 ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleToughness('*',true)" *ngIf="c_filter.toughness.indexOf(';*;') !== -1">
        <i class="ms ms-x ms-toughness ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Toughness' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('1',true)" *ngIf="c_filter.loyalty.indexOf(';1;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-1 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('2',true)" *ngIf="c_filter.loyalty.indexOf(';2;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-2 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('3',true)" *ngIf="c_filter.loyalty.indexOf(';3;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-3 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('4',true)" *ngIf="c_filter.loyalty.indexOf(';4;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-4 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('5',true)" *ngIf="c_filter.loyalty.indexOf(';5;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-5 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('6',true)" *ngIf="c_filter.loyalty.indexOf(';6;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-6 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('7',true)" *ngIf="c_filter.loyalty.indexOf(';7;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-7 ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleLoyalty('X',true)" *ngIf="c_filter.loyalty.indexOf(';X;') !== -1">
        <i class="ms ms-loyalty-start ms-loyalty-x ms-2x"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Loyalty' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleRarity('Common',true)" *ngIf="c_filter.rarity.indexOf('Common') !== -1">
        <i class="ms ms-rarity ms-2x ss-common ss-grad"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Common' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleRarity('Uncommon',true)" *ngIf="c_filter.rarity.indexOf('Uncommon') !== -1">
        <i class="ms ms-rarity ms-2x ss-uncommon ss-grad"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Uncommon' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleRarity('Rare',true)" *ngIf="c_filter.rarity.indexOf('Rare') !== -1">
        <i class="ms ms-rarity ms-2x ss-rare ss-grad"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Rare' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleRarity('Mythic',true)" *ngIf="c_filter.rarity.indexOf('Mythic') !== -1">
        <i class="ms ms-rarity ms-2x ss-mythic ss-grad"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Mythic' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('White',true)" *ngIf="c_filter.permanent.indexOf('White') !== -1">
        <i class="ms ms-w ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'White' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Blue',true)" *ngIf="c_filter.permanent.indexOf('Blue') !== -1">
        <i class="ms ms-u ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Blue' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Red',true)" *ngIf="c_filter.permanent.indexOf('Red') !== -1">
        <i class="ms ms-r ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Red' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Black',true)" *ngIf="c_filter.permanent.indexOf('Black') !== -1">
        <i class="ms ms-b ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Black' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Green',true)" *ngIf="c_filter.permanent.indexOf('Green') !== -1">
        <i class="ms ms-g ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Green' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Colorless',true)" *ngIf="c_filter.permanent.indexOf('Colorless') !== -1">
        <i class="ms ms-c ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Colorless' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="togglePermanent('Multicolor',true)" *ngIf="c_filter.permanent.indexOf('Multicolor') !== -1">
        <i class="ms ms-w ms-cost"></i>
        <i class="ms ms-u ms-cost"></i>
        <i class="ms ms-b ms-cost"></i>
        <i class="ms ms-r ms-cost"></i>
        <i class="ms ms-g ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Multicolor' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('0',true)" *ngIf="c_filter.cmc.indexOf(';0;') !== -1">
        <i class="ms ms-0 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('1',true)" *ngIf="c_filter.cmc.indexOf(';1;') !== -1">
        <i class="ms ms-1 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('2',true)" *ngIf="c_filter.cmc.indexOf(';2;') !== -1">
        <i class="ms ms-2 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('3',true)" *ngIf="c_filter.cmc.indexOf(';3;') !== -1">
        <i class="ms ms-3 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('4',true)" *ngIf="c_filter.cmc.indexOf(';4;') !== -1">
        <i class="ms ms-4 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('5',true)" *ngIf="c_filter.cmc.indexOf(';5;') !== -1">
        <i class="ms ms-5 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('6',true)" *ngIf="c_filter.cmc.indexOf(';6;') !== -1">
        <i class="ms ms-6 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('7',true)" *ngIf="c_filter.cmc.indexOf(';7;') !== -1">
        <i class="ms ms-7 ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="toggleCMC('8',true)" *ngIf="c_filter.cmc.indexOf(';8;') !== -1">
        <i class="ms ms-8-plus ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'CMC' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="resetAbilityChip('_hasXCost')" *ngIf="c_filter._hasXCost !== null">
        <i class="ms ms-x ms-2x ms-cost"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Cost' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
      <ion-chip (click)="resetAbilityChip('_hasHCost')" *ngIf="c_filter._hasHCost !== null">
        <i class="ms ms-bg ms-2x ms-cost ms-split"></i>
        <label [class.hoverSelection]="isMobile === false">
          {{ 'Hybrid' | translate }}
          <ion-icon name="trash"></ion-icon>
        </label>
      </ion-chip>
    </ion-row>
  </ion-grid>
</ion-footer>

<!-- Modal for advanced selections on desktop -->
<div id="advSelectionsModal" class="advSelectionsModal" (click)="toggleAdvancedSelectionsModal()">
  <div class="advSelectionsModal-content">
    <span class="advSelectionsModal-close-button">×</span>
    <ion-grid>
      <ion-row class="container_yesno_header">
        <ion-col size="auto">
          <div [ngSwitch]="cardAbility">
            <div *ngSwitchCase="'identity'">
              <h1 class="advancedSelectionContainer_header">{{ 'Card Identity' | translate }}</h1>
              <h4 class="container_yesno_subheader">{{ 'Card Layout' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_layout_Normal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="layoutNormal" id="layoutNormal1" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                      [value]="false" class="no-radio">
                    <label for="layoutNormal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="layoutNormal" id="layoutNormal2" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                      [value]="null" class="maybe-radio">
                    <label for="layoutNormal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="layoutNormal" id="layoutNormal3" [(ngModel)]="c_filter._layout_Normal" (change)="addAbilityChip('_layout_Normal')"
                      [value]="true" class="yes-radio">
                    <label for="layoutNormal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_Split':format_filter)">
                  <label position="stacked">
                    {{ '_layout_Split' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="layoutSplit" id="layoutSplit1" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                      [value]="false" class="no-radio">
                    <label for="layoutSplit1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="layoutSplit" id="layoutSplit2" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                      [value]="null" class="maybe-radio">
                    <label for="layoutSplit2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="layoutSplit" id="layoutSplit3" [(ngModel)]="c_filter._layout_Split" (change)="addAbilityChip('_layout_Split')"
                      [value]="true" class="yes-radio">
                    <label for="layoutSplit3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_Flip':format_filter)">
                  <label position="stacked">
                    {{ '_layout_Flip' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="layoutFlip" id="layoutFlip1" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                      [value]="false" class="no-radio">
                    <label for="layoutFlip1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="layoutFlip" id="layoutFlip2" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                      [value]="null" class="maybe-radio">
                    <label for="layoutFlip2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="layoutFlip" id="layoutFlip3" [(ngModel)]="c_filter._layout_Flip" (change)="addAbilityChip('_layout_Flip')"
                      [value]="true" class="yes-radio">
                    <label for="layoutFlip3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_layout_DoubleFaced':format_filter)">
                  <label position="stacked">
                    {{ '_layout_DoubleFaced' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced1" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                      [value]="false" class="no-radio">
                    <label for="layoutDoubleFaced1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced2" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                      [value]="null" class="maybe-radio">
                    <label for="layoutDoubleFaced2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="layoutDoubleFaced" id="layoutDoubleFaced3" [(ngModel)]="c_filter._layout_DoubleFaced" (change)="addAbilityChip('_layout_DoubleFaced')"
                      [value]="true" class="yes-radio">
                    <label for="layoutDoubleFaced3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Color Identity' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_white':format_filter)">
                  <label position="stacked">
                    {{ '_ci_white' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="CIwhite" id="CIwhite1" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                      [value]="false" class="no-radio">
                    <label for="CIwhite1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="CIwhite" id="CIwhite2" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                      [value]="null" class="maybe-radio">
                    <label for="CIwhite2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="CIwhite" id="CIwhite3" [(ngModel)]="c_filter._ci_white" (change)="addAbilityChip('_ci_white')"
                      [value]="true" class="yes-radio">
                    <label for="CIwhite3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_blue':format_filter)">
                  <label position="stacked">
                    {{ '_ci_blue' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="CIblue" id="CIblue1" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="false"
                      class="no-radio">
                    <label for="CIblue1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="CIblue" id="CIblue2" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="null"
                      class="maybe-radio">
                    <label for="CIblue2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="CIblue" id="CIblue3" [(ngModel)]="c_filter._ci_blue" (change)="addAbilityChip('_ci_blue')" [value]="true"
                      class="yes-radio">
                    <label for="CIblue3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_black':format_filter)">
                  <label position="stacked">
                    {{ '_ci_black' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="CIblack" id="CIblack1" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                      [value]="false" class="no-radio">
                    <label for="CIblack1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="CIblack" id="CIblack2" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                      [value]="null" class="maybe-radio">
                    <label for="CIblack2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="CIblack" id="CIblack3" [(ngModel)]="c_filter._ci_black" (change)="addAbilityChip('_ci_black')"
                      [value]="true" class="yes-radio">
                    <label for="CIblack3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_red':format_filter)">
                  <label position="stacked">
                    {{ '_ci_red' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="CIred" id="CIred1" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="false"
                      class="no-radio">
                    <label for="CIred1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="CIred" id="CIred2" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="null"
                      class="maybe-radio">
                    <label for="CIred2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="CIred" id="CIred3" [(ngModel)]="c_filter._ci_red" (change)="addAbilityChip('_ci_red')" [value]="true"
                      class="yes-radio">
                    <label for="CIred3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_ci_green':format_filter)">
                  <label position="stacked">
                    {{ '_ci_green' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="CIgreen" id="CIgreen1" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                      [value]="false" class="no-radio">
                    <label for="CIgreen1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="CIgreen" id="CIgreen2" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                      [value]="null" class="maybe-radio">
                    <label for="CIgreen2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="CIgreen" id="CIgreen3" [(ngModel)]="c_filter._ci_green" (change)="addAbilityChip('_ci_green')"
                      [value]="true" class="yes-radio">
                    <label for="CIgreen3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Card Category' | translate }}</h4>
              <ion-list class="list_columns">
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_commander':format_filter)">
                <label position="stacked">
                  {{ '_commander' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="commander" id="commander1" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="false" class="no-radio">
                  <label for="commander1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="commander" id="commander2" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="null" class="maybe-radio">
                  <label for="commander2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="commander" id="commander3" [(ngModel)]="c_filter._commander" (change)="addAbilityChip('_commander')"
                    [value]="true" class="yes-radio">
                  <label for="commander3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_legendary':format_filter)">
                <label position="stacked">
                  {{ '_legendary' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="legendary" id="legendary1" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="false" class="no-radio">
                  <label for="legendary1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="legendary" id="legendary2" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="null" class="maybe-radio">
                  <label for="legendary2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="legendary" id="legendary3" [(ngModel)]="c_filter._legendary" (change)="addAbilityChip('_legendary')"
                    [value]="true" class="yes-radio">
                  <label for="legendary3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_historic':format_filter)">
                <label position="stacked">
                  {{ '_historic' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="historic" id="historic1" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="false" class="no-radio">
                  <label for="historic1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="historic" id="historic2" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="null" class="maybe-radio">
                  <label for="historic2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="historic" id="historic3" [(ngModel)]="c_filter._historic" (change)="addAbilityChip('_historic')"
                    [value]="true" class="yes-radio">
                  <label for="historic3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_saga':format_filter)">
                <label position="stacked">
                  {{ '_saga' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="saga" id="saga1" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="false"
                    class="no-radio">
                  <label for="saga1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="saga" id="saga2" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="null"
                    class="maybe-radio">
                  <label for="saga2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="saga" id="saga3" [(ngModel)]="c_filter._saga" (change)="addAbilityChip('_saga')" [value]="true"
                    class="yes-radio">
                  <label for="saga3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_equipment':format_filter)">
                <label position="stacked">
                  {{ '_equipment' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="equipment" id="equipment1" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="false" class="no-radio">
                  <label for="equipment1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="equipment" id="equipment2" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="null" class="maybe-radio">
                  <label for="equipment2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="equipment" id="equipment3" [(ngModel)]="c_filter._equipment" (change)="addAbilityChip('_equipment')"
                    [value]="true" class="yes-radio">
                  <label for="equipment3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_vehicle':format_filter)">
                <label position="stacked">
                  {{ '_vehicle' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="vehicle" id="vehicle1" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="false"
                    class="no-radio">
                  <label for="vehicle1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="vehicle" id="vehicle2" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="null"
                    class="maybe-radio">
                  <label for="vehicle2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="vehicle" id="vehicle3" [(ngModel)]="c_filter._vehicle" (change)="addAbilityChip('_vehicle')" [value]="true"
                    class="yes-radio">
                  <label for="vehicle3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_aura':format_filter)">
                <label position="stacked">
                  {{ '_aura' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="aura" id="aura1" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="false"
                    class="no-radio">
                  <label for="aura1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="aura" id="aura2" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="null"
                    class="maybe-radio">
                  <label for="aura2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="aura" id="aura3" [(ngModel)]="c_filter._aura" (change)="addAbilityChip('_aura')" [value]="true"
                    class="yes-radio">
                  <label for="aura3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tribal':format_filter)">
                <label position="stacked">
                  {{ '_tribal' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="tribal" id="tribal1" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="false"
                    class="no-radio">
                  <label for="tribal1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="tribal" id="tribal2" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="null"
                    class="maybe-radio">
                  <label for="tribal2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="tribal" id="tribal3" [(ngModel)]="c_filter._tribal" (change)="addAbilityChip('_tribal')" [value]="true"
                    class="yes-radio">
                  <label for="tribal3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_snow':format_filter)">
                <label position="stacked">
                  {{ '_snow' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="snow" id="snow1" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="false"
                    class="no-radio">
                  <label for="snow1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="snow" id="snow2" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="null"
                    class="maybe-radio">
                  <label for="snow2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="snow" id="snow3" [(ngModel)]="c_filter._snow" (change)="addAbilityChip('_snow')" [value]="true"
                    class="yes-radio">
                  <label for="snow3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
              <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_world':format_filter)">
                <label position="stacked">
                  {{ '_world' | translate }}</label>
                <div class="wrapper">
                  <input type="radio" name="world" id="world1" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="false"
                    class="no-radio">
                  <label for="world1" class="no-lbl">{{false_lbl}}</label>
                  <input type="radio" name="world" id="world2" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="null"
                    class="maybe-radio">
                  <label for="world2" class="maybe-lbl">{{null_lbl}}</label>
                  <input type="radio" name="world" id="world3" [(ngModel)]="c_filter._world" (change)="addAbilityChip('_world')" [value]="true"
                    class="yes-radio">
                  <label for="world3" class="yes-lbl">{{true_lbl}}</label>
                  <div class="toggle"></div>
                </div>
              </ion-item>
            </ion-list>
            </div>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div [ngSwitch]="cardAbility">
            <div *ngSwitchCase="'action'">
              <h1 class="advancedSelectionContainer_header">{{ 'Player Action' | translate }}</h1>
              <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activate':format_filter)">
                  <label position="stacked">
                    {{ '_activate' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Activate" id="Activate1" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                      [value]="false" class="no-radio">
                    <label for="Activate1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Activate" id="Activate2" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                      [value]="null" class="maybe-radio">
                    <label for="Activate2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Activate" id="Activate3" [(ngModel)]="c_filter._activate" (change)="addAbilityChip('_activate')"
                      [value]="true" class="yes-radio">
                    <label for="Activate3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_attach':format_filter)">
                  <label position="stacked">
                    {{ '_attach' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Attach" id="Attach1" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="false"
                      class="no-radio">
                    <label for="Attach1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Attach" id="Attach2" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="null"
                      class="maybe-radio">
                    <label for="Attach2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Attach" id="Attach3" [(ngModel)]="c_filter._attach" (change)="addAbilityChip('_attach')" [value]="true"
                      class="yes-radio">
                    <label for="Attach3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cast':format_filter)">
                  <label position="stacked">
                    {{ '_cast' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Cast" id="Cast1" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="false"
                      class="no-radio">
                    <label for="Cast1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Cast" id="Cast2" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="null"
                      class="maybe-radio">
                    <label for="Cast2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Cast" id="Cast3" [(ngModel)]="c_filter._cast" (change)="addAbilityChip('_cast')" [value]="true"
                      class="yes-radio">
                    <label for="Cast3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counter':format_filter)">
                  <label position="stacked">
                    {{ '_counter' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Counter" id="Counter1" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="false"
                      class="no-radio">
                    <label for="Counter1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Counter" id="Counter2" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="null"
                      class="maybe-radio">
                    <label for="Counter2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Counter" id="Counter3" [(ngModel)]="c_filter._counter" (change)="addAbilityChip('_counter')" [value]="true"
                      class="yes-radio">
                    <label for="Counter3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_copy':format_filter)">
                    <label position="stacked">
                      {{ '_copy' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="Copy" id="Copy1" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="false"
                        class="no-radio">
                      <label for="Copy1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="Copy" id="Copy2" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="null"
                        class="maybe-radio">
                      <label for="Copy2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="Copy" id="Copy3" [(ngModel)]="c_filter._copy" (change)="addAbilityChip('_copy')" [value]="true"
                        class="yes-radio">
                      <label for="Copy3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_create':format_filter)">
                  <label position="stacked">
                    {{ '_create' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Create" id="Create1" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="false"
                      class="no-radio">
                    <label for="Create1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Create" id="Create2" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="null"
                      class="maybe-radio">
                    <label for="Create2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Create" id="Create3" [(ngModel)]="c_filter._create" (change)="addAbilityChip('_create')" [value]="true"
                      class="yes-radio">
                    <label for="Create3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cycle':format_filter)">
                  <label position="stacked">
                    {{ '_cycle' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Cycle" id="Cycle1" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="false"
                      class="no-radio">
                    <label for="Cycle1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Cycle" id="Cycle2" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="null"
                      class="maybe-radio">
                    <label for="Cycle2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Cycle" id="Cycle3" [(ngModel)]="c_filter._cycle" (change)="addAbilityChip('_cycle')" [value]="true"
                      class="yes-radio">
                    <label for="Cycle3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_destroy':format_filter)">
                  <label position="stacked">
                    {{ '_destroy' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Destroy" id="Destroy1" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="false"
                      class="no-radio">
                    <label for="Destroy1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Destroy" id="Destroy2" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="null"
                      class="maybe-radio">
                    <label for="Destroy2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Destroy" id="Destroy3" [(ngModel)]="c_filter._destroy" (change)="addAbilityChip('_destroy')" [value]="true"
                      class="yes-radio">
                    <label for="Destroy3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_discard':format_filter)">
                  <label position="stacked">
                    {{ '_discard' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Discard" id="Discard1" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="false"
                      class="no-radio">
                    <label for="Discard1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Discard" id="Discard2" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="null"
                      class="maybe-radio">
                    <label for="Discard2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Discard" id="Discard3" [(ngModel)]="c_filter._discard" (change)="addAbilityChip('_discard')" [value]="true"
                      class="yes-radio">
                    <label for="Discard3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_double':format_filter)">
                  <label position="stacked">
                    {{ '_double' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Double" id="Double1" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="false"
                      class="no-radio">
                    <label for="Double1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Double" id="Double2" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="null"
                      class="maybe-radio">
                    <label for="Double2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Double" id="Double3" [(ngModel)]="c_filter._double" (change)="addAbilityChip('_double')" [value]="true"
                      class="yes-radio">
                    <label for="Double3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_draw':format_filter)">
                  <label position="stacked">
                    {{ '_draw' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Draw" id="Draw1" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="false"
                      class="no-radio">
                    <label for="Draw1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Draw" id="Draw2" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="null"
                      class="maybe-radio">
                    <label for="Draw2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Draw" id="Draw3" [(ngModel)]="c_filter._draw" (change)="addAbilityChip('_draw')" [value]="true"
                      class="yes-radio">
                    <label for="Draw3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exchange':format_filter)">
                  <label position="stacked">
                    {{ '_exchange' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Exchange" id="Exchange1" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                      [value]="false" class="no-radio">
                    <label for="Exchange1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Exchange" id="Exchange2" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                      [value]="null" class="maybe-radio">
                    <label for="Exchange2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Exchange" id="Exchange3" [(ngModel)]="c_filter._exchange" (change)="addAbilityChip('_exchange')"
                      [value]="true" class="yes-radio">
                    <label for="Exchange3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_exile':format_filter)">
                  <label position="stacked">
                    {{ '_exile' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Exile" id="Exile1" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="false"
                      class="no-radio">
                    <label for="Exile1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Exile" id="Exile2" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="null"
                      class="maybe-radio">
                    <label for="Exile2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Exile" id="Exile3" [(ngModel)]="c_filter._exile" (change)="addAbilityChip('_exile')" [value]="true"
                      class="yes-radio">
                    <label for="Exile3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_fight':format_filter)">
                  <label position="stacked">
                    {{ '_fight' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Fight" id="Fight1" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="false"
                      class="no-radio">
                    <label for="Fight1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Fight" id="Fight2" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="null"
                      class="maybe-radio">
                    <label for="Fight2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Fight" id="Fight3" [(ngModel)]="c_filter._fight" (change)="addAbilityChip('_fight')" [value]="true"
                      class="yes-radio">
                    <label for="Fight3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mill':format_filter)">
                  <label position="stacked">
                    {{ '_mill' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Mill" id="Mill1" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="false"
                      class="no-radio">
                    <label for="Mill1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Mill" id="Mill2" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="null"
                      class="maybe-radio">
                    <label for="Mill2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Mill" id="Mill3" [(ngModel)]="c_filter._mill" (change)="addAbilityChip('_mill')" [value]="true"
                      class="yes-radio">
                    <label for="Mill3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_play':format_filter)">
                  <label position="stacked">
                    {{ '_play' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Play" id="Play1" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="false"
                      class="no-radio">
                    <label for="Play1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Play" id="Play2" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="null"
                      class="maybe-radio">
                    <label for="Play2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Play" id="Play3" [(ngModel)]="c_filter._play" (change)="addAbilityChip('_play')" [value]="true"
                      class="yes-radio">
                    <label for="Play3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_reveal':format_filter)">
                  <label position="stacked">
                    {{ '_reveal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Reveal" id="Reveal1" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="false"
                      class="no-radio">
                    <label for="Reveal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Reveal" id="Reveal2" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="null"
                      class="maybe-radio">
                    <label for="Reveal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Reveal" id="Reveal3" [(ngModel)]="c_filter._reveal" (change)="addAbilityChip('_reveal')" [value]="true"
                      class="yes-radio">
                    <label for="Reveal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_sacrifice':format_filter)">
                  <label position="stacked">
                    {{ '_sacrifice' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Sacrifice" id="Sacrifice1" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                      [value]="false" class="no-radio">
                    <label for="Sacrifice1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Sacrifice" id="Sacrifice2" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                      [value]="null" class="maybe-radio">
                    <label for="Sacrifice2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Sacrifice" id="Sacrifice3" [(ngModel)]="c_filter._sacrifice" (change)="addAbilityChip('_sacrifice')"
                      [value]="true" class="yes-radio">
                    <label for="Sacrifice3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_scry':format_filter)">
                  <label position="stacked">
                    {{ '_scry' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Scry" id="Scry1" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="false"
                      class="no-radio">
                    <label for="Scry1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Scry" id="Scry2" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="null"
                      class="maybe-radio">
                    <label for="Scry2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Scry" id="Scry3" [(ngModel)]="c_filter._scry" (change)="addAbilityChip('_scry')" [value]="true"
                      class="yes-radio">
                    <label for="Scry3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_search':format_filter)">
                  <label position="stacked">
                    {{ '_search' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Search" id="Search1" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="false"
                      class="no-radio">
                    <label for="Search1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Search" id="Search2" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="null"
                      class="maybe-radio">
                    <label for="Search2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Search" id="Search3" [(ngModel)]="c_filter._search" (change)="addAbilityChip('_search')" [value]="true"
                      class="yes-radio">
                    <label for="Search3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_shuffle':format_filter)">
                  <label position="stacked">
                    {{ '_shuffle' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Shuffle" id="Shuffle1" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="false"
                      class="no-radio">
                    <label for="Shuffle1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Shuffle" id="Shuffle2" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="null"
                      class="maybe-radio">
                    <label for="Shuffle2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Shuffle" id="Shuffle3" [(ngModel)]="c_filter._shuffle" (change)="addAbilityChip('_shuffle')" [value]="true"
                      class="yes-radio">
                    <label for="Shuffle3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tap':format_filter)">
                  <label position="stacked">
                    {{ '_tap' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Tap" id="Tap1" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="false" class="no-radio">
                    <label for="Tap1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Tap" id="Tap2" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="null" class="maybe-radio">
                    <label for="Tap2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Tap" id="Tap3" [(ngModel)]="c_filter._tap" (change)="addAbilityChip('_tap')" [value]="true" class="yes-radio">
                    <label for="Tap3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_untap':format_filter)">
                  <label position="stacked">
                    {{ '_untap' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Untap" id="Untap1" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="false"
                      class="no-radio">
                    <label for="Untap1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Untap" id="Untap2" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="null"
                      class="maybe-radio">
                    <label for="Untap2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Untap" id="Untap3" [(ngModel)]="c_filter._untap" (change)="addAbilityChip('_untap')" [value]="true"
                      class="yes-radio">
                    <label for="Untap3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div [ngSwitch]="cardAbility">
            <div *ngSwitchCase="'ability'">
              <h1 class="advancedSelectionContainer_header">{{ 'Card Ability' | translate }}</h1>
              <h4 class="container_yesno_subheader">{{ 'Evergreen' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_deathtouch' | translate }}</label>
                  <div class="wrapper" text-start>
                    <input type="radio" name="Deathtouch" id="Deathtouch1" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                      [value]="false" class="no-radio">
                    <label for="Deathtouch1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Deathtouch" id="Deathtouch2" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                      [value]="null" class="maybe-radio">
                    <label for="Deathtouch2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Deathtouch" id="Deathtouch3" [(ngModel)]="c_filter._deathtouch" (change)="addAbilityChip('_deathtouch')"
                      [value]="true" class="yes-radio">
                    <label for="Deathtouch3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_defender' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Defender" id="Defender1" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                      [value]="false" class="no-radio">
                    <label for="Defender1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Defender" id="Defender2" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                      [value]="null" class="maybe-radio">
                    <label for="Defender2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Defender" id="Defender3" [(ngModel)]="c_filter._defender" (change)="addAbilityChip('_defender')"
                      [value]="true" class="yes-radio">
                    <label for="Defender3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_double_strike' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="DoubleStrike" id="DoubleStrike1" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                      [value]="false" class="no-radio">
                    <label for="DoubleStrike1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="DoubleStrike" id="DoubleStrike2" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                      [value]="null" class="maybe-radio">
                    <label for="DoubleStrike2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="DoubleStrike" id="DoubleStrike3" [(ngModel)]="c_filter._double_strike" (change)="addAbilityChip('_double_strike')"
                      [value]="true" class="yes-radio">
                    <label for="DoubleStrike3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_enchant' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Enchant" id="Enchant1" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="false"
                      class="no-radio">
                    <label for="Enchant1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Enchant" id="Enchant2" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="null"
                      class="maybe-radio">
                    <label for="Enchant2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Enchant" id="Enchant3" [(ngModel)]="c_filter._enchant" (change)="addAbilityChip('_enchant')" [value]="true"
                      class="yes-radio">
                    <label for="Enchant3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_equip' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Equip" id="Equip1" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="false"
                      class="no-radio">
                    <label for="Equip1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Equip" id="Equip2" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="null"
                      class="maybe-radio">
                    <label for="Equip2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Equip" id="Equip3" [(ngModel)]="c_filter._equip" (change)="addAbilityChip('_equip')" [value]="true"
                      class="yes-radio">
                    <label for="Equip3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_first_strike' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="FirstStrike" id="FirstStrike1" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                      [value]="false" class="no-radio">
                    <label for="FirstStrike1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="FirstStrike" id="FirstStrike2" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                      [value]="null" class="maybe-radio">
                    <label for="FirstStrike2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="FirstStrike" id="FirstStrike3" [(ngModel)]="c_filter._first_strike" (change)="addAbilityChip('_first_strike')"
                      [value]="true" class="yes-radio">
                    <label for="FirstStrike3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_flash' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Flash" id="Flash1" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="false"
                      class="no-radio">
                    <label for="Flash1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Flash" id="Flash2" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="null"
                      class="maybe-radio">
                    <label for="Flash2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Flash" id="Flash3" [(ngModel)]="c_filter._flash" (change)="addAbilityChip('_flash')" [value]="true"
                      class="yes-radio">
                    <label for="Flash3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_flying' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Flying" id="Flying1" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="false"
                      class="no-radio">
                    <label for="Flying1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Flying" id="Flying2" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="null"
                      class="maybe-radio">
                    <label for="Flying2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Flying" id="Flying3" [(ngModel)]="c_filter._flying" (change)="addAbilityChip('_flying')" [value]="true"
                      class="yes-radio">
                    <label for="Flying3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_haste' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Haste" id="Haste1" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="false"
                      class="no-radio">
                    <label for="Haste1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Haste" id="Haste2" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="null"
                      class="maybe-radio">
                    <label for="Haste2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Haste" id="Haste3" [(ngModel)]="c_filter._haste" (change)="addAbilityChip('_haste')" [value]="true"
                      class="yes-radio">
                    <label for="Haste3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_hexproof' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Hexproof" id="Hexproof1" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                      [value]="false" class="no-radio">
                    <label for="Hexproof1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Hexproof" id="Hexproof2" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                      [value]="null" class="maybe-radio">
                    <label for="Hexproof2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Hexproof" id="Hexproof3" [(ngModel)]="c_filter._hexproof" (change)="addAbilityChip('_hexproof')"
                      [value]="true" class="yes-radio">
                    <label for="Hexproof3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_indestructible' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Indestructible" id="Indestructible1" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                      [value]="false" class="no-radio">
                    <label for="Indestructible1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Indestructible" id="Indestructible2" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                      [value]="null" class="maybe-radio">
                    <label for="Indestructible2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Indestructible" id="Indestructible3" [(ngModel)]="c_filter._indestructible" (change)="addAbilityChip('_indestructible')"
                      [value]="true" class="yes-radio">
                    <label for="Indestructible3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_lifelink' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Lifelink" id="Lifelink1" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                      [value]="false" class="no-radio">
                    <label for="Lifelink1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Lifelink" id="Lifelink2" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                      [value]="null" class="maybe-radio">
                    <label for="Lifelink2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Lifelink" id="Lifelink3" [(ngModel)]="c_filter._lifelink" (change)="addAbilityChip('_lifelink')"
                      [value]="true" class="yes-radio">
                    <label for="Lifelink3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_menace' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Menace" id="Menace1" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="false"
                      class="no-radio">
                    <label for="Menace1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Menace" id="Menace2" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="null"
                      class="maybe-radio">
                    <label for="Menace2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Menace" id="Menace3" [(ngModel)]="c_filter._menace" (change)="addAbilityChip('_menace')" [value]="true"
                      class="yes-radio">
                    <label for="Menace3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_prowess' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Prowess" id="Prowess1" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="false"
                      class="no-radio">
                    <label for="Prowess1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Prowess" id="Prowess2" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="null"
                      class="maybe-radio">
                    <label for="Prowess2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Prowess" id="Prowess3" [(ngModel)]="c_filter._prowess" (change)="addAbilityChip('_prowess')" [value]="true"
                      class="yes-radio">
                    <label for="Prowess3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_reach' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Reach" id="Reach1" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="false"
                      class="no-radio">
                    <label for="Reach1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Reach" id="Reach2" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="null"
                      class="maybe-radio">
                    <label for="Reach2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Reach" id="Reach3" [(ngModel)]="c_filter._reach" (change)="addAbilityChip('_reach')" [value]="true"
                      class="yes-radio">
                    <label for="Reach3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_trample' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Trample" id="Trample1" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="false"
                      class="no-radio">
                    <label for="Trample1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Trample" id="Trample2" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="null"
                      class="maybe-radio">
                    <label for="Trample2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Trample" id="Trample3" [(ngModel)]="c_filter._trample" (change)="addAbilityChip('_trample')" [value]="true"
                      class="yes-radio">
                    <label for="Trample3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_vigilance' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Vigilance" id="Vigilance1" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                      [value]="false" class="no-radio">
                    <label for="Vigilance1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Vigilance" id="Vigilance2" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                      [value]="null" class="maybe-radio">
                    <label for="Vigilance2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Vigilance" id="Vigilance3" [(ngModel)]="c_filter._vigilance" (change)="addAbilityChip('_vigilance')"
                      [value]="true" class="yes-radio">
                    <label for="Vigilance3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes">
                  <label position="stacked">
                    {{ '_ward' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Ward" id="Ward1" [(ngModel)]="c_filter._ward" (change)="addAbilityChip('_ward')"
                      [value]="false" class="no-radio">
                    <label for="Ward1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Ward" id="Ward2" [(ngModel)]="c_filter._ward" (change)="addAbilityChip('_ward')"
                      [value]="null" class="maybe-radio">
                    <label for="Ward2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Ward" id="Ward3" [(ngModel)]="c_filter._ward" (change)="addAbilityChip('_ward')"
                      [value]="true" class="yes-radio">
                    <label for="Ward3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div [ngSwitch]="cardAbility">
            <div *ngSwitchCase="'trigger'">
              <h1 class="advancedSelectionContainer_header">{{ 'Trigger' | translate }}</h1>
              <h4 class="container_yesno_subheader">{{ 'Type' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activated_ability':format_filter)">
                  <label position="stacked">
                    {{ '_activated_ability' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activatedAbility" id="activatedAbility1" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                      [value]="false" class="no-radio">
                    <label for="activatedAbility1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activatedAbility" id="activatedAbility2" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                      [value]="null" class="maybe-radio">
                    <label for="activatedAbility2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activatedAbility" id="activatedAbility3" [(ngModel)]="c_filter._activated_ability" (change)="addAbilityChip('_activated_ability')"
                      [value]="true" class="yes-radio">
                    <label for="activatedAbility3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_casting_ability':format_filter)">
                  <label position="stacked">
                    {{ '_casting_ability' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="castingAbility" id="castingAbility1" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                      [value]="false" class="no-radio">
                    <label for="castingAbility1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="castingAbility" id="castingAbility2" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                      [value]="null" class="maybe-radio">
                    <label for="castingAbility2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="castingAbility" id="castingAbility3" [(ngModel)]="c_filter._casting_ability" (change)="addAbilityChip('_casting_ability')"
                      [value]="true" class="yes-radio">
                    <label for="castingAbility3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_passive_ability':format_filter)">
                  <label position="stacked">
                    {{ '_passive_ability' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="passiveAbility" id="passiveAbility1" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                      [value]="false" class="no-radio">
                    <label for="passiveAbility1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="passiveAbility" id="passiveAbility2" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                      [value]="null" class="maybe-radio">
                    <label for="passiveAbility2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="passiveAbility" id="passiveAbility3" [(ngModel)]="c_filter._passive_ability" (change)="addAbilityChip('_passive_ability')"
                      [value]="true" class="yes-radio">
                    <label for="passiveAbility3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_triggered_ability':format_filter)">
                  <label position="stacked">
                    {{ '_triggered_ability' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="triggeredAbility" id="triggeredAbility1" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                      [value]="false" class="no-radio">
                    <label for="triggeredAbility1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="triggeredAbility" id="triggeredAbility2" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                      [value]="null" class="maybe-radio">
                    <label for="triggeredAbility2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="triggeredAbility" id="triggeredAbility3" [(ngModel)]="c_filter._triggered_ability" (change)="addAbilityChip('_triggered_ability')"
                      [value]="true" class="yes-radio">
                    <label for="triggeredAbility3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_enter_the_battlefield':format_filter)">
                  <label position="stacked">
                    {{ '_enter_the_battlefield' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="ETB" id="ETB1" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                      [value]="false" class="no-radio">
                    <label for="ETB1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="ETB" id="ETB2" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                      [value]="null" class="maybe-radio">
                    <label for="ETB2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="ETB" id="ETB3" [(ngModel)]="c_filter._enter_the_battlefield" (change)="addAbilityChip('_enter_the_battlefield')"
                      [value]="true" class="yes-radio">
                    <label for="ETB3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Cost' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc0':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc0' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC0" id="activationCMC0_1" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC0_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC0" id="activationCMC0_2" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC0_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC0" id="activationCMC0_3" [(ngModel)]="c_filter._activation_cmc0" (change)="addAbilityChip('_activation_cmc0')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC0_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc1':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc1' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC1" id="activationCMC1_1" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC1_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC1" id="activationCMC1_2" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC1_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC1" id="activationCMC1_3" [(ngModel)]="c_filter._activation_cmc1" (change)="addAbilityChip('_activation_cmc1')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC1_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc2':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc2' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC2" id="activationCMC2_1" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC2_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC2" id="activationCMC2_2" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC2_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC2" id="activationCMC2_3" [(ngModel)]="c_filter._activation_cmc2" (change)="addAbilityChip('_activation_cmc2')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC2_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc3':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc3' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC3" id="activationCMC3_1" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC3_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC3" id="activationCMC3_2" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC3_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC3" id="activationCMC3_3" [(ngModel)]="c_filter._activation_cmc3" (change)="addAbilityChip('_activation_cmc3')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC3_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc4':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc4' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC4" id="activationCMC4_1" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC4_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC4" id="activationCMC4_2" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC4_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC4" id="activationCMC4_3" [(ngModel)]="c_filter._activation_cmc4" (change)="addAbilityChip('_activation_cmc4')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC4_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc5':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc5' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC5" id="activationCMC5_1" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC5_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC5" id="activationCMC5_2" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC5_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC5" id="activationCMC5_3" [(ngModel)]="c_filter._activation_cmc5" (change)="addAbilityChip('_activation_cmc5')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC5_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc6':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc6' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC6" id="activationCMC6_1" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC6_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC6" id="activationCMC6_2" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC6_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC6" id="activationCMC6_3" [(ngModel)]="c_filter._activation_cmc6" (change)="addAbilityChip('_activation_cmc6')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC6_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc7':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc7' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC7" id="activationCMC7_1" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC7_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC7" id="activationCMC7_2" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC7_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC7" id="activationCMC7_3" [(ngModel)]="c_filter._activation_cmc7" (change)="addAbilityChip('_activation_cmc7')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC7_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_activation_cmc8_plus':format_filter) && c_filter._activated_ability === true">
                  <label position="stacked">
                    {{ '_activation_cmc8_plus' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_1" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                      [value]="false" class="no-radio">
                    <label for="activationCMC8_plus_1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_2" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                      [value]="null" class="maybe-radio">
                    <label for="activationCMC8_plus_2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="activationCMC8_plus" id="activationCMC8_plus_3" [(ngModel)]="c_filter._activation_cmc8_plus" (change)="addAbilityChip('_activation_cmc8_plus')"
                      [value]="true" class="yes-radio">
                    <label for="activationCMC8_plus_3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_tap':format_filter)">
                  <label position="stacked">
                    {{ '_require_tap' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="requireTap" id="requireTap1" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                      [value]="false" class="no-radio">
                    <label for="requireTap1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="requireTap" id="requireTap2" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                      [value]="null" class="maybe-radio">
                    <label for="requireTap2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="requireTap" id="requireTap3" [(ngModel)]="c_filter._require_tap" (change)="addAbilityChip('_require_tap')"
                      [value]="true" class="yes-radio">
                    <label for="requireTap3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_untap':format_filter)">
                  <label position="stacked">
                    {{ '_require_untap' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="requireUntap" id="requireUntap1" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                      [value]="false" class="no-radio">
                    <label for="requireUntap1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="requireUntap" id="requireUntap2" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                      [value]="null" class="maybe-radio">
                    <label for="requireUntap2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="requireUntap" id="requireUntap3" [(ngModel)]="c_filter._require_untap" (change)="addAbilityChip('_require_untap')"
                      [value]="true" class="yes-radio">
                    <label for="requireUntap3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_colorless':format_filter)">
                  <label position="stacked">
                    {{ '_require_colorless' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="requireColorless" id="requireColorless1" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                      [value]="false" class="no-radio">
                    <label for="requireColorless1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="requireColorless" id="requireColorless2" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                      [value]="null" class="maybe-radio">
                    <label for="requireColorless2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="requireColorless" id="requireColorless3" [(ngModel)]="c_filter._require_colorless" (change)="addAbilityChip('_require_colorless')"
                      [value]="true" class="yes-radio">
                    <label for="requireColorless3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_require_mana':format_filter)">
                  <label position="stacked">
                    {{ '_require_mana' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="requireMana" id="requireMana1" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                      [value]="false" class="no-radio">
                    <label for="requireMana1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="requireMana" id="requireMana2" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                      [value]="null" class="maybe-radio">
                    <label for="requireMana2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="requireMana" id="requireMana3" [(ngModel)]="c_filter._require_mana" (change)="addAbilityChip('_require_mana')"
                      [value]="true" class="yes-radio">
                    <label for="requireMana3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div [ngSwitch]="cardAbility">
            <div *ngSwitchCase="'effect'">
              <h1 class="advancedSelectionContainer_header">{{ 'Effect' | translate }}</h1>
              <h4 class="container_yesno_subheader">{{ 'Generic' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_alt_loss':format_filter)">
                  <label position="stacked">
                    {{ '_alt_loss' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="AltLoss" id="AltLoss1" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                      [value]="false" class="no-radio">
                    <label for="AltLoss1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="AltLoss" id="AltLoss2" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                      [value]="null" class="maybe-radio">
                    <label for="AltLoss2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="AltLoss" id="AltLoss3" [(ngModel)]="c_filter._alt_loss" (change)="addAbilityChip('_alt_loss')"
                      [value]="true" class="yes-radio">
                    <label for="AltLoss3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_alt_win':format_filter)">
                  <label position="stacked">
                    {{ '_alt_win' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="AltWin" id="AltWin1" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="false"
                      class="no-radio">
                    <label for="AltWin1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="AltWin" id="AltWin2" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="null"
                      class="maybe-radio">
                    <label for="AltWin2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="AltWin" id="AltWin3" [(ngModel)]="c_filter._alt_win" (change)="addAbilityChip('_alt_win')" [value]="true"
                      class="yes-radio">
                    <label for="AltWin3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_anti_discard':format_filter)">
                  <label position="stacked">
                    {{ '_anti_discard' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="AntiDiscard" id="AntiDiscard1" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                      [value]="false" class="no-radio">
                    <label for="AntiDiscard1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="AntiDiscard" id="AntiDiscard2" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                      [value]="null" class="maybe-radio">
                    <label for="AntiDiscard2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="AntiDiscard" id="AntiDiscard3" [(ngModel)]="c_filter._anti_discard" (change)="addAbilityChip('_anti_discard')"
                      [value]="true" class="yes-radio">
                    <label for="AntiDiscard3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_bounce':format_filter)">
                  <label position="stacked">
                    {{ '_bounce' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Bounce" id="Bounce1" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="false"
                      class="no-radio">
                    <label for="Bounce1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Bounce" id="Bounce2" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="null"
                      class="maybe-radio">
                    <label for="Bounce2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Bounce" id="Bounce3" [(ngModel)]="c_filter._bounce" (change)="addAbilityChip('_bounce')" [value]="true"
                      class="yes-radio">
                    <label for="Bounce3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_burn':format_filter)">
                  <label position="stacked">
                    {{ '_burn' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Burn" id="Burn1" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="false"
                      class="no-radio">
                    <label for="Burn1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Burn" id="Burn2" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="null"
                      class="maybe-radio">
                    <label for="Burn2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Burn" id="Burn3" [(ngModel)]="c_filter._burn" (change)="addAbilityChip('_burn')" [value]="true"
                      class="yes-radio">
                    <label for="Burn3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_cantrip':format_filter)">
                  <label position="stacked">
                    {{ '_cantrip' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Cantrip" id="Cantrip1" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="false"
                      class="no-radio">
                    <label for="Cantrip1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Cantrip" id="Cantrip2" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="null"
                      class="maybe-radio">
                    <label for="Cantrip2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Cantrip" id="Cantrip3" [(ngModel)]="c_filter._cantrip" (change)="addAbilityChip('_cantrip')" [value]="true"
                      class="yes-radio">
                    <label for="Cantrip3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counterspell':format_filter)">
                  <label position="stacked">
                    {{ '_counterspell' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Counterspell" id="Counterspell1" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                      [value]="false" class="no-radio">
                    <label for="Counterspell1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Counterspell" id="Counterspell2" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                      [value]="null" class="maybe-radio">
                    <label for="Counterspell2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Counterspell" id="Counterspell3" [(ngModel)]="c_filter._counterspell" (change)="addAbilityChip('_counterspell')"
                      [value]="true" class="yes-radio">
                    <label for="Counterspell3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_looting':format_filter)">
                  <label position="stacked">
                    {{ '_looting' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Looting" id="Looting1" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="false"
                      class="no-radio">
                    <label for="Looting1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Looting" id="Looting2" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="null"
                      class="maybe-radio">
                    <label for="Looting2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Looting" id="Looting3" [(ngModel)]="c_filter._looting" (change)="addAbilityChip('_looting')" [value]="true"
                      class="yes-radio">
                    <label for="Looting3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_self_mill':format_filter)">
                  <label position="stacked">
                    {{ '_self_mill' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="SelfMill" id="SelfMill1" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="false"
                      class="no-radio">
                    <label for="SelfMill1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="SelfMill" id="SelfMill2" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="null"
                      class="maybe-radio">
                    <label for="SelfMill2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="SelfMill" id="SelfMill3" [(ngModel)]="c_filter._self_mill" (change)="addAbilityChip('_self_mill')" [value]="true"
                      class="yes-radio">
                    <label for="SelfMill3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_rummaging':format_filter)">
                  <label position="stacked">
                    {{ '_rummaging' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Rummaging" id="Rummaging1" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="false"
                      class="no-radio">
                    <label for="Rummaging1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Rummaging" id="Rummaging2" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="null"
                      class="maybe-radio">
                    <label for="Rummaging2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Rummaging" id="Rummaging3" [(ngModel)]="c_filter._rummaging" (change)="addAbilityChip('_rummaging')" [value]="true"
                      class="yes-radio">
                    <label for="Rummaging3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_stat_increase':format_filter)">
                  <label position="stacked">
                    {{ '_stat_increase' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="StatIncrease" id="StatIncrease1" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                      [value]="false" class="no-radio">
                    <label for="StatIncrease1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="StatIncrease" id="StatIncrease2" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                      [value]="null" class="maybe-radio">
                    <label for="StatIncrease2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="StatIncrease" id="StatIncrease3" [(ngModel)]="c_filter._stat_increase" (change)="addAbilityChip('_stat_increase')"
                      [value]="true" class="yes-radio">
                    <label for="StatIncrease3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_stat_reduce':format_filter)">
                  <label position="stacked">
                    {{ '_stat_reduce' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="StatReduce" id="StatReduce1" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                      [value]="false" class="no-radio">
                    <label for="StatReduce1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="StatReduce" id="StatReduce2" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                      [value]="null" class="maybe-radio">
                    <label for="StatReduce2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="StatReduce" id="StatReduce3" [(ngModel)]="c_filter._stat_reduce" (change)="addAbilityChip('_stat_reduce')"
                      [value]="true" class="yes-radio">
                    <label for="StatReduce3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_tutor':format_filter)">
                  <label position="stacked">
                    {{ '_tutor' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Tutor" id="Tutor1" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="false"
                      class="no-radio">
                    <label for="Tutor1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Tutor" id="Tutor2" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="null"
                      class="maybe-radio">
                    <label for="Tutor2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Tutor" id="Tutor3" [(ngModel)]="c_filter._tutor" (change)="addAbilityChip('_tutor')" [value]="true"
                      class="yes-radio">
                    <label for="Tutor3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Removal' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_mass_removal':format_filter)">
                  <label position="stacked">
                    {{ '_mass_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="massRemoval" id="massRemoval1" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                      [value]="false" class="no-radio">
                    <label for="massRemoval1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="massRemoval" id="massRemoval2" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="massRemoval2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="massRemoval" id="massRemoval3" [(ngModel)]="c_filter._mass_removal" (change)="addAbilityChip('_mass_removal')"
                      [value]="true" class="yes-radio">
                    <label for="massRemoval3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_spot_removal':format_filter)">
                  <label position="stacked">
                    {{ '_spot_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="spotRemoval" id="spotRemoval1" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                      [value]="false" class="no-radio">
                    <label for="spotRemoval1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="spotRemoval" id="spotRemoval2" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="spotRemoval2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="spotRemoval" id="spotRemoval3" [(ngModel)]="c_filter._spot_removal" (change)="addAbilityChip('_spot_removal')"
                      [value]="true" class="yes-radio">
                    <label for="spotRemoval3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_1_removal':format_filter)">
                  <label position="stacked">
                    {{ '_1_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="1Removal" id="1Removal1" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                      [value]="false" class="no-radio">
                    <label for="1Removal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="1Removal" id="1Removal2" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="1Removal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="1Removal" id="1Removal3" [(ngModel)]="c_filter._1_removal" (change)="addAbilityChip('_1_removal')"
                      [value]="true" class="yes-radio">
                    <label for="1Removal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_2_removal':format_filter)">
                  <label position="stacked">
                    {{ '_2_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="2Removal" id="2Removal1" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                      [value]="false" class="no-radio">
                    <label for="2Removal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="2Removal" id="2Removal2" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="2Removal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="2Removal" id="2Removal3" [(ngModel)]="c_filter._2_removal" (change)="addAbilityChip('_2_removal')"
                      [value]="true" class="yes-radio">
                    <label for="2Removal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_3_removal':format_filter)">
                  <label position="stacked">
                    {{ '_3_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="3Removal" id="3Removal1" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                      [value]="false" class="no-radio">
                    <label for="3Removal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="3Removal" id="3Removal2" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="3Removal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="3Removal" id="3Removal3" [(ngModel)]="c_filter._3_removal" (change)="addAbilityChip('_3_removal')"
                      [value]="true" class="yes-radio">
                    <label for="3Removal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_4_removal':format_filter)">
                  <label position="stacked">
                    {{ '_4_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="4Removal" id="4Removal1" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                      [value]="false" class="no-radio">
                    <label for="4Removal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="4Removal" id="4Removal2" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="4Removal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="4Removal" id="4Removal3" [(ngModel)]="c_filter._4_removal" (change)="addAbilityChip('_4_removal')"
                      [value]="true" class="yes-radio">
                    <label for="4Removal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_5_removal':format_filter)">
                  <label position="stacked">
                    {{ '_5_removal' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="5Removal" id="5Removal1" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                      [value]="false" class="no-radio">
                    <label for="5Removal1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="5Removal" id="5Removal2" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                      [value]="null" class="maybe-radio">
                    <label for="5Removal2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="5Removal" id="5Removal3" [(ngModel)]="c_filter._5_removal" (change)="addAbilityChip('_5_removal')"
                      [value]="true" class="yes-radio">
                    <label for="5Removal3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Counters' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_counters':format_filter)">
                  <label position="stacked">
                    {{ '_counters' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="counters" id="counters1" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                      [value]="false" class="no-radio">
                    <label for="counters1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="counters" id="counters2" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                      [value]="null" class="maybe-radio">
                    <label for="counters2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="counters" id="counters3" [(ngModel)]="c_filter._counters" (change)="addAbilityChip('_counters')"
                      [value]="true" class="yes-radio">
                    <label for="counters3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_energy':format_filter)">
                  <label position="stacked">
                    {{ '_energy' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="Energy" id="Energy1" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="false"
                      class="no-radio">
                    <label for="Energy1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="Energy" id="Energy2" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="null"
                      class="maybe-radio">
                    <label for="Energy2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="Energy" id="Energy3" [(ngModel)]="c_filter._energy" (change)="addAbilityChip('_energy')" [value]="true"
                      class="yes-radio">
                    <label for="Energy3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_plus1_counters':format_filter)">
                  <label position="stacked">
                    {{ '_plus1_counters' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="plus1Counters" id="plus1Counters1" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                      [value]="false" class="no-radio">
                    <label for="plus1Counters1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="plus1Counters" id="plus1Counters2" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                      [value]="null" class="maybe-radio">
                    <label for="plus1Counters2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="plus1Counters" id="plus1Counters3" [(ngModel)]="c_filter._plus1_counters" (change)="addAbilityChip('_plus1_counters')"
                      [value]="true" class="yes-radio">
                    <label for="plus1Counters3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_minus1_counters':format_filter)">
                  <label position="stacked">
                    {{ '_minus1_counters' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="minus1Counters" id="minus1Counters1" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                      [value]="false" class="no-radio">
                    <label for="minus1Counters1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="minus1Counters" id="minus1Counters2" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                      [value]="null" class="maybe-radio">
                    <label for="minus1Counters2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="minus1Counters" id="minus1Counters3" [(ngModel)]="c_filter._minus1_counters" (change)="addAbilityChip('_minus1_counters')"
                      [value]="true" class="yes-radio">
                    <label for="minus1Counters3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Mana' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_give_colorless':format_filter)">
                  <label position="stacked">
                    {{ '_give_colorless' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="giveColorless" id="giveColorless1" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                      [value]="false" class="no-radio">
                    <label for="giveColorless1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="giveColorless" id="giveColorless2" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                      [value]="null" class="maybe-radio">
                    <label for="giveColorless2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="giveColorless" id="giveColorless3" [(ngModel)]="c_filter._give_colorless" (change)="addAbilityChip('_give_colorless')"
                      [value]="true" class="yes-radio">
                    <label for="giveColorless3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_give_mana':format_filter)">
                  <label position="stacked">
                    {{ '_give_mana' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="giveMana" id="giveMana1" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                      [value]="false" class="no-radio">
                    <label for="giveMana1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="giveMana" id="giveMana2" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                      [value]="null" class="maybe-radio">
                    <label for="giveMana2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="giveMana" id="giveMana3" [(ngModel)]="c_filter._give_mana" (change)="addAbilityChip('_give_mana')"
                      [value]="true" class="yes-radio">
                    <label for="giveMana3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
              <h4 class="container_yesno_subheader">{{ 'Duration' | translate }}</h4>
              <ion-list class="list_columns">
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_until_eot':format_filter)">
                  <label position="stacked">
                    {{ '_until_eot' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="untilEOT" id="untilEOT1" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                      [value]="false" class="no-radio">
                    <label for="untilEOT1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="untilEOT" id="untilEOT2" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                      [value]="null" class="maybe-radio">
                    <label for="untilEOT2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="untilEOT" id="untilEOT3" [(ngModel)]="c_filter._until_eot" (change)="addAbilityChip('_until_eot')"
                      [value]="true" class="yes-radio">
                    <label for="untilEOT3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
                <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_until_condition':format_filter)">
                  <label position="stacked">
                    {{ '_until_condition' | translate }}</label>
                  <div class="wrapper">
                    <input type="radio" name="untilCondition" id="untilCondition1" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                      [value]="false" class="no-radio">
                    <label for="untilCondition1" class="no-lbl">{{false_lbl}}</label>
                    <input type="radio" name="untilCondition" id="untilCondition2" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                      [value]="null" class="maybe-radio">
                    <label for="untilCondition2" class="maybe-lbl">{{null_lbl}}</label>
                    <input type="radio" name="untilCondition" id="untilCondition3" [(ngModel)]="c_filter._until_condition" (change)="addAbilityChip('_until_condition')"
                      [value]="true" class="yes-radio">
                    <label for="untilCondition3" class="yes-lbl">{{true_lbl}}</label>
                    <div class="toggle"></div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
          <ion-col size="auto">
            <div [ngSwitch]="cardAbility">
              <div *ngSwitchCase="'target'">
                <h1 class="advancedSelectionContainer_header">{{ 'Target' | translate }}</h1>
                <h4 class="container_yesno_subheader">{{ 'Generic' | translate }}</h4>
                <ion-list class="list_columns">
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_artifact':format_filter)">
                    <label position="stacked">
                      {{ '_target_artifact' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetArtifact" id="targetArtifact1" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                        [value]="false" class="no-radio">
                      <label for="targetArtifact1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetArtifact" id="targetArtifact2" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                        [value]="null" class="maybe-radio">
                      <label for="targetArtifact2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetArtifact" id="targetArtifact3" [(ngModel)]="c_filter._target_artifact" (change)="addAbilityChip('_target_artifact')"
                        [value]="true" class="yes-radio">
                      <label for="targetArtifact3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_creature':format_filter)">
                    <label position="stacked">
                      {{ '_target_creature' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetCreature" id="targetCreature1" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                        [value]="false" class="no-radio">
                      <label for="targetCreature1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetCreature" id="targetCreature2" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                        [value]="null" class="maybe-radio">
                      <label for="targetCreature2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetCreature" id="targetCreature3" [(ngModel)]="c_filter._target_creature" (change)="addAbilityChip('_target_creature')"
                        [value]="true" class="yes-radio">
                      <label for="targetCreature3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_card':format_filter)">
                    <label position="stacked">
                      {{ '_target_card' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetCard" id="targetCard1" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                        [value]="false" class="no-radio">
                      <label for="targetCard1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetCard" id="targetCard2" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                        [value]="null" class="maybe-radio">
                      <label for="targetCard2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetCard" id="targetCard3" [(ngModel)]="c_filter._target_card" (change)="addAbilityChip('_target_card')"
                        [value]="true" class="yes-radio">
                      <label for="targetCard3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_enchantment':format_filter)">
                    <label position="stacked">
                      {{ '_target_enchantment' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetEnchantment" id="targetEnchantment1" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                        [value]="false" class="no-radio">
                      <label for="targetEnchantment1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetEnchantment" id="targetEnchantment2" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                        [value]="null" class="maybe-radio">
                      <label for="targetEnchantment2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetEnchantment" id="targetEnchantment3" [(ngModel)]="c_filter._target_enchantment" (change)="addAbilityChip('_target_enchantment')"
                        [value]="true" class="yes-radio">
                      <label for="targetEnchantment3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_land':format_filter)">
                    <label position="stacked">
                      {{ '_target_land' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetLand" id="targetLand1" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                        [value]="false" class="no-radio">
                      <label for="targetLand1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetLand" id="targetLand2" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                        [value]="null" class="maybe-radio">
                      <label for="targetLand2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetLand" id="targetLand3" [(ngModel)]="c_filter._target_land" (change)="addAbilityChip('_target_land')"
                        [value]="true" class="yes-radio">
                      <label for="targetLand3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_permanent':format_filter)">
                    <label position="stacked">
                      {{ '_target_permanent' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetPermanent" id="targetPermanent1" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                        [value]="false" class="no-radio">
                      <label for="targetPermanent1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetPermanent" id="targetPermanent2" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                        [value]="null" class="maybe-radio">
                      <label for="targetPermanent2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetPermanent" id="targetPermanent3" [(ngModel)]="c_filter._target_permanent" (change)="addAbilityChip('_target_permanent')"
                        [value]="true" class="yes-radio">
                      <label for="targetPermanent3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_planeswalker':format_filter)">
                    <label position="stacked">
                      {{ '_target_planeswalker' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker1" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                        [value]="false" class="no-radio">
                      <label for="targetPlaneswalker1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker2" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                        [value]="null" class="maybe-radio">
                      <label for="targetPlaneswalker2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetPlaneswalker" id="targetPlaneswalker3" [(ngModel)]="c_filter._target_planeswalker" (change)="addAbilityChip('_target_planeswalker')"
                        [value]="true" class="yes-radio">
                      <label for="targetPlaneswalker3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                  <ion-item class="item_attributes" *ngIf="(formattedCardCollection | showAbilityFilter:'_target_player':format_filter)">
                    <label position="stacked">
                      {{ '_target_player' | translate }}</label>
                    <div class="wrapper">
                      <input type="radio" name="targetPlayer" id="targetPlayer1" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                        [value]="false" class="no-radio">
                      <label for="targetPlayer1" class="no-lbl">{{false_lbl}}</label>
                      <input type="radio" name="targetPlayer" id="targetPlayer2" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                        [value]="null" class="maybe-radio">
                      <label for="targetPlayer2" class="maybe-lbl">{{null_lbl}}</label>
                      <input type="radio" name="targetPlayer" id="targetPlayer3" [(ngModel)]="c_filter._target_player" (change)="addAbilityChip('_target_player')"
                        [value]="true" class="yes-radio">
                      <label for="targetPlayer3" class="yes-lbl">{{true_lbl}}</label>
                      <div class="toggle"></div>
                    </div>
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </ion-col>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<!-- Sidebar sliding div for wishlist -->
<div id="wishlist" class="wishlist" [class.wishlistHover]="isMobile === false">
  <!-- Show a clickable/hoverable icon -->
  <div (click)="toggleWishlistShow()" *ngIf="wishList.length!=0">  
    <i class="ss ss-s99 ms-4x ms-cost wishlist-icon"></i>
  </div>
  <!-- Wishlist -->
  <div id="wishlist-inner" class="wishlist-inner" [class.wishlist-open]="wishListOpen === true && isMobile === true">
    <button ion-button icon-only clear small (click)="toggleWishlistShow()" class="wishlist-close" *ngIf="isMobile === true">
        <ion-icon name="md-close"></ion-icon>
    </button>
    <form id="wishlist-form" class="wishlist-form">
      <table id="wishlist-table" class="wishlist-table">
        <thead>
          <tr>
            <th id="thAmount" (click)="sort('amount')"></th>
            <th id="thCard" (click)="sort('name')">{{ 'Card' | translate }}</th>
            <th id="thPrice" (click)="sort('priceTotal')"> {{ 'Price' | translate }} </th>
          </tr>
        </thead>
        <tbody>
          <!-- Creatures -->
          <tr *ngIf= "(wishList | wishListFilter:'Creatures':triggerPipeCounter)?.length > 0">
              <th>{{ 'Creatures' | translate }}</th>
          </tr>
          <tr *ngFor="let wishCard of wishList ; let i = index" class="notfirst">
            <td class="tdAmount" *ngIf="wishCard.wishCard.mainType[0] === 'Creature' || wishCard.wishCard.mainType[0] === 'Artifact Creature'">
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'+')">
                  <ion-icon name='arrow-up'></ion-icon>
                </ion-button>
              </div>
              <div>
                <ion-button size="small" expand="block" color="light">
                    <p>{{wishCard.amount}}</p>
                </ion-button>
              </div>
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'-')">
                  <ion-icon name='arrow-down'></ion-icon>
                </ion-button>
              </div>
            </td>
            <td class="tdCard" (click)="openModal(wishCard.wishCard, cardCollection)" *ngIf="wishCard.wishCard.mainType[0] === 'Creature' || wishCard.wishCard.mainType[0] === 'Artifact Creature'">
              <div innerHTML="<i class='ss ss-{{wishCard.wishCard.edition | lowercase}} ss-{{wishCard.wishCard.rarity | lowercase}} ss-grad ss-2x ss-fw'></i> &nbsp {{wishCard.wishCard.manaCost | toManaIcon}} "></div>
              <p>{{wishCard.wishCard[name_lang] !== '' ? wishCard.wishCard[name_lang] : wishCard.wishCard.name}}</p>
              <span><img src={{wishCard.wishCard[imagePath_lang]}} alt="image" height="279"/></span>
              <p *ngIf="language !== 'EN'">
                {{wishCard.wishCard[cardType_lang] !== '' ? wishCard.wishCard[cardType_lang] : wishCard.wishCard.subType}} ({{wishCard.wishCard.pt}})
              </p>
              <p *ngIf="language === 'EN'">
                  {{wishCard.wishCard.subType}} ({{wishCard.wishCard.pt}})
              </p>
            </td>
            <td class="tdPrice" *ngIf="wishCard.wishCard.mainType[0] === 'Creature' || wishCard.wishCard.mainType[0] === 'Artifact Creature'">
              ${{wishCard.priceTotal | number : '1.2-2'}}
            </td>
          </tr>
          <!-- Spells -->
          <tr *ngIf= "(wishList | wishListFilter:'Spells':triggerPipeCounter)?.length > 0">
              <th>{{ 'Spells' | translate }}</th>
          </tr>
          <tr *ngFor="let wishCard of wishList ; let i = index" class="notfirst">
            <td class="tdAmount" *ngIf="wishCard.wishCard.mainType[0] === 'Instant' || wishCard.wishCard.mainType[0] === 'Sorcery'">
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'+')">
                  <ion-icon name='arrow-up'></ion-icon>
                </ion-button>
              </div>
              <div>
                <ion-button size="small" expand="block" color="light">
                    <p>{{wishCard.amount}}</p>
                </ion-button>
              </div>
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'-')">
                  <ion-icon name='arrow-down'></ion-icon>
                </ion-button>
              </div>
            </td>
            <td class="tdCard" (click)="openModal(wishCard.wishCard, cardCollection)" *ngIf="wishCard.wishCard.mainType[0] === 'Instant' || wishCard.wishCard.mainType[0] === 'Sorcery'">
              <div innerHTML="<i class='ss ss-{{wishCard.wishCard.edition | lowercase}} ss-{{wishCard.wishCard.rarity | lowercase}} ss-grad ss-2x ss-fw'></i> &nbsp {{wishCard.wishCard.manaCost | toManaIcon}} "></div>
              <p>{{wishCard.wishCard[name_lang] !== '' ? wishCard.wishCard[name_lang] : wishCard.wishCard.name}}</p>
              <span><img src={{wishCard.wishCard[imagePath_lang]}} alt="image" height="279"/></span>
              <p *ngIf="wishCard.wishCard.mainType[0] === 'Instant'">
                {{ 'Instant' | translate}}
              </p>
              <p *ngIf="wishCard.wishCard.mainType[0] === 'Sorcery'">
                {{ 'Sorcery' | translate}}
              </p>
            </td>
            <td class="tdPrice" *ngIf="wishCard.wishCard.mainType[0] === 'Instant' || wishCard.wishCard.mainType[0] === 'Sorcery'">
              ${{wishCard.priceTotal | number : '1.2-2'}}
            </td>
          </tr>
          <!-- Other -->
          <tr *ngIf= "(wishList | wishListFilter:'Other':triggerPipeCounter)?.length > 0">
              <th>{{ 'Other' | translate }}</th>
          </tr>
          <tr *ngFor="let wishCard of wishList; let i = index" class="notfirst">
            <td class="tdAmount" *ngIf="wishCard.wishCard.mainType[0] === 'Artifact' || wishCard.wishCard.mainType[0] === 'Enchantment' || wishCard.wishCard.mainType[0] === 'Planeswalker'">
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'+')">
                  <ion-icon name='arrow-up'></ion-icon>
                </ion-button>
              </div>
              <div>
                <ion-button size="small" expand="block" color="light">
                    <p>{{wishCard.amount}}</p>
                </ion-button>
              </div>
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'-')">
                  <ion-icon name='arrow-down'></ion-icon>
                </ion-button>
              </div>
            </td>
            <td class="tdCard" (click)="openModal(wishCard.wishCard, cardCollection)" *ngIf="wishCard.wishCard.mainType[0] === 'Artifact' || wishCard.wishCard.mainType[0] === 'Enchantment' || wishCard.wishCard.mainType[0] === 'Planeswalker'">
              <div innerHTML="<i class='ss ss-{{wishCard.wishCard.edition | lowercase}} ss-{{wishCard.wishCard.rarity | lowercase}} ss-grad ss-2x ss-fw'></i> &nbsp {{wishCard.wishCard.manaCost | toManaIcon}} "></div>
              <p>{{wishCard.wishCard[name_lang] !== '' ? wishCard.wishCard[name_lang] : wishCard.wishCard.name}}</p>
              <span><img src={{wishCard.wishCard[imagePath_lang]}} alt="image" height="279"/></span>
              <p *ngIf="wishCard.wishCard.mainType[0] === 'Planeswalker'">
                {{ 'Planeswalker' | translate}} [{{wishCard.wishCard.loyalty}}]
              </p>
              <p *ngIf="wishCard.wishCard.mainType[0] === 'Artifact'">
                {{ 'Artifact' | translate}}
              </p>
              <p *ngIf="wishCard.wishCard.mainType[0] === 'Enchantment'">
                {{ 'Enchantment' | translate}}
              </p>
            </td>
            <td class="tdPrice" *ngIf="wishCard.wishCard.mainType[0] === 'Artifact' || wishCard.wishCard.mainType[0] === 'Enchantment' || wishCard.wishCard.mainType[0] === 'Planeswalker'">
              ${{wishCard.priceTotal | number : '1.2-2'}}
            </td>
          </tr>
          <!-- Lands -->
          <tr *ngIf= "(wishList | wishListFilter:'Land':triggerPipeCounter)?.length > 0">
              <th>{{ 'Lands' | translate }}</th>
          </tr>
          <tr *ngFor="let wishCard of wishList ; let i = index" class="notfirst">
            <td class="tdAmount" *ngIf="wishCard.wishCard.mainType[0] === 'Land'">
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'+')">
                  <ion-icon name='arrow-up'></ion-icon>
                </ion-button>
              </div>
              <div>
                <ion-button size="small" expand="block" color="light">
                    <p>{{wishCard.amount}}</p>
                </ion-button>
              </div>
              <div>
                <ion-button class="buttonGlowRed" size="small" fill="clear" expand="block" color="medium" icon-only (click)="wishListAmountUpdate(i,'-')">
                  <ion-icon name='arrow-down'></ion-icon>
                </ion-button>
              </div>
            </td>
            <td class="tdCard" (click)="openModal(wishCard.wishCard, cardCollection)" *ngIf="wishCard.wishCard.mainType[0] === 'Land'">
              <div innerHTML="<i class='ss ss-{{wishCard.wishCard.edition | lowercase}} ss-{{wishCard.wishCard.rarity | lowercase}} ss-grad ss-2x ss-fw'></i> &nbsp {{wishCard.wishCard.manaCost | toManaIcon}} "></div>
              <p>{{wishCard.wishCard[name_lang] !== '' ? wishCard.wishCard[name_lang] : wishCard.wishCard.name}}</p>
              <span><img src={{wishCard.wishCard[imagePath_lang]}} alt="image" height="279" z-index="12"/></span>
              <p>
                  {{wishCard.wishCard[cardType_lang]}}
              </p>
            </td>
            <td class="tdPrice" *ngIf="wishCard.wishCard.mainType[0] === 'Land'">
              ${{wishCard.priceTotal | number : '1.2-2'}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
              <td id="tfAmount" class="wishListTotals">
                  # {{ wishListAmountTotal | number : '1.0' }}
              </td>
              <td id="tfCard" class="wishListTotals">
                    
              </td>
              <td id="tfPrice" class="wishListTotals">
                  ${{ wishListPriceTotal | number : '1.2-2' }}
              </td>
          </tr>
        </tfoot>
      </table>
      <!-- Export buttons -->
      <ion-grid class="wishlist-grid">
        <ion-row>
            <ion-col>
              <ion-button size="medium" fill="solid" expand="block" color="danger" icon-only (click)="emptyWishlist()">
                <ion-icon name="trash"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col>
              <a href="#dec" target="_blank" id="saveAsDec" rel="download" download="Atlas_wishlist.dec" class="advancedSelectionsBtn wishlist-button">
                <label title="Export as .dec">
                  <div>
                    <ion-icon name="download"></ion-icon>
                  </div>DEC
                </label>
              </a>
            </ion-col>
            <ion-col>
              <a href="#MWD" target="_blank" id="saveAsMWD" rel="download" download="Atlas_wishlist.mwDeck" class="advancedSelectionsBtn wishlist-button">
                <label title="Export as .mwDeck">
                  <div>
                    <ion-icon name="download"></ion-icon>
                  </div>MWDECK
                </label>
              </a>
            </ion-col>
            <ion-col>
              <ion-button href="#TCG" target="_blank" id="shopTCG" rel="external" size="medium" fill="solid" expand="block" color="success" icon-only>
                <ion-icon name="cart"></ion-icon>
              </ion-button>
              <!--a href="#TCG" target="_blank" id="shopTCG" class="wishlist-button SHOP" color="positive" icon-only>
                <label title="Go to Shop">
                  <div>
                    <ion-icon name="cart"></ion-icon>
                  </div>
                </label>
              </a-->
            </ion-col>
        </ion-row>
      </ion-grid>
      <br>
      <!--div class="wishlist-shops">
        <p>{{ 'wishlistPricing' | translate }}</p> 
        <button ion-button small color="light" (click)="changeShop('TCGPlayer')">
            <label>TCGPlayer</label>
        </button><br>
        <button ion-button small color="light" (click)="changeShop('Card Kingdom')">
            <label>Card Kingdom</label>
        </button><br>
        <button ion-button small color="light" (click)="changeShop('MagicCardMarket')">
            <label>MagicCardMarket</label>
        </button><br>
        <button ion-button small color="light" (click)="changeShop('MTG Goldfish')">
            <label>MTG Goldfish</label>
        </button><br>
      </div-->
    </form>
  </div>
</div>