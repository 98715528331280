
import { Pipe, PipeTransform } from '@angular/core';

// This pipe is intended to transform the incoming string
// to an icon class supported by https://andrewgioia.github.io/Mana/cheatsheet.html

// It should return a string

@Pipe({
    name: 'toManaIcon',
    pure: true
})
export class ToManaIconPipe implements PipeTransform {
  transform(ManaCost: string): string {
    if (!ManaCost) {
      // If either input is missing, return an empty string
        return '';
    }

    // console.log('toManaIcon updated');
    let result = '';

    /* tslint:disable:qoutemark */

    // If there is a manaCost
    if (ManaCost.match(/\{/g) != null) {
        result = ManaCost // Set the result
        .replace(/\{2\/W\}/g, "<i class='ms ms-2w ms-cost ms-split'></i>") // Replace all 2/? costs
        .replace(/\{2\/U\}/g, "<i class='ms ms-2u ms-cost ms-split'></i>") // Replace all 2/? costs
        .replace(/\{2\/B\}/g, "<i class='ms ms-2b ms-cost ms-split'></i>") // Replace all 2/? costs
        .replace(/\{2\/R\}/g, "<i class='ms ms-2r ms-cost ms-split'></i>") // Replace all 2/? costs
        .replace(/\{2\/G\}/g, "<i class='ms ms-2g ms-cost ms-split'></i>") // Replace all 2/? costs
        .replace(/\{W\/U\}/g, "<i class='ms ms-wu ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{W\/B\}/g, "<i class='ms ms-wb ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{U\/B\}/g, "<i class='ms ms-ub ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{U\/R\}/g, "<i class='ms ms-ur ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{B\/R\}/g, "<i class='ms ms-br ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{B\/G\}/g, "<i class='ms ms-bg ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{R\/W\}/g, "<i class='ms ms-rw ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{R\/G\}/g, "<i class='ms ms-rg ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{G\/W\}/g, "<i class='ms ms-gw ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{G\/U\}/g, "<i class='ms ms-gu ms-cost ms-split'></i>") // Replace all hybrid costs
        .replace(/\{W\/P\}/g, "<i class='ms ms-wp ms-cost'></i>") // Replace all phyrexian costs
        .replace(/\{U\/P\}/g, "<i class='ms ms-up ms-cost'></i>") // Replace all phyrexian costs
        .replace(/\{B\/P\}/g, "<i class='ms ms-bp ms-cost'></i>") // Replace all phyrexian costs
        .replace(/\{R\/P\}/g, "<i class='ms ms-rp ms-cost'></i>") // Replace all phyrexian costs
        .replace(/\{G\/P\}/g, "<i class='ms ms-gp ms-cost'></i>") // Replace all phyrexian costs
        .replace(/\{16\}/g, "<i class='ms ms-16 ms-cost'></i>") // Replace all costs of 16
        .replace(/\{15\}/g, "<i class='ms ms-15 ms-cost'></i>") // Replace all costs of 15
        .replace(/\{14\}/g, "<i class='ms ms-14 ms-cost'></i>") // Replace all costs of 14
        .replace(/\{13\}/g, "<i class='ms ms-13 ms-cost'></i>") // Replace all costs of 13
        .replace(/\{12\}/g, "<i class='ms ms-12 ms-cost'></i>") // Replace all costs of 12
        .replace(/\{11\}/g, "<i class='ms ms-11 ms-cost'></i>") // Replace all costs of 11
        .replace(/\{10\}/g, "<i class='ms ms-10 ms-cost'></i>") // Replace all costs of 10
        .replace(/\{9\}/g, "<i class='ms ms-9 ms-cost'></i>") // Replace all costs of 9
        .replace(/\{8\}/g, "<i class='ms ms-8 ms-cost'></i>") // Replace all costs of 8
        .replace(/\{7\}/g, "<i class='ms ms-7 ms-cost'></i>") // Replace all costs of 7
        .replace(/\{6\}/g, "<i class='ms ms-6 ms-cost'></i>") // Replace all costs of 6
        .replace(/\{5\}/g, "<i class='ms ms-5 ms-cost'></i>") // Replace all costs of 5
        .replace(/\{4\}/g, "<i class='ms ms-4 ms-cost'></i>") // Replace all costs of 4
        .replace(/\{3\}/g, "<i class='ms ms-3 ms-cost'></i>") // Replace all costs of 3
        .replace(/\{2\}/g, "<i class='ms ms-2 ms-cost'></i>") // Replace all costs of 2
        .replace(/\{1\}/g, "<i class='ms ms-1 ms-cost'></i>") // Replace all costs of 1
        .replace(/\{0\}/g, "<i class='ms ms-0 ms-cost'></i>") // Replace all costs of 1
        .replace(/\{W\}/g, "<i class='ms ms-w ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{U\}/g, "<i class='ms ms-u ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{B\}/g, "<i class='ms ms-b ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{R\}/g, "<i class='ms ms-r ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{G\}/g, "<i class='ms ms-g ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{S\}/g, "<i class='ms ms-s ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{P\}/g, "<i class='ms ms-p ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{C\}/g, "<i class='ms ms-c ms-cost'></i>") // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        .replace(/\{X\}/g, "<i class='ms ms-x ms-cost'></i>"); // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian)
        }
        /* tslint:enable:qoutemark */
    return result;
  }
}
