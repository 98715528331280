import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

// Pipes & Filters (declarations)
import { AbilityFilterPipe } from './pipes/filters/ability-filter-pipe.pipe';
import { BasketFilterPipe } from './pipes/filters/basket-filter-pipe.pipe';
import { CardSearchPipe } from './pipes/functions/card-search-pipe.pipe';
import { CMCFilterPipe } from './pipes/filters/cmc-filter-pipe.pipe';
import { EditionFilterPipe } from './pipes/filters/edition-filter-pipe.pipe';
import { EditionSelectionFilterPipe } from './pipes/filters/edition-selection-filter-pipe.pipe';
import { FormatFilterPipe } from './pipes/filters/format-filter-pipe.pipe';
import { GetCardByNamePipe } from './pipes/functions/get-card-by-name-pipe.pipe';
import { GetCardByUUIDPipe } from './pipes/functions/get-card-by-uuid-pipe.pipe';
import { GetSimilarCardsPipe } from './pipes/functions/get-similar-cards-pipe.pipe';
import { LoyaltyFilterPipe } from './pipes/filters/loyalty-filter-pipe.pipe';
import { MainTypeFilterPipe } from './pipes/filters/main-type-filter-pipe.pipe';
import { PermanentFilterPipe } from './pipes/filters/permanent-filter-pipe.pipe';
import { PowerFilterPipe } from './pipes/filters/power-filter-pipe.pipe';
import { RarityFilterPipe } from './pipes/filters/rarity-filter-pipe.pipe';
import { ShowAbilityFilterPipe } from './pipes/filters/show-ability-filter-pipe.pipe';
import { ShowImageByNamePipe } from './pipes/functions/show-image-by-name-pipe.pipe';
import { ToManaIconPipe } from './pipes/functions/to-mana-icon-pipe.pipe';
import { ToughnessFilterPipe } from './pipes/filters/toughness-filter-pipe.pipe';
import { WishListFilterPipe } from './pipes/filters/wish-list-filter-pipe.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
    ],
    declarations: [
        AbilityFilterPipe,
        BasketFilterPipe,
        CardSearchPipe,
        CMCFilterPipe,
        EditionFilterPipe,
        EditionSelectionFilterPipe,
        FormatFilterPipe,
        GetCardByNamePipe,
        GetCardByUUIDPipe,
        GetSimilarCardsPipe,
        LoyaltyFilterPipe,
        MainTypeFilterPipe,
        PermanentFilterPipe,
        PowerFilterPipe,
        RarityFilterPipe,
        ShowAbilityFilterPipe,
        ShowImageByNamePipe,
        ToManaIconPipe,
        ToughnessFilterPipe,
        WishListFilterPipe
    ],
    providers: [
        FormatFilterPipe,
        EditionFilterPipe,
        EditionSelectionFilterPipe,
        ShowImageByNamePipe,
        GetCardByNamePipe,
        GetCardByUUIDPipe,
        GetSimilarCardsPipe,
        BasketFilterPipe
    ],
    exports: [
        AbilityFilterPipe,
        BasketFilterPipe,
        CardSearchPipe,
        CMCFilterPipe,
        EditionFilterPipe,
        EditionSelectionFilterPipe,
        FormatFilterPipe,
        GetCardByNamePipe,
        GetCardByUUIDPipe,
        GetSimilarCardsPipe,
        LoyaltyFilterPipe,
        MainTypeFilterPipe,
        PermanentFilterPipe,
        PowerFilterPipe,
        RarityFilterPipe,
        ShowAbilityFilterPipe,
        ShowImageByNamePipe,
        ToManaIconPipe,
        ToughnessFilterPipe,
        WishListFilterPipe
    ]
})
export class ComponentsModule {}
