import { MtgCard } from '../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getCardByUUID',
    pure: true
})
export class GetCardByUUIDPipe implements PipeTransform {
  transform(items: MtgCard[], uuid: string): MtgCard {
    if (!items || !uuid) {
        // If either input is null, return nothing
        return null;
    }

    // Loop through the cardcollection
    // Find the first card with the matching UUID
    for (let i = 0, len = items.length; i < len; i++) {
        if (items[i].uuid === uuid) {
            return items[i];
        }
    }
  }
}
