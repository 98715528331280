import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an OR-pipe,
// which means that any filters coming in will generate total sum of all cards in the selection(s)
// This is intended for everyhing that is not card abilities, prefixed with "_"

@Pipe({
    name: 'permanentFilter',
    pure: true
})
export class PermanentFilterPipe implements PipeTransform {
  transform(items: MtgCard[], filter: MtgCard, counter?: number): MtgCard[] {
    if (!items || !filter.permanent) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    // console.log('permanentFilter updated');
    return items.filter((item: MtgCard) => this.applyFilter(item, filter));
  }

  /**
   * Perform the filtering.
   *
   * @param {Card} card The card to compare to the filter.
   * @param {Card} filter The filter to apply.
   * @return {boolean} True if card satisfies filters, false if not.
   */
  applyFilter(card: MtgCard, filter: MtgCard): boolean {
      if (filter['permanent']) { // Apply filter only to c_filter.permanent
          if (filter['permanent'].toLowerCase().indexOf(card.permanent.toLowerCase()) === -1) {
            // If the cards permanent name is not found in the filters permanent sting, return false
            return false;
          }
        return true;
        }
    }
}
