import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  HostListener,
  Renderer2,
  ViewChild,
  ElementRef,
  Injectable,
  Inject } from '@angular/core';

// Import ionic native like functionality
import { Platform, ToastController, ModalController, DomController } from '@ionic/angular';

// Import modalController response interface
import { OverlayEventDetail } from '@ionic/core/components';
// Import interface
import { MtgCard } from './../../models/mtgcard.model';
// Import interface
import { Wishlist } from './../../models/wishlist.model';

// Import web worker script
import { LOAD_COLLECTION } from './../../services/web-worker.script';
// Import web worker service
import { WebworkerService } from './../../services/web-worker.service';

// Translation Service
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
// Provider of TCG Player price info
import { TcgPlayerService } from './../../services/tcg-player.service';
// Provider of MTGJson Data
import { MtgDataService } from './../../services/mtg-data.service';
// Service for collection sorting
import { CollectionSortService } from './../../services/collection-sort.service';
// Service for wishlist handling
import { WishlistHandlerService } from './../../services/wishlist-handler.service';
// Service for storage handling
import { LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
// Service for ionic storage handling
import { Storage } from '@ionic/storage';

// Import some pipes to be able to create predefined subsets of the cardCollection
import { FormatFilterPipe } from './../../components/pipes/filters/format-filter-pipe.pipe';
import { EditionFilterPipe } from './../../components/pipes/filters/edition-filter-pipe.pipe';
import { EditionSelectionFilterPipe } from './../../components/pipes/filters/edition-selection-filter-pipe.pipe';

// Import the modal page
import { CardModalPage } from './../card-modal/card-modal.page';
//import { stringify } from '@angular/compiler/src/util';

// Import the introduction JS
import * as IntroJs from 'intro.js/intro.js';

// Import the DragScroll Component to support altering object state
import { DragScrollComponent } from 'ngx-drag-scroll';


@Injectable()
@Component({
  selector: 'app-page-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePage implements OnInit {

  // Set DEV-mode:
  // true means data is fetched, parsed, and available for download once a format is selected
  // false means data is fetched locally through subscription, already transformed
  public devMode = false;
  // Set version:
  // this is used to determine the filename for cardCollection_[version].json and cardCollection_standard_[version].json
  // to be able to allow a long expiration date for cache headers
  // increment this every time a new cardCollection.json is to be saved
  private version = 1; // ONLY CHANGE WHEN devMode = true

  // Creating a card filter with the same fields as a MtgCard interface
  c_filter: MtgCard = {
    // Unique card id
    uuid: '',
    // External store properties
    tcg_productId: '',
    tcg_url: '',
    // Edition properties
    releaseDate: '',
    edition: '',
    editionName: '',
    editionType: '',
    format: [],
    // Required properties
    name: '',
    names: [],
    imagePath: '',
    rarity: '',
    cmc: '',
    manaCost: '',
    permanent: '',
    subColor: [],
    mainType: [],
    printings: [],
    // orderedPrintings: [],
    // Optional properties
    rulings: [],
    cardType: '',
    superType: [],
    subType: [],
    power: '',
    toughness: '',
    pt: '',
    loyalty: '',
    // text: '',
    cleantext: '',
    value: '',
    price: 0,
    // All yes/no fields will be preceded with an underscore for visibility
    _isOnWishList: null,
    // Legality
    _standard_banned: null,
    _standard_restricted: null,
    _standard_legal: null,
    _modern_banned: null,
    _modern_restricted: null,
    _modern_legal: null,
    _commander_banned: null,
    _commander_restricted: null,
    _commander_legal: null,
    _legacy_banned: null,
    _legacy_restricted: null,
    _legacy_legal: null,
    _vintage_banned: null,
    _vintage_restricted: null,
    _vintage_legal: null,
    // Print
    _firstPrint: null,
    _lastPrint: null,
    _basicLand: null,
    // Mana relation
    _subColorBlack: null,
    _subColorBlue: null,
    _subColorGreen: null,
    _subColorRed: null,
    _subColorWhite: null,
    _hasXCost: null,
    _hasHCost: null,
    _hasPCost: null,
    // Keywords: Evergreen
    _deathtouch: null,
    _defender: null,
    _double_strike: null,
    _enchant: null,
    _equip: null,
    _first_strike: null,
    _flash: null,
    _flying: null,
    _haste: null,
    _hexproof: null,
    _indestructible: null,
    _lifelink: null,
    _menace: null,
    _prowess: null,
    _reach: null,
    _trample: null,
    _vigilance: null,
    _ward: null,
    // Keywords: Other
    _absorb: null,
    _affinity: null,
    _afflict: null,
    _afterlife: null,
    _aftermath: null,
    _amass: null,
    _amplify: null,
    _annihilator: null,
    _ascend: null,
    _aura_swap: null,
    _awaken: null,
    _banding: null,
    _battle_cry: null,
    _bestow: null,
    _bloodthirst: null,
    _boast: null,
    _bushido: null,
    _buyback: null,
    _cascade: null,
    _champion: null,
    _changeling: null,
    _cipher: null,
    _companion: null,
    _conspire: null,
    _convoke: null,
    _crew: null,
    _cumulative_upkeep: null,
    _cycling: null,
    _dash: null,
    _daybound: null,
    _decayed: null,
    _delve: null,
    _dethrone: null,
    _devoid: null,
    _devour: null,
    _disturb: null,
    _dredge: null,
    _echo: null,
    _embalm: null,
    _emerge: null,
    _entwine: null,
    _epic: null,
    _escalate: null,
    _escape: null,
    _eternalize: null,
    _evoke: null,
    _evolve: null,
    _exalted: null,
    _exploit: null,
    _extort: null,
    _fabricate: null,
    _fading: null,
    _fear: null,
    _flanking: null,
    _flashback: null,
    _forecast: null,
    _foretell: null,
    _fortify: null,
    _frenzy: null,
    _fuse: null,
    _graft: null,
    _gravestorm: null,
    _haunt: null,
    _hideaway: null,
    _horsemanship: null,
    _improvise: null,
    _infect: null,
    _ingest: null,
    _intimidate: null,
    _jump_start: null,
    _kicker: null,
    _landwalk: null,
    _level_up: null,
    _living_weapon: null,
    _madness: null,
    _melee: null,
    _mentor: null,
    _miracle: null,
    _modular: null,
    _morph: null,
    _mutate: null,
    _myriad: null,
    _nightbound: null,
    _ninjutsu: null,
    _offering: null,
    _outlast: null,
    _overload: null,
    _partner: null,
    _persist: null,
    _phasing: null,
    _poisonous: null,
    _protection: null,
    _provoke: null,
    _prowl: null,
    _rampage: null,
    _rebound: null,
    _recover: null,
    _reinforce: null,
    _renown: null,
    _replicate: null,
    _retrace: null,
    _riot: null,
    _ripple: null,
    _scavenge: null,
    _shadow: null,
    _shroud: null,
    _skulk: null,
    _soulbond: null,
    _soulshift: null,
    _spectacle: null,
    _splice: null,
    _split_second: null,
    _storm: null,
    _sunburst: null,
    _surge: null,
    _suspend: null,
    _totem_armor: null,
    _transfigure: null,
    _transmute: null,
    _tribute: null,
    _undaunted: null,
    _undying: null,
    _unearth: null,
    _unleash: null,
    _vanishing: null,
    _wither: null,
    // Actions: Evergreen
    _activate: null,
    _attach: null,
    _cast: null,
    _counter: null,
    _copy: null,
    _create: null,
    _cycle: null,
    _destroy: null,
    _discard: null,
    _double: null,
    _draw: null,
    _exchange: null,
    _exile: null,
    _fight: null,
    _mill: null,
    _play: null,
    _reveal: null,
    _sacrifice: null,
    _scry: null,
    _search: null,
    _shuffle: null,
    _tap: null,
    _untap: null,
    // Actions: Other
    _adapt: null,
    _bolster: null,
    _clash: null,
    _detain: null,
    _exert: null,
    _explore: null,
    _fateseal: null,
    _goad: null,
    _investigate: null,
    _learn: null,
    _manifest: null,
    _meld: null,
    _monstrosity: null,
    _populate: null,
    _proliferate: null,
    _regenerate: null,
    _roll: null,
    _support: null,
    _surveil: null,
    _transform: null,
    _venture: null,
    _vote: null,
    // Ability words
    _adamant: null,
    _addendum: null,
    _battalion: null,
    _bloodrush: null,
    _channel: null,
    _chroma: null,
    _cohort: null,
    _constellation: null,
    _converge: null,
    _coven: null,
    _delirium: null,
    _domain: null,
    _eminence: null,
    _enrage: null,
    _fateful_hour: null,
    _ferocious: null,
    _formidable: null,
    _grandeur: null,
    _hellbent: null,
    _heroic: null,
    _imprint: null,
    _inspired: null,
    _join_forces: null,
    _kinship: null,
    _landfall: null,
    _lieutenant: null,
    _magecraft: null,
    _metalcraft: null,
    _morbid: null,
    _pack_tactics: null,
    _radiance: null,
    _raid: null,
    _rally: null,
    _revolt: null,
    _spell_mastery: null,
    _strive: null,
    _sweep: null,
    _tempting_offer: null,
    _threshold: null,
    _undergrowth: null,
    // Misc mechanics
    _abyss: null,
    _alt_win: null,
    _alt_loss: null,
    _animate: null,
    _anti_discard: null,
    _anti_flying: null,
    _artifactize: null,
    _artifact_destruction: null,
    _assistance: null,
    _attack_alone: null,
    _attack_together: null,
    _block_alone: null,
    _block_together: null,
    _board_wipe: null,
    _bounce: null,
    _burn: null,
    _cantrip: null,
    _charm: null,
    _color_changer: null,
    _counterspell: null,
    _curiosity: null,
    _daunt: null,
    _devotion: null,
    _dexterity_card: null,
    _dies: null,
    _divvy: null,
    _en_kor_ability: null,
    _enter_the_battlefield: null,
    _firebreathing: null,
    _flickering: null,
    _free_spells: null,
    _gating: null,
    _growing_enchantments: null,
    _high_flying: null,
    _hoser: null,
    _impulse: null,
    _lace: null,
    _land_changer: null,
    _land_destruction: null,
    _lobotomy: null,
    _loners: null,
    _looting: null,
    _lure: null,
    _mana_stone: null,
    _modal_spell: null,
    _nettling: null,
    _one_sided_fight: null,
    _panic: null,
    _ping: null,
    _pitch_spell: null,
    _pt_switcher: null,
    _punisher: null,
    _quest: null,
    _reanimation: null,
    _redirection: null,
    _removal: null,
    _rhystic: null,
    _rule_setting: null,
    _rummaging: null,
    _ruthless: null,
    _saboteur: null,
    _self_mill: null,
    _skulking: null,
    _sleeping_enchantment: null,
    _stalking: null,
    _stat_increase: null,
    _stat_reduce: null,
    _stealing: null,
    _super_trample: null,
    _taxing: null,
    _tucking: null,
    _tutor: null,
    _unblockable: null,
    _uncounterable: null,
    _untrample: null,
    _venom: null,
    _wheel_effect: null,
    _wisdom: null,
    _wrath: null,
    // Terminology
    _activated_ability: null,
    _activation_cmc0: null,
    _activation_cmc1: null,
    _activation_cmc2: null,
    _activation_cmc3: null,
    _activation_cmc4: null,
    _activation_cmc5: null,
    _activation_cmc6: null,
    _activation_cmc7: null,
    _activation_cmc8_plus: null,
    _casting_ability: null,
    _passive_ability: null,
    _triggered_ability: null,
    _require_tap: null,
    _require_untap: null,
    _give_colorless: null,
    _require_colorless: null,
    _give_mana: null,
    _require_mana: null,
    _energy: null,
    _counters: null,
    _plus1_counters: null,
    _minus1_counters: null,
    _mass_removal: null,
    _spot_removal: null,
    _target_artifact: null,
    _target_creature: null,
    _target_card: null,
    _target_enchantment: null,
    _target_land: null,
    _target_permanent: null,
    _target_planeswalker: null,
    _target_player: null,
    _1_removal: null,
    _2_removal: null,
    _3_removal: null,
    _4_removal: null,
    _5_removal: null,
    _until_eot: null,
    _until_condition: null,
    // Identity: Evergreen
    _layout_Normal: null,
    _layout_Split: null,
    _layout_Flip: null,
    _layout_DoubleFaced: null,
    _ci_white: null,
    _ci_blue: null,
    _ci_black: null,
    _ci_red: null,
    _ci_green: null,
    // Identity: Other
    _historic: null,
    _saga: null,
    _equipment: null,
    _vehicle: null,
    _aura: null,
    _commander: null,
    _legendary: null,
    _tribal: null,
    _snow: null,
    _world: null
  };

  // Initiate the variable that controls showing off loading spinner
  public isLoading: boolean;
  // Initiate the variable showing the loading progress bar
  public isLoadingPercent = 100;
  // Variable telling if the full cardCollection.json has been loaded yet or not
  private isFullyLoaded = false;

  // Initiate the cardCollection
  private cardCollection: MtgCard[];
  private formattedCardCollection: MtgCard[];


  // Initiate the introduction
  private introduction = IntroJs();

  // Initiate default introduction message strings
  private intro1_pre = 'First time here?';
  private intro1_post = 'Results: Browse cards by scrolling through the images. <br>' +
  'Search: Freeform text search.<br>' +
  'Explore: Explore cards by type (1st row), rarity (2nd row), color (3rd row) or cost (4th row). <br>' +
  'Selections: Any choices you make in "Explore" will be visible here. Click any badge to undo that selection.';
  private intro2_pre = 'Making selections';
  private intro2_post = 'Selections in a row means "OR" between those selections. <br>' +
  'Selections made over different rows means "AND" for those selections. <br>' +
  'The example above means "Planeswalker" AND (Loyalty: 4 OR Loyalty: 5) AND' +
  ' (any rarity) AND (White OR Black OR Green) AND (any cost). <br>' +
  'Check if the results match the selections!';
  private intro3_pre = 'Exploring for creatures';
  private intro3_post = 'Clicking card type "Creature" enables drill-down selections on "Power" and "Toughness". <br>' +
  'Clicking color "Multicolor" enables selections on what colors to include/exclude. <br>' +
  'I am looking for a creature in standard. 2/3 or 3/2 maybe? I think it was blue and green. <br>' +
  '9 possibilities.. and there it is! Saving it to the wishlist!';
  private intro4_pre = 'Using your wishlist';
  private intro4_post = 'Hover an item to view its image. Click the item to go directly to its card details (see next slide).';
  private intro5_pre = 'Card details';
  private intro5_post = 'Click any card for specific information related to that card.';

  // Iniate the promise from the web worker
  private webWorker_promise: Promise<any>;

  // Initiate the basketAnalysis
  public basketAnalysis;

  // Initiate the wishList as empty
  public wishList: Wishlist[] = [];

  // Initiating wishList sum row values
  public wishListAmountTotal = 0;
  public wishListPriceTotal = 0;
  public tableIsDesc = false;

  // Determine wether the wishList is shown or not
  public wishListOpen = false;

  // Determine wether the advanced selections are shown or not
  public advancedSelectionsShown = false;

  // Initiate the variable to hold viewport details based on platform
  public platformWidth: number;
  // Intended to determine if the page is shown on a mobile or not
  public isMobile = false;

  // Initiate the models for lazy loading in chunks
  private limit: number; // based on the viewport width / cardwidth
  private page = 1;
  private lastScrollY = 0; // Define where scrolling begins when the page is loaded

  // Initiate the DragScrollComponent used in imageScrollX for later manipulation
  @ViewChild('imageDragScrollX', { read: DragScrollComponent, static: false })
  public imageDragScrollX: DragScrollComponent;

  // Initiate the variable for what view to show in the imageScroll
  private scrollView = 'cardImage';

  // Determine if the imageScroll is being scrolled/dragged or not
  public isDragged = false;

  // Determine if the format selection is enabled or not
  public disableFormatSelection = true;

  // Initiate the models for format, and edition selections
  private formatSelection: string[];
  private editionSelection: string[];
  private format_filter: string;
  private edition_filter: string[] = [];

  // Sort By selections
  private sortOptions: string[] = ['CMC', 'Color', 'Edition', 'Name', 'Power', 'Rarity', 'Toughness', 'Type'];
  private sortBy_1: string;
  private sortBy_2: string;
  private sortBy_3: string;
  private sortOrder_1 = String.fromCharCode(9660);
  private sortOrder_2 = String.fromCharCode(9660);
  private sortOrder_3 = String.fromCharCode(9660);

  // For use in the sortOrder popover
  public sortOrderPopoverOptions: any = {
    header: '',
    subHeader: '',
    message: ''
  };

  // Variable for showing the searchbars to avoid android mobile bug
  private showSearch = false;
  // Search variables
  public cardNameSearch = '';
  public cardTextSearch = '';
  public cardTypeSearch = '';

  // GUI checkbox states
  // Permanent
  private White = false;
  private Blue = false;
  private Green = false;
  private Red = false;
  private Black = false;
  private Colorless = false;
  private Multicolor = false;
  // MainType
  private Land = false;
  private Artifact = false;
  private ArtifactCreature = false;
  private Creature = false;
  private Instant = false;
  private Sorcery = false;
  private Enchantment = false;
  private Planeswalker = false;
  // Rarity
  private Common = false;
  private Uncommon = false;
  private Rare = false;
  private Mythic = false;
  // CMC
  private CMC0 = false;
  private CMC1 = false;
  private CMC2 = false;
  private CMC3 = false;
  private CMC4 = false;
  private CMC5 = false;
  private CMC6 = false;
  private CMC7 = false;
  private CMC8 = false;
  // Power
  private Power0 = false;
  private Power1 = false;
  private Power2 = false;
  private Power3 = false;
  private Power4 = false;
  private Power5 = false;
  private Power6 = false;
  private Power7 = false;
  private Power8 = false;
  private PowerX = false;
  // Toughness
  private Toughness0 = false;
  private Toughness1 = false;
  private Toughness2 = false;
  private Toughness3 = false;
  private Toughness4 = false;
  private Toughness5 = false;
  private Toughness6 = false;
  private Toughness7 = false;
  private Toughness8 = false;
  private ToughnessX = false;
  // Loyalty
  private Loyalty1 = false;
  private Loyalty2 = false;
  private Loyalty3 = false;
  private Loyalty4 = false;
  private Loyalty5 = false;
  private Loyalty6 = false;
  private Loyalty7 = false;
  private LoyaltyX = false;

  // Language
  public language: string;
  public name_lang: string;
  public cardType_lang: string;
  public cleantext_lang: string;
  public imagePath_lang: string;

  // Initiate the default segment selection for ngSwitch
  public cardAbility = 'ability';

  // Strings to use in HTML for choosing between false/null/true
  // https://dev.w3.org/html5/html-author/charref
  private false_lbl = String.fromCharCode(10006); // Cross
  private null_lbl = '?';
  private true_lbl = String.fromCharCode(10003); // Checkmark

  private asc_arrow = String.fromCharCode(9650); // UP indicator
  private desc_arrow = String.fromCharCode(9660); // DOWN indicator

  // Counter to increment to manually trigger the abilityPipe
  public triggerPipeCounter = 0;

  // Constructor is called first time before the ngOnInit()
  constructor(
    public webworkerService: WebworkerService,
    public TCGPlayer: TcgPlayerService,
    public mtgService: MtgDataService,
    public sortService: CollectionSortService,
    public wishlistService: WishlistHandlerService,
    //public introJS: IntroJs, --TODO--
    public ionicStorage: Storage,
    @Inject(LOCAL_STORAGE) public storageService: StorageService,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public domCtrl: DomController,
    public platform: Platform,
    public cdRef: ChangeDetectorRef,
    public renderer: Renderer2,
    public el: ElementRef,
    public formatFilterPipe: FormatFilterPipe,
    public editionFilterPipe: EditionFilterPipe,
    public editionSelectionFilterPipe: EditionSelectionFilterPipe) {

    // Enable spinner
    this.isLoading = true;

    // Set firstPrint filter off by default
    this.c_filter._firstPrint = null;
    // Set basicLand filter false by default
    this.c_filter._basicLand = false;

    // Set limit based on viewport width (room for how many cards to be viewed)
    this.platformWidth = this.platform.width();
    this.limit = Math.ceil(this.platform.width() / 200);

    // console.log('Platforms: ' + this.platform.platforms());
    // console.log('Limit: ' + this.limit);

    if ( this.platform.is('ios') || this.platform.is('android')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // To make sure the wishlist can be shown on hover for non-mobile platforms
    if (this.isMobile === false) {
      this.wishListOpen = true;
    }

    // The language defined here will be used as a fallback when no translation is found in the current language
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    // console.log(browserLang);
    const browserLangMain = browserLang.substring(0, 2);
    // console.log(browserLangMain);

    // Default values for language
    this.language = 'EN';
    this.translate.use('en');
    this.name_lang = 'name';
    this.cardType_lang = 'cardType';
    this.cleantext_lang = 'cleantext';
    this.imagePath_lang = 'imagePath';

    // Set language - using browser language if applicable, fallback is used when no translations are found there
    // Make sure 'en' is not in this array, as we do not want to append anything for 'en'
    if (['ch', 'de', 'es', 'fr', 'it', 'jp', 'ks', 'pt', 'ru'].indexOf(browserLangMain) !== -1) {
      this.language = browserLangMain.toUpperCase();
      this.translate.use(browserLangMain);
      this.name_lang = 'name_' + browserLangMain.toUpperCase();
      this.cardType_lang = 'cardType_' + browserLangMain.toUpperCase();
      this.cleantext_lang = 'cleantext_' + browserLangMain.toUpperCase();
      this.imagePath_lang = 'imagePath_' + browserLangMain.toUpperCase();
    }

    // Set introduction messages depending on language
    this.translate.get('intro1_pre').subscribe((result: string) => {
      this.intro1_pre = result;
    });
    this.translate.get('intro1_post').subscribe((result: string) => {
      this.intro1_post = result;
    });
    this.translate.get('intro2_pre').subscribe((result: string) => {
      this.intro2_pre = result;
    });
    this.translate.get('intro2_post').subscribe((result: string) => {
      this.intro2_post = result;
    });
    this.translate.get('intro3_pre').subscribe((result: string) => {
      this.intro3_pre = result;
    });
    this.translate.get('intro3_post').subscribe((result: string) => {
      this.intro3_post = result;
    });
    this.translate.get('intro4_pre').subscribe((result: string) => {
      this.intro4_pre = result;
    });
    this.translate.get('intro4_post').subscribe((result: string) => {
      this.intro4_post = result;
    });
    this.translate.get('intro5_pre').subscribe((result: string) => {
      this.intro5_pre = result;
    });
    this.translate.get('intro5_post').subscribe((result: string) => {
      this.intro5_post = result;
      // Not closing the subscribe yet on purpose, to load translations before setting up introduction options

      // Define what the introduction should look like
      
      this.introduction.setOptions({
        'nextLabel': String.fromCharCode(9655),
        'prevLabel': String.fromCharCode(9665),
        'skipLabel': String.fromCharCode(10006),
        'doneLabel': String.fromCharCode(10003),
        'hidePrev': true,
        'hideNext': true,
        'showStepNumbers': false,
        'tooltipClass': 'introCustom',
        steps: [
          {
            intro: '<b>' +
            this.intro1_pre +
            '</b><br>' +
            '<img src="../../../assets/gui/tour/' +
            this.language +
            '/1.png"/><br>' +
            this.intro1_post
          },
          {
            intro: '<b>' +
            this.intro2_pre +
            '</b> <br>' +
            '<img src="../../../assets/gui/tour/' +
            this.language +
            '/2.png"/><br>' +
            this.intro2_post
          },
          {
            intro: '<b>' +
            this.intro3_pre +
            '</b> <br>' +
            '<img src="../../../assets/gui/tour/' +
            this.language +
            '/3.png"/><br>' +
            this.intro3_post
          },
          {
            intro: '<b>' +
            this.intro4_pre +
            '</b> <br>' +
            '<img src="../../../assets/gui/tour/' +
            this.language +
            '/4.png"/><br>' +
            this.intro4_post
          },
          {
            intro: '<b>' +
            this.intro5_pre +
            '</b> <br>' +
            '<img src="../../../assets/gui/tour/' +
            this.language +
            '/5.png"/><br>' +
            this.intro5_post
          }
          // ,
          // {
          // intro: 'For more tutorials, please visit <a href="www.youtube.com"> AceCollection on Youtube'
          // }
        ]
      });
      
    // Closing the subscribe
    });
  }

  // ngOnInit is called after the constructor and called after the first ngOnChanges()
  ngOnInit() {
    // v- DEV MODE -v //
    if (this.devMode === true) {
      console.log('Cards in ViewPort: ' + this.limit);
      console.log('Platforms: ' + this.platform.platforms());

      // Disable the spinner when fetching data without an observable
      this.isLoading = false;

      // Allow for selections in format
      this.disableFormatSelection = false;

      // Set values for Format selections (for use in dev-mode)
      this.formatSelection = ['Standard', 'Modern', 'Legacy', 'Vintage', 'Commander'];

      // Initializing data from AllSets.json (LOCAL)
      // this.cardCollection = this.mtgService.getRemoteData('assets/data/AllSets.json');
      this.cardCollection = this.mtgService.getRemoteData('assets/data/AllPrintings.json');
      // this.cardCollection = this.mtgService.getRemoteData('assets/data/ONE.json');

      // Initializing data from AllSets.json (REMOTE)
      // this.cardCollection = this.mtgService.getRemoteData('http://mtgjson.com/json/AllSets.json');
      console.log(this.cardCollection);

      // Initializing data from basketAnalysis
      this.mtgService.getBasketAnalysis('assets/data/basket/basket.json')
      // .subscribe acts on the data as it becomes available
      .subscribe( analysis => {
        this.basketAnalysis = analysis;
        console.log(this.basketAnalysis);
      });

    } else { // ^- DEV MODE -^ //
      // Only trigger the webWorker for non-mobile environments
      if (this.isMobile === false) {
        // Check if indexedDB is up to date
        this.ionicStorage.get('cardCollection_' + this.version)
        .then((controlKey) => {
          // console.log(controlKey);
          // controlKey should be equal to the number of chunks for that version, i.e. 5
          if (controlKey === 5) {
            this.disableFormatSelection = false;
            // console.log('Controlkey correct, skipping webWorker');
          } else {
            // Show introduction
            this.introduction.start();

            // Run web worker script
            // console.log('webworker started loading version: ' + this.version);
            // Returns a promise, to evaluate later (in the formatFilterChange)
            // Use parameter JSON for promise of JSON file, and INDEXEDDB for storing results to IndexedDB in web worker
            this.webWorker_promise =
            // this.webworkerService.run(LOAD_COLLECTION, 'INDEXEDDB;http://localhost:8100/assets/data/transformed/cardCollection_'
            // + this.version + '.json' + ';' + this.version + '');
            this.webworkerService.run(LOAD_COLLECTION, 'INDEXEDDB;https://www.mtgatlas.com/assets/data/transformed/cardCollection_'
            + this.version + '.json' + ';' + this.version + '');

            // Evaluate the promise
            this.webWorker_promise
            .then((workerResponse) => {
              // console.log('webWorkerResponse: ' + workerResponse);
              if (workerResponse === true) {
                this.disableFormatSelection = false;
                // Mark the page for change detection
                this.cdRef.detectChanges();
                this.cdRef.markForCheck();
              }
            });
          }
        });
      }

      // Initializing data from transformed cardCollection
      this.mtgService.getLocalData('assets/data/transformed/cardCollection_standard_' + this.version + '.json')
      // .subscribe acts on the data as it becomes available
      .subscribe( data => {

        this.cardCollection = data;
        // console.log(this.cardCollection);

        // Restricting non-desktop to Standard because of resource constraints
        if (this.isMobile === false) {
          this.formatSelection = ['Standard', 'Modern', 'Legacy', 'Vintage', 'Commander'];
        } else { // For everything but desktop
          this.formatSelection = ['Standard'];
        }

        // Set the default selection in Format
        this.format_filter = 'Standard';
        // Update the local variables to match
        this.formattedCardCollection = this.formatFilterPipe.transform(this.cardCollection, this.format_filter);

        // Sort the data by default on Edition, Color, Name
        this.formattedCardCollection = this.sortService.sortCardCollection(
          this.formattedCardCollection,
          'Edition', // this.sortBy_1,
          String.fromCharCode(9650), // this.sortOrder_1,
          'Color', // this.sortBy_2,
          '', // this.sortOrder_2,
          'Name', // this.sortBy_3,
          '' // this.sortOrder_3);
        );

        // IF on desktop, make sure sort order selections are already filled in
        if (this.platformWidth > 1199) {
          this.sortBy_1 = 'Edition', // Oldest to newest edition
          this.sortOrder_1 = String.fromCharCode(9650), // Backward
          this.sortBy_2 = 'Color',
          this.sortOrder_2 =  String.fromCharCode(9660), // Forward
          this.sortBy_3 = 'Name',
          this.sortOrder_3 =  String.fromCharCode(9660); // Forward
        }

        // Grab editionSelections from the formatted card collection
        this.editionSelection = this.editionSelectionFilterPipe.transform(this.formattedCardCollection);

        // Disable loading spinner
        this.isLoading = false;

        // Fetch the wishList from localStorage if it exists, and is not empty
        if (this.storageService.has('wishlist') && this.storageService.get('wishlist').length >= 0) {
          this.wishList = this.storageService.get('wishlist');
          // Update the formattedCardCollection to match the _isOnWishList attributes
          this.formatWishlist();
          // Also update the wishlist .dec export link with the new card language
          if (this.wishList.length > 0) { // only if wishlist is not empty
            this.wishListExportUpdate();
          }
        }

        // Mark the page for change detection
        this.cdRef.detectChanges();
        this.cdRef.markForCheck();

        // Add event listners for imageScroll to prevent opening modals after dragging more than 25px
        // MUST be within the subscribe for it to work properly
        const _this = this;
        let startingPos = [0, 0];

         // Only add these event listeners when imageScrollX is present, i.e. platformwidth<1200
        if (this.platformWidth < 1200) {
          document.getElementById('imageScrollX').addEventListener('mousedown', function(evt) {
            // console.log('mousedown');
            // Reset the drag variable
            _this.isDragged = false;
            startingPos = [evt.pageX, evt.pageY];
          }, false);
          document.getElementById('imageScrollX').addEventListener('mouseup', function(evt) {
            if (_this.isDragged === false &&
              (Math.abs(evt.pageX - startingPos[0]) > 25 || Math.abs(evt.pageY - startingPos[1]) > 25)) {
                // If more than 25 pixels away from starting position - mark drag as true
                _this.isDragged = true;
              }
            // console.log('mouseup');
            // console.log(_this.isDragged ? 'drag' : 'click');
          }, false);
        }
      });

      // Initializing data from basketAnalysis
      this.mtgService.getBasketAnalysis('assets/data/basket/basket.json')
      // .subscribe acts on the data as it becomes available
      .subscribe( analysis => {
        this.basketAnalysis = analysis;
        // console.log(this.basketAnalysis);
      });
    }
  }

  // Listening for scroll events to hide elements on scroll down, and show on scroll up
  @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
    // Define which elements we want to manipulate
    const imageGrid = document.getElementById('imageGridDesktop');
    const selectionGrid = document.getElementById('selectionGridDesktop');
    const selectionColumnPower = document.getElementById('selectionColumnPower');
    const selectionColumnToughness = document.getElementById('selectionColumnToughness');
    const selectionColumnLoyalty = document.getElementById('selectionColumnLoyalty');

    // Record scroll positions for Y (vertical) scrolling
    const newScrollY = $event.detail.scrollTop;

    // Add a new page to search results only if one row (400px) of cards has been scrolled
    if (newScrollY > this.lastScrollY && newScrollY > 400) {
      // console.log('scrolling down, new: ' + newScrollY, 'before: ' + this.lastScrollY);
      this.page = this.page + 1;
      // console.log('page: ' + this.page);
    }

    // Make sure to hide the selectionGridDesktop upon scrolling down, and make it reappear when scrolling up
    if (newScrollY > this.lastScrollY && newScrollY > 200) { // Scrolling down
      // ONLY DO THIS IF (imageGrid.clientHeight - selectionGrid.clientHeight) > 316px (+300 + safe margin)
      // To prevent trying to show more of the imageGrid than there is to show, i.e. graphical glitch
      if ((imageGrid.clientHeight - selectionGrid.clientHeight) > 800) {
        this.domCtrl.write(() => {
          this.renderer.setStyle(selectionGrid, 'margin-top', `-${ selectionGrid.clientHeight }px`);
          this.renderer.setStyle(imageGrid, 'margin-top', '0');
          /* HIDE THE selectionColumns
          this.renderer.setStyle(selectionColumnPower, 'display', 'none');
          this.renderer.setStyle(selectionColumnToughness, 'display', 'none');
          this.renderer.setStyle(selectionColumnLoyalty, 'display', 'none');
          */
        });
      }
    } else { // Scrolling up
      this.domCtrl.write(() => {
        this.renderer.setStyle(imageGrid, 'margin-top', `${ selectionGrid.clientHeight }px`);
        this.renderer.setStyle(selectionGrid, 'margin-top', '0');
        // SHOW THE selectionColumns
        // this.renderer.setStyle(selectionColumnPower, 'display', 'initial');
        // this.renderer.setStyle(selectionColumnToughness, 'display', 'initial');
        // this.renderer.setStyle(selectionColumnLoyalty, 'display', 'initial');
      });
    }
    // If we're within 600px of the top of the imageGrid
    if (newScrollY < 400) {
      this.domCtrl.write(() => {
        /* SHOW THE selectionColumns
        this.renderer.setStyle(selectionColumnPower, 'display', 'initial');
        this.renderer.setStyle(selectionColumnToughness, 'display', 'initial');
        this.renderer.setStyle(selectionColumnLoyalty, 'display', 'initial');
        */
      });
    }
    this.lastScrollY = newScrollY;
  }

  // v- ALL CODE RELATED TO FORMAT/EDITION CHANGE -v //

  // Supporting function for fetching cardCollection from local JSON
  fetchCardCollectionLocalJSON() {
    // console.log('fetchCardCollectionLocalJSON started');
    this.isLoadingPercent = 100; // show all spinners when fetching from JSON
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
    // Loading the full cardCollection
    this.mtgService.getLocalData('assets/data/transformed/cardCollection_' + this.version + '.json')
    .subscribe ( data => {
      // Update the cardCollection once finished loading
      this.cardCollection = data;
      // console.log('fetchCardCollectionLocalJSON finished');
      // Save the cardCollection to indexedDB - not necessary as this is already taken place by the service worker upon page start
      // this.saveCardCollectionIndexedDB();

      // Update the local variables to match
      this.formattedCardCollection = this.formatFilterPipe.transform(this.cardCollection, this.format_filter);
      // console.log('localJSON', this.formattedCardCollection.length);

      // Sort the data by default on Edition, Color, Name
      this.formattedCardCollection = this.sortService.sortCardCollection(
        this.formattedCardCollection,
        'Edition', // this.sortBy_1,
        // Oldest to newest edition sortOrder for the first time the full collection is loaded
        String.fromCharCode(9660), // this.sortOrder_1,
        'Color', // this.sortBy_2,
        '', // this.sortOrder_2,
        'Name', // this.sortBy_3,
        '' // this.sortOrder_3);
      );

      // Grab editionSelections from the formatted card collection
      this.editionSelection = this.editionSelectionFilterPipe.transform(this.formattedCardCollection);
      // console.log (this.editionSelection);

      if (this.platformWidth < 1200) {
        // Clear any selections on Order By
        this.sortBy_1 = '';
        this.sortBy_2 = '';
        this.sortBy_3 = '';
      }

      // Loaded cardCollection post processing
      this.formatFilterChangePostProcessing();
    });
  }

  /* Supporting function for saving the cardCollection to IndexedDB in 10 chunks - currently not used
  saveCardCollectionIndexedDB() {
    console.log('saveCardCollectionIndexedDB started');

    // Slice the array in chunks
    const chunks = [];
    // Create 5 chunks of cards
    const chunksize = Math.floor(this.cardCollection.length / 5) + 1; // Number of cards per chunk
    for (let i = 0, len = this.cardCollection.length; i < len; i += chunksize) {
        chunks.push(this.cardCollection.slice(i, i + chunksize));
    }
    console.log(chunks);

    // Save this.cardCollection in 5 chunks to cardCollection_version in ionicStorage (indexedDB)
    const _this = this;
    chunks.forEach ( function (chunk) {
        _this.ionicStorage.set('cardCollection_' + _this.version + '_' + (chunks.indexOf(chunk) + 1), chunk);
    });
    console.log('saveCardCollectionIndexedDB finished');
  }
  */

  // Supporting function for postprocessing a loaded cardCollection
  formatFilterChangePostProcessing() {
    // console.log('formatFilterChangePostProcessing started');
    // Disable the loading spinner
    this.isLoading = false;
    // Set the page to fully loaded
    this.isFullyLoaded = true;

    // Update the formattedCardCollection _isOnWishList attribute to match the previous formattedCardCollection
    if (this.wishList.length >= 0) {
      this.formatWishlist();
    }

    // Reset any selections made in Edition
    this.edition_filter = [];

    if (this.format_filter === 'Standard') {
      // Make sure that _firstPrint flag is reset to null for standard
      this.c_filter._firstPrint = null;
    } else {
      // Make sure that _firstPrint flag is reset to true for everything but standard
      this.c_filter._firstPrint = true;
    }

    // Also, trigger the resetImageScroll
    this.resetImageScroll(true);

    // Finally, re-add any event listeners on the imageScroll (duplicates will be automatically discarded)
    // Add event listners for imageScroll to prevent opening modals after dragging more than 25px
    const _this = this;
    let startingPos = [0, 0];

    // Only add these event listeners when imageScrollX is present, i.e. platformwidth<1200
    if (this.platformWidth < 1200) {
      document.getElementById('imageScrollX').addEventListener('mousedown', function(evt) {
        // console.log('mousedown');
        // Reset the drag variable
        _this.isDragged = false;
        startingPos = [evt.pageX, evt.pageY];
      }, false);
      document.getElementById('imageScrollX').addEventListener('mouseup', function(evt) {
        if (_this.isDragged === false &&
          (Math.abs(evt.pageX - startingPos[0]) > 25 || Math.abs(evt.pageY - startingPos[1]) > 25)) {
            // If more than 25 pixels away from starting position - mark drag as true
            _this.isDragged = true;
          }
        // console.log('mouseup');
        // console.log(_this.isDragged ? 'drag' : 'click');
      }, false);
    }

    // IF on desktop, make sure sort order selections are already filled in
    if (this.platformWidth > 1199) {
      this.sortBy_1 = 'Edition', // Oldest to newest edition
      this.sortOrder_1 = String.fromCharCode(9660), // Asc
      this.sortBy_2 = 'Color',
      this.sortOrder_2 =  String.fromCharCode(9660), // Asc
      this.sortBy_3 = 'Name',
      this.sortOrder_3 =  String.fromCharCode(9660); // Asc
    }
    // console.log('formatFilterChangePostProcessing finished');
  }

  // ACTUAL CODE FOR CHANGING FORMAT
  formatFilterChange() {
    if (this.devMode === true) { console.log('formatFilterChange'); }
    // console.log('formatFilterChange', this.format_filter);

    // When changing format, trigger the load of all cards
    // Only for desktop, and not when already loaded once
    if (this.isMobile === false && this.isFullyLoaded === false && this.devMode === false) {

      // Enable loading spinner again
      this.isLoading = true;
      this.isLoadingPercent = 20; // start with one spinner when trying indexedDB
      this.cdRef.detectChanges();
      this.cdRef.markForCheck();

      // SECTION ON LOADING FROM INDEXEDDB
      // console.log('Trying indexedDB');

      // Check here if the cardCollection_version, all chunks, are available in ionicStorage
        this.ionicStorage.get('cardCollection_' + this.version + '_1')
        .then((res1) => {
          if (res1 != null) {
            // console.log('chunk 1 exists in indexedDB');
            // Update loading screen to reflect 40% complete next
            this.isLoadingPercent = 40;
            this.cdRef.detectChanges();
            this.cdRef.markForCheck();
            this.ionicStorage.get('cardCollection_' + this.version + '_2')
            .then((res2) => {
              if (res2 != null) {
                // console.log('chunk 2 exists in indexedDB');
                // Update loading screen to reflect 60% complete next
                this.isLoadingPercent = 60;
                this.cdRef.detectChanges();
                this.cdRef.markForCheck();
                this.ionicStorage.get('cardCollection_' + this.version + '_3')
                .then((res3) => {
                if (res3 != null) {
                  // console.log('chunk 3 exists in indexedDB');
                  // Update loading screen to reflect 80% complete next
                  this.isLoadingPercent = 80;
                  this.cdRef.detectChanges();
                  this.cdRef.markForCheck();
                  this.ionicStorage.get('cardCollection_' + this.version + '_4')
                  .then((res4) => {
                  if (res4 != null) {
                    // console.log('chunk 4 exists in indexedDB');
                    // Update loading screen to reflect 100% complete next
                    this.isLoadingPercent = 100;
                    this.cdRef.detectChanges();
                    this.cdRef.markForCheck();
                    this.ionicStorage.get('cardCollection_' + this.version + '_5')
                    .then((res5) => {
                    if (res5 != null) {
                      // console.log('chunk 5 exists in indexedDB');
                      // Update the cardCollection once finished loading all 5 chunks
                      this.cardCollection = res1.concat(res2).concat(res3).concat(res4).concat(res5);

                      // Update the local variables to match
                      this.formattedCardCollection = this.formatFilterPipe.transform(this.cardCollection, this.format_filter);
                      // console.log('indexedDB', this.formattedCardCollection.length);

                      // Grab editionSelections from the formatted card collection, sorted by falling edition
                      this.editionSelection = this.editionSelectionFilterPipe.transform(
                        this.sortService.sortCardCollection(this.formattedCardCollection,
                          'Edition', String.fromCharCode(9650), 'Color', '', 'name', ''));
                      // console.log (this.editionSelection);

                      // Sort the data by default on Edition, Color, Name
                      this.formattedCardCollection = this.sortService.sortCardCollection(
                        this.formattedCardCollection,
                        'Edition', // this.sortBy_1,
                        // Oldest to newest edition sortOrder for the first time the full collection is loaded
                        String.fromCharCode(9660), // this.sortOrder_1,
                        'Color', // this.sortBy_2,
                        '', // this.sortOrder_2,
                        'Name', // this.sortBy_3,
                        '' // this.sortOrder_3);
                      );

                      if (this.platformWidth < 1200) {
                        // Clear any selections on Order By
                        this.sortBy_1 = '';
                        this.sortBy_2 = '';
                        this.sortBy_3 = '';
                      }

                      // Loaded cardCollection post processing
                      this.formatFilterChangePostProcessing();
                    } else {
                      // Load from local JSON if any chunk of the cardCollection is missing
                      this.fetchCardCollectionLocalJSON();
                    }
                  });
                  } else {
                    // Load from local JSON if any chunk of the cardCollection is missing
                    this.fetchCardCollectionLocalJSON();
                  }
                });
                } else {
                  // Load from local JSON if any chunk of the cardCollection is missing
                  this.fetchCardCollectionLocalJSON();
                }
              });
              } else {
                // Load from local JSON if any chunk of the cardCollection is missing
                this.fetchCardCollectionLocalJSON();
              }
            });
          } else { // If there is no first chunk (i.e. no indexedDB storage of the correct cardCollection_version)
          // console.log('Trying local JSON');
          // Load from local JSON if IndexedDB doesn't return anything
          this.fetchCardCollectionLocalJSON();
          }
        });
      // END SECTION ON LOADING FROM INDEXEDDB

    } else {

      // If the full cardCollection is already loaded:
      // 1. Update the formattedCardCollection
      this.formattedCardCollection = this.formatFilterPipe.transform(this.cardCollection, this.format_filter);
      // console.log('already loaded', this.formattedCardCollection.length);

      // 2. Sort the data by default on Edition, Color, Name
      this.formattedCardCollection = this.sortService.sortCardCollection(
        this.formattedCardCollection,
        'Edition', // this.sortBy_1,
        String.fromCharCode(9650), // this.sortOrder_1,
        'Color', // this.sortBy_2,
        '', // this.sortOrder_2,
        'Name', // this.sortBy_3,
        '' // this.sortOrder_3);
      );

      // 3. Grab editionSelections from the formatted card collection
      this.editionSelection = this.editionSelectionFilterPipe.transform(this.formattedCardCollection);
      // console.log (this.editionSelection);

      // 4. Re-apply existing sort conditions on the filtered formattedCardCollection
      this.sortCardCollection();

      // 5. Run the post processing (minus the sort reset)
      this.formatFilterChangePostProcessing();

    }
    // Finally, trigger the resetImageScroll
    this.resetImageScroll(true);

    // v- DEV MODE -v //
    if (this.devMode === true) {
      let blob: Blob;
      let fileName: string;
      // Save cardCollection to JSON pending on selection
      if (this.format_filter === 'Standard') {
        // Create a blob with the expected JSON for Standard only
        blob = new Blob([JSON.stringify(this.formattedCardCollection)], {type : 'text/json'});
        fileName = 'cardCollection_standard_' + this.version + '.json';
      } else {
        // Create a blob with the expected JSON for all cards
        blob = new Blob([JSON.stringify(this.cardCollection)], {type : 'text/json'});
        fileName = 'cardCollection_' + this.version + '.json';
      }

      // Create the URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      // Write it as the href for a link
      const link = document.createElement('a');
      link.setAttribute('href', blobUrl);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      // Add the link to the page
      document.body.appendChild(link);
      // Click the link
      link.click();
      // Remove the link from the page
      document.body.removeChild(link);
    }
    // ^- DEV MODE -^ //
  }

  editionFilterChange() {
    if (this.devMode === true) { console.log('editionFilterChange'); }
    // console.log('editionFilterChange');

    if (this.edition_filter.length === 0 && this.format_filter !== 'Standard') {
      // If no edition selections has been made, keep the _firstPrint as true (except for standard)
      this.c_filter._firstPrint = true;
    } else {
      // Else uncheck _firstPrint flag,
      // to show all possible cards in the selected edition(s)
      this.c_filter._firstPrint = null;
    }

    // Re-apply search condition on the filtered formattedCardCollection
    this.sortCardCollection();

    // Also, trigger the resetImageScroll
    this.resetImageScroll(true);
  }
  // ^- ALL CODE RELATED TO FORMAT/EDITION CHANGE -^ //

  // v- ALL CODE RELATED TO SCROLLING -v //
  resetImageScroll(skip?: boolean) {
    if (this.devMode === true) { console.log('resetImageScroll'); }
    // console.log('resetImageScroll');

    // Reset imageScroll variables
    this.page = 1;
    this.lastScrollY = 0;
    if (skip) {
      // If a skip parameter has been added
      // do not reset the scrollbar, as it may not yet be available
    } else {
      if (this.platformWidth < 1200) {
        // Set the imageDragScrollX to the first positioned card in focus
        this.imageDragScrollX.moveTo(1);
      } else {
        const ionContent = document.querySelector('ion-content');
        ionContent.scrollToTop(500);
      }
    }

    // Increment the counter to manually trigger the AbilityFilterPipes
    this.triggerPipeCounter++;
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  // Add additional objects to the DOM upon scrolling the img-view
  addPage(event) {
    if (event == true) {
      if (this.devMode === true) { console.log('reachesRightBound'); }
      //console.log('reachesRightBound');
      this.page = this.page + 1;
    }
  }

  mouseWheelUpFunc(event) {
    if (this.devMode === true) { console.log('mouseWheelUpFunc'); }
    // console.log('mouseWheelUpFunc');

    const el = document.getElementById('imageScroll');
    // Here we automatically detect the width of the viewport to make sure
    // we scroll exactly one width away (minus one card, to not miss out on cards)
    const platformWidth = this.platform.width() - 200;
    if (el.scrollLeft >= platformWidth) {
        // Scroll back on mousewheelUp
        el.scrollLeft = el.scrollLeft - platformWidth;
    } else {
        el.scrollLeft = 0;
    }
    // The onScroll event is triggered by manually changing the scrollLeft value
    // Effectively triggering the above function to expand the DOM
  }

  mouseWheelDownFunc(event) {
    if (this.devMode === true) { console.log('mouseWheelDownFunc'); }
    // console.log('mouseWheelDownFunc');

    const el = document.getElementById('imageScroll');
    // Here we automatically detect the width of the viewport to make sure
    // we scroll exactly one width away (minus one card, to not miss out on cards)
    const platformWidth = this.platform.width() - 200;
    // Scroll forward on mousewheelDown
    el.scrollLeft = el.scrollLeft + platformWidth;
    // The onScroll event is triggered by manually changing the scrollLeft value
    // Effectively triggering the above function to expand the DOM
  }

  changeScrollView(option: string) {
    if (this.devMode === true) { console.log('changeScrollView'); }
    // console.log('changeScrollView');

    this.scrollView = option;
  }
  // ^- ALL CODE RELATED TO SCROLLING -^ //

  // v- ALL CODE RELATED TO MAIN SELECTIONS -v //
  toggleMainType(mainType, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('toggleMainType'); }
    // console.log('toggleMainType');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    // mainType.replace(' ','') to transform 'Artifact Creature' to 'ArtifactCreature'
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById(mainType.replace(' ', ''));
      inputElement.checked = false;

      // To make sure not only the checkbox is unchecked, but also the local variable is set
      eval ('this.' + mainType.replace(' ', '') + ' = false');
      // console.log (this.Creature, this.ArtifactCreature);
    }

    // If the value of mainType is missing both Creature and Artifact Creature upon toggling
    if (this.Creature === false && this.ArtifactCreature === false) {
      // Reset the filter on power & toughness
      this.c_filter.power = '';
      this.Power0 = false;
      this.Power1 = false;
      this.Power2 = false;
      this.Power3 = false;
      this.Power4 = false;
      this.Power5 = false;
      this.Power6 = false;
      this.Power7 = false;
      this.Power8 = false;
      this.PowerX = false;
      this.c_filter.toughness = '';
      this.Toughness0 = false;
      this.Toughness1 = false;
      this.Toughness2 = false;
      this.Toughness3 = false;
      this.Toughness4 = false;
      this.Toughness5 = false;
      this.Toughness6 = false;
      this.Toughness7 = false;
      this.Toughness8 = false;
      this.ToughnessX = false;
    }
    // If the value of mainType is missing Planeswalker upon toggling
    if (this.Planeswalker === false) {
      // Reset the filter on loyalty
      this.c_filter.loyalty = '';
      this.Loyalty1 = false;
      this.Loyalty2 = false;
      this.Loyalty3 = false;
      this.Loyalty4 = false;
      this.Loyalty5 = false;
      this.Loyalty6 = false;
      this.Loyalty7 = false;
      this.LoyaltyX = false;
    }

    // If the current selection of mainTypes doesn't include mainType
    if (this.c_filter.mainType.indexOf(mainType) === -1) {
      // include it
      this.c_filter.mainType.push(mainType);
    } else { // else delete it
      this.c_filter.mainType.splice(this.c_filter.mainType.indexOf(mainType), 1);
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  togglePower(power, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('togglePower'); }
    // console.log('togglePower');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    // make sure * converts to X for the purpose of variable consistency
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById('Power' + power.replace('*', 'X'));
      inputElement.checked = false;
      eval ('this.Power' + power.replace('*', 'X') + ' = false');
    }

    // Special logic for power:8, which replaces power 8-16
    if (power === '8') {
      power = '8;9;10;11;12;13;14;15;16';
    }
    // If the current selection of power doesn't include power
    if (this.c_filter.power.indexOf(';' + power + ';') === -1) {
      // include it
      this.c_filter.power = this.c_filter.power.concat(';' + power + ';');
    } else { // else delete it
      this.c_filter.power = this.c_filter.power.replace(';' + power + ';', '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  toggleToughness(toughness, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('toggleToughness'); }
    // console.log('toggleToughness');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    // make sure * converts to X for the purpose of variable consistency
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById('Toughness' + toughness.replace('*', 'X'));
      inputElement.checked = false;
      eval('this.Toughness' + toughness.replace('*', 'X') + ' = false');
    }

    // Special logic for toughness:8, which replaces toughness 8-16
    if (toughness === '8') {
      toughness = '8;9;10;11;12;13;14;15;16';
    }
    // If the current selection of toughness doesn't include toughness
    if (this.c_filter.toughness.indexOf(';' + toughness + ';') === -1) {
      // include it
      this.c_filter.toughness = this.c_filter.toughness.concat(';' + toughness + ';');
    } else { // else delete it
      this.c_filter.toughness = this.c_filter.toughness.replace(';' + toughness + ';', '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  toggleLoyalty(loyalty, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('toggleLoyalty'); }
    // console.log('toggleLoyalty');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById('Loyalty' + loyalty);
      inputElement.checked = false;
      eval('this.Loyalty' + loyalty + ' = false');
    }

    // If the current selection of loyalty doesn't include loyalty
    if (this.c_filter.loyalty.indexOf(';' + loyalty + ';') === -1) {
      // include it
      this.c_filter.loyalty = this.c_filter.loyalty.concat(';' + loyalty + ';');
    } else { // else delete it
      this.c_filter.loyalty = this.c_filter.loyalty.replace(';' + loyalty + ';', '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  toggleRarity(rarity, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('toggleRarity'); }
    // console.log('toggleRarity');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById(rarity);
      inputElement.checked = false;
      eval('this.' + rarity + ' = false');
    }

    // If the current selection of rarities doesn't include rarity
    if (this.c_filter.rarity.indexOf(rarity) === -1) {
      // include it
      this.c_filter.rarity = this.c_filter.rarity.concat(rarity);
    } else { // else delete it
      this.c_filter.rarity = this.c_filter.rarity.replace(rarity, '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  togglePermanent(permanent: string, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('togglePermanent'); }

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById(permanent);
      inputElement.checked = false;
      eval('this.' + permanent + ' = false');
    }

    // Create the necessary constants for all permanent checkboxes to make them available for manipulation
    const white = <HTMLInputElement>document.getElementById('White');
    const blue = <HTMLInputElement>document.getElementById('Blue');
    const black = <HTMLInputElement>document.getElementById('Black');
    const red = <HTMLInputElement>document.getElementById('Red');
    const green = <HTMLInputElement>document.getElementById('Green');
    const colorless = <HTMLInputElement>document.getElementById('Colorless');

    // Reset the values of checkboxes for _subColors every time the Multicolor icon is triggered
    // If another icon is triggered, keep the values set
    this.c_filter._subColorWhite = (permanent === 'Multicolor') ? null : this.c_filter._subColorWhite;
    this.c_filter._subColorBlue = (permanent === 'Multicolor') ? null : this.c_filter._subColorBlue;
    this.c_filter._subColorBlack = (permanent === 'Multicolor') ? null : this.c_filter._subColorBlack;
    this.c_filter._subColorRed = (permanent === 'Multicolor') ? null : this.c_filter._subColorRed;
    this.c_filter._subColorGreen = (permanent === 'Multicolor') ? null : this.c_filter._subColorGreen;

    // If the current selection of permanents doesn't include permanent
    if (this.c_filter.permanent.indexOf(permanent) === -1) {
      // If the selection was multicolor, select ONLY multicolor, otherwise concat
      if (permanent === 'Multicolor') {
        this.c_filter.permanent = permanent;
        // Uncheck all other permanent selections and make the selections inactive
        // White
        white.disabled = true;
        white.checked = false;
        this.White = false;
        // Blue
        blue.disabled = true;
        blue.checked = false;
        this.Blue = false;
        // Black
        black.disabled = true;
        black.checked = false;
        this.Black = false;
        // Red
        red.disabled = true;
        red.checked = false;
        this.Red = false;
        // Green
        green.disabled = true;
        green.checked = false;
        this.Green = false;
        // Colorless
        colorless.disabled = true;
        colorless.checked = false;
        this.Colorless = false;
      } else { // include it
      this.c_filter.permanent = this.c_filter.permanent.concat(permanent);
      }
    } else { // else delete it
      // and make sure all permanent selections are available for selection again
      white.disabled = false;
      blue.disabled = false;
      black.disabled = false;
      red.disabled = false;
      green.disabled = false;
      colorless.disabled = false;
      this.c_filter.permanent = this.c_filter.permanent.replace(permanent, '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  toggleCMC(cmc, chipDelete?: boolean) {
    if (this.devMode === true) { console.log('toggleCMC'); }
    // console.log('toggleCMC');

    // Make sure the checkbox state is the same as the ngModel for that selection
    // in case triggered from deleting a chip
    if (chipDelete) {
      const inputElement = <HTMLInputElement>document.getElementById('CMC' + cmc);
      inputElement.checked = false;
      eval('this.CMC' + cmc + ' = false');
    }

    // Special logic for cmc:8, which replaces cmc 8-16
    if (cmc === '8') {
      cmc = '8;9;10;11;12;13;14;15;16';
    }
    // If the current selection of cmc doesn't include cmc
    if (this.c_filter.cmc.indexOf(';' + cmc + ';') === -1) {
      // include it
      this.c_filter.cmc = this.c_filter.cmc.concat(';' + cmc + ';');
    } else { // else delete it
      this.c_filter.cmc = this.c_filter.cmc.replace(';' + cmc + ';', '');
    }
    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  toggleManaSelection(selection) {
    if (this.devMode === true) { console.log('toggleManaSelection'); }
    // console.log('toggleManaSelection');

    if (selection === 'X') {
      if (this.c_filter._hasXCost === false) {
        this.c_filter._hasXCost = null;
      }
    }
    if (selection === 'H') {
      if (this.c_filter._hasHCost === false) {
        this.c_filter._hasHCost = null;
      }
    }

    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }
  // ^- ALL CODE RELATED TO MAIN SELECTIONS -^ //

  // v- ALL CODE RELATED TO CHIPS -v //
  addAbilityChip(ability: string) {
    if (this.devMode === true) { console.log('addAbilityChip'); }
    // console.log('addAbilityChip');

    // First remove any existing chip for that ability if it exists
    if (document.getElementById('#' + ability)) {
      document.getElementById('#' + ability).remove();
    }

    // Then make sure we have a name for the new chip
    let abilityName: string;
    // Use translation service to provide the mapping from ability to abilityName
    this.translate.get(ability).subscribe((result: string) => {
      // console.log(result);
      abilityName = result;
    });

    // Then identify where the new chip should be placed
    const abilityRow = document.getElementById('abilityChips');

    // Then create the newChip
    const newChip = this.renderer.createElement('ion-chip');
    // Add attributes to the newChip
    this.renderer.setAttribute(newChip, 'id', '#' + ability);
    this.renderer.addClass(newChip, 'chip');
    this.renderer.addClass(newChip, 'chip-md');
    // Add click functionality to the newChip
    this.renderer.listen(newChip, 'click', () => this.deleteAbilityChip(ability));

    // Add a chipIcon
    const chipIcon = this.renderer.createElement('ion-icon');
    // Add attributes to the chipIcon // Different icon for true/false
    if (eval('this.c_filter.' + ability) === true) {
      this.renderer.setAttribute(chipIcon, 'name', 'checkmark');
      this.renderer.addClass(chipIcon, 'chip-icon-yes');

      this.renderer.addClass(chipIcon, 'ms-cost');
    }
    if (eval('this.c_filter.' + ability) === false) {
      this.renderer.setAttribute(chipIcon, 'name', 'close');
      this.renderer.addClass(chipIcon, 'chip-icon-no');

      this.renderer.addClass(chipIcon, 'ms-cost');
    }

    // Append the chipIcon to the newChip
    this.renderer.appendChild(newChip, chipIcon);

    // Add a label-element to the newChip
    //const chipLabel = this.renderer.createElement('ion-label');
    const chipLabel = this.renderer.createElement('label');
    // Add hover class to the chipLabel IF isMobile === false
    if ( this.isMobile === false) {
      this.renderer.setAttribute(chipLabel, 'class', 'hoverSelection');
    }
    // Add a chipLabelText to the chipLabel
    const chipLabelText = this.renderer.createText(abilityName);
    // Append the chipLabelText to the chipLabel
    this.renderer.appendChild(chipLabel, chipLabelText);

    // // Add a deleteIcon to the chipLabel - REMOVED
    // const deleteIcon = this.renderer.createElement('ion-icon');
    // this.renderer.setAttribute(deleteIcon, 'name', 'trash');
    // // Append the deleteIcon to the chipLabel
    // this.renderer.appendChild(chipLabel, deleteIcon);

    // Append the chipLabel to the newChip
    this.renderer.appendChild(newChip, chipLabel);

    // Then append the newChip to the placement in the DOM if the ability is not null
    if (eval('this.c_filter.' + ability) !== null) {
      this.renderer.appendChild(abilityRow, newChip);
    }

    // Always reset the pagination and scroll when applying filters
    this.resetImageScroll();
  }

  // Function for resetting selections through clicking on yes/no chips
  deleteAbilityChip(ability: string) {
    if (this.devMode === true) { console.log('deleteAbilityChip'); }
    // console.log('deleteAbilityChip');

    eval('this.c_filter.' + ability + '= null'); // Sets the chip value to null when clicked

    // Removes the chip from the DOM
    document.getElementById('#' + ability).remove();

    // Always reset imagescroll when updating selections
    this.resetImageScroll();
  }

  // Support for yes/no chips with persistent DOM presence
  // (currently: _hasXcost, _hasPcost, and _hasHcost)
  resetAbilityChip(ability: string) {
    if (this.devMode === true) { console.log('resetAbilityChip'); }
    // console.log('resetAbilityChip');

    eval('this.c_filter.' + ability + '= null'); // Sets the chip value to null when clicked

    // Always reset imagescroll when updating selections
    this.resetImageScroll();
  }
  // ^- ALL CODE RELATED TO CHIPS -^ //

  // v- ALL CODE RELATED TO WISHLIST -v //
  toggleWishList(card: MtgCard) { // change this to start with a small letter later on
    if (this.devMode === true) { console.log('toggleWishList'); }
    // console.log('toggleWishList');

    // If the card is on the wishlist, remove it
    if (card._isOnWishList) {

      // 1. Update its cardCollection property .isOnWishList to false
      for (let i = 0, len_a = this.formattedCardCollection.length; i < len_a; i++) {
        // Identify the card with the same uuid
        if (this.formattedCardCollection[i].uuid === card.uuid) {
              // Update it's _isOnWishList to false
              this.formattedCardCollection[i]._isOnWishList = false;
        }
      }

      // 2. Show a toast notifying the user the card has been removed
      // Get the translated name of the card to be removed
      const removeCardName = card[this.name_lang] !== '' ? card[this.name_lang] : card.name;

      this.translate.get('toastRemoveFromWishList').subscribe((result: string) => {
        this.presentToast(removeCardName + result);
      });
      // 3. Remove the card from the wishlist
      // Call the function in the wishlist-handler.service that removes cards from wishList
      this.wishList = this.wishlistService.removeCard(card, this.wishList);

      // 4. Update the wishList totals
      this.wishListTotalUpdate();
      // 5. Update export links
      this.wishListExportUpdate();

  } else {
    // If the card is not on the wishlist, add it

    // 1. Update its cardCollection property .isOnWishList to true
    this.formattedCardCollection[this.formattedCardCollection.indexOf(card)]._isOnWishList = true;

    // 2. Show a toast notifying the user the card has been added
    // Get the translated name of the card to be added
    const addCardName = card[this.name_lang] !== '' ? card[this.name_lang] : card.name;
    this.translate.get('toastAddToWishList').subscribe((result: string) => {
      this.presentToast(addCardName + result);
    });

    // 3. Call the wishlist-handler service to add a new card to the wishlist
    this.wishList = this.wishlistService.addCard(card, this.wishList);

    // Updating states to trigger UI update
    // Increment the counter to manually trigger the AbilityFilterPipes
    this.triggerPipeCounter++;
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();

    // Update the prices of the cards in the wishList
    this.wishListPriceUpdate();
  }
}

  wishListAmountUpdate(index, action: string) {
    if (this.devMode === true) { console.log('wishListAmountUpdate'); }
    // console.log('wishListAmountUpdate');

    if (action === '+') {
      // Increment the amount for the current index
      this.wishList[index].amount++;
      // Update the price to match (to make price sortable)
      this.wishList[index].priceTotal = this.wishList[index].price * this.wishList[index].amount;
    }
    if (action === '-') {
      // Decrement the amount for the current index
      this.wishList[index].amount--;
      // Update the price to match (to make price sortable)
      this.wishList[index].priceTotal = this.wishList[index].price * this.wishList[index].amount;

      // If amount = 0, remove the record
      if (this.wishList[index].amount === 0) {

        // Update its cardCollection property .isOnWishList to false
        for (let i = 0, len_a = this.formattedCardCollection.length; i < len_a; i++) {
          // Identify the card with the same uuid
          if (this.formattedCardCollection[i].uuid === this.wishList[index].wishCard.uuid) {
                // Update it's _isOnWishList to false
                this.formattedCardCollection[i]._isOnWishList = false;
          }
        }

        const removeCardName = this.wishList[index].wishCard[this.name_lang] !== '' ?
        this.wishList[index].wishCard[this.name_lang] :
        this.wishList[index].wishCard.name;

        // Show a toast notifying the user the card has been removed
        this.translate.get('toastRemoveFromWishList').subscribe((result: string) => {
          this.presentToast(removeCardName + result);
        });

        // Remove the object at index
        this.wishList = this.wishlistService.removeCard(this.wishList[index].wishCard, this.wishList);
      }
    }

    // Update the wishList price & amount totals
    this.wishListTotalUpdate();

    // Update export links
    this.wishListExportUpdate();
  }

  wishListPriceUpdate() {
    if (this.devMode === true) { console.log('wishListPriceUpdate'); }
    // console.log('wishListPriceUpdate');

    // 1. Start by updating the totals and the exports
    // If nothing is changed in the subscription later, these values will remain unchanged

    // Update the wishList price total once subscription has generated a result
    this.wishListTotalUpdate();

    // Update export links
    this.wishListExportUpdate();

    // 2. Loop through the items on the wishList
    for (let i = 0; i < this.wishList.length; i++) {
      if (this.wishList[i]) { // Safeguard to make sure the item is still there before accessing its properties
        if (this.wishList[i].price === 0) { // If no price has been set
          if (this.wishList[i].wishCard.tcg_productId !== '') { // Verify a tcg_productId exists
            // Fetch the TCG product price for the actual card to show in the wishlist
            this.TCGPlayer.getProductPrice(this.wishList[i].wishCard.tcg_productId)
            .subscribe(data => {
              // console.log(data);
              let _existsNormal = false;
              if (data) { // Avoiding null results
                data.results.forEach(priceAttribute => {
                  if (priceAttribute.subTypeName === 'Normal') { // Normal pricing
                    _existsNormal = true;
                    // Safeguard to make sure the item is still there before editing its properties
                    if (this.wishList[i]) {
                      this.wishList[i].price = priceAttribute.marketPrice;
                      this.wishList[i].priceTotal = priceAttribute.marketPrice;
                    }
                  }
                  if (priceAttribute.subTypeName === 'Foil') { // Foil pricing
                    const foilPrice = priceAttribute.marketPrice;
                    if (_existsNormal === false) { // If the card is only available in foil, show that price
                      // Safeguard to make sure the item is still there before editing its properties
                      if (this.wishList[i]) {
                        this.wishList[i].price = foilPrice;
                        this.wishList[i].priceTotal = foilPrice;
                      }
                    }
                  }
                });
              }
              // 3. Each iteration a 0 price is found, that has a tcg_productId - update the totals and exports

              // Update the wishList price total once subscription has generated a result
              this.wishListTotalUpdate();
              // Update export links
              this.wishListExportUpdate();
            }, (err) => { // ending the getProductPrice subscription
              console.log(err);
            });
          }
        }
      }
    }
  }

  wishListTotalUpdate() {
    if (this.devMode === true) { console.log('wishListTotalUpdate'); }
    // console.log('wishListTotalUpdate');

    // Update the amountTotal
    this.wishListAmountTotal = this.wishlistService.updateAmountTotal(this.wishList);

    // Update the priceTotal
    this.wishListPriceTotal = this.wishlistService.updatePriceTotal(this.wishList);

  }

  wishListExportUpdate() {
    if (this.devMode === true) { console.log('wishListExportUpdate'); }
    // console.log('wishListExportUpdate');

    const decUrl = this.wishlistService.getExportData('dec', this.wishList, this.name_lang, 'uri');
    const mwdUrl = this.wishlistService.getExportData('mwd', this.wishList, this.name_lang, 'uri');
    const tcgUrl = this.wishlistService.getExportData('tcg', this.wishList, this.name_lang);

    // Write it as the href for the link
    document.getElementById('saveAsDec').setAttribute('href', decUrl);
    document.getElementById('saveAsMWD').setAttribute('href', mwdUrl);
    // Also update the link to TCGPlayer shop
    document.getElementById('shopTCG').setAttribute('href', tcgUrl);

    // Add the wishList to a persistent local storage
    this.storageService.set('wishlist', this.wishList);
    // console.log(this.storageService.get('wishlist'));

    // Increment the counter to manually trigger the AbilityFilterPipes
    this.triggerPipeCounter++;
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  emptyWishlist() {
    if (this.devMode === true) { console.log('emptyWishlist'); }
    // console.log('emptyWishlist');

    this.wishList.forEach(element => {
      // For each element in the wishList, loop through the cardCollection,
      // and reset the _isOnWishlist boolean
      for (let i = 0, len_a = this.formattedCardCollection.length; i < len_a; i++) {
        if (this.formattedCardCollection[i].uuid === element.wishCard.uuid) {
            this.formattedCardCollection[i]._isOnWishList = false;
        }
      }
      // Clear the wishList element
      this.wishList = [];

      // Show a toast notifying the user the card has been removed
      this.translate.get('toastEmptyWishList').subscribe((result: string) => {
        this.presentToast(result);
      });

      // Update the wishList price total once subscription has generated a result
      this.wishListTotalUpdate();
      // Update export links
      this.wishListExportUpdate();
    });
  }

  formatWishlist() {
    if (this.devMode === true) { console.log('formatWishlist'); }
    // console.log('formatWishlist');

    this.wishList.forEach(element => {
      // Loop through the newly formatted card collection for each item on the wishlist
      for (let i = 0, len_a = this.formattedCardCollection.length; i < len_a; i++) {
        // Identify the card with the same uuid
        if (this.formattedCardCollection[i].uuid === element.wishCard.uuid) {
          // Update it's _isOnWishList to true
          this.formattedCardCollection[i]._isOnWishList = true;
        }
      }
    });
  }

  toggleWishlistShow() {
    if (this.devMode === true) { console.log('toggleWishlistShow'); }
    // console.log('toggleWishlistShow');

    // Only allow the toggle on mobile
    if (this.isMobile === true) {
      this.wishListOpen = !this.wishListOpen;
    }
  }

  changeShop(shop: string) {
    if (this.devMode === true) { console.log('changeShop'); }
    // console.log('changeShop');

  }
  // ^- ALL CODE RELATED TO WISHLIST -^ //

  // v- ALL CODE RELATED TO SORTING -v //
  toggleSortOrder(SortOrder: number) {
   
    switch (SortOrder) {
      case 1:
          if (this.sortOrder_1 == this.desc_arrow) {
            this.sortOrder_1 = this.asc_arrow
          } else     
          if (this.sortOrder_1 == this.asc_arrow) {
            this.sortOrder_1 = this.desc_arrow
          }
          this.sortCardCollection();
          console.log('ToggleSortOrder1');
          break;
      case 2:
          if (this.sortOrder_2 == this.desc_arrow) {
            this.sortOrder_2 = this.asc_arrow
          } else     
          if (this.sortOrder_2 == this.asc_arrow) {
            this.sortOrder_2 = this.desc_arrow
          }
          this.sortCardCollection();
          console.log('ToggleSortOrder2');
          break;
      case 3:
          if (this.sortOrder_3 == this.desc_arrow) {
            this.sortOrder_3 = this.asc_arrow
          } else     
          if (this.sortOrder_3 == this.asc_arrow) {
            this.sortOrder_3 = this.desc_arrow
          }
          this.sortCardCollection();
          console.log('ToggleSortOrder3');
          break;
    }   
  }
  
  
  sortCardCollection() {
    if (this.devMode === true) { console.log('sortCardCollection'); }
    // console.log('sortCardCollection');

    this.formattedCardCollection = this.sortService.sortCardCollection(
      this.formattedCardCollection,
      this.sortBy_1,
      this.sortOrder_1,
      this.sortBy_2,
      this.sortOrder_2,
      this.sortBy_3,
      this.sortOrder_3
    );

    // Always reset imagescroll when updating sort
    this.resetImageScroll(true);
  }

  // Function for sorthing wishList HTML table
  sort(property) {
    this.tableIsDesc = !this.tableIsDesc; // Change the direction
    const direction = this.tableIsDesc ? 1 : -1;

    this.wishList.sort(function(a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else
        if ( a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
    });
  }

  // ^- ALL CODE RELATED TO SORTING -^ //

  // v- ALL CODE RELATED TO OTHER FUNCTIONS -v //
  async openModal(modalCard: MtgCard) {
    if (this.devMode === true) { console.log('openModal', modalCard); }
    // console.log('openModal');

    // Determine if a modal should be opened or not, pending imageScroll coming out of a drag or click
    if (this.isDragged) {
      // console.log('Not opening cardModal due to recent dragging operation');
      // Resetting variable and returning without action
      this.isDragged = false;
      return null;
    }

    // Creating the modal, with the added parameters object
    // Also binding this function to get passed to the modal, so it can reference back if it needs to create more modals from the modal
    const cardModal = await this.modalCtrl.create({
      component: CardModalPage,
      componentProps: {
        modalCard: modalCard,
        cardCollection: this.formattedCardCollection,
        basketAnalysis: this.basketAnalysis,
        format: this.format_filter,
        language: this.language,
        open: this.openModal.bind(this)
      }
      // ,cssClass: 'custom-card-modal'
    });

    // Subscribing to any closed modals, and what dismiss arguments they may have
    cardModal.onDidDismiss().then((detail: OverlayEventDetail) => {

      // This should always provide info on what cards to add to the wishlist
      // console.log(detail.data);
      if (detail.data) { // If data was passed on closing the modal
        this.toggleWishList(detail.data);
        this.cdRef.detectChanges();
        this.cdRef.markForCheck();
      }
    });

    return await cardModal.present();
  }

  // Presenting toasts
  async presentToast(msg: string) {
    if (this.devMode === true) { console.log('presentToast'); }
    // console.log('presentToast');

    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      mode: 'ios',
      cssClass: 'toast'
    });

    toast.onDidDismiss().catch(() => {
      // console.log('Dismissed toast');
    });

    toast.present();
  }

  // Toggle visibility of advanced selections
  async toggleAdvancedSelections() {
    if (this.platformWidth < 1200) { // MOBILE
      const collapseDiv = document.getElementById('collapseDiv');
      const collapseBtn = document.getElementById('collapseBtn');
      if (collapseDiv.style.display !== 'none') {
        collapseDiv.style.display = 'none';
        collapseBtn.setAttribute('aria-expanded', 'false');
        this.advancedSelectionsShown = false;
      } else {
        collapseDiv.style.display = '';
        collapseBtn.setAttribute('aria-expanded', 'true');
        // Hide collapseBtn upon expansion - OPTIONAL
        collapseBtn.style.display = 'none';
        this.advancedSelectionsShown = true;
      }
    } else { // DESKTOP
      const row1 = document.getElementById('advancedSelectionsBtns1');
      const row2 = document.getElementById('advancedSelectionsBtns2');
      const openBtn = document.getElementById('advancedSelectionsOpenBtn');
      if (row1.style.display !== 'none') {
        row1.style.display = 'none';
        row2.style.display = 'none';
        openBtn.setAttribute('aria-expanded', 'false');
        this.advancedSelectionsShown = false;
      } else {
        row1.style.display = '';
        row2.style.display = '';
        openBtn.setAttribute('aria-expanded', 'true');
        // Hide collapseBtn upon clicking it
        openBtn.style.display = 'none';
        this.advancedSelectionsShown = true;
      }
    }
  }

  // Toggle AdvancedSelection Modal
  async toggleAdvancedSelectionsModal(section?: string) {
    const modalDiv = document.getElementById('advSelectionsModal');
    // If the modal itself is clicked (not the modal-contents), hide it
    if (section) {
      // First, determine which content to show in the modal-content using an ng-switch
      this.cardAbility = section;
      // Finally, show the modal
      modalDiv.classList.toggle('advSelectionsModal-show');
      // And shift focus to the modal window
      modalDiv.focus();
    } else { // If there is no section requested, assume it's the modal itself clicked
      modalDiv.classList.toggle('advSelectionsModal-show');
    }
  }

  // Hide/Show selectionGrid upon scrolling down/up
  async toggleSelectionGrid(scrollDirection) {
    const selectionGrid = document.getElementById('selectionGrid');
    if (scrollDirection === 'Up') {
      selectionGrid.style.display = '';
    }
    if (scrollDirection === 'Down') {
      selectionGrid.style.display = 'none';
    }
  }

  // Change language
  changeLanguage(lang) {
    this.language = lang.toUpperCase();
    this.translate.use(lang);
    if (lang === 'en') {
      this.name_lang = 'name';
      this.cardType_lang = 'cardType';
      this.cleantext_lang = 'cleantext';
      this.imagePath_lang = 'imagePath';
    } else {
      this.name_lang = 'name_' + lang.toUpperCase();
      this.cardType_lang = 'cardType_' + lang.toUpperCase();
      this.cleantext_lang = 'cleantext_' + lang.toUpperCase();
      this.imagePath_lang = 'imagePath_' + lang.toUpperCase();
    }
    // console.log(this.language, this.name_lang);
    // Also update the wishlist .dec export link with the new card language
    if (this.wishList.length > 0) { // only if wishlist is not empty
      this.wishListExportUpdate();
    }
  }


  // Hide keyboard when searching and pressing enter key
  onSearch(event) {
    if (event.keyCode === 13) { // Checking if the key last pressed was enter
      const activeElement = <HTMLElement>document.activeElement;
      activeElement.blur(); // If so, blur the element to close the keyboard
    }
  }

  // ^- ALL CODE RELATED TO OTHER FUNCTIONS -^ //
}
