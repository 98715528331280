import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'showImageByName',
    pure: true
})
export class ShowImageByNamePipe implements PipeTransform {
  transform(items: MtgCard[], name: string, edition?: string): string {
    if (!items || !name) {
        // If either input is null
        return '../assets/card_images/Card_Back_200px.jpg';
    }

    // Loop through the cardcollection
    // If edition is given, find the imagePath of the card with the given name and edition
    if (edition) { // If the edition is given
        for (let i = 0, len_a = items.length; i < len_a; i++) {
            if (items[i].name === name) {
                if (items[i].edition === edition) {
                    return items[i].imagePath;
                }
            }
        }
      } else {
        // If there is no edition given, find the imagePath of the first card with the given name
        for (let j = 0, len_b = items.length; j < len_b; j++) {
            if (items[j].name === name) {
                return items[j].imagePath;
            }
        }
      }
  }
}
