import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name : 'wishListFilter',
  pure: true
})
export class WishListFilterPipe implements PipeTransform {
  public transform(items, term: string, counter?: number) {

    // This pipe determines if the headers of the wishList should be shown or not for each category

    // console.log(items);
    // console.log(term);

    let result = false;

    return items.filter((item) => {
        // console.log(item.wishCard.name, item.wishCard.mainType[0]);
      if (item) {
        if (term === 'Creatures') {
            if (item.wishCard.mainType[0] === 'Creature' || item.wishCard.mainType[0] === 'Artifact Creature') {
                result = true;
            }
        }
        if (term === 'Spells') {
            if (item.wishCard.mainType[0] === 'Instant' || item.wishCard.mainType[0] === 'Sorcery') {
                result = true;
            }
        }
        if (term === 'Other') {
            if (item.wishCard.mainType[0] === 'Artifact' ||
             item.wishCard.mainType[0] === 'Enchantment' ||
             item.wishCard.mainType[0] === 'Planeswalker') {
                result = true;
            }
        }
        if (term === 'Land') {
            if (item.wishCard.mainType[0] === 'Land') {
                result = true;
            }
        }
      }
      // If term criteria has been met at this point, the result will show as true
      return result;
    });
  }
}
