import { Injectable, OnInit } from '@angular/core';
// Importing Http that enables http get/set requests
import { HttpClient } from '@angular/common/http';
// Importing observable that enables to return something that can be subscribed to
import { Observable } from 'rxjs';
// Importing map that enables manipulation of the observable element the http returns
import { map } from 'rxjs/operators';
// Import interface
import { MtgCard } from './../models/mtgcard.model';
// Import md5 hash
import { Md5 } from 'ts-md5/dist/md5';


@Injectable({
  providedIn: 'root'
})
export class MtgDataService implements OnInit {

  // To determine if a card value can be null, as it will never show in regular search
  public showsInSearch: boolean;

  public TCGproducts;
  public TCGproductsMap = new Map();

  constructor(public http: HttpClient) {

  }

  // ngOnInit is called after the constructor and called after the first ngOnChanges()
  ngOnInit() {

  }

  // this.mtgService.getBasketAnalysis('assets/data/basket/basket.json')
  getBasketAnalysis(input: string): Observable<any> {
    // Return an observable
    return this.http.get<any>(input);
  }

  getLocalData(input: string): Observable<MtgCard[]> {

    // Return an observable
    return this.http.get<MtgCard[]>(input);
  }

  getRemoteData(input: string): MtgCard[] {

    // Defining the constant to return later on
    const SeveralCards = [];

    // Return an observable
    this.http.get<MtgCard[]>(input)
    // .subscribe tells what to do with it
    .subscribe( data => {
      // console.log( data );

      // Fetch the TCGproductMap to be able to pinpoint the TCGProductId later on
      // source should be fetched once per release of new sets
      this.http.get<any>('assets/data/pricing/TCGcatalogue.json')
      .subscribe(products => {
        this.TCGproducts = products; //

        /* Should contain the following:
          productId,
          productName,
          // editionName,
          editionShort,
          productURL
        */

        // Try using maps described here: https://ng2.codecraft.tv/es6-typescript/mapset/
        // Loop the TCGproducts to create the TCGproductIdURLMap
        this.TCGproducts.forEach(element => {
          if (element.productId && element.productName && element.editionShort && element.productURL) { // If neither is null
            this.TCGproductsMap // (Name[Edition], productId[URL])
            .set(element.productName.toLowerCase()
            + '(' + element.editionShort.toLowerCase()
            + ')' , element.productId
            + '(' + element.productURL
            + ')' );
          }
        });

      // Define variables
      let AllEditionArray: object[][];

      // If looking for all files
      if (input.indexOf('AllPrintings') !== -1) {
      // Transform the generated JSON-object into an array
      AllEditionArray = Object.keys(data['data']).map(function(key) {
        return data['data'][key];
      });

      // If looking for a single set
      } else {
      // Transform the generated JSON-object into an array
      const EditionArray: object[] = data['data'];
      // const EditionArray: object[] = data; // OLD CODE FOR OLD SETS LIKE RNA.JSON
      AllEditionArray = [EditionArray];
      }

      console.log(AllEditionArray);

      // Looping through the array for all editions
      AllEditionArray.forEach(function(EditionArray) { // FIRST LOOP

      // Fetching the card array for each edition
      const CardsArray = EditionArray['cards'];

      // Looping through each individual card in the edition to alter its attributes
      CardsArray.forEach(function(card) { // SECOND LOOP

        // For error searching
        // console.log(card.name);

        // Create the commonplace name for a card, based on it's layout - to use in tcg-product matching
        const a_name: string = card.name;

        // *** TCG pricing attributes *** //
        // Use the above created map to fetch the productId and URL for TCG products
        let tcg_productId = '';
        let tcg_url = '';

        const map_result = this.TCGproductsMap.get(a_name.toLowerCase() + '(' + EditionArray['code'].toLowerCase() + ')');
        // console.log(map_result);

        if (map_result) { // If there is a match, update the productId and URL
          tcg_productId = map_result.replace(/ *\([^)]*\) */g, ''); // Use everything BUT what's in parenthesis
          tcg_url = map_result.match(/\(([^)]+)\)/)[1];
        }
        // console.log(tcg_productId, tcg_url);

        // *** Correction of attributes *** //

        const q = new Date();
        const m = q.getMonth();
        const d = q.getDate();
        const y = q.getFullYear() - 1;

        const lastYearDate = new Date( y, m, d);
        const compareDate = new Date(EditionArray['releaseDate']);
        const standardDate = new Date('2020-09-20'); // Any starter or expansion or core >= this date is in standard

        // *** Banlists - Updated 2021-10-20 *** //
        // https://magic.wizards.com/en/game-info/gameplay/rules-and-formats/banned-restricted
        const standard_banned: string[] = [
          'Omnath, Locus of Creation'
        ];
        const standard_restricted: string[] = [];
        const modern_banned: string[] = [
          'Ancient Den',
          'Arcum\'s Astrolabe',
          'Birthing Pod',
          'Blazing Shoal',
          'Bridge From Below',
          'Chrome Mox',
          'Cloudpost',
          'Dark Depths',
          'Deathrite Shaman',
          'Dig Through Time',
          'Dread Return',
          'Eye of Ugin',
          'Faithless Looting',
          'Field of the Dead',
          'Gitaxian Probe',
          'Glimpse of Nature',
          'Golgari Grave-Troll',
          'Great Furnace',
          'Green Sun\'s Zenith',
          'Hogaak, Arisen Necropolis',
          'Hypergenesis',
          'Krark-Clan Ironworks',
          'Mental Misstep',
          'Mox Opal',
          'Mycosynth Lattice',
          'Mystic Sanctuary',
          'Oko, Thief of Crowns',
          'Once Upon a Time',
          'Ponder',
          'Preordain',
          'Punishing Fire',
          'Rite of Flame',
          'Seat of the Synod',
          'Second Sunrise',
          'Seething Song',
          'Sensei\'s Divining Top',
          'Simian Spirit Guide',
          'Skullclamp',
          'Splinter Twin',
          'Summer Bloom',
          'Tibalt\'s Trickery',
          'Treasure Cruise',
          'Tree of Tales',
          'Umezawa\'s Jitte',
          'Uro, Titan of Nature\'s Wrath',
          'Vault of Whispers'
        ];
        const modern_restricted: string[] = [];
        const commander_banned: string[] = [
          'Amulet of Quoz',
          'Bronze Tablet',
          'Contract from Below',
          'Darkpact',
          'Demonic Attorney',
          'Jeweled Bird',
          'Rebirth',
          'Tempest Efreet',
          'Timmerian Fiends',
          'Adriana\'s Valor',
          'Advantageous Proclamation',
          'Assemble the Rank and Vile',
          'Backup Plan',
          'Brago\'s Favor',
          'Double Stroke',
          'Echoing Boon',
          'Emissary\'s Ploy',
          'Hired Heist',
          'Hold the Perimeter',
          'Hymn of the Wilds',
          'Immediate Action',
          'Incendiary Dissent',
          'Iterative Analysis',
          'Muzzio\'s Preparations',
          'Natural Unity',
          'Power Play',
          'Secret Summoning',
          'Secrets of Paradise',
          'Sentinel Dispatch',
          'Sovereign\'s Realm',
          'Summoner\'s Bond',
          'Unexpected Potential',
          'Weight Advantage',
          'Worldknit',
          'Ancestral Recall',
          'Balance',
          'Biorhythm',
          'Black Lotus',
          'Braids, Cabal Minion',
          'Chaos Orb',
          'Coalition Victory',
          'Channel',
          'Emrakul, the Aeons Torn',
          'Erayo, Soratami Ascendant',
          'Falling Star',
          'Fastbond',
          'Flash',
          'Gifts Ungiven',
          'Golos, Tireless Pilgrim',
          'Griselbrand',
          'Hullbreacher',
          'Iona, Shield of Emeria',
          'Karakas',
          'Leovold, Emissary of Trest',
          'Library of Alexandria',
          'Limited Resources',
          'Lutri, the Spellchaser',
          'Mox Emerald',
          'Mox Jet',
          'Mox Pearl',
          'Mox Ruby',
          'Mox Sapphire',
          'Panoptic Mirror',
          'Paradox Engine',
          'Primeval Titan',
          'Prophet of Kruphix',
          'Recurring Nightmare',
          'Rofellos, Llanowar Emissary',
          'Shahrazad',
          'Sundering Titan',
          'Sway of the Stars',
          'Sylvan Primordial',
          'Time Vault',
          'Time Walk',
          'Tinker',
          'Tolarian Academy',
          'Trade Secrets',
          'Upheaval',
          'Yawgmoth\'s Bargain',
          'Invoke Prejudice',
          'Cleanse',
          'Stone-Throwing Devils',
          'Pradesh Gypsies',
          'Jihad',
          'Imprison',
          'Crusade'
        ];
        const commander_restricted: string[] = [];
        const vintage_banned: string[] = [
          'Amulet of Quoz',
          'Bronze Tablet',
          'Contract from Below',
          'Darkpact',
          'Demonic Attorney',
          'Jeweled Bird',
          'Rebirth',
          'Tempest Efreet',
          'Timmerian Fiends',
          'Adriana\'s Valor',
          'Advantageous Proclamation',
          'Assemble the Rank and Vile',
          'Backup Plan',
          'Brago\'s Favor',
          'Double Stroke',
          'Echoing Boon',
          'Emissary\'s Ploy',
          'Hired Heist',
          'Hold the Perimeter',
          'Hymn of the Wilds',
          'Immediate Action',
          'Incendiary Dissent',
          'Iterative Analysis',
          'Muzzio\'s Preparations',
          'Natural Unity',
          'Power Play',
          'Secret Summoning',
          'Secrets of Paradise',
          'Sentinel Dispatch',
          'Sovereign\'s Realm',
          'Summoner\'s Bond',
          'Unexpected Potential',
          'Weight Advantage',
          'Worldknit',
          'Chaos Orb',
          'Falling Star',
          'Shahrazad',
          'Invoke Prejudice',
          'Cleanse',
          'Stone-Throwing Devils',
          'Pradesh Gypsies',
          'Jihad',
          'Imprison',
          'Crusade'
        ];
        const vintage_restricted: string[] = [
          'Ancestral Recall',
          'Balance',
          'Black Lotus',
          'Brainstorm',
          'Chalice of the Void',
          'Channel',
          'Demonic Consultation',
          'Demonic Tutor',
          'Dig Through Time',
          'Flash',
          'Gitaxian Probe',
          'Golgari Grave-Troll',
          'Gush',
          'Imperial Seal',
          'Karn, the Great Creator',
          'Library of Alexandria',
          'Lion\'s Eye Diamond',
          'Lodestone Golem',
          'Lotus Petal',
          'Mana Crypt',
          'Mana Vault',
          'Memory Jar',
          'Mental Misstep',
          'Merchant Scroll',
          'Mind\'s Desire',
          'Monastery Mentor',
          'Mox Emerald',
          'Mox Jet',
          'Mox Pearl',
          'Mox Ruby',
          'Mox Sapphire',
          'Mystic Forge',
          'Mystical Tutor',
          'Narset, Parter of Veils',
          'Necropotence',
          'Ponder',
          'Sol Ring',
          'Strip Mine',
          'Thorn of Amethyst',
          'Time Vault',
          'Time Walk',
          'Timetwister',
          'Tinker',
          'Tolarian Academy',
          'Treasure Cruise',
          'Trinisphere',
          'Vampiric Tutor',
          'Wheel of Fortune',
          'Windfall',
          'Yawgmoth\'s Will'
        ];
        const legacy_banned: string[] = [
          'Amulet of Quoz',
          'Bronze Tablet',
          'Contract from Below',
          'Darkpact',
          'Demonic Attorney',
          'Jeweled Bird',
          'Rebirth',
          'Tempest Efreet',
          'Timmerian Fiends',
          'Adriana\'s Valor',
          'Advantageous Proclamation',
          'Assemble the Rank and Vile',
          'Backup Plan',
          'Brago\'s Favor',
          'Double Stroke',
          'Echoing Boon',
          'Emissary\'s Ploy',
          'Hired Heist',
          'Hold the Perimeter',
          'Hymn of the Wilds',
          'Immediate Action',
          'Incendiary Dissent',
          'Iterative Analysis',
          'Muzzio\'s Preparations',
          'Natural Unity',
          'Power Play',
          'Secret Summoning',
          'Secrets of Paradise',
          'Sentinel Dispatch',
          'Sovereign\'s Realm',
          'Summoner\'s Bond',
          'Unexpected Potential',
          'Weight Advantage',
          'Worldknit',
          'Ancestral Recall',
          'Arcum\'s Astrolabe',
          'Balance',
          'Bazaar of Baghdad',
          'Black Lotus',
          'Channel',
          'Chaos Orb',
          'Deathrite Shaman',
          'Demonic Consultation',
          'Demonic Tutor',
          'Dig Through Time',
          'Dreadhorde Arcanist',
          'Earthcraft',
          'Falling Star',
          'Fastbond',
          'Flash',
          'Frantic Search',
          'Gitaxian Probe',
          'Goblin Recruiter',
          'Gush',
          'Hermit Druid',
          'Imperial Seal',
          'Library of Alexandria',
          'Lurrus of the Dream-Den',
          'Mana Crypt',
          'Mana Drain',
          'Mana Vault',
          'Memory Jar',
          'Mental Misstep',
          'Mind Twist',
          'Mind’s Desire',
          'Mishra’s Workshop',
          'Mox Emerald',
          'Mox Jet',
          'Mox Pearl',
          'Mox Ruby',
          'Mox Sapphire',
          'Mystical Tutor',
          'Necropotence',
          'Oath of Druids',
          'Oko, Thief of Crowns',
          'Sensei\'s Divining Top',
          'Shahrazad',
          'Skullclamp',
          'Sol Ring',
          'Strip Mine',
          'Survival of the Fittest',
          'Time Vault',
          'Time Walk',
          'Timetwister',
          'Tinker',
          'Tolarian Academy',
          'Treasure Cruise',
          'Underworld Breach',
          'Vampiric Tutor',
          'Wheel of Fortune',
          'Windfall',
          'Wrenn and Six',
          'Yawgmoth\'s Bargain',
          'Yawgmoth\'s Will',
          'Zirda, the Dawnwaker',
          'Invoke Prejudice',
          'Cleanse',
          'Stone-Throwing Devils',
          'Pradesh Gypsies',
          'Jihad',
          'Imprison',
          'Crusade'
        ];
        const legacy_restricted: string[] = [];
        // *** End of Banlists *** //

        /**
         * LEGALITY (entirely based on card name)
         */
        const a_standard_banned: boolean = (standard_banned.indexOf(card.name) > - 1) ? true : false;
        const a_standard_restricted: boolean = (standard_restricted.indexOf(card.name) > - 1) ? true : false;
        const a_standard_legal: boolean = (!a_standard_banned && !a_standard_restricted) ? true : false;

        const a_modern_banned: boolean = (modern_banned.indexOf(card.name) > - 1) ? true : false;
        const a_modern_restricted: boolean = (modern_restricted.indexOf(card.name) > - 1) ? true : false;
        const a_modern_legal: boolean = (!a_modern_banned && !a_modern_restricted) ? true : false;

        const a_commander_banned: boolean = (commander_banned.indexOf(card.name) > - 1) ? true : false;
        const a_commander_restricted: boolean = (commander_restricted.indexOf(card.name) > - 1) ? true : false;
        const a_commander_legal: boolean = (!a_commander_banned && !a_commander_restricted) ? true : false;

        const a_legacy_banned: boolean = (legacy_banned.indexOf(card.name) > - 1) ? true : false;
        const a_legacy_restricted: boolean = (legacy_restricted.indexOf(card.name) > - 1) ? true : false;
        const a_legacy_legal: boolean = (!a_legacy_banned && !a_legacy_restricted) ? true : false;

        const a_vintage_banned: boolean = (vintage_banned.indexOf(card.name) > - 1) ? true : false;
        const a_vintage_restricted: boolean = (vintage_restricted.indexOf(card.name) > - 1) ? true : false;
        const a_vintage_legal: boolean = (!a_vintage_banned && !a_vintage_restricted) ? true : false;


        /**
         * FORMAT
         */
        // Create an array holding all formats for each card
        const a_format = [];
        if (card.legalities) { // If legalities exists on a card
          // Check only if the format listing appears, as legal/restricted/banned is handled elsewhere
          // Checking for standard (where releaseDate is later than standardDate)
          if (card.legalities.standard && compareDate >= standardDate) {
            a_format.push('Standard');
          }

          // Checking for modern
          if (card.legalities.modern) {
            a_format.push('Modern');
          }

          // Checking for commander
          if (card.legalities.commander) {
            a_format.push('Commander');
          }

          // Checking for legacy
          if (card.legalities.legacy) {
            a_format.push('Legacy');
          }

          // Checking for vintage
          if (card.legalities.vintage) {
            a_format.push('Vintage');
          }

          // For all other cards, check if they are missing a 'Standard' in legality,
          // and are released in the current standard cycle,
          // and is of type expansion or starter or core
          // and only then add them to standard
          if (card.legalities.standard == null &&
           (EditionArray['type'] === 'expansion' ||
            EditionArray['type'] === 'starter' ||
            EditionArray['type'] === 'core') &&
            compareDate >= standardDate) {
            a_format.push('Standard');
          }
        } else { // If the sets doesn't yet have legalities printed
        console.log('legalities missing: ', card.name, EditionArray['name']);

          // Most recent expansions doesn't yet have rulings and must be added manually
          // If a set is of type expansion or starter or core,
          // and has been released the last year,
          // add it's card as available in Standard, Modern, Commander, Legacy & Vintage
          if ((EditionArray['type'] === 'expansion' ||
           EditionArray['type'] === 'starter' ||
           EditionArray['type'] === 'core') &&
           compareDate >= lastYearDate) {
            // console.log(EditionArray['name'], compareDate, lastYearDate);
            a_format.push('Standard');
            a_format.push('Modern');
            a_format.push('Commander');
            a_format.push('Legacy');
            a_format.push('Vintage');
          } else {
            // All cards are allowed in Commander, Legacy, and Vintage (exceptions handled by banlist)
            a_format.push('Commander');
            a_format.push('Legacy');
            a_format.push('Vintage');
          }
          /* If a set is of type commander, and has been released the last year, add it's cards as legal in Commander, Legacy & Vintage
          if (EditionArray['type'] === 'commander' ||
          EditionArray['type'] === 'draft_innovation' &&
          compareDate >= lastYearDate) {
            // console.log(EditionArray['name'], compareDate, lastYearDate);
            a_format.push('Commander');
            a_format.push('Legacy');
            a_format.push('Vintage');
          }*/
        }

        /**
         * FOREGIN DATA
         */
        // Chinese (simplified)
        let c_multiverseId_CH;
        let c_imagePath_CH = '';
        let c_name_CH = '';
        let c_text_CH = '';
        let c_type_CH = '';
        // German
        let c_multiverseId_DE;
        let c_imagePath_DE = '';
        let c_name_DE = '';
        let c_text_DE = '';
        let c_type_DE = '';
        // Spanish
        let c_multiverseId_ES;
        let c_imagePath_ES = '';
        let c_name_ES = '';
        let c_text_ES = '';
        let c_type_ES = '';
        // French
        let c_multiverseId_FR;
        let c_imagePath_FR = '';
        let c_name_FR = '';
        let c_text_FR = '';
        let c_type_FR = '';
        // Italian
        let c_multiverseId_IT;
        let c_imagePath_IT = '';
        let c_name_IT = '';
        let c_text_IT = '';
        let c_type_IT = '';
        // Japanese
        let c_multiverseId_JP;
        let c_imagePath_JP = '';
        let c_name_JP = '';
        let c_text_JP = '';
        let c_type_JP = '';
        // Korean
        let c_multiverseId_KS;
        let c_imagePath_KS = '';
        let c_name_KS = '';
        let c_text_KS = '';
        let c_type_KS = '';
        // Portugese
        let c_multiverseId_PT;
        let c_imagePath_PT = '';
        let c_name_PT = '';
        let c_text_PT = '';
        let c_type_PT = '';
        // Russian
        let c_multiverseId_RU;
        let c_imagePath_RU = '';
        let c_name_RU = '';
        let c_text_RU = '';
        let c_type_RU = '';

        if (card.foreignData) { // If the card has foreignData associated
          for (let j = 0, len = card.foreignData.length; j < len; j++) {
            if (card.foreignData[j].language === 'Chinese Simplified') {
                // If any looped card foreign language match the requested conditions,
                // Update it's related variables
                c_multiverseId_CH = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
                c_name_CH = (card.foreignData[j].name) ? card.foreignData[j].name : '';
                c_text_CH = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
                c_type_CH = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'German') {
              // Update it's related variables
              c_multiverseId_DE = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_DE = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_DE = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_DE = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Spanish') {
              // Update it's related variables
              c_multiverseId_ES = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_ES = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_ES = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_ES = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'French') {
              // Update it's related variables
              c_multiverseId_FR = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_FR = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_FR = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_FR = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Italian') {
              // Update it's related variables
              c_multiverseId_IT = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_IT = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_IT = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_IT = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Japanese') {
              // Update it's related variables
              c_multiverseId_JP = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_JP = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_JP = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_JP = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Korean') {
              // Update it's related variables
              c_multiverseId_KS = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_KS = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_KS = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_KS = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Portuguese (Brazil)') {
              // Update it's related variables
              c_multiverseId_PT = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_PT = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_PT = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_PT = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
            if (card.foreignData[j].language === 'Russian') {
              // Update it's related variables
              c_multiverseId_RU = (card.foreignData[j].multiverseId) ? card.foreignData[j].multiverseId : card.identifiers.multiverseId;
              c_name_RU = (card.foreignData[j].name) ? card.foreignData[j].name : '';
              c_text_RU = (card.foreignData[j].text) ? card.foreignData[j].text.replace(/ *\([^)]*\) */g, '') : '';
              c_type_RU = (card.foreignData[j].type) ? card.foreignData[j].type : '';
            }
          }
        }

        /**
         * ADVANCED ATTRIBUTE REQUIREMENT
         */
        if (EditionArray['type'] === 'expansion' ||
           EditionArray['type'] === 'starter' ||
           EditionArray['type'] === 'core' ||
           EditionArray['type'] === 'commander' || // May contain new cards for commander
           EditionArray['type'] === 'draft_innovation') { // May contain new cards for commander, legacy, and vintage
           this.showsInSearch = true;
         } else {
           this.showsInSearch = false;
         }

        /**
         * ADDITIONAL SEARCH EXCLUSIONS
         */
        // Hide cards with frameEffects extended art or showcase
        if (card.frameEffects) { // If the card has frame effects associated
          for (let k = 0, len = card.frameEffects.length; k < len; k++) {
            if (card.frameEffects[k].toLowerCase().match(/\b(extendedart)\b/g)) {
              this.showsInSearch = false;
            }
            if (card.frameEffects[k].toLowerCase().match(/\b(showcase)\b/g)) {
              this.showsInSearch = false;
            }
          }
        }

        /**
         * CARD IMAGE
         */
        let a_imagePath: string;
        let c_hasMultiverseId: boolean;
        if (card.identifiers.multiverseId) { // If the card exists on gatherer
          // Run a simple md5 on multiverseid, then look for the image in a folder
          // based on the first character, making sure it's of type .jpg. I.e. /b/b0129...c4dec1.jpg
          const imageHash = Md5.hashStr(card.identifiers.multiverseId.toString()).toString();
          a_imagePath = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
          // console.log(a_imagePath);
          // Also define if the card has a multiverseId or not
          c_hasMultiverseId = true;
        } else {
          // If the card has no multiverseid, return the back of a card as the image
          a_imagePath = '../assets/card_images/Card_Back_200px.jpg';
          // Also define if the card has a multiverseId or not
          c_hasMultiverseId = false;
          // console.log('Missing multiverseId: ', card.name, EditionArray['name']);
        }

        /**
         * CARD IMAGE - FOREIGN
         */
        // Chinese
        if (c_multiverseId_CH) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_CH.toString()).toString();
          c_imagePath_CH = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_CH = a_imagePath;
        }
        // German
        if (c_multiverseId_DE) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_DE.toString()).toString();
          c_imagePath_DE = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_DE = a_imagePath;
        }
        // Spanish
        if (c_multiverseId_ES) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_ES.toString()).toString();
          c_imagePath_ES = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_ES = a_imagePath;
        }
        // French
        if (c_multiverseId_FR) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_FR.toString()).toString();
          c_imagePath_FR = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_FR = a_imagePath;
        }
        // Italian
        if (c_multiverseId_IT) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_IT.toString()).toString();
          c_imagePath_IT = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_IT = a_imagePath;
        }
        // Japanese
        if (c_multiverseId_JP) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_JP.toString()).toString();
          c_imagePath_JP = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_JP = a_imagePath;
        }
        // Korean
        if (c_multiverseId_KS) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_KS.toString()).toString();
          c_imagePath_KS = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_KS = a_imagePath;
        }
        // Portuguese
        if (c_multiverseId_PT) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_PT.toString()).toString();
          c_imagePath_PT = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_PT = a_imagePath;
        }
        // Russian
        if (c_multiverseId_RU) { // If there is a multiverseId for the card
          // Create a unique imagePath for that card
          const imageHash = Md5.hashStr(c_multiverseId_RU.toString()).toString();
          c_imagePath_RU = '../assets/card_images/' + imageHash.charAt(0) + '/' + imageHash + '.jpg';
        } else {
          // If not, use the regular imagePath
          c_imagePath_RU = a_imagePath;
        }


        /**
         * PERMANENT
         */
        let a_permanent: string;
        if (card.colors) { // If the color exists on a card, check single or multicolor
          if (card.colors.length > 1) { // Multicolor
            a_permanent = 'Multicolor';
          } else if (card.colors[0] === 'W') { // Permanents
            a_permanent = 'White';
          } else if (card.colors[0] === 'U') { // Permanents
            a_permanent = 'Blue';
          } else if (card.colors[0] === 'B') { // Permanents
            a_permanent = 'Black';
          } else if (card.colors[0] === 'R') { // Permanents
            a_permanent = 'Red';
          } else if (card.colors[0] === 'G') { // Permanents
            a_permanent = 'Green';
          } else if (card.colors.length === 0) { // Land
            a_permanent = 'Colorless'; // Empty array
          }
        } else { // If no color, then brand colorless
            a_permanent = 'Colorless'; // Empty array
        }


        /**
         * MAINTYPE
         */
        // In case of Artifact Creature - create the new type ArtifactCreature
        // In case of Enchantment Creature, Enchantment Artifact, Tribal XYZ, and all other cases, keep as is
          if (card.types) {
            if (card.types.length > 1) {
              // console.log(card.types);
              // If mainTypes contain both Artifact and Creature, determine ArtifactCreature
              if (card.types[0] === 'Artifact' && card.types[1] === 'Creature') {
                card.types[0] = 'Artifact Creature'; // Change the value at position 0
                card.types.splice(1, 1); // Delete the value at position 1
              }
              // console.log(card.types);
            }
          } else {
            console.log(card.name, EditionArray['name']); // Tokens
            card.types = ['Unknown']; // Give a mainType to the card that doesn't have any
          }

        /**
         * POWER/TOUGHNESS
         */
        // If power and toughness exists, create power/toughness, else set to null
        const a_pt = (card.power && card.toughness) ? card.power + '/' + card.toughness : null;

        /**
         * RARITY - putting things back the way they were in MtgJSON v3
         */
        if (card.rarity === 'common') {
          card.rarity = 'Common';
        } else if (card.rarity === 'uncommon') {
          card.rarity = 'Uncommon';
        } else if (card.rarity === 'rare') {
          card.rarity = 'Rare';
        } else if (card.rarity === 'mythic') {
          card.rarity = 'Mythic Rare';
        } else if (card.rarity === 'basic') {
          card.rarity = 'Basic';
        }

        /**
         * VALUE - adding support for price ranges
         * $ <$1, 2$ $1 < $3, 3$ $3 < $5, 4$ $5 < $10, 5$ $10 < $25, 6$ $25 < $50, 7$ $50+
         */
        const a_price = 0; // Default if unknown
        const a_value = '?'; // Default if unknown
        // PRICES ARE NOW FETCHED FROM A SEPARATE FILE ALTOGETHER - NEED TO UPDATE THIS!
        /*
        if (card.prices) { // If prices exists on the card
          // Set the price to the latest known
          a_price = card.prices.paper['2019-04-24'];
          // console.log(a_price);
          // Also set the value range
          if (a_price < 1 && a_price > 0) {
            a_value = '1$';
          } else if (a_price < 3 && a_price > 0) {
            a_value = '2$';
          } else if (a_price < 5 && a_price > 0) {
            a_value = '3$';
          } else if (a_price < 10 && a_price > 0) {
            a_value = '4$';
          } else if (a_price < 25 && a_price > 0) {
            a_value = '5$';
          } else if (a_price < 50 && a_price > 0) {
            a_value = '6$';
          } else if (a_price >= 50) {
            a_value = '7$';
          }
        } // If prices do not exist on the card, use the mcm pricing to determine card pricing
        // console.log(card.name, a_price, a_value);
        */


        /**
         * CMC
         */
        card.cmc = (!card.convertedManaCost) ? '0' : Math.floor(card.convertedManaCost).toString(); // If card cmc is missing, set it to 0
        // console.log(card.cmc); // convertedManaCost (i.e. 5.0)

        /**
         * PRINTINGS
         */
        // Make sure to update this list whenever a new set comes out ()
        // Contains Core sets, Starters, Expansions, Commander, and Draft Innovation
        // https://mtg.fandom.com/wiki/Set
        // --- Code updated until Innistrad: Midnight Hunt - MID --- //
        const core_edition_map: string[] = [
          'WHO', 'LTR', 'LTC', 'MAT', 'PCTB', 'MOM',
          'MOC', 'ONE', 'ONC', 'SCD', 'BRO', 'BRC', '40K', 'DMU', 'DMC', 'PHED',
          'NCC', 'SNC', 'NEO', 'NEC', 'VOC', 'VOW', 'MIC', 'MID', 'AFC', 'AFR',
          'MH2', 'H1R', 'C21', 'STX', 'KHC', 'KHM', 'CMR', 'ZNC', 'ZNR', 'ANB',
          'JMP', 'M21', 'IKO', 'C20', 'THB', 'ELD', 'M20', 'C19', 'MH1', 'WAR',
          'RNA', 'GRN', 'C18', 'M19', 'CM2', 'BBD', 'DOM', 'RIX', 'XLN', 'C17',
          'HOU', 'CMA', 'AKH', 'W17', 'AET', 'C16', 'KLD', 'CN2', 'EMN', 'W16',
          'SOI', 'OGW', 'C15', 'BFZ', 'ORI', 'DTK', 'FRF', 'C14', 'KTK', 'M15',
          'CNS', 'JOU', 'BNG', 'C13', 'THS', 'M14', 'DGM', 'GTC', 'CM1', 'RTR',
          'M13', 'AVR', 'DKA', 'ISD', 'M12', 'CMD', 'NPH', 'MBS', 'SOM', 'M11',
          'ROE', 'WWK', 'ZEN', 'M10', 'ARB', 'CON', 'ALA', 'EVE', 'SHM', 'MOR',
          'LRW', '10E', 'FUT', 'PLC', 'TSB', 'TSP', 'CSP', 'DIS', 'GPT', 'RAV',
          '9ED', 'SOK', 'BOK', 'CHK', '5DN', 'DST', 'MRD', '8ED', 'SCG', 'LGN',
          'ONS', 'JUD', 'TOR', 'ODY', 'APC', '7ED', 'PLS', 'INV', 'PCY', 'S00',
          'NEM', 'MMQ', 'S99', 'UDS', 'PTK', '6ED', 'ULG', 'USG', 'EXO', 'P02',
          'STH', 'TMP', 'WTH', 'POR', '5ED', 'VIS', 'MIR', 'ALL', 'HML', 'ICE',
          '4ED', 'FEM', 'DRK', 'LEG', '3ED', 'ATQ', 'ARN', '2ED', 'LEB', 'LEA'
        ];

        // Create a new array for identifying first and last print of any searchable card
        // Based on card.printings
        const orderedPrintings: string[] =
        core_edition_map.filter(f => card.printings.includes(f));
        // console.log(card.name, orderedPrintings);

        // Reformat card.printings to remove Sets not on Gatherer (not in cardCollection) from the list
        // Also removing any promos from the list (any set with a 4 letter code that starts with a lowercase 'p')
        const cleanPrintings: string[] = [];
        for (let j = 0, len = card.printings.length; j < len; j++) {
          if (
            card.printings[j] === 'ATH'
            || card.printings[j] === 'CED'
            || card.printings[j] === 'CEI'
            || card.printings[j] === 'CST'
            || card.printings[j] === 'DKM'
            || card.printings[j] === 'DPA'
            || card.printings[j] === 'DVD'
            || card.printings[j] === 'F03'
            || card.printings[j] === 'F05'
            || card.printings[j] === 'F15'
            || card.printings[j] === 'FBB'
            || card.printings[j] === 'G09'
            || card.printings[j] === 'G10'
            || card.printings[j] === 'GVL'
            || card.printings[j] === 'ITP'
            || card.printings[j] === 'ME1'
            || card.printings[j] === 'ME2'
            || card.printings[j] === 'ME3'
            || card.printings[j] === 'ME4'
            || card.printings[j] === 'MP2'
            || card.printings[j] === 'OVNT'
            || card.printings[j] === 'P07'
            || card.printings[j] === 'PARL'
            || card.printings[j] === 'PPRE'
            || card.printings[j] === 'PRM'
            || card.printings[j] === 'PTC'
            || card.printings[j] === 'PZ1'
            || card.printings[j] === 'PZ2'
            || card.printings[j] === 'RQS'
            || card.printings[j] === 'SUM'
            || card.printings[j] === 'TD0'
            || card.printings[j] === 'TD2'
            || card.printings[j] === 'TPR'
            || card.printings[j] === 'VMA'
            || card.printings[j] === 'WC00'
            || card.printings[j] === 'WC03'
            || card.printings[j] === 'WC04'
            || card.printings[j] === 'WC97'
            || card.printings[j] === 'WC98'
            || (card.printings[j].length === 4 && card.printings[j].charAt(0) === 'P')
            || (card.printings[j].length === 4 && card.printings[j].charAt(0) === 'W' && card.printings[j].charAt(1) === 'C')
          ) {
              // If match, do nothing
          } else {
            // If they do not match the requested conditions
            // add it to the cleanPrintings array
            cleanPrintings.push(card.printings[j]);
          }
        }

        // _firstPrint
        // const c_firstPrint: boolean = (orderedPrintings[orderedPrintings.length - 1] === EditionArray['code']) ? true : false;
        const c_firstPrint: boolean = (!card.isReprint);

        // _lastPrint
        const c_lastPrint: boolean = (orderedPrintings[0] === EditionArray['code']) ? true : false;

        // _basicLand
        const c_basicLand: boolean = (card.type && card.type.toLowerCase().indexOf('basic') >= 0) ? true : false;

        // _subColorBlack
        const c_subcolorBlack: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('b') >= 0) ? true : false;

        // _subColorBlue
        const c_subcolorBlue: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('u') >= 0) ? true : false;

        // _subColorBlack
        const c_subcolorRed: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('r') >= 0) ? true : false;

        // _subColorBlack
        const c_subcolorGreen: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('g') >= 0) ? true : false;

        // _subColorBlack
        const c_subcolorWhite: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('w') >= 0) ? true : false;

        // _hasXCost
        const c_hasXCost: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('x') >= 0) ? true : false;

        // _hasHCost
        const c_hasHCost: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('/') >= 0) ? true : false;

        // _hasPCost
        const c_hasPCost: boolean = (card.manaCost && card.manaCost.toLowerCase().indexOf('p') >= 0) ? true : false;

        // card text - remove all contents of parenthesis helptext
        // this helps when searching for abilities below, to ignore finding abilities in help text
        // we also replace any occurrences of the card name in the text with '[THIS]' to prevent finding abilities on card names
        // Test replacing weird characters in the cardname before using it in regex
        const re = new RegExp(card.name.replace(/\+[0-9]/, ''), 'g');
        let a_text: string = (card.text) ? card.text.replace(/ *\([^)]*\) */g, '').replace(re, '[THIS]') : null;

        /*
        // Escape sensitive regex characters, then replace wildcards with their respective regex counterparts
        let newterm = term.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&').replace('\\*', '.*').replace('\\?', '.');
        let regExp = new RegExp('\\b' + newterm, 'gi');
        */

        /**
         * KEYWORD ABILITIES
         */

        /**
         * EVERGREEN
         */

        // _deathtouch
        const a_deathtouch: boolean = (a_text && a_text.toLowerCase().match(/\b(deathtouch)\b/g) != null) ? true : false;

        // _defender
        const a_defender: boolean = (a_text && a_text.toLowerCase().match(/\b(defender)\b/g) != null) ? true : false;

        // _double strike
        const a_double_strike: boolean = (a_text && a_text.toLowerCase().match(/\b(double strike)\b/g) != null) ? true : false;

        // _echant
        const a_enchant: boolean = (a_text && a_text.toLowerCase().match(/\b(enchant)\b/g) != null) ? true : false;

        // _equip
        const a_equip: boolean = (a_text && a_text.toLowerCase().match(/\b(equip)\b/g) != null) ? true : false;

        // _first strike
        const a_first_strike: boolean = (a_text && a_text.toLowerCase().match(/\b(first strike)\b/g) != null) ? true : false;

        // _flash
        const a_flash: boolean = (a_text && a_text.toLowerCase().match(/\b(flash)\b/g) != null) ? true : false;

        // _flying
        const a_flying: boolean = (a_text && a_text.toLowerCase().match(/\b(flying)\b/g) != null) ? true : false;

        // _haste
        const a_haste: boolean = (a_text && a_text.toLowerCase().match(/\b(haste)\b/g) != null) ? true : false;

        // _hexproof
        const a_hexproof: boolean = (a_text && a_text.toLowerCase().match(/\b(hexproof)\b/g) != null) ? true : false;

        // _indestructible
        const a_indestructible: boolean = (a_text && a_text.toLowerCase().match(/\b(indestructible)\b/g) != null) ? true : false;

        // _lifelink
        const a_lifelink: boolean = (a_text && a_text.toLowerCase().match(/\b(lifelink)\b/g) != null) ? true : false;

        // _menace
        const a_menace: boolean = (a_text && a_text.toLowerCase().match(/\b(menace)\b/g) != null) ? true : false;

        // _prowess
        const a_prowess: boolean = (a_text && a_text.toLowerCase().match(/\b(prowess)\b/g) != null) ? true : false;

        // _reach
        const a_reach: boolean = (a_text && a_text.toLowerCase().match(/\b(reach)\b/g) != null) ? true : false;

        // _trample
        const a_trample: boolean = (a_text && a_text.toLowerCase().match(/\b(trample)\b/g) != null) ? true : false;

        // _vigilance
        const a_vigilance: boolean = (a_text && a_text.toLowerCase().match(/\b(vigilance)\b/g) != null) ? true : false;

        // _ward
        const a_ward: boolean = (a_text && a_text.toLowerCase().match(/\b(ward)\b/g) != null) ? true : false;


        /**
         * OTHER
         */

        // _absorb
        const a_absorb: boolean = (a_text && a_text.toLowerCase().match(/\b(absorb)\b/g) != null) ? true : false;

        // _affinity
        const a_affinity: boolean = (a_text && a_text.toLowerCase().match(/\b(affinity)\b/g) != null) ? true : false;

        // _afflict
        // using regexp to only search for the whole word not preceded or succeded by any letter
        const a_afflict: boolean = (a_text && a_text.toLowerCase().match(/\b(afflict)\b/g) != null) ? true : false;

        // _afterlife
        const a_afterlife: boolean = (a_text && a_text.toLowerCase().match(/\b(afterlife)\b/g) != null) ? true : false;

        // _aftermath
        const a_aftermath: boolean = (a_text && a_text.toLowerCase().match(/\b(aftermath)\b/g) != null) ? true : false;

        // _amplify
        const a_amplify: boolean = (a_text && a_text.toLowerCase().match(/\b(amplify)\b/g) != null) ? true : false;

        // _annihilator
        const a_annihilator: boolean = (a_text && a_text.toLowerCase().match(/\b(annihilator)\b/g) != null) ? true : false;

        // _ascend
        const a_ascend: boolean = (a_text && a_text.toLowerCase().match(/\b(ascend)\b/g) != null) ? true : false;

        // _aura_swap
        const a_aura_swap: boolean = (a_text && a_text.toLowerCase().match(/\b(aura swap)\b/g) != null) ? true : false;

        // _awaken
        const a_awaken: boolean = (a_text && a_text.toLowerCase().match(/\b(awaken)\b/g) != null) ? true : false;

        // _banding <-- outdated evergreen
        const a_banding: boolean = (a_text && a_text.toLowerCase().match(/\b(banding)\b/g) != null) ? true : false;

        // _battle_cry
        const a_battle_cry: boolean = (a_text && a_text.toLowerCase().match(/\b(battle cry)\b/g) != null) ? true : false;

        // _bestow
        const a_bestow: boolean = (a_text && a_text.toLowerCase().match(/\b(bestow)\b/g) != null) ? true : false;

        // _bloodthirst
        const a_bloodthirst: boolean = (a_text && a_text.toLowerCase().match(/\b(bloodthirst)\b/g) != null) ? true : false;

        // _boast
        const a_boast: boolean = (a_text && a_text.toLowerCase().match(/\b(boast)\b/g) != null) ? true : false;

        // _bushido
        const a_bushido: boolean = (a_text && a_text.toLowerCase().match(/\b(bushido)\b/g) != null) ? true : false;

        // _buyback
        const a_buyback: boolean = (a_text && a_text.toLowerCase().match(/\b(buyback)\b/g) != null) ? true : false;

        // _cascade
        const a_cascade: boolean = (a_text && a_text.toLowerCase().match(/\b(cascade)\b/g) != null) ? true : false;

        // _champion
        const a_champion: boolean = (a_text && a_text.toLowerCase().match(/\b(champion)\b/g) != null) ? true : false;

        // _changeling
        const a_changeling: boolean = (a_text && a_text.toLowerCase().match(/\b(changeling)\b/g) != null) ? true : false;

        // _cipher
        const a_cipher: boolean = (a_text && a_text.toLowerCase().match(/\b(cipher)\b/g) != null) ? true : false;

        // _companion
        const a_companion: boolean = (a_text && a_text.toLowerCase().match(/\b(companion)\b/g) != null) ? true : false;

        // _conspire
        const a_conspire: boolean = (a_text && a_text.toLowerCase().match(/\b(conspire)\b/g) != null) ? true : false;

        // _convoke
        const a_convoke: boolean = (a_text && a_text.toLowerCase().match(/\b(convoke)\b/g) != null) ? true : false;

        // _crew
        const a_crew: boolean = (a_text && a_text.toLowerCase().match(/\b(crew [0-9])\b/g) != null) ? true : false;

        // _cumulative_upkeep
        const a_cumulative_upkeep: boolean = (a_text && a_text.toLowerCase().match(/\b(cumulative upkeep)\b/g) != null) ? true : false;

        // _cycling
        const a_cycling: boolean = (a_text && a_text.toLowerCase().match(/\b(cycling|cycles|cycled)\b/g) != null) ? true : false;

        // _dash
        const a_dash: boolean = (a_text && a_text.toLowerCase().match(/\b(dash)\b/g) != null) ? true : false;

        // _daybound
        const a_daybound: boolean = (a_text && a_text.toLowerCase().match(/\b(daybound)\b/g) != null) ? true : false;

        // _decayed
        const a_decayed: boolean = (a_text && a_text.toLowerCase().match(/\b(decayed)\b/g) != null) ? true : false;

        // _delve
        const a_delve: boolean = (a_text && a_text.toLowerCase().match(/\b(delve)\b/g) != null) ? true : false;

        // _dethrone
        const a_dethrone: boolean = (a_text && a_text.toLowerCase().match(/\b(dethrone)\b/g) != null) ? true : false;

        // _devoid
        const a_devoid: boolean = (a_text && a_text.toLowerCase().match(/\b(devoid)\b/g) != null) ? true : false;

        // _devour
        const a_devour: boolean = (a_text && a_text.toLowerCase().match(/\b(devour)\b/g) != null) ? true : false;

        // _disturb
        const a_disturb: boolean = (a_text && a_text.toLowerCase().match(/\b(disturb)\b/g) != null) ? true : false;

        // _dredge
        const a_dredge: boolean = (a_text && a_text.toLowerCase().match(/\b(dredge)\b/g) != null) ? true : false;

        // _echo
        const a_echo: boolean = (a_text && a_text.toLowerCase().match(/\b(echo)\b/g) != null) ? true : false;

        // _embalm
        const a_embalm: boolean = (a_text && a_text.toLowerCase().match(/\b(embalm)\b/g) != null) ? true : false;

        // _emerge
        const a_emerge: boolean = (a_text && a_text.toLowerCase().match(/\b(emerge)\b/g) != null) ? true : false;

        // _entwine
        const a_entwine: boolean = (a_text && a_text.toLowerCase().match(/\b(entwine)\b/g) != null) ? true : false;

        // _epic
        const a_epic: boolean = (a_text && a_text.toLowerCase().match(/\b(epic)\b/g) != null) ? true : false;

        // _escalate
        const a_escalate: boolean = (a_text && a_text.toLowerCase().match(/\b(escalate)\b/g) != null) ? true : false;

        // _escape
        const a_escape: boolean = (a_text && a_text.toLowerCase().match(/\b(escape)\b/g) != null) ? true : false;

        // _eternalize
        const a_eternalize: boolean = (a_text && a_text.toLowerCase().match(/\b(eternalize)\b/g) != null) ? true : false;

        // _evoke
        const a_evoke: boolean = (a_text && a_text.toLowerCase().match(/\b(evoke)\b/g) != null) ? true : false;

        // _evolve
        const a_evolve: boolean = (a_text && a_text.toLowerCase().match(/\b(evolve)\b/g) != null) ? true : false;

        // _exalted
        const a_exalted: boolean = (a_text && a_text.toLowerCase().match(/\b(exalted)\b/g) != null) ? true : false;

        // _exploit
        const a_exploit: boolean = (a_text && a_text.toLowerCase().match(/\b(exploit)\b/g) != null) ? true : false;

        // _extort
        const a_extort: boolean = (a_text && a_text.toLowerCase().match(/\b(extort)\b/g) != null) ? true : false;

        // _fabricate
        const a_fabricate: boolean = (a_text && a_text.toLowerCase().match(/\b(fabricate)\b/g) != null) ? true : false;

        // _fading
        const a_fading: boolean = (a_text && a_text.toLowerCase().match(/\b(fading)\b/g) != null) ? true : false;

        // _fear <-- outdated evergreen
        const a_fear: boolean = (a_text && a_text.toLowerCase().match(/\b(fear)\b/g) != null) ? true : false;

        // _flanking
        const a_flanking: boolean = (a_text && a_text.toLowerCase().match(/\b(flanking)\b/g) != null) ? true : false;

        // _flashback
        const a_flashback: boolean = (a_text && a_text.toLowerCase().match(/\b(flashback)\b/g) != null) ? true : false;

        // _forecast
        const a_forecast: boolean = (a_text && a_text.toLowerCase().match(/\b(forecast)\b/g) != null) ? true : false;

        // _foretell
        const a_foretell: boolean = (a_text && a_text.toLowerCase().match(/\b(foretell)\b/g) != null) ? true : false;

        // _fortify
        const a_fortify: boolean = (a_text && a_text.toLowerCase().match(/\b(fortify)\b/g) != null) ? true : false;

        // _frenzy
        const a_frenzy: boolean = (a_text && a_text.toLowerCase().match(/\b(frenzy)\b/g) != null) ? true : false;

        // _fuse
        const a_fuse: boolean = (a_text && a_text.toLowerCase().match(/\b(fuse)\b/g) != null) ? true : false;

        // _graft
        const a_graft: boolean = (a_text && a_text.toLowerCase().match(/\b(graft)\b/g) != null) ? true : false;

        // _gravestorm
        const a_gravestorm: boolean = (a_text && a_text.toLowerCase().match(/\b(gravestorm)\b/g) != null) ? true : false;

        // _haunt
        const a_haunt: boolean = (a_text && a_text.toLowerCase().match(/\b(haunt)\b/g) != null) ? true : false;

        // _hideaway
        const a_hideaway: boolean = (a_text && a_text.toLowerCase().match(/\b(hideaway)\b/g) != null) ? true : false;

        // _horsemanship <-- outdated mechanic
        const a_horsemanship: boolean = (a_text && a_text.toLowerCase().match(/\b(horsemanship)\b/g) != null) ? true : false;

        // _improvise
        const a_improvise: boolean = (a_text && a_text.toLowerCase().match(/\b(improvise)\b/g) != null) ? true : false;

        // _infect
        const a_infect: boolean = (a_text && a_text.toLowerCase().match(/\b(infect)\b/g) != null) ? true : false;

        // _ingest
        const a_ingest: boolean = (a_text && a_text.toLowerCase().match(/\b(ingest)\b/g) != null) ? true : false;

        // _intimidate <-- outdated mechanic
        const a_intimidate: boolean = (a_text && a_text.toLowerCase().match(/\b(intimidate)\b/g) != null) ? true : false;

        // _jump_start
        const a_jump_start: boolean = (a_text && a_text.toLowerCase().match(/\b(jump-start)\b/g) != null) ? true : false;

        // _kicker - or multikicker
        const a_kicker: boolean = (a_text && a_text.toLowerCase().match(/\b(kicker)\b/g) != null) ? true : false;

        // _landwalk <-- outdated evergreen
        const a_landwalk: boolean = (a_text &&
          (a_text.toLowerCase().match(/\b(islandwalk)\b/g) != null ||
          a_text.toLowerCase().match(/\b(mountainwalk)\b/g) != null ||
          a_text.toLowerCase().match(/\b(swampwalk)\b/g) != null ||
          a_text.toLowerCase().match(/\b(plainswalk)\b/g) != null ||
          a_text.toLowerCase().match(/\b(forestwalk)\b/g) != null ||
          a_text.toLowerCase().match(/\b(landwalk)\b/g) != null)) ? true : false;

        // _level_up
        const a_level_up: boolean = (a_text && a_text.toLowerCase().match(/\b(level up)\b/g) != null) ? true : false;

        // _living_weapon
        const a_living_weapon: boolean = (a_text && a_text.toLowerCase().match(/\b(living weapon)\b/g) != null) ? true : false;

        // _madness
        const a_madness: boolean = (a_text && a_text.toLowerCase().match(/\b(madness)\b/g) != null) ? true : false;

        // _melee
        const a_melee: boolean = (a_text && a_text.toLowerCase().match(/\b(melee)\b/g) != null) ? true : false;

        // _mentor
        const a_mentor: boolean = (a_text && a_text.toLowerCase().match(/\b(mentor)\b/g) != null) ? true : false;

        // _miracle
        const a_miracle: boolean = (a_text && a_text.toLowerCase().match(/\b(miracle)\b/g) != null) ? true : false;

        // _modular
        const a_modular: boolean = (a_text && a_text.toLowerCase().match(/\b(modular)\b/g) != null) ? true : false;

        // _morph - or megamorph
        const a_morph: boolean = (a_text && a_text.toLowerCase().match(/\b(morph)\b/g) != null) ? true : false;

        // _mutate
        const a_mutate: boolean = (a_text && a_text.toLowerCase().match(/\b(mutate)\b/g) != null) ? true : false;

        // _myriad
        const a_myriad: boolean = (a_text && a_text.toLowerCase().match(/\b(myriad)\b/g) != null) ? true : false;

        // _nightbound
        const a_nightbound: boolean = (a_text && a_text.toLowerCase().match(/\b(nightbound)\b/g) != null) ? true : false;

        // _ninjutsu
        const a_ninjutsu: boolean = (a_text && a_text.toLowerCase().match(/\b(ninjutsu)\b/g) != null) ? true : false;

        // _offering
        const a_offering: boolean = (a_text && a_text.toLowerCase().match(/\b(offering)\b/g) != null) ? true : false;

        // _outlast
        const a_outlast: boolean = (a_text && a_text.toLowerCase().match(/\b(outlast)\b/g) != null) ? true : false;

        // _overload
        const a_overload: boolean = (a_text && a_text.toLowerCase().match(/\b(overload)\b/g) != null) ? true : false;

        // _partner
        const a_partner: boolean = (a_text && a_text.toLowerCase().match(/\b(partner)\b/g) != null) ? true : false;

        // _persist
        const a_persist: boolean = (a_text && a_text.toLowerCase().match(/\b(persist)\b/g) != null) ? true : false;

        // _phasing <-- outdated mechanic
        const a_phasing: boolean = (a_text && a_text.toLowerCase().match(/\b(phasing)\b/g) != null) ? true : false;

        // _poisonous
        const a_poisonous: boolean = (a_text && a_text.toLowerCase().match(/\b(poisonous)\b/g) != null) ? true : false;

        // _protection <-- outdated mechanic
        const a_protection: boolean = (a_text && a_text.toLowerCase().match(/\b(protection from)\b/g) != null) ? true : false;

        // _provoke
        const a_provoke: boolean = (a_text && a_text.toLowerCase().match(/\b(provoke)\b/g) != null) ? true : false;

        // _prowl
        const a_prowl: boolean = (a_text && a_text.toLowerCase().match(/\b(prowl)\b/g) != null) ? true : false;

        // _rampage
        const a_rampage: boolean = (a_text && a_text.toLowerCase().match(/\b(rampage)\b/g) != null) ? true : false;

        // _rebound
        const a_rebound: boolean = (a_text && a_text.toLowerCase().match(/\b(rebound)\b/g) != null) ? true : false;

        // _recover
        const a_recover: boolean = (a_text && a_text.toLowerCase().match(/\b(recover)\b/g) != null) ? true : false;

        // _reinforce
        const a_reinforce: boolean = (a_text && a_text.toLowerCase().match(/\b(reinforce)\b/g) != null) ? true : false;

        // _renown
        const a_renown: boolean = (a_text && a_text.toLowerCase().match(/\b(renown)\b/g) != null) ? true : false;

        // _replicate
        const a_replicate: boolean = (a_text && a_text.toLowerCase().match(/\b(replicate)\b/g) != null) ? true : false;

        // _retrace
        const a_retrace: boolean = (a_text && a_text.toLowerCase().match(/\b(retrace)\b/g) != null) ? true : false;

        // _riot
        const a_riot: boolean = (a_text && a_text.toLowerCase().match(/\b(riot)\b/g) != null) ? true : false;

        // _ripple
        const a_ripple: boolean = (a_text && a_text.toLowerCase().match(/\b(ripple)\b/g) != null) ? true : false;

        // _scavenge
        const a_scavenge: boolean = (a_text && a_text.toLowerCase().match(/\b(scavenge)\b/g) != null) ? true : false;

        // _shadow
        const a_shadow: boolean = (a_text && a_text.toLowerCase().match(/\b(shadow)\b/g) != null) ? true : false;

        // _shroud
        const a_shroud: boolean = (a_text && a_text.toLowerCase().match(/\b(shroud)\b/g) != null) ? true : false;

        // _skulk
        const a_skulk: boolean = (a_text && a_text.toLowerCase().match(/\b(skulk)\b/g) != null) ? true : false;

        // _soulbond
        const a_soulbond: boolean = (a_text && a_text.toLowerCase().match(/\b(soulbond)\b/g) != null) ? true : false;

        // _soulshift
        const a_soulshift: boolean = (a_text && a_text.toLowerCase().match(/\b(soulshift)\b/g) != null) ? true : false;

        // _spectacle
        const a_spectacle: boolean = (a_text && a_text.toLowerCase().match(/\b(spectacle)\b/g) != null) ? true : false;

        // _splice
        const a_splice: boolean = (a_text && a_text.toLowerCase().match(/\b(splice)\b/g) != null) ? true : false;

        // _split_second
        const a_split_second: boolean = (a_text && a_text.toLowerCase().match(/\b(split second)\b/g) != null) ? true : false;

        // _storm
        const a_storm: boolean = (a_text && a_text.toLowerCase().match(/\b(storm)\b/g) != null) ? true : false;

        // _sunburst
        const a_sunburst: boolean = (a_text && a_text.toLowerCase().match(/\b(sunburst)\b/g) != null) ? true : false;

        // _surge
        const a_surge: boolean = (a_text && a_text.toLowerCase().match(/\b(surge)\b/g) != null) ? true : false;

        // _suspend
        const a_suspend: boolean = (a_text && a_text.toLowerCase().match(/\b(suspend)\b/g) != null) ? true : false;

        // _totem_armor
        const a_totem_armor: boolean = (a_text && a_text.toLowerCase().match(/\b(totem armor)\b/g) != null) ? true : false;

        // _transfigure
        const a_transfigure: boolean = (a_text && a_text.toLowerCase().match(/\b(transfigure)\b/g) != null) ? true : false;

        // _transmute
        const a_transmute: boolean = (a_text && a_text.toLowerCase().match(/\b(transmute)\b/g) != null) ? true : false;

        // _tribute
        const a_tribute: boolean = (a_text && a_text.toLowerCase().match(/\b(tribute)\b/g) != null) ? true : false;

        // _undaunted
        const a_undaunted: boolean = (a_text && a_text.toLowerCase().match(/\b(undaunted)\b/g) != null) ? true : false;

        // _undying
        const a_undying: boolean = (a_text && a_text.toLowerCase().match(/\b(undying)\b/g) != null) ? true : false;

        // _unearth
        const a_unearth: boolean = (a_text && a_text.toLowerCase().match(/\b(unearth)\b/g) != null) ? true : false;

        // _unleash
        const a_unleash: boolean = (a_text && a_text.toLowerCase().match(/\b(unleash)\b/g) != null) ? true : false;

        // _vanishing
        const a_vanishing: boolean = (a_text && a_text.toLowerCase().match(/\b(vanishing)\b/g) != null) ? true : false;

        // _wither
        const a_wither: boolean = (a_text && a_text.toLowerCase().match(/\b(wither)\b/g) != null) ? true : false;

        /**
         * KEYWORD ACTIONS
         */

        /**
         * EVERGREEN
         */

        // _activate
        const a_activate: boolean = (a_text && a_text.toLowerCase().match(/\b(activates?)\b/g) != null) ? true : false;

        // _attach
        const a_attach: boolean = (a_text && a_text.toLowerCase().match(/\b(attach|attaches)\b/g) != null) ? true : false;

        // _cast
        const a_cast: boolean = (a_text && a_text.toLowerCase().match(/\b(casts?)\b/g) != null) ? true : false;

        // _counter
        const a_counter: boolean = (a_text && a_text.toLowerCase().match(/\b(counter target)\b/g) != null) ? true : false;

        // _copy
        const a_copy: boolean = (a_text && a_text.toLowerCase().match(/\b(copy|copies)\b/g) != null &&
        a_text.toLowerCase().match(/\b(a copy|are copies|become copies)\b/g) == null) ? true : false;

        // _create - i.e. tokens
        const a_create: boolean = (a_text && a_text.toLowerCase().match(/\b(creates?)\b/g) != null) ? true : false;

        // _cycle
        const a_cycle: boolean = (a_text && a_text.toLowerCase().match(/\b(cycles?)\b/g) != null) ? true : false;

        // _destroy
        const a_destroy: boolean = (a_text && a_text.toLowerCase().match(/\b(destroys?)\b/g) != null) ? true : false;

        // _discard
        const a_discard: boolean = (a_text && a_text.toLowerCase().match(/\b(discards?)\b/g) != null) ? true : false;

        // _double
        const a_double: boolean = (a_text && a_text.toLowerCase().match(/\b(doubles?)\b/g) != null &&
        a_text.toLowerCase().match(/\b(double strike)\b/g) == null) ? true : false;

        // _draw
        const a_draw: boolean = (a_text && a_text.toLowerCase().match(/\b(draws?)\b/g) != null) ? true : false;

        // _exchange
        const a_exchange: boolean = (a_text && a_text.toLowerCase().match(/\b(exchanges?)\b/g) != null) ? true : false;

        // _exile
        const a_exile: boolean = (a_text && a_text.toLowerCase().match(/\b(exiles?)\b/g) != null) ? true : false;

        // _fight
        const a_fight: boolean = (a_text && a_text.toLowerCase().match(/\b(fights?)\b/g) != null) ? true : false;

        // _mill: Mill - effects forcing player to put cards from library into graveyard
        const a_mill: boolean = (a_text && a_text.toLowerCase().match(/\b(mills?)\b/g) != null) ? true : false;
          // a_text.toLowerCase().match(/put.*(card|half).*(his|her|player|their).*library.*into.*graveyard/g) != null) ? true : false;

        // _play
        const a_play: boolean = (a_text && a_text.toLowerCase().match(/\b(plays?)\b/g) != null) ? true : false;

        // _reveal
        const a_reveal: boolean = (a_text && a_text.toLowerCase().match(/\b(reveals?)\b/g) != null) ? true : false;

        // _sacrifice
        const a_sacrifice: boolean = (a_text && a_text.toLowerCase().match(/\b(sacrifices?)\b/g) != null) ? true : false;

        // _scry
        const a_scry: boolean = (a_text && a_text.toLowerCase().match(/\b(scry)\b/g) != null) ? true : false;

        // _search
        const a_search: boolean = (a_text && a_text.toLowerCase().match(/\b(search|searches)\b/g) != null) ? true : false;

        // _shuffle
        const a_shuffle: boolean = (a_text && a_text.toLowerCase().match(/\b(shuffles?)\b/g) != null) ? true : false;

        // _tap
        const a_tap: boolean = (a_text && a_text.toLowerCase().match(/\b(taps?)\b/g) != null) ? true : false;

        // _untap
        const a_untap: boolean = (a_text && a_text.toLowerCase().match(/\b(untaps?)\b/g) != null &&
        a_text.toLowerCase().match(/\b(untap steps?)\b/g) == null) ? true : false;

        /**
         * OTHER
         */

        // _adapt
        const a_adapt: boolean = (a_text && a_text.toLowerCase().match(/\b(adapt)\b/g) != null) ? true : false;

        // _amass
        const a_amass: boolean = (a_text && a_text.toLowerCase().match(/\b(amass)\b/g) != null) ? true : false;

        // _bolster
        const a_bolster: boolean = (a_text && a_text.toLowerCase().match(/\b(bolster)\b/g) != null) ? true : false;

        // _clash
        const a_clash: boolean = (a_text && a_text.toLowerCase().match(/\b(clash)\b/g) != null) ? true : false;

        // _detain
        const a_detain: boolean = (a_text && a_text.toLowerCase().match(/\b(detain)\b/g) != null) ? true : false;

        // _exert
        const a_exert: boolean = (a_text && a_text.toLowerCase().match(/\b(exert)\b/g) != null) ? true : false;

        // _explore
        const a_explore: boolean = (a_text && a_text.toLowerCase().match(/\b(explores)\b/g) != null) ? true : false;

        // _fateseal
        const a_fateseal: boolean = (a_text && a_text.toLowerCase().match(/\b(fateseal)\b/g) != null) ? true : false;

        // _goad
        const a_goad: boolean = (a_text && a_text.toLowerCase().match(/\b(goad)\b/g) != null) ? true : false;

        // _investigate
        const a_investigate: boolean = (a_text && a_text.toLowerCase().match(/\b(investigates?)\b/g) != null) ? true : false;

        // _learn
        const a_learn: boolean = (a_text && a_text.toLowerCase().match(/\b(learns?\.)\b/g) != null) ? true : false;

        // _manifest
        const a_manifest: boolean = (a_text && a_text.toLowerCase().match(/\b(manifests?)\b/g) != null) ? true : false;

        // _meld <-- may be able to fetch from card type
        const a_meld: boolean = (a_text && a_text.toLowerCase().match(/\b(meld)\b/g) != null) ? true : false;

        // _monstrosity
        const a_monstrosity: boolean = (a_text && a_text.toLowerCase().match(/\b(monstrosity)\b/g) != null) ? true : false;

        // _populate
        const a_populate: boolean = (a_text && a_text.toLowerCase().match(/\b(populate)\b/g) != null) ? true : false;

        // _proliferate
        const a_proliferate: boolean = (a_text && a_text.toLowerCase().match(/\b(proliferate)\b/g) != null) ? true : false;

        // _regenerate <-- outdated mechanic
        const a_regenerate: boolean = (a_text && a_text.toLowerCase().match(/\b(regenerate)\b/g) != null) ? true : false;

        // _roll_a_die
        const a_roll: boolean = (a_text && a_text.toLowerCase().match(/\b(roll a d..?)\b/g) != null) ? true : false;

        // _support
        const a_support: boolean = (a_text && a_text.toLowerCase().match(/\b(support)\b/g) != null) ? true : false;

        // _surveil
        const a_surveil: boolean = (a_text && a_text.toLowerCase().match(/\b(surveil)\b/g) != null) ? true : false;

        // _transform <<-- could potentially be identified by cardtype instead
        const a_transform: boolean = (a_text && a_text.toLowerCase().match(/\b(transform)\b/g) != null) ? true : false;

        // _venture_into_the_dungeon
        const a_venture: boolean = (a_text && a_text.toLowerCase().match(/\b(venture into the dungeon)\b/g) != null) ? true : false;

        // _vote
        const a_vote: boolean = (a_text && a_text.toLowerCase().match(/\b(vote)\b/g) != null) ? true : false;

        /**
         * CARD FOCUS
         */

        /**
         * ABILITY WORDS
         */

        // _adamant
        const a_adamant: boolean = (a_text && a_text.toLowerCase().match(/\b(adamant)\b/g) != null) ? true : false;

        // _addendum
        const a_addendum: boolean = (a_text && a_text.toLowerCase().match(/\b(addendum)\b/g) != null) ? true : false;

        // _battalion
        const a_battalion: boolean = (a_text && a_text.toLowerCase().match(/\b(battalion)\b/g) != null) ? true : false;

        // _bloodrush
        const a_bloodrush: boolean = (a_text && a_text.toLowerCase().match(/\b(bloodrush)\b/g) != null) ? true : false;

        // _channel
        const a_channel: boolean = (a_text && a_text.toLowerCase().match(/\b(channel)\b/g) != null) ? true : false;

        // _chroma
        const a_chroma: boolean = (a_text && a_text.toLowerCase().match(/\b(chroma)\b/g) != null) ? true : false;

        // _cohort
        const a_cohort: boolean = (a_text && a_text.toLowerCase().match(/\b(cohort)\b/g) != null) ? true : false;

        // _constellation
        const a_constellation: boolean = (a_text && a_text.toLowerCase().match(/\b(constellation)\b/g) != null) ? true : false;

        // _converge
        const a_converge: boolean = (a_text && a_text.toLowerCase().match(/\b(converge)\b/g) != null) ? true : false;

        // _coven
        const a_coven: boolean = (a_text && a_text.toLowerCase().match(/\b(coven)\b/g) != null) ? true : false;

        // _delirium
        const a_delirium: boolean = (a_text && a_text.toLowerCase().match(/\b(delirium)\b/g) != null) ? true : false;

        // _domain
        const a_domain: boolean = (a_text && a_text.toLowerCase().match(/\b(domain)\b/g) != null) ? true : false;

        // _eminence
        const a_eminence: boolean = (a_text && a_text.toLowerCase().match(/\b(eminence)\b/g) != null) ? true : false;

        // _enrage
        const a_enrage: boolean = (a_text && a_text.toLowerCase().match(/\b(enrage)\b/g) != null) ? true : false;

        // _fateful_hour
        const a_fateful_hour: boolean = (a_text && a_text.toLowerCase().match(/\b(fateful hour)\b/g) != null) ? true : false;

        // _ferocious
        const a_ferocious: boolean = (a_text && a_text.toLowerCase().match(/\b(ferocious)\b/g) != null) ? true : false;

        // _formidable
        const a_formidable: boolean = (a_text && a_text.toLowerCase().match(/\b(formidable)\b/g) != null) ? true : false;

        // _grandeur
        const a_grandeur: boolean = (a_text && a_text.toLowerCase().match(/\b(grandeur)\b/g) != null) ? true : false;

        // _hellbent
        const a_hellbent: boolean = (a_text && a_text.toLowerCase().match(/\b(hellbent)\b/g) != null) ? true : false;

        // _heroic
        const a_heroic: boolean = (a_text && a_text.toLowerCase().match(/\b(heroic)\b/g) != null) ? true : false;

        // _imprint
        const a_imprint: boolean = (a_text && a_text.toLowerCase().match(/\b(imprint)\b/g) != null) ? true : false;

        // _inspired
        const a_inspired: boolean = (a_text && a_text.toLowerCase().match(/\b(inspired)\b/g) != null) ? true : false;

        // _join_forces
        const a_join_forces: boolean = (a_text && a_text.toLowerCase().match(/\b(join forces)\b/g) != null) ? true : false;

        // _kinship
        const a_kinship: boolean = (a_text && a_text.toLowerCase().match(/\b(kinship)\b/g) != null) ? true : false;

        // _landfall
        const a_landfall: boolean = (a_text && a_text.toLowerCase().match(/\b(landfall)\b/g) != null) ? true : false;

        // _liutenant
        const a_lieutenant: boolean = (a_text && a_text.toLowerCase().match(/\b(lieutenant)\b/g) != null) ? true : false;

        // _magecraft
        const a_magecraft: boolean = (a_text && a_text.toLowerCase().match(/\b(magecraft)\b/g) != null) ? true : false;

        // _metalcraft
        const a_metalcraft: boolean = (a_text && a_text.toLowerCase().match(/\b(metalcraft)\b/g) != null) ? true : false;

        // _morbid
        const a_morbid: boolean = (a_text && a_text.toLowerCase().match(/\b(morbid)\b/g) != null) ? true : false;

        // _pack_tactics
        const a_pack_tactics: boolean = (a_text && a_text.toLowerCase().match(/\b(pack tactics)\b/g) != null) ? true : false;

        // _radiance
        const a_radiance: boolean = (a_text && a_text.toLowerCase().match(/\b(radiance)\b/g) != null) ? true : false;

        // _raid
        const a_raid: boolean = (a_text && a_text.toLowerCase().match(/\b(raid)\b/g) != null) ? true : false;

        // _rally
        const a_rally: boolean = (a_text && a_text.toLowerCase().match(/\b(rally)\b/g) != null) ? true : false;

        // _revolt
        const a_revolt: boolean = (a_text && a_text.toLowerCase().match(/\b(revolt)\b/g) != null) ? true : false;

        // _spell_mastery
        const a_spell_mastery: boolean = (a_text && a_text.toLowerCase().match(/\b(spell mastery)\b/g) != null) ? true : false;

        // _strive
        const a_strive: boolean = (a_text && a_text.toLowerCase().match(/\b(strive)\b/g) != null) ? true : false;

        // _sweep
        const a_sweep: boolean = (a_text && a_text.toLowerCase().match(/\b(sweep)\b/g) != null) ? true : false;

        // _tempting_offer
        const a_tempting_offer: boolean = (a_text && a_text.toLowerCase().match(/\b(tempting offer)\b/g) != null) ? true : false;

        // _threshold
        const a_threshold: boolean = (a_text && a_text.toLowerCase().match(/\b(threshold)\b/g) != null) ? true : false;

        // _undergrowth
        const a_undergrowth: boolean = (a_text && a_text.toLowerCase().match(/\b(undergrowth)\b/g) != null) ? true : false;

        /**
         *  MISC MECHANICS
         */
        // ...http://mtg.gamepedia.com/Category:Miscellaneous_mechanics
        // Abyss - spell that forces players/opponents to sacrifice a creature each turn
        // _alt_win: Alternate-win-card - additional means of winning the game
        const a_alt_win: boolean = (a_text && a_text.toLowerCase().match(/wins? the game/g) != null) ? true : false;
        // _alt_loss: Alternate-loss-card - additional means of losing the game
        const a_alt_loss: boolean = (a_text && a_text.toLowerCase().match(/loses? the game/g) != null) ? true : false;
        // Animate - turns non-creature into a creature
        // _anti_discard: Anti-discard - provide a benefit (or punish opponent) when discarded
        const a_anti_discard: boolean = (a_text && a_text.toLowerCase().match(/causes.*discard/g) != null ||
         (a_text && a_text.toLowerCase().match(/\b(madness)\b/g) != null)) ? true : false;
        // Anti-flying - deals specifically with flying creatures through various tactics
        // Atifact-ize - turns non-artifacts into artifacts
        // Artifact destruction - cards that can destroy artifacts
        // Assistance - abilities like: target attacking or blocking creature gets +N/+N until end of turn
        // Attack alone - creatures who can only attack alone
        // Attack together - creatures who can only attack not-alone
        // Block alone - creatures who can only block alone
        // Block together - creatures who can only block not-alone
        // Board wipe - insant/sorcery that destroys or exiles all permanents of a certain type
        // _bounce: Bounce - returns a card from battlefield to owners hand
        const a_bounce: boolean = (a_text && a_text.toLowerCase().match(/return (a|target|all|any).*to.*owner/g) != null) ? true : false;
        // _burn: Burn - spell/ability that deals direct damage to a target player and/or creature
        const a_burn: boolean = (a_text && a_text.toLowerCase().match(/deal.*damage.*to/g) != null &&
         a_text.toLowerCase().match(/\b(prevent)\b/g) === null &&
         a_text.toLowerCase().match(/(target.*creature|target.*player|target.*opponent|any target)/g) != null) ? true : false;
        // _cantrip: Cantrip - spell that draws a card in addition to its other effects
        const a_cantrip: boolean = (a_text && a_text.match(/\bDraw a card\.$/g) != null &&
         (card.types[0] === 'Instant' || card.types[0] === 'Sorcery')) ? true : false;
        // Charm - modal instant spell with three possible results of which one can be selected
        // Color changer - effect/spell that change a spell or permanent to a particular color
        // _counterspell: Counterspell - spells with the ability to counter spells
        const a_counterspell: boolean = (a_text &&
          a_text.toLowerCase().match(/counter target.*(spell|ability|effect)/g) != null) ? true : false;
        // Curiosity - ability like 'when creature deals combat damage, draw a card'
        // Daunt - ability like 'can't be blocked by creatures with power 2 or less'
        // Devotion - cards with 'devotion to'
        // Dexterity card - cards that require physical activity i.e. 'drop from a height of at least one foot'
        // Dies - triggering ability on creatures put into the graveyard from the battlefield <- PRIO
        // Divvy - ability where players create piles of cards for any reason
        // En-Kor ability - ability for creature to redirect received dmg for a cost of 0 mana
        // _enter_the_battlefield: Enters the battlefield effect (ETB) - effect on 'enters the battlefield'
        const a_enter_the_battlefield: boolean = (a_text &&
           a_text.toLowerCase().match(/when.*enters? the battlefield/g) != null) ? true : false;
        // Firebreathing - cards with {mana}:+1/+0 until end of turn <- PRIO
        // Flickering - cards that exiles then returns to the battlefield <- PRIO
        // Free spells - spells that when played untap the same amount of lands used to play them
        // Gating - requires you to return a permanent that shares a color with it to your hand when comes into play
        // Growing enchantments - enchantments which accumulates counters
        // High flying - creatures with flying only able to block flying
        // Hoser (red/blue/white/green/black) - cards destroying, targeting or preventing something from a specific color
        // Hybrid mana - cards/abilities able to be paid with hybrid mana
        // Impulse - i.e. 'look at the top X cards of your library, pick one, and put the rest on bottom'
        // Lace - 5 instant cards ending with the word 'lace' in their card name
        // Land changer - effects/spells that change a land cards land type
        // Land destruction - effect that destroys lands <- PRIO
        // Lobotomy - effect that exiles all (or any) copies of a card from hand/library/graveyard
        // Loners - creatures that get better when they're the only creature controlled by a player
        // _looting - effect to draw x cards, then discard x cards
        const a_looting: boolean = (a_text &&
           a_text.toLowerCase().match(/draw .*card.*discard .* card/g) != null) ? true : false;
        // Lure - ability that forces blocking options
        // Mana stone - artifact that produces mana
        // _self_mill: Self Mill - effects allowing you to put cards from library into graveyard
        const a_self_mill: boolean = (a_text && a_text.toLowerCase().match(/\b(mill)\b/g) != null) ? true : false;
          // a_text.toLowerCase().match(/put.*(card|half).*your.*library.*into.*graveyard/g) || a_text &&
          // a_text.toLowerCase().match(/target player.* put.*(card|half).*(player|their|your).*library.*into.*graveyard/g)
          // != null) ? true : false;
        // Modal spell - spell/ability that gives the caster a choice of two or more effects when cast
        // Nettling - ability that forces creature(s) to attack
        // One-sided fight - ability that deals dmg = power to target creature you don't control
        // Panic - prevents opponents creature(s) from blocking
        // Phyrexian mana - card/ability paid with phyrexian mana
        // Ping - activated ability that deals 1 dmg to target creature or player <- PRIO
        // Pitch spell - spell allowed to be cast for an alternative cost <- PRIO
        // P/T switchers - effects/spells that change the power and tougness of a creature
        // Punisher - spells that force an opponent to decide between bad outcomes
        // Quest - cards featuring quest counters
        // Reanimation - effect that return cards from a graveyard directly to play <- PRIO
        // Redirection effect - effect causing damage dealt to one target being dealt to another target instead
        // Removal - cards that: destroys, deals dmg to creature, reduce toughness, exile permanent/creature, ..
        // ..forces controller to sacrifice, moves the creature to inaccessible place
        // Rhystic - effect that happens unless a player pays to stop it
        // Rule-setting - cards that alter the possibilites of how the game is played, i.e. players can't gain life <- PRIO
        // Rummaging - "red looting", i.e. discard, then draw
        const a_rummaging: boolean = (a_text &&
          a_text.toLowerCase().match(/discard .*card.*draw .*card/g) != null) ? true : false;
        // Ruthless - cards with 'when ~ enters the battlefield, put ... -1/-1 counters on target creature you control'
        // Saboteur - creature with triggered ability upon dealing combat damage to a player
        // Skulking - creatures which have to be sacrificed when targeted by spell or ability
        // Sleeping enchantments - enchantments turning into creatures when triggered
        // Stalking - the ability 'can't be blocked by more than one creature'
        // _stat_increase - ability positively increasing power and/or toughness
        const a_stat_increase: boolean = (a_text &&
           a_text.toLowerCase().match(/gets? (\+[0-9]+\/(-|\+)|(-|\+)[0-9]+\/\+)/g) != null) ? true : false;
        // _stat_reduce - ability negatively decreasing power and/or toughness
        const a_stat_reduce: boolean = (a_text &&
           a_text.toLowerCase().match(/gets? (-[0-9]+\/(-|\+)|(-|\+)[0-9]+\/-)/g) != null) ? true : false;
        // Stealing - mechanic that allows a player to gain control of an opponents permanent
        // Super trample - ability of creatures to assign combat damage as though the weren't blocked
        // Taxing - spells that stop an opponent from doing something unelss they pay a price
        // Tucking - removal effect that moves a creature to a generally inaccessible place (i.e. bottom of library)
        // _tutor: Tutor - a spell which searches your library for another spell
        const a_tutor: boolean = (a_text &&
           a_text.toLowerCase().match(/search.*library.*card.*(hand|top|graveyard|battlefield)/g) != null &&
           a_text.toLowerCase().match(/shuffle/g) != null) ? true : false;
        /*if(a_tutor === true){
          console.log(card.name,'tutor');
        }*/
        // Unblockable - cards with 'can't be blocked'
        // Uncounterable - cards with 'can't be countered' <- PRIO
        // Untrample - ability of creatures to assign combat damage to target creature instead of defending player if not blocked
        // Venom - combat damage forcing destroy at end of combat
        // Wheel effect - effect causing player(s) to lose hand and draw a completely new one
        // Wisdom - effect granting ability based on player handsize
        // Wrath effect - removal of most (or all) creatures in play by any spell or aility


        /**
         * TERMINOLOGY
         */

        // Split the cleaned cardText into its respective rows (if it has card text) to identify abilities within
        const c_abilities: string[] = (a_text != null) ? a_text.split(/\r?\n/) : null;

        // Define all abilityTypes as false per default
        // _activatedAbility
        let a_activated_ability = false;
        // _castingAbility
        let a_casting_ability = false;
        // _passiveAbility
        let a_passive_ability = false;
        // _triggeredAbility
        let a_triggered_ability = false;

        // Also set the default value for ActivationCMC
        const a_activation_cmc: string[] = [];

        // Loop through the array of c_abilities
        if (c_abilities) { // If the card has any abilities
          // check for each item in the array
          c_abilities.forEach(function(item) {
            // _activatedAbility - activated
            a_activated_ability = (item.match(/:/g) != null) ? true : a_activated_ability; // switch to true if match found, else keep as is
            // _castingAbility - not activated, and of type instant or sorcery
            a_casting_ability = (item.match(/[^:]/g) != null &&
             (card.types[0] === 'Instant' ||
              card.types[0] === 'Sorcery')) ? true : a_casting_ability; // switch to true if match found, else keep as is
            // _passiveAbility - not activated, and not of type instant, nor sorcery, and not containing 'when' or 'enchant '
            a_passive_ability = (item.match(/[^:]/g) != null &&
             card.types[0] !== 'Instant' &&
              card.types[0] !== 'Sorcery' &&
              item.match(/^When/g) === null &&
              item.match(/— When/g) === null &&
              item.match(/^Enchant /g) === null) ? true : a_passive_ability; // switch to true if match found, else keep as is
            // _triggeredAbility - not activated, and not of type instant, nor sorcery, and starting with 'when' or containing '— when'
            a_triggered_ability = (item.match(/[^:]/g) != null &&
             card.types[0] !== 'Instant' &&
             card.types[0] !== 'Sorcery' &&
             (item.match(/^When/g) !== null) ||
              item.match(/— When/g) !== null ||
              item.match(/^At /g) !== null) ? true : a_triggered_ability; // switch to true if match found, else keep as is

            /*if (a_activated_ability === true) {
              console.log(item);
            }
            if (a_casting_ability === true) {
              console.log(item);
            }
            if (a_passive_ability === true) {
              console.log(item);
            }
            if (a_triggered_ability === true) {
              console.log(item);
            }*/

            // ActivationCMC
            if (item.match(/:/g) != null && card.types[0] !== 'Planeswalker') {
              const temp: string = item.substring(0, item.indexOf(':')) // Only fetch the cost BEFORE the :
              .replace(/\{2\/?\}/g, 'öö') // Replace all 2/? costs
              .replace(/\{(W|U|B|R|G|S|P|C)\/(W|U|B|R|G|S|P|C)\}/g, 'ö') // Replace all hybrid costs
              .replace(/\{10\}/g, 'öööööööööö') // Replace all costs of 10
              .replace(/\{9\}/g, 'ööööööööö') // Replace all costs of 9
              .replace(/\{8\}/g, 'öööööööö') // Replace all costs of 8
              .replace(/\{7\}/g, 'ööööööö') // Replace all costs of 7
              .replace(/\{6\}/g, 'öööööö') // Replace all costs of 6
              .replace(/\{5\}/g, 'ööööö') // Replace all costs of 5
              .replace(/\{4\}/g, 'öööö') // Replace all costs of 4
              .replace(/\{3\}/g, 'ööö') // Replace all costs of 3
              .replace(/\{2\}/g, 'öö') // Replace all costs of 2
              .replace(/\{1\}/g, 'ö') // Replace all costs of 1
              .replace(/\{(W|U|B|R|G|S|P|C)\}/g, 'ö'); // Replace all costs of w|u|b|r|g (and s: snow, and p: phyrexian, and c: colorless)
              if (temp.match(/ö/g)) { // If there are matches, count the CMC
                a_activation_cmc.push(';' + temp.match(/ö/g).length.toString() + ';');
                // console.log(temp.length.toString());
              } else {
                a_activation_cmc.push(';0;'); // If an activated ability is found, but no cost
              }
            }
            });
        }

        // _activation_cmc0
        const a_activation_cmc0: boolean = (a_activation_cmc.indexOf(';0;') >= 0) ? true : false;
        // _activation_cmc1
        const a_activation_cmc1: boolean = (a_activation_cmc.indexOf(';1;') >= 0) ? true : false;
        // _activation_cmc2
        const a_activation_cmc2: boolean = (a_activation_cmc.indexOf(';2;') >= 0) ? true : false;
        // _activation_cmc3
        const a_activation_cmc3: boolean = (a_activation_cmc.indexOf(';3;') >= 0) ? true : false;
        // _activation_cmc4
        const a_activation_cmc4: boolean = (a_activation_cmc.indexOf(';4;') >= 0) ? true : false;
        // _activation_cmc5
        const a_activation_cmc5: boolean = (a_activation_cmc.indexOf(';5;') >= 0) ? true : false;
        // _activation_cmc6
        const a_activation_cmc6: boolean = (a_activation_cmc.indexOf(';6;') >= 0) ? true : false;
        // _activation_cmc7
        const a_activation_cmc7: boolean = (a_activation_cmc.indexOf(';7;') >= 0) ? true : false;
        // _activation_cmc8_plus
        const a_activation_cmc8_plus: boolean = (a_activation_cmc.indexOf(';8;') >= 0 ||
         a_activation_cmc.indexOf(';9;') >= 0 ||
         a_activation_cmc.indexOf(';10;') >= 0 ||
         a_activation_cmc.indexOf(';11;') >= 0 ||
         a_activation_cmc.indexOf(';12;') >= 0) ? true : false;


        // _require_tap: Require Tap - ability that requires tap
        const a_require_tap: boolean = (a_text && a_text.toLowerCase().match(/\{t\}.*:/g) != null) ? true : false;
        // _require_untap: Require Untap - ability that requires untap
        const a_require_untap: boolean = (a_text && a_text.toLowerCase().match(/\{q\}.*:/g) != null) ? true : false;
        // _give_colorless: Add Colorless - ability that gives specifically colorless mana
        const a_give_colorless: boolean = (a_text &&
        a_text.toLowerCase().match(/add \{c\}|add an amount of \{c\}/g) != null) ? true : false;
        // _require_colorless: Require Colorless - ability that requires specifically colorless mana
        const a_require_colorless: boolean = (a_text && a_text.toLowerCase().match(/\{c\}.*:/g) != null) ? true : false;
        // _give_mana: Add Mana - ability that gives mana
        const a_give_mana: boolean = (a_text && (
        a_text.toLowerCase().match(/add (one|two|three|four|five|six|seven|eight|nine|ten|x) mana|add \{(w|u|r|b|g|[1-99])/g) ||
        a_text.toLowerCase().match(/add an amount of (\{w|\{u|\{r|\{b|\{g|[1-99]|mana)|add that much (\{w|\{u|\{r|\{b|\{g|[1-99]|mana)/g) ||
        a_text.toLowerCase().match(/add \{c\}\{(w|u|r|b|g|[1-99])|add one additional mana|add mana equal to/g) ||
        a_text.toLowerCase().match(/add an additional (\{w|\{u|\{r|\{b|\{g|[1-99]|mana|one mana)/g)
        ) != null) ? true : false;
        // |add an amount of \{(w|u|r|b|g|[1-99]|mana)
        // _require_mana: Require Mana - ability that requires mana
        const a_require_mana: boolean = (a_text && a_text.toLowerCase().match(/(w|u|r|b|g|[1-99])\}.*:/g) != null) ? true : false;
        // _energy: Energy - ability that relates to energy counters in any way
        const a_energy: boolean = (a_text && a_text.toLowerCase().match(/\{e\}/g) != null) ? true : false;
        // _counters: Counters - ability that relates to counters in any way
        const a_counters: boolean = (a_text && a_text.toLowerCase()
        .match(/(put|distribute|remove) (a|one|two|three|four|five|x|all|that many) .* counter [^target]/g) != null) ? true : false;
        // _plus1_counters: +1/+1 Counters - ability that relates to +1/+1 counters in any way
        const a_plus1_counters: boolean = (a_text && a_text.toLowerCase().match(/\+1\/\+1 counter/g) != null) ? true : false;
        // _minus1_counters: -1/-1 Counters - ability that relates to -1/-1 counters in any way
        const a_minus1_counters: boolean = (a_text && a_text.toLowerCase().match(/\-1\/\-1 counter/g) != null) ? true : false;
        // _target_land: Target Land - ability that targets lands
        const a_target_land: boolean = (a_text && a_text.toLowerCase()
.match(/(target (nonbasic land|land)|target .* or (nonbasic land|land)|target .*, (nonbasic land|land)|target .* (nonbasic land|land))/g)
        != null) ? true : false;
        // _target_artifact: Target Artifact - ability that targets artifacts
        const a_target_artifact: boolean = (a_text && a_text.toLowerCase()
        .match(/(target artifact|target .* or artifact|target .*, artifact|target .* artifact)/g) != null) ? true : false;
        // _target_creature: Target Creature - ability that targets creatures
        const a_target_creature: boolean = (a_text && a_text.toLowerCase()
        .match(/(target creature|target .* or creature|target .*, creature|target .* creature|any target)/g) != null) ? true : false;
        // _target_card: Target Card - ability that targets cards
        const a_target_card: boolean = (a_text && a_text.toLowerCase()
        .match(/(target card|target [a-z]* card|target [a-z]* or [a-z]* card|you choose a (card|[a-z]* card) )/g) != null) ? true : false;
        // _target_enchantment: Target Enchantment - ability that targets enchantments
        const a_target_enchantment: boolean = (a_text && a_text.toLowerCase()
        .match(/(target enchantment|target .* or enchantment|target .*, enchantment|target .* enchantment)/g) != null) ? true : false;
        // _target_planeswalker: Target Planeswalker - ability that targets planeswalkers
        const a_target_planeswalker: boolean = (a_text && a_text.toLowerCase()
        .match(/(target planeswalker|target .* or planeswalker|target .*, planeswalker|target .* planeswalker|any target)/g)
        != null && a_text.toLowerCase().match(/a [a-z]* planeswalker/g) === null) ? true : false;
        // _target_permanent: Target Permanent - ability that targets permanents
        const a_target_permanent: boolean = (a_text && a_text.toLowerCase()
        .match(/(target permanent|target .* or permanent|target .*, permanent|target .* permanent)/g) != null) ? true : false;
        // _target_player: Target Player - ability that targets players
        const a_target_player: boolean = (a_text && a_text.toLowerCase()
.match(/(target (player|opponent)|target .* or (player|opponent)|target .*, (player|opponent)|target .* (player|opponent)|any target)/g)
        != null && a_text.toLowerCase().match(/[^target (player|opponent)] .* (player|opponent) controls/g) === null) ? true : false;
        // _spot_removal: Spot Removal - ability that destroys or exiles its target
        const a_spot_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(destroy [any ]?target|exile [any ]?target)/g) != null) ? true : false;
        // _mass_removal: Mass Removal - ability that destroys or exiles many targets at once
        const a_mass_removal: boolean = (a_text && a_text.toLowerCase()
.match(/(destroy all|exile all [^cards]|sacrifice? (all|rest)|each player sacrifices|all creatures get*\/-[1-20])/g)
!= null) ? true : false;
        // _1_removal: ?/1 Removal - ability that kills creatures with 1 toughness
        const a_1_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(target .* get.*\/-1|deals? 1 damage to (target .*creature|any target)|(exile|destroy) target .* with .*toughness 1)/g)
        != null) ? true : false;
        // _2_removal: ?/2 Removal - ability that kills creatures with 2 toughness
        const a_2_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(target .* get.*\/-2|deals? 2 damage to (target .*creature|any target)|(exile|destroy) target .* with .*toughness 2)/g)
        != null) ? true : false;
        // _3_removal: ?/3 Removal - ability that kills creatures with 3 toughness
        const a_3_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(target .* get.*\/-3|deals? 3 damage to (target .*creature|any target)|(exile|destroy) target .* with .*toughness 3)/g)
        != null) ? true : false;
        // _4_removal: ?/4 Removal - ability that kills creatures with 4 toughness
        const a_4_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(target .* get.*\/-4|deals? 4 damage to (target .*creature|any target)|(exile|destroy) target .* with .*toughness 4)/g)
        != null) ? true : false;
        // _5_removal: ?/5 Removal - ability that kills creatures with 5 toughness
        const a_5_removal: boolean = (a_text && a_text.toLowerCase()
        .match(/(target .* get.*\/-5|deals? 5 damage to (target .*creature|any target)|(exile|destroy) target .* with .*toughness 5)/g)
        != null) ? true : false;

        // Timings
        // _until_eot: Until End of Turn effects
        const a_until_eot: boolean = (a_text && a_text.toLowerCase().match(/until end of turn/g) != null) ? true : false;
        // _until_condition: Until other condition triggers
        const a_until_condition: boolean = (a_text && a_text.toLowerCase().match(/until [^end of turn]/g) != null) ? true : false;
        /*
        Fler abilities till Terminology:
          * ActivationCost, - går att få fram av ActivatedAbility
          * ActivationEffect, - går att få fram av ActivatedAbility

          * 'devotion' som räknar det maximala antalet mana av varje färg i en manacost
          i.e. _devotionBlack: 1, _devotionBlue: 2, _devotionGreen: 0, _devotionRed: 0, _devotionWhite: 0
          */


        /**
         * IDENTITY
         */

        /**
         * EVERGREEN
         */

        // _layout_Normal
        const c_layout_Normal: boolean = (card.layout && card.layout.toLowerCase().indexOf('normal') >= 0) ? true : false;

        // _layout_Split
        const c_layout_Split: boolean = (card.layout && card.layout.toLowerCase().match(/(split|aftermath)/g) != null) ? true : false;

        // _layout_Flip
        const c_layout_Flip: boolean = (card.layout && card.layout.toLowerCase().indexOf('flip') >= 0) ? true : false;

        // _layout_Double
        const c_layout_Double: boolean = (card.layout && card.layout.toLowerCase().match(/(transform|modal_dfc)/g) != null) ? true : false;

        // Define all color identities as false per default
        // _ci_white
        let c_ci_white = false;
        // _ci_blue
        let c_ci_blue = false;
        // _ci_black
        let c_ci_black = false;
        // _ci_red
        let c_ci_red = false;
        // _ci_green
        let c_ci_green = false;

        // Loop through the array of color identities
        if (card.colorIdentity) { // If the card has any color identities set
          // check for each item in the array
          card.colorIdentity.forEach(function(item) {
            // _ci_white: Color Identity White - cards that have White in their cost or text
            c_ci_white = (item.toLowerCase().match(/w/g) != null) ? true : c_ci_white; // switch to true if match found, else keep as is
            // _ci_blue: Color Identity Blue - cards that have Blue in their cost or text
            c_ci_blue = (item.toLowerCase().match(/u/g) != null) ? true : c_ci_blue; // switch to true if match found, else keep as is
            // _ci_white: Color Identity Black - cards that have Black in their cost or text
            c_ci_black = (item.toLowerCase().match(/b/g) != null) ? true : c_ci_black; // switch to true if match found, else keep as is
            // _ci_white: Color Identity Red - cards that have Red in their cost or text
            c_ci_red = (item.toLowerCase().match(/r/g) != null) ? true : c_ci_red; // switch to true if match found, else keep as is
            // _ci_white: Color Identity Green - cards that have Green in their cost or text
            c_ci_green = (item.toLowerCase().match(/g/g) != null) ? true : c_ci_green; // switch to true if match found, else keep as is
          });
        }


        // _commander: Commander - cards that are eligible to be commanders in commander format
        const c_commander: boolean = (card.leadershipSkills && card.leadershipSkills.commander) ? true : false;

        // _legendary: Legendary - cards that are of supertype Legendary
        const c_legendary: boolean = (card.type && card.type.toLowerCase().match(/legendary/g) != null) ? true : false;

        /**
         * OTHER
         */

        // _historic: Historic - cards that are artefacts, legendaries or sagas
        const c_historic: boolean = (card.type && card.type.toLowerCase().match(/(artifact|legendary|saga)/g) != null) ? true : false;
        // _saga: Saga - cards that are enchantments of subtype Saga
        const c_saga: boolean = (card.type && card.type.toLowerCase().match(/saga/g) != null) ? true : false;
        // _equipment: Equipment - cards that are artifacts of subtype Equipment
        const c_equipment: boolean = (card.type && card.type.toLowerCase().match(/equipment/g) != null) ? true : false;
        // _vehicle: Vehicle - cards that are artifacts of subtype Vehicle
        const c_vehicle: boolean = (card.type && card.type.toLowerCase().match(/vehicle/g) != null) ? true : false;
        // _aura: Aura - cards that are enchantments of subtype aura
        const c_aura: boolean = (card.type && card.type.toLowerCase().match(/aura/g) != null) ? true : false;
        // _tribal: Tribal - cards that are of supertype Tribal
        const c_tribal: boolean = (card.type && card.type.toLowerCase().match(/tribal/g) != null) ? true : false;
        // _snow: Snow - cards that are of supertype Snow
        const c_snow: boolean = (card.type && card.type.toLowerCase().match(/snow/g) != null) ? true : false;
        // _world: World - cards that are of supertype World
        const c_world: boolean = (card.type && card.type.toLowerCase().match(/world/g) != null) ? true : false;

        /*
          Fler abilities till Identity - other:
          * 'devotion' som räknar det maximala antalet mana av varje färg i en manacost
          i.e. _devotionBlack: 1, _devotionBlue: 2, _devotionGreen: 0, _devotionRed: 0, _devotionWhite: 0
        */

        /**
         * MISSING VALUES
        */
        // Give all possibly missing attributes, a default empty value if missing
        // manaCost
        card.manaCost = (!card.manaCost) ? null : card.manaCost; // If missing, set to empty
        // subColor
        card.colors = (!card.colors) ? [''] : card.colors; // If missing, set to empty
        // cardType
        card.type = (!card.type) ? null : card.type; // If missing, set to empty
        // superType - if not visible in search, also set to empty
        card.supertypes = (!card.supertypes || this.showsInSearch === false) ? [''] : card.supertypes; // If missing, set to empty
        // subType - if not visible in search, also set to empty
        card.subtypes = (!card.subtypes || this.showsInSearch === false) ? [''] : card.subtypes; // If missing, set to empty
        // power - if not visible in search, also set to empty
        card.power = (!card.power || this.showsInSearch === false) ? null : card.power; // If missing, set to empty
        // toughness - if not visible in search, also set to empty
        card.toughness = (!card.toughness || this.showsInSearch === false) ? null : card.toughness; // If missing, set to empty
        // loyalty
        card.loyalty = (!card.loyalty && card.types[0] === 'Planeswalker') ? 'X' : (!card.loyalty) ? null : card.loyalty;
        // If missing, set to empty, unless it's a PW, set to X
        // text
        card.text = (!card.text) ? null : card.text; // If missing, set to empty
        // names
        card.names = (!card.otherFaceIds) ? [''] : card.otherFaceIds; // If missing, set to empty

        // Before creating the array of cards, make sure to convert the cardtext back to showing the cardname instead of '[THIS]'
        a_text = (a_text) ? a_text.replace(/\[THIS\]/g, card.name) : null;


        // Creating each single card based on the model of MtgCard
        if (this.showsInSearch) {
          const SingleCard: MtgCard = {
            // Unique card id
            uuid: card.uuid,
            // Pricing properties
            tcg_productId: tcg_productId, // '86'
            tcg_url: tcg_url, // 'http://store.tcgplayer.com/magic/portal-second-age/abyssal-nightstalker'

            // Edition properties
            releaseDate: EditionArray['releaseDate'], // YYYY-MM-DD
            edition: EditionArray['code'], // M10
            editionName: EditionArray['name'], // Magic 2010
            editionType: EditionArray['type'], // Expansion, Starter, Reprint, etc
            format: a_format, // [{'Legacy', 'Modern', 'Standard'}]

            // Required properties
            name: card.name, // Ajani Goldmane
            names: card.names, // {UUID for Farm, UUID for Market} - for layouts split, flip, aftermath or double-faced (and melds)
            imagePath: a_imagePath, // Image path based on http://theshitbin.com/mtgcards/
            rarity: card.rarity, // Mythic Rare
            cmc: card.cmc, // 4
            manaCost: card.manaCost, // {2}{W}{W} - preferably converted to 2WW
            permanent: a_permanent, // Calculated from colors
            subColor: card.colors, // {Green, Black} / {Blue} - fetched from colors
            mainType: card.types, // Creature / Land / Artifact / Instant / Planeswalker / Enchantment / Sorcery - fetched from types[]
            printings: cleanPrintings, // [NPH, KLD]
            // orderedPrintings: orderedPrintings, // All Core sets, Starters, Expansions, Commander, and Draft Innovation the card appears
            // Optional properties
            rulings: card.rulings, // [{date: 2011-06-01, text: 'you may still activate..'}, {date: ..., text: ...}]
            cardType: card.type, // {Creature - Elemental Hound} / {Land} - fetched from type
            superType: card.supertypes, // {Basic, Snow} / {Legendary} / {World} / {Ongoing}
            subType: card.subtypes, // {Creature, Artifact} / {Aura}
            power: card.power, // 3 / * / 0 / X
            toughness: card.toughness, // 2 / * / 0 / X
            pt: a_pt, // Power/Toughness
            loyalty: card.loyalty, // 4
            // text: card.text, //
            cleantext: a_text, // Card text with help-text in parenthesis removed
            value: a_value, // $ <$1, 2$ $1 < $3, 3$ $3 < $5, 4$ $5 < $10, 5$ $10 < $25, 6$ $25 < $50, 7$ $50+
            price: a_price,

            // Foreign data properties
            // Chinese Simplified
            name_CH: c_name_CH,
            cardType_CH: c_type_CH,
            cleantext_CH: c_text_CH,
            imagePath_CH: c_imagePath_CH,
            // German
            name_DE: c_name_DE,
            cardType_DE: c_type_DE,
            cleantext_DE: c_text_DE,
            imagePath_DE: c_imagePath_DE,
            // Spanish
            name_ES: c_name_ES,
            cardType_ES: c_type_ES,
            cleantext_ES: c_text_ES,
            imagePath_ES: c_imagePath_ES,
            // French
            name_FR: c_name_FR,
            cardType_FR: c_type_FR,
            cleantext_FR: c_text_FR,
            imagePath_FR: c_imagePath_FR,
            // Italian
            name_IT: c_name_IT,
            cardType_IT: c_type_IT,
            cleantext_IT: c_text_IT,
            imagePath_IT: c_imagePath_IT,
            // Japanese
            name_JP: c_name_JP,
            cardType_JP: c_type_JP,
            cleantext_JP: c_text_JP,
            imagePath_JP: c_imagePath_JP,
            // Korean
            name_KS: c_name_KS,
            cardType_KS: c_type_KS,
            cleantext_KS: c_text_KS,
            imagePath_KS: c_imagePath_KS,
            // Portuguese
            name_PT: c_name_PT,
            cardType_PT: c_type_PT,
            cleantext_PT: c_text_PT,
            imagePath_PT: c_imagePath_PT,
            // Russian
            name_RU: c_name_RU,
            cardType_RU: c_type_RU,
            cleantext_RU: c_text_RU,
            imagePath_RU: c_imagePath_RU,

            // All yes/no fields will be preceded with an underscore for visibility
            _isOnWishList: false,
            // Legality
            _standard_banned: a_standard_banned,
            _standard_restricted: a_standard_restricted,
            _standard_legal: a_standard_legal,
            _modern_banned: a_modern_banned,
            _modern_restricted: a_modern_restricted,
            _modern_legal: a_modern_legal,
            _commander_banned: a_commander_banned,
            _commander_restricted: a_commander_restricted,
            _commander_legal: a_commander_legal,
            _legacy_banned: a_legacy_banned,
            _legacy_restricted: a_legacy_restricted,
            _legacy_legal: a_legacy_legal,
            _vintage_banned: a_vintage_banned,
            _vintage_restricted: a_vintage_restricted,
            _vintage_legal: a_vintage_legal,
            // Print
            _firstPrint: c_firstPrint,
            _lastPrint: c_lastPrint,
            _basicLand: c_basicLand,
            // Mana relation
            _subColorBlack: c_subcolorBlack,
            _subColorBlue: c_subcolorBlue,
            _subColorGreen: c_subcolorGreen,
            _subColorRed: c_subcolorRed,
            _subColorWhite: c_subcolorWhite,
            _hasXCost: c_hasXCost,
            _hasHCost: c_hasHCost,
            _hasPCost: c_hasPCost,
            // Keywords: Evergreen
            _deathtouch: a_deathtouch,
            _defender: a_defender,
            _double_strike: a_double_strike,
            _enchant: a_enchant,
            _equip: a_equip,
            _first_strike: a_first_strike,
            _flash: a_flash,
            _flying: a_flying,
            _haste: a_haste,
            _hexproof: a_hexproof,
            _indestructible: a_indestructible,
            _lifelink: a_lifelink,
            _menace: a_menace,
            _prowess: a_prowess,
            _reach: a_reach,
            _trample: a_trample,
            _vigilance: a_vigilance,
            _ward: a_ward, // NEW
            // Keywords: Other
            _absorb: a_absorb,
            _affinity: a_affinity,
            _afflict: a_afflict,
            _afterlife: a_afterlife,
            _aftermath: a_aftermath,
            _amplify: a_amplify,
            _annihilator: a_annihilator,
            _ascend: a_ascend,
            _aura_swap: a_aura_swap,
            _awaken: a_awaken,
            _banding: a_banding,
            _battle_cry: a_battle_cry,
            _bestow: a_bestow,
            _bloodthirst: a_bloodthirst,
            _boast: a_boast, // NEW
            _bushido: a_bushido,
            _buyback: a_buyback,
            _cascade: a_cascade,
            _champion: a_champion,
            _changeling: a_changeling,
            _cipher: a_cipher,
            _companion: a_companion,
            _conspire: a_conspire,
            _convoke: a_convoke,
            _crew: a_crew,
            _cumulative_upkeep: a_cumulative_upkeep,
            _cycling: a_cycling,
            _dash: a_dash,
            _daybound: a_daybound, // NEW
            _decayed: a_decayed, // NEW
            _delve: a_delve,
            _dethrone: a_dethrone,
            _devoid: a_devoid,
            _devour: a_devour,
            _disturb: a_disturb, // NEW
            _dredge: a_dredge,
            _echo: a_echo,
            _embalm: a_embalm,
            _emerge: a_emerge,
            _entwine: a_entwine,
            _epic: a_epic,
            _escalate: a_escalate,
            _escape: a_escape,
            _eternalize: a_eternalize,
            _evoke: a_evoke,
            _evolve: a_evolve,
            _exalted: a_exalted,
            _exploit: a_exploit,
            _extort: a_extort,
            _fabricate: a_fabricate,
            _fading: a_fading,
            _fear: a_fear,
            _flanking: a_flanking,
            _flashback: a_flashback,
            _forecast: a_forecast,
            _foretell: a_foretell, // NEW
            _fortify: a_fortify,
            _frenzy: a_frenzy,
            _fuse: a_fuse,
            _graft: a_graft,
            _gravestorm: a_gravestorm,
            _haunt: a_haunt,
            _hideaway: a_hideaway,
            _horsemanship: a_horsemanship,
            _improvise: a_improvise,
            _infect: a_infect,
            _ingest: a_ingest,
            _intimidate: a_intimidate,
            _jump_start: a_jump_start,
            _kicker: a_kicker,
            _landwalk: a_landwalk,
            _level_up: a_level_up,
            _living_weapon: a_living_weapon,
            _madness: a_madness,
            _melee: a_melee,
            _mentor: a_mentor,
            _miracle: a_miracle,
            _modular: a_modular,
            _morph: a_morph,
            _mutate: a_mutate,
            _myriad: a_myriad,
            _nightbound: a_nightbound, // NEW
            _ninjutsu: a_ninjutsu,
            _offering: a_offering,
            _outlast: a_outlast,
            _overload: a_overload,
            _partner: a_partner,
            _persist: a_persist,
            _phasing: a_phasing,
            _poisonous: a_poisonous,
            _protection: a_protection,
            _provoke: a_provoke,
            _prowl: a_prowl,
            _rampage: a_rampage,
            _rebound: a_rebound,
            _recover: a_recover,
            _reinforce: a_reinforce,
            _renown: a_renown,
            _replicate: a_replicate,
            _retrace: a_retrace,
            _riot: a_riot, // NEW
            _ripple: a_ripple,
            _scavenge: a_scavenge,
            _shadow: a_shadow,
            _shroud: a_shroud,
            _skulk: a_skulk,
            _soulbond: a_soulbond,
            _soulshift: a_soulshift,
            _spectacle: a_spectacle, // NEW
            _splice: a_splice,
            _split_second: a_split_second,
            _storm: a_storm,
            _sunburst: a_sunburst,
            _surge: a_surge,
            _suspend: a_suspend,
            _totem_armor: a_totem_armor,
            _transfigure: a_transfigure,
            _transmute: a_transmute,
            _tribute: a_tribute,
            _undaunted: a_undaunted,
            _undying: a_undying,
            _unearth: a_unearth,
            _unleash: a_unleash,
            _vanishing: a_vanishing,
            _wither: a_wither,
            // Actions: Evergreen
            _activate: a_activate,
            _attach: a_attach,
            _cast: a_cast,
            _counter: a_counter,
            _copy: a_copy,
            _create: a_create,
            _cycle: a_cycle,
            _destroy: a_destroy,
            _discard: a_discard,
            _double: a_double,
            _draw: a_draw,
            _exchange: a_exchange,
            _exile: a_exile,
            _fight: a_fight,
            _mill: a_mill,
            _play: a_play,
            _reveal: a_reveal,
            _sacrifice: a_sacrifice,
            _scry: a_scry,
            _search: a_search,
            _shuffle: a_shuffle,
            _tap: a_tap,
            _untap: a_untap,
            // Actions: Other
            _adapt: a_adapt,
            _amass: a_amass,
            _bolster: a_bolster,
            _clash: a_clash,
            _detain: a_detain,
            _exert: a_exert,
            _explore: a_explore,
            _fateseal: a_fateseal,
            _goad: a_goad,
            _investigate: a_investigate,
            _learn: a_learn, // NEW
            _manifest: a_manifest,
            _meld: a_meld,
            _monstrosity: a_monstrosity,
            _populate: a_populate,
            _proliferate: a_proliferate,
            _regenerate: a_regenerate,
            _roll: a_roll, // NEW
            _support: a_support,
            _surveil: a_surveil,
            _transform: a_transform,
            _venture: a_venture, // NEW
            _vote: a_vote,
            // Ability words
            _adamant: a_adamant,
            _addendum: a_addendum,
            _battalion: a_battalion,
            _bloodrush: a_bloodrush,
            _channel: a_channel,
            _chroma: a_chroma,
            _cohort: a_cohort,
            _constellation: a_constellation,
            _converge: a_converge,
            _coven: a_coven, // NEW
            _delirium: a_delirium,
            _domain: a_domain,
            _eminence: a_eminence,
            _enrage: a_enrage,
            _fateful_hour: a_fateful_hour,
            _ferocious: a_ferocious,
            _formidable: a_formidable,
            _grandeur: a_grandeur,
            _hellbent: a_hellbent,
            _heroic: a_heroic,
            _imprint: a_imprint,
            _inspired: a_inspired,
            _join_forces: a_join_forces,
            _kinship: a_kinship,
            _landfall: a_landfall,
            _lieutenant: a_lieutenant,
            _magecraft: a_magecraft, // NEW
            _metalcraft: a_metalcraft,
            _morbid: a_morbid,
            _pack_tactics: a_pack_tactics, // NEW
            _radiance: a_radiance,
            _raid: a_raid,
            _rally: a_rally,
            _revolt: a_revolt,
            _spell_mastery: a_spell_mastery,
            _strive: a_strive,
            _sweep: a_sweep,
            _tempting_offer: a_tempting_offer,
            _threshold: a_threshold,
            _undergrowth: a_undergrowth,
            // Misc mechanics
            _alt_win: a_alt_win,
            _alt_loss: a_alt_loss,
            _anti_discard: a_anti_discard,
            _bounce: a_bounce,
            _burn: a_burn,
            _cantrip: a_cantrip,
            _counterspell: a_counterspell,
            _enter_the_battlefield: a_enter_the_battlefield,
            _looting: a_looting,
            _self_mill: a_self_mill,
            _rummaging: a_rummaging,
            _stat_increase: a_stat_increase,
            _stat_reduce: a_stat_reduce,
            _tutor: a_tutor,
            // Terminology
            _activated_ability: a_activated_ability,
            _activation_cmc0: a_activation_cmc0,
            _activation_cmc1: a_activation_cmc1,
            _activation_cmc2: a_activation_cmc2,
            _activation_cmc3: a_activation_cmc3,
            _activation_cmc4: a_activation_cmc4,
            _activation_cmc5: a_activation_cmc5,
            _activation_cmc6: a_activation_cmc6,
            _activation_cmc7: a_activation_cmc7,
            _activation_cmc8_plus: a_activation_cmc8_plus,
            _casting_ability: a_casting_ability,
            _passive_ability: a_passive_ability,
            _triggered_ability: a_triggered_ability,
            _require_tap: a_require_tap,
            _require_untap: a_require_untap,
            _give_colorless: a_give_colorless,
            _require_colorless: a_require_colorless,
            _give_mana: a_give_mana,
            _require_mana: a_require_mana,
            _energy: a_energy,
            _counters: a_counters,
            _plus1_counters: a_plus1_counters,
            _minus1_counters: a_minus1_counters,
            _mass_removal: a_mass_removal,
            _spot_removal: a_spot_removal,
            _target_artifact: a_target_artifact,
            _target_creature: a_target_creature,
            _target_card: a_target_card,
            _target_enchantment: a_target_enchantment,
            _target_land: a_target_land,
            _target_permanent: a_target_permanent,
            _target_planeswalker: a_target_planeswalker,
            _target_player: a_target_player,
            _1_removal: a_1_removal,
            _2_removal: a_2_removal,
            _3_removal: a_3_removal,
            _4_removal: a_4_removal,
            _5_removal: a_5_removal,
            _until_eot: a_until_eot,
            _until_condition: a_until_condition,
            // Identity: Evergreen
            _layout_Normal: c_layout_Normal,
            _layout_Split: c_layout_Split,
            _layout_Flip: c_layout_Flip,
            _layout_DoubleFaced: c_layout_Double,
            _ci_white: c_ci_white,
            _ci_blue: c_ci_blue,
            _ci_black: c_ci_black,
            _ci_red: c_ci_red,
            _ci_green: c_ci_green,
            _commander: c_commander,
            _legendary: c_legendary,
            // Identity: Other
            _historic: c_historic,
            _saga: c_saga,
            _equipment: c_equipment,
            _vehicle: c_vehicle,
            _aura: c_aura,
            _tribal: c_tribal,
            _snow: c_snow,
            _world: c_world
          };
          // console.log(SingleCard);

          // Add each card to the SeveralCards array IF it has a multiverseid
          // && it is NOT part of the non-sanctioned-for-play cards (schemes or un-sets)
          if (c_hasMultiverseId
            && EditionArray['type'].toLowerCase().match(/\b(un|promo)\b/g) === null
            && card.layout.toLowerCase().match(/(scheme|token|augment|vanguard|emblem|plane|host)/g) === null
            && card.isOnlineOnly !== true
            && card.isFoilOnly !== true
            ) {
                  SeveralCards.push(SingleCard);
                  // console.log(SeveralCards);
          }
        } else {
          const SingleCard: MtgCard = {
            // Unique card id
            uuid: card.uuid,
            // Pricing properties
            tcg_productId: tcg_productId, // '86'
            tcg_url: tcg_url, // 'http://store.tcgplayer.com/magic/portal-second-age/abyssal-nightstalker'

            // Edition properties
            releaseDate: EditionArray['releaseDate'], // YYYY-MM-DD
            edition: EditionArray['code'], // M10
            editionName: EditionArray['name'], // Magic 2010
            editionType: EditionArray['type'], // Expansion, Starter, Reprint, etc
            format: a_format, // [{'Legacy', 'Modern', 'Standard'}]

            // Required properties
            name: card.name, // Ajani Goldmane
            names: card.names, // {UUID for Farm, UUID for Market} - for layouts split, flip, aftermath or double-faced (and melds)
            imagePath: a_imagePath, // Image path based on http://theshitbin.com/mtgcards/
            rarity: card.rarity, // Mythic Rare
            cmc: card.cmc, // 4
            manaCost: card.manaCost, // {2}{W}{W} - preferably converted to 2WW
            permanent: a_permanent, // Calculated from colors
            subColor: card.colors, // {Green, Black} / {Blue} - fetched from colors
            mainType: card.types, // Creature / Land / Artifact / Instant / Planeswalker / Enchantment / Sorcery - fetched from types[]
            printings: cleanPrintings, // [NPH, KLD]
            // orderedPrintings: orderedPrintings, // All Core sets, Starters, Expansions, Commander, and Draft Innovation the card appears
            // Optional properties
            rulings: card.rulings, // [{date: 2011-06-01, text: 'you may still activate..'}, {date: ..., text: ...}]
            cardType: card.type, // {Creature - Elemental Hound} / {Land} - fetched from type
            pt: a_pt, // Power/Toughness
            loyalty: card.loyalty, // 4
            // text: card.text, //
            cleantext: a_text, // Card text with help-text in parenthesis removed
            value: a_value, // $ <$1, 2$ $1 < $3, 3$ $3 < $5, 4$ $5 < $10, 5$ $10 < $25, 6$ $25 < $50, 7$ $50+
            price: a_price,

            // Foreign data properties
            // Chinese Simplified
            name_CH: c_name_CH,
            cardType_CH: c_type_CH,
            cleantext_CH: c_text_CH,
            imagePath_CH: c_imagePath_CH,
            // German
            name_DE: c_name_DE,
            cardType_DE: c_type_DE,
            cleantext_DE: c_text_DE,
            imagePath_DE: c_imagePath_DE,
            // Spanish
            name_ES: c_name_ES,
            cardType_ES: c_type_ES,
            cleantext_ES: c_text_ES,
            imagePath_ES: c_imagePath_ES,
            // French
            name_FR: c_name_FR,
            cardType_FR: c_type_FR,
            cleantext_FR: c_text_FR,
            imagePath_FR: c_imagePath_FR,
            // Italian
            name_IT: c_name_IT,
            cardType_IT: c_type_IT,
            cleantext_IT: c_text_IT,
            imagePath_IT: c_imagePath_IT,
            // Japanese
            name_JP: c_name_JP,
            cardType_JP: c_type_JP,
            cleantext_JP: c_text_JP,
            imagePath_JP: c_imagePath_JP,
            // Korean
            name_KS: c_name_KS,
            cardType_KS: c_type_KS,
            cleantext_KS: c_text_KS,
            imagePath_KS: c_imagePath_KS,
            // Portuguese
            name_PT: c_name_PT,
            cardType_PT: c_type_PT,
            cleantext_PT: c_text_PT,
            imagePath_PT: c_imagePath_PT,
            // Russian
            name_RU: c_name_RU,
            cardType_RU: c_type_RU,
            cleantext_RU: c_text_RU,
            imagePath_RU: c_imagePath_RU,

            // All yes/no fields will be preceded with an underscore for visibility
            _isOnWishList: false,
            // Legality
            _standard_banned: a_standard_banned,
            _standard_restricted: a_standard_restricted,
            _standard_legal: a_standard_legal,
            _modern_banned: a_modern_banned,
            _modern_restricted: a_modern_restricted,
            _modern_legal: a_modern_legal,
            _commander_banned: a_commander_banned,
            _commander_restricted: a_commander_restricted,
            _commander_legal: a_commander_legal,
            _legacy_banned: a_legacy_banned,
            _legacy_restricted: a_legacy_restricted,
            _legacy_legal: a_legacy_legal,
            _vintage_banned: a_vintage_banned,
            _vintage_restricted: a_vintage_restricted,
            _vintage_legal: a_vintage_legal,
            // Modal action related abilities
            _layout_Split: c_layout_Split,
            _layout_Flip: c_layout_Flip,
            _layout_DoubleFaced: c_layout_Double,
          };
          // console.log(SingleCard);

          // Add each card to the SeveralCards array IF it has a multiverseid
          // && it is NOT part of the non-sanctioned-for-play cards (schemes or un-sets)
          if (c_hasMultiverseId
            && EditionArray['type'].toLowerCase().match(/\b(un|promo)\b/g) === null
            && card.layout.toLowerCase().match(/(scheme|token|augment|vanguard|emblem|plane|host)/g) === null
            && card.isOnlineOnly !== true
            && card.isFoilOnly !== true
            ) {
                  SeveralCards.push(SingleCard);
                  // console.log(SeveralCards);
          }
        }

      }, this); // End of CardsArrayForEach
      }, this); // End of AllEditionsArrayForEach
      // Return the SeveralCards array holding the card information for each card in the edition
      // console.log(SeveralCards);
      return SeveralCards;
    });
    }); // second subscribe ends here
    console.log ('cardCollection created');
    return SeveralCards;
  }
}
