import { MtgCard } from './../../../models/mtgcard.model';
import { Pipe, PipeTransform } from '@angular/core';

// This is an OR-pipe,
// which means that any filters coming in will generate total sum of all cards in the selection(s)
// This is intended for everyhing that is NOT card abilities prefixed with "_"

@Pipe({
    name: 'toughnessFilter',
    pure: true
})
export class ToughnessFilterPipe implements PipeTransform {
  transform(items: MtgCard[], filter: MtgCard, counter?: number): MtgCard[] {
    if (!items || !filter.toughness) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    const results = items.filter((item: MtgCard) => this.applyFilter(item, filter));
    // console.log('toughnessFilter updated');
    return results;
  }

  /**
   * Perform the filtering.
   *
   * @param {Card} card The card to compare to the filter.
   * @param {Card} filter The filter to apply.
   * @return {boolean} True if card satisfies filters, false if not.
   */
  applyFilter(card: MtgCard, filter: MtgCard): boolean {
    if (filter['toughness']) { // Apply filter only to c_filter.toughness
          if (filter['toughness'].indexOf(';' + card.toughness + ';') === -1) {
              // If the cards toughness is not found in the filters toughness string, return false
              return false;
          }
        return true;
    }
  }
}
