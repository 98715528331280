import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Language support
import { TranslateModule } from '@ngx-translate/core';
// Import all components
import { ComponentsModule } from './../../components/components';

// DragScroll for imageScroll
import { DragScrollModule } from 'ngx-drag-scroll';

// Directives (declarations)
import { MouseWheelDirective } from './../../directives/mouse-wheel-directive.directive';

// Import necessary pages
import { HomePage } from './home.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomePage
      }
    ]),
    TranslateModule.forChild(),
    ComponentsModule,
    DragScrollModule
  ],
  declarations: [
    HomePage,
    MouseWheelDirective
  ]
})
export class HomePageModule {}
